/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import HelpPopupLayout from '../components/HelpPopup/HelpPopupLayout.jsx';
import intl from 'intl';
import {SessionStore, MapPageStore} from '../stores';

export default {
  // This function maps a given route to a key for HelpPopupLayout.js
  // HelpPopupLayout then maps these keys to lists of help menu items.
  // This is needed because many routes can map to the same list of help topics.
  // i.e. workloads, workloadCreate, workloadEdit, etc all map to 'workloads' in HelpPopupLayout.js
  getHelpPopupKeyFromRoute(route, helpPopupTitle) {
    switch (route) {
      case 'map':
      case 'mapLevel':
      case 'prevMapLevel':
        if (helpPopupTitle === intl('Map.DraftView')) {
          return 'mapDraft';
        }

        if (helpPopupTitle === intl('Map.ReportedView')) {
          return 'map';
        }
      case 'appMap':
      case 'appMapLevel':
      case 'prevAppMapLevel':
        if (MapPageStore.getPolicyVersion() === 'draft') {
          return MapPageStore.getAppMapVersion() === 'policy' ? 'appMapDraft' : 'appMapVulnerabilityDraft';
        }

        if (MapPageStore.getPolicyVersion() === 'reported') {
          return MapPageStore.getAppMapVersion() === 'policy' ? 'appMap' : 'appMapVulnerability';
        }

      case 'segmentationTemplates.list':
      case 'segmentationTemplate.detail':
        return 'segmentationTemplates';
      case 'services':
      case 'services.item':
      case 'services.item.edit':
      case 'services.create':
        return 'services';
      case 'iplists':
      case 'iplists.item':
      case 'iplistCreate':
      case 'iplistEdit':
      case 'iplist':
        return 'iplists';
      case 'labelGroups':
      case 'labelGroupCreate':
      case 'labelGroupSummaryEdit':
      case 'labelGroupTab':
        return 'labelGroups';
      case 'userGroups':
      case 'userGroupCreate':
      case 'userGroup':
      case 'userGroupEdit':
        return 'userGroups';
      case 'virtualServers':
      case 'virtualServerSummaryEdit':
      case 'virtualServerProvidersEdit':
      case 'virtualServerTab':
        return 'virtualServers';
      case 'securitysettings':
        return 'settings';
      case 'pending':
      case 'provision':
      case 'revert':
        return 'provision';
      case 'workloads':
      case 'workloadCreate':
      case 'workloadEdit':
      case 'workloadServices':
      case 'workloadRules':
        return 'workloads';
      case 'workloads.item':
        if (SessionStore.areVulnerabilitiesEnabled()) {
          return 'workloadDetailsVulnerabilities';
        }

        return 'workloadDetails';
      case 'workloadVulnerabilities':
        return 'workloadVulnerabilities';
      case 'virtualServices':
      case 'virtualServiceCreate':
      case 'virtualService':
      case 'virtualServiceAddWorkloads':
      case 'virtualServiceWorkloads':
      case 'virtualServices.item.edit':
        return 'virtualServices';
      case 'labels':
      case 'labelCreate':
      case 'labelEdit':
      case 'label':
        return 'labels';
      case 'pairingProfiles':
      case 'pairingProfiles.pair':
        return 'pairingProfiles';
      case 'loadBalancers':
      case 'loadBalancerEdit':
      case 'loadBalancerTab':
      case 'loadBalancersCreate':
        return 'loadBalancers';
      case 'blockedTraffic':
      case 'blockedTrafficDetail':
        return 'blockedTraffic';
      case 'orgevents':
      case 'orgevent':
        return 'orgevents';
      case 'connectivity':
        return 'connectivity';
      case 'rulesetRules':
        return 'rulesetRules';
      case 'rulesets':
      case 'rulesetCreate':
      case 'rulesetClone':
      case 'rulesetSummaryEdit':
      case 'rulesetSummary':
        return 'rulesets';
      case 'policygenerator':
      case 'intraScopePreview':
      case 'intraScopeSuccess':
      case 'extraScopeChoose':
      case 'extraScopePreview':
      case 'extraScopeSuccess':
      case 'ipListChoose':
      case 'ipListPreview':
      case 'ipListSuccess':
      case 'ringFenceChoose':
      case 'ringFencePreview':
      case 'ringFenceSuccess':
        return 'policygenerator';
      case 'intraScopeConfigure':
        return SessionStore.areVulnerabilitiesEnabled()
          ? 'policygeneratorConfigureIntraScopeVulnerability'
          : 'policygenerator';
      case 'extraScopeConfigure':
        return SessionStore.areVulnerabilitiesEnabled()
          ? 'policygeneratorConfigureExtraScopeVulnerability'
          : 'policygenerator';
      case 'ipListConfigure':
        return SessionStore.areVulnerabilitiesEnabled()
          ? 'policygeneratorIpListConfigureVulnerability'
          : 'policygenerator';
      case 'appGroupWorkloads':
        return SessionStore.areVulnerabilitiesEnabled() ? 'appGroupDetailsVulnerability' : 'appGroupDetails';
      case 'appGroupContainerWorkloads':
        return 'appGroupContainerWorkloads';
      case 'appGroupVirtualServices':
        return 'appGroupVirtualServices';
      case 'appGroupPairingProfiles':
        return 'appGroupDetailsPP';
      case 'appGroupRules':
        return 'appGroupDetailsRules';
      case 'appGroupVulnerabilities':
        return 'appGroupVulnerabilities';
      case 'appGroupPolicyGenerator':
        return 'appGroupPolicyGenerator';
      case 'appGroupExplorer':
        return 'appGroupExplorer';
      case 'explorer':
        return 'explorer';
      case 'rbacGlobalList':
        return 'globalRoles';
      case 'rbacScopedList':
        return 'scopedRoles';
      case 'ssoPicker':
      case 'samlConfigs':
      case 'samlConfigDetail':
      case 'samlConfigEdit':
        return 'sso';
      case 'appGroups':
        return SessionStore.areVulnerabilitiesEnabled() ? 'appGroupsVulnerabilities' : 'appGroups';
      case 'appGroupType':
        return 'appGroupType';
      case 'health.list':
      case 'healthDetail':
        return 'pceHealth';
      case 'apikeys':
        return 'apiKeys';
      case 'ruleSearch':
        return 'ruleSearch';
      default:
        return 'noRoute';
    }
  },

  getHelpPopupItems(helpPopupKey) {
    return HelpPopupLayout[helpPopupKey] || HelpPopupLayout.noRoute;
  },
};
