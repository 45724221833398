/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import * as PropTypes from 'prop-types';
import {Children, Fragment, useRef, Component, isValidElement} from 'react';
import type {JSXElementConstructor, ReactNode, ComponentType, ReactElement} from 'react';
import type {TruthFull} from './types';

export type MutuallyExclusiveTrueValidator = (
  props: {[prop: string]: unknown},
  propName: string,
  componentName: string,
) => Error | undefined;

/**
 * Asynchronous version of setState.
 * Returns promise that is resolved once setState second parameter is called (after componentDidUpdate)
 *
 * @param newState Regular setState parameter, that can be object or function
 * @param Instance (this) of your component
 * @returns Promise resolved with updated state object
 */
export const setStateAsync = (newState: {[key: string]: unknown}, instance: Component): Promise<unknown> =>
  new Promise(resolve => {
    instance.setState(newState, () => resolve(instance.state));
  });

/**
 * Returns an array of not false/null/undefined children with unwrapped (flattened) Fragment
 * Can get a function as children to call
 *
 * @param children Children from props
 * @param options
 * @param {Object|Function|String} [options.match] If only components of specified type should be returned
 * @returns
 */
export function unwrapChildren<T extends ReactNode>(
  children: T | T[] | (() => T | T[]),
  options: {
    match?: string | JSXElementConstructor<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  } = {},
): TruthFull<T>[] {
  let actualChildren: T | T[];

  if (typeof children === 'function') {
    actualChildren = children() as T | T[];
  } else {
    actualChildren = children;
  }

  return (Array.isArray(actualChildren) ? actualChildren : (Children.toArray(actualChildren) as T[])).reduce(
    (result: TruthFull<T>[], child) => {
      if (child !== false && child !== null && child !== undefined) {
        if (isValidElement(child) && child.type === Fragment) {
          const unWrap = unwrapChildren(child.props.children, options);

          result.push(...unWrap);
        } else if (options.match === undefined || (isValidElement(child) && child.type === options.match)) {
          result.push(child as TruthFull<T>);
        }
      }

      return result;
    },
    [],
  );
}

/**
 * PropType to validate that if target prop is true, all other specified props are not true
 *
 * @param List of other boolean prop names that should be mutually exclusive with current one
 * @returns PropTypes validator or TypeError
 */
export const mutuallyExclusiveTrueProps = (...exclusiveProps: string[]): TypeError | MutuallyExclusiveTrueValidator => {
  if (!exclusiveProps.length) {
    throw new TypeError('Exclusive true props must be specified');
  }

  if (exclusiveProps.some(x => typeof x !== 'string')) {
    throw new TypeError('Exclusive true props must be strings');
  }

  return function mutuallyExclusiveTrueValidator(
    props: {[prop: string]: unknown},
    propName: string,
    componentName: string,
  ): Error | undefined {
    const value = props[propName];

    if (value !== undefined && typeof value !== 'boolean') {
      return new Error(`Prop '${propName}' supplied to '${componentName}' should be a Boolean`);
    }

    if (value === true) {
      const otherTrueProps = exclusiveProps.filter(prop => props[prop] === true);

      if (otherTrueProps.length > 1) {
        return new Error(
          `If in ${componentName} '${propName}' prop is true, '${intl.list(
            otherTrueProps.map(p => `'${p}'`),
          )}' can't be set to true`,
        );
      }
    }
  };
};

/**
 * Gets list of boolean prop names and enforces that only one of them is allowed to be true
 * Returns object of PropTypes for given prop names that should be mixed into target propTypes
 *
 * @param List of other boolean prop names that should be mutually exclusive with current one
 * @returns Object of props that should be mixed into propTypes
 */
export const mutuallyExclusiveTruePropsSpread = (...exclusiveProps: string[]): {[key: string]: unknown} | Error => {
  if (exclusiveProps.length < 2) {
    throw new TypeError('You should specify two or more exclusive true props');
  }

  if (exclusiveProps.some(x => typeof x !== 'string')) {
    throw new TypeError('Exclusive true props must be strings');
  }

  let checkedCounter = 0;

  function mutuallyExclusiveTruePropsSpreadValidator(
    props: {[prop: string]: unknown},
    propName: string,
    componentName: string,
  ): Error | undefined {
    const value = props[propName];

    if (checkedCounter === exclusiveProps.length) {
      checkedCounter = 0;
    }

    checkedCounter++;

    if (value !== undefined && typeof value !== 'boolean') {
      return new Error(`Prop '${propName}' supplied to '${componentName}' should be a Boolean`);
    }

    if (checkedCounter === 1) {
      const trueProps = exclusiveProps.filter(prop => props[prop] === true);

      if (trueProps.length > 1) {
        return new Error(
          `Component ${componentName} can't have ${intl.list(
            trueProps.map(p => `'${p}'`),
          )} props to be true at the same time`,
        );
      }
    }
  }

  return exclusiveProps.reduce((props, prop) => {
    props[prop] = mutuallyExclusiveTruePropsSpreadValidator;

    return props;
  }, {} as {[key: string]: unknown});
};

/**
 * React hook to compare new value with the previous one, and return the previous one if they are deeply equal.
 * Useful to compare complex objects in hooks dependencies to bail out of their updates.
 *
 * @param value
 * @returns
 */
export const useDeepCompareMemo = <T>(value: T): T => {
  const ref = useRef<T>();

  if (!_.isEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current!;
};

/**
 * PropType to describe a ref prop
 */
export const PropTypeRef = PropTypes.oneOfType([
  // Either a function
  PropTypes.func,
  // Or any value saved in the `current` prop
  PropTypes.shape({current: PropTypes.any}),
]);

/**
 * A conventional way to get the HTML element of a component
 * @param node the react element
 * @returns {HTMLElement}
 */
export const getNativeElement = (
  node?: HTMLElement | (ReactElement & WithElement) | null,
): HTMLElement | null | undefined => {
  if (!node) {
    return node;
  }

  // in the case of <Button>, the HTML element is in `button`
  // in most cases, there is an `element` instance member for HTML element
  // in all other cases, we assume the node itself is the HTML element (native html tags e.g. <div>)
  return node instanceof HTMLElement ? node : node.button ?? node.element;
};

/**
 * Conventionally some React component class exposes the underlying DOM node through an `element` property
 */
export type WithElement<E extends Element = HTMLElement> = {
  element?: E | null;
  button?: E | null;
};

/**
 * Check if an object is a react element of certain component type
 * @param obj The object to be tested
 * @param componentType The react component type, either the component function or component class
 * @returns boolean
 */
export const isReactElementOf = <P>(obj: ReactNode, componentType: ComponentType<P>): obj is ReactElement<P> => {
  return isValidElement(obj) && obj.type === componentType;
};
