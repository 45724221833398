/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {Component} from 'react';
import cx from 'classnames';
import intl from 'intl';
import actionCreators from '../../../actions/actionCreators';
import {RestApiUtils, RenderUtils, PolicyGeneratorUtils, GraphDataUtils, ServiceUtils} from '../../../utils';
import {Button, RadioGroup, AlertDialog, Icon} from '../..';
import {SelectRuleset, SelectEndpoint, SelectService} from '.';
import {SessionStore, TrafficFilterStore} from '../../../stores';

export default class AddRulePanel extends Component {
  constructor(props) {
    super(props);

    const data = props.data.form;

    // Save the labels of the group or provider scope
    this.labels = data.labels;

    if (data.ruleType !== 'ringfencing') {
      const {traffic} = this.props.data.form;
      const {target} = traffic;

      this.selected = {};
      this.labels = target.cluster ? target.cluster.labels : target.labels;
      this.extraScope = RenderUtils.isInterappTraffic(traffic);
      this.virtualServerLink =
        RenderUtils.isToVirtualServerTraffic(traffic) || RenderUtils.isToVirtualServiceTraffic(traffic);
    }

    this.labels = _.reduce(
      this.labels,
      (result, label) => {
        if (label.key) {
          result[label.key] = label;
        }

        return result;
      },
      {},
    );

    this.handleSave = this.handleSave.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleRulesetSelect = this.handleRulesetSelect.bind(this);
    this.handleEndpointSelect = this.handleEndpointSelect.bind(this);
    this.handleServiceSelect = this.handleServiceSelect.bind(this);
    this.handleSecConnectChange = this.handleSecConnectChange.bind(this);

    this.state = {
      selected: {
        service: this.virtualServerLink ? null : this.getService(),
        ruleset: {},
        provider: {},
        consumer: {},
        secureConnect: false,
      },
    };
  }

  getService() {
    if (this.props.data.form.ruleType === 'ringfencing' || this.props.data.form.ruleType === 'allServices') {
      return {services: [PolicyGeneratorUtils.getAllServices()]};
    }

    const selectedService = this.props.data.form.trafficSelection.service;

    const matchedService = PolicyGeneratorUtils.buildService(selectedService);
    const portService = ServiceUtils.isIcmp(selectedService?.protocol)
      ? [PolicyGeneratorUtils.getAllServices()]
      : PolicyGeneratorUtils.buildPortService(selectedService);
    const newService = [PolicyGeneratorUtils.buildNewService(selectedService)];

    // Service will be edited, port, newService, and match will stay as references
    // ICMP cannot use ports so a new service must be created if the default ICMP service is deleted (if user is scoped, set services to all services)
    let services = matchedService;

    if (!services) {
      services = portService;

      if (SessionStore.isGlobalEditEnabled()) {
        services = newService;
      }
    }

    const port =
      selectedService && newService
        ? {
            [[ServiceUtils.getPort(selectedService), selectedService.protocol].join(',')]:
              PolicyGeneratorUtils.buildPort(newService[0], selectedService),
          }
        : null;
    const match = matchedService;

    if (services && !services[0].href) {
      services[0].description = '';
    }

    return {services, port, newService, match, portService};
  }

  getMergedRule(selected) {
    const {ruleset, service, provider, consumer, secConnect} = selected;
    const selectedProvider = Object.values(provider)[0];
    const selectedConsumer = Object.values(consumer)[0];

    if (_.isEmpty(selectedProvider) || _.isEmpty(selectedConsumer) || !ruleset || !ruleset.rules) {
      return;
    }

    let merged;

    ruleset.rules.forEach(rule => {
      // Make sure the rule is live

      const isRuleLive =
        rule.enabled &&
        rule.update_type !== 'delete' &&
        // note: not for virtual server only rules
        rule.resolve_labels_as.consumers.includes('workloads') &&
        rule.resolve_labels_as.providers.includes('workloads') &&
        // Services match
        rule.ingress_services &&
        rule.ingress_services.length === 1 &&
        service &&
        service.services &&
        service.services.length &&
        rule.ingress_services[0].port === service.services[0].port &&
        rule.ingress_services[0].protocol === service.services[0].protocol &&
        rule.ingress_services[0].href === service.services[0].href &&
        // intra/extra scope matches
        rule.unscoped_consumers === this.extraScope &&
        // Secure connect matches
        ((rule.sec_connect && secConnect) || (!rule.sec_connect && !secConnect));

      if (isRuleLive) {
        // Look for matching provider with matching consumer scope
        const providerMatch =
          PolicyGeneratorUtils.getEndpointKey(rule.providers) ===
            PolicyGeneratorUtils.getEndpointKey(selectedProvider) &&
          PolicyGeneratorUtils.getEndpointScopeKey(rule.consumers) ===
            PolicyGeneratorUtils.getEndpointScopeKey(selectedConsumer);

        // Look for matching consumer with matching provider scope
        const consumerMatch =
          PolicyGeneratorUtils.getEndpointKey(rule.consumers) ===
            PolicyGeneratorUtils.getEndpointKey(selectedConsumer) &&
          PolicyGeneratorUtils.getEndpointScopeKey(rule.providers) ===
            PolicyGeneratorUtils.getEndpointScopeKey(selectedProvider);

        if (providerMatch) {
          let consumers;
          let removedConsumers;
          const consumerType = Object.keys(rule.consumers[0])[0];
          const selectedConsumerType = Object.keys(selectedConsumer[0])[0];

          if (consumerType === selectedConsumerType) {
            consumers = [
              ...rule.consumers,
              ...PolicyGeneratorUtils.findEndpointLabels(selectedConsumer, ['role', 'other']),
            ];
          } else if (selectedConsumerType === 'actors') {
            // If new item is actor=ams, remove other items
            removedConsumers = rule.consumers;
            consumers = selectedConsumer;
          }

          if (consumers) {
            merged = {};
            // Old consumers for display
            merged.consumer = PolicyGeneratorUtils.findEndpointLabels(rule.consumers, ['role', 'other']);
            // Removed consumers for display
            merged.removed = {consumer: removedConsumers};
            merged.rule = {...rule, consumers};
          }
        } else if (consumerMatch) {
          let providers;
          let removedProviders;
          const providerType = Object.keys(rule.providers[0])[0];
          const selectedProviderType = Object.keys(selectedProvider[0])[0];

          if (providerType === selectedProviderType) {
            providers = [
              ...rule.providers,
              ...PolicyGeneratorUtils.findEndpointLabels(selectedProvider, ['role', 'other']),
            ];
          } else if (selectedProviderType === 'actors') {
            // If new item is actor=ams, remove other items
            removedProviders = rule.providers;
            providers = selectedProvider;
          }

          if (providers) {
            merged = {};
            // Old providers for display
            merged.provider = PolicyGeneratorUtils.findEndpointLabels(rule.providers, ['role', 'other']);
            // Removed providers for display
            merged.removed = {provider: removedProviders};
            merged.rule = {...rule, providers};
          }
        }
      }
    });

    return merged;
  }

  showRbacError(error) {
    const body = _.get(error, 'parsedBody[0]');

    // All other errors are handled by the ErrorStore
    if (error.status === 406 && body && body.token.includes('rbac')) {
      actionCreators.openDialog(<AlertDialog message={body.message} />);
    }
  }

  async handleSave() {
    const {service, ruleset, consumer, provider, secureConnect} = this.state.selected;
    const {resolveLabelsAs} = this.state;
    let selectedService;
    let selectedIpList;

    if (service && service.services.length) {
      selectedService = {...service.services[0]};

      // Create the service first
      if (!selectedService.href && !selectedService.hasOwnProperty('port')) {
        const response = await RestApiUtils.services.create(selectedService, 'draft', false);

        selectedService = response.body;
        RestApiUtils.services.getInstance(selectedService.href.split('/').pop(), 'draft', true);
      }
    }

    const providers = this.state.merged ? this.state.merged.rule.providers : Object.values(provider)[0];
    const consumers = Object.values(consumer)[0];

    // For dns providers create an IP List here
    for (const [index, provider] of providers.entries()) {
      if (provider.ip_list && !provider.ip_list.href) {
        const response = await RestApiUtils.ipLists.create(provider.ip_list, 'draft', true);

        selectedIpList = response.body;
        RestApiUtils.ipLists.getInstance(selectedIpList.href.split('/').pop(), 'draft', true);
        await new Promise(resolve => setTimeout(resolve, 2000));
        await GraphDataUtils.loadLevelTraffic('rebuild', TrafficFilterStore.getTransmissionFilters());

        providers[index] = {ip_list: {href: selectedIpList.href}};
      }
    }

    // Build the rule
    const rule = {
      consuming_security_principals: [],
      enabled: true,
      sec_connect: secureConnect,
      resolve_labels_as: {
        providers:
          resolveLabelsAs.provider ||
          (providers[0].hasOwnProperty('virtual_service') ? ['virtual_services'] : ['workloads']),
        consumers:
          resolveLabelsAs.consumer ||
          (consumers[0].hasOwnProperty('virtual_service') ? ['virtual_services'] : ['workloads']),
      },
      unscoped_consumers: this.extraScope,
      providers,
      consumers,
      ingress_services: [],
    };

    if (selectedService) {
      rule.ingress_services = selectedService.href
        ? [{href: selectedService.href}]
        : [{port: selectedService.port, proto: selectedService.proto}];
    }

    // If we defaulted to workloads, but there are no services, change the default to virtual services
    if (!rule.ingress_services.length) {
      rule.resolve_labels_as.providers = ['virtual_services'];
    }

    // For ring fence rules always add virtual services
    if (
      this.props.data.form.ruleType === 'ringfencing' &&
      rule.providers[0] &&
      rule.providers[0].actors === 'ams' &&
      !secureConnect
    ) {
      rule.resolve_labels_as.providers = ['virtual_services', 'workloads'];
    }

    if (
      this.props.data.form.ruleType === 'ringfencing' &&
      rule.consumers[0] &&
      rule.consumers[0].actors === 'ams' &&
      !secureConnect
    ) {
      rule.resolve_labels_as.consumers = ['virtual_services', 'workloads'];
    }

    let rulesetId;
    let result;

    try {
      if (ruleset.href) {
        const {merged} = this.state;

        rulesetId = ruleset.href.split('/').pop();

        if (merged) {
          const id = merged.rule.href.split('/').pop();

          //Update the rule in the existing Ruleset
          await RestApiUtils.secRule.update(
            rulesetId,
            id,
            PolicyGeneratorUtils.getStrippedRules([merged.rule], true)[0],
          );
        } else {
          //Create the rule in the existing Ruleset
          await RestApiUtils.secRules.create(rulesetId, PolicyGeneratorUtils.getStrippedRules([rule])[0]);
        }
      } else {
        // Create a new Ruleset with the rule
        result = await RestApiUtils.ruleSets.create(
          PolicyGeneratorUtils.getStrippedRuleset(
            {
              ...ruleset,
              enabled: true,
              rules: [rule],
            },
            true,
          ),
        );
      }

      // Load the final version of the ruleset
      RestApiUtils.ruleSets.getInstance(rulesetId || result.body.href.split('/').pop(), 'draft', true);
      RestApiUtils.secPolicies.dependencies();

      actionCreators.updateRingFenceRules();
      actionCreators.closeActionItem();
    } catch (error) {
      // Handle RBAC Error
      this.showRbacError(error);
    }
  }

  handleCancel() {
    actionCreators.closeActionItem();
  }

  handleEdit(type) {
    this.setState({edit: type});
  }

  handleRulesetSelect(ruleset) {
    this.setState(prevState => {
      const selected = {...prevState.selected, ruleset};
      const merged = this.getMergedRule(selected);

      return {merged, selected};
    });
  }

  handleEndpointSelect(type, selectedEndpoint, resolveAs) {
    this.setState(prevState => {
      let selected = {...prevState.selected, [type]: selectedEndpoint};
      const merged = this.getMergedRule(selected);
      const resolveLabelsAs = {...prevState.resolveLabelsAs, [type]: resolveAs};

      if (type === 'provider' && _.isEqual(resolveAs, ['virtual_services'])) {
        selected = {...selected, service: {services: []}};
      }

      return {merged, selected, resolveLabelsAs};
    });
  }

  handleServiceSelect(services) {
    if (services) {
      this.setState(prevState => {
        let selected = {...prevState.selected, service: {...prevState.selected.service, services}};

        if (prevState.resolveLabelsAs && prevState.resolveLabelsAs.provider === ['virtual_services']) {
          selected = {...prevState.selected, service: {services: []}};
        }

        const merged = this.getMergedRule(selected);

        return {merged, selected};
      });
    }
  }

  handleSecConnectChange(evt) {
    const secConnect = evt.target.value;

    this.setState(prevState => {
      const selected = {...prevState.selected, secureConnect: secConnect === 'on'};
      const merged = this.getMergedRule(selected);

      return {merged, selected};
    });
  }

  renderEndpoint(type) {
    const {edit, selected, merged} = this.state;
    const {ruleset} = selected;

    // If the Ruleset has any scope, and the traffic is extrascope, the consumers are extrascope
    const title =
      type === 'consumer'
        ? this.extraScope && ruleset && ruleset.scopes && ruleset.scopes[0].length
          ? intl('Map.ExtraScopeConsumers')
          : intl('Common.Consumers')
        : intl('Common.Providers');

    // Allow overflow for the Objectselector
    const classNames = cx('MapInfoPanel-Row-Value', {
      'MapInfoPanel-Row-Value-Overflow': edit === type,
      'MapFormPanel-Wrap': edit !== type,
    });

    return (
      <tr className="MapInfoPanel-Row" data-tid="map-info-panel-row">
        <td>
          <div className="MapFormPanel-Row">
            <div className="MapInfoPanel-Row-Label" data-tid="map-info-panel-row-label">
              {title}
            </div>
            <div className={classNames} data-tid="map-info-panel-row-value">
              <SelectEndpoint
                data={this.props.data.form}
                labels={this.labels}
                ruleset={ruleset}
                selectedEndpoint={selected[type]}
                onSelect={this.handleEndpointSelect}
                extraScope={this.extraScope}
                type={type}
                editable={!edit}
                edit={edit === type}
                onEdit={this.handleEdit}
                merged={merged && merged[type]}
                removed={merged && merged.removed[type]}
              />
            </div>
          </div>
        </td>
      </tr>
    );
  }

  renderRulesets() {
    const {selected, edit} = this.state;
    // Allow overflow for the Objectselector
    const classNames = cx('MapInfoPanel-Row-Value', {
      'MapInfoPanel-Row-Value-Overflow': edit === 'ruleset',
    });

    return (
      <tr className="MapInfoPanel-Row" data-tid="map-info-panel-row">
        <td>
          <div className="MapFormPanel-Row">
            <div className="MapInfoPanel-Row-Label" data-tid="map-info-panel-row-label">
              {intl('Rulesets.AddTo')}
            </div>
            <div
              className={classNames}
              title={selected.ruleset && selected.ruleset.name}
              data-tid="map-info-panel-row-value"
            >
              <SelectRuleset
                groupTypes={this.props.data.form.groupTypes}
                selectedRuleset={selected.ruleset}
                onSelect={this.handleRulesetSelect}
                labels={this.labels || {}}
                editable={!edit}
                edit={edit === 'ruleset'}
                onEdit={this.handleEdit}
                withScopes={SessionStore.isUserScoped()}
              />
            </div>
          </div>
        </td>
      </tr>
    );
  }

  renderScopes() {
    const {ruleset} = this.state.selected;

    if (ruleset && ruleset.scopes) {
      const scopes = RenderUtils.getScope(ruleset);

      return (
        <tr className="MapInfoPanel-Row" data-tid="map-info-panel-row">
          <td>
            <div className="MapFormPanel-Row">
              <div className="MapInfoPanel-Row-Label" data-tid="map-info-panel-row-label">
                {intl('Common.Scopes')}
              </div>
              <div
                className="MapInfoPanel-Row-Value MapFormPanel-Wrap MapFormPanel-Overflow-Visible"
                data-tid="map-info-panel-row-value"
              >
                {scopes}
              </div>
            </div>
          </td>
        </tr>
      );
    }
  }

  renderServices() {
    const {selected, edit} = this.state;

    let service = null;

    if (this.virtualServerLink || (selected.service.services && !selected.service.services.length)) {
      service = intl('Rulesets.Rules.DerivedFromProviderVirtualServices');
    } else {
      const serviceName = selected.service.services
        ? selected.service.services[0].name
        : selected.service.newService
        ? selected.service.newService[0].name
        : null;

      service = (
        <SelectService
          row={selected.service}
          name={serviceName}
          onClose={this.handleServiceSelect}
          editable={true}
          edit={edit === 'service'}
          onEdit={this.handleEdit}
          index={0}
        />
      );
    }

    // Allow overflow for the Objectselector
    const classNames = cx('MapInfoPanel-Row-Value', {
      'MapInfoPanel-Row-Value-Overflow': edit === 'service',
    });

    return (
      <tr className="MapInfoPanel-Row" data-tid="map-info-panel-row">
        <td>
          <div className="MapFormPanel-Row">
            <div className="MapInfoPanel-Row-Label" data-tid="map-info-panel-row-label">
              {intl('Common.Service')}
            </div>
            <div className={classNames} data-tid="map-info-panel-row-value">
              {service}
            </div>
          </div>
        </td>
      </tr>
    );
  }

  renderSecureConnect() {
    const {secureConnect} = this.state.selected;
    const data = this.props.data.form;
    let disabled = false;

    if (data.ruleType !== 'ringfencing') {
      const {source, target} = data.traffic;

      disabled = RenderUtils.isInternetIpList(source) || RenderUtils.isInternetIpList(target);
    }

    if (disabled) {
      return null;
    }

    const radioGroupData = [
      {key: 'on', value: intl('Common.On')},
      {key: 'off', value: intl('Common.Off')},
    ];

    return (
      <tr className="MapInfoPanel-Row" data-tid="map-info-panel-row">
        <td>
          <div className="MapFormPanel-Row">
            <div className="MapInfoPanel-Row-Label" data-tid="map-info-panel-row-label">
              {intl('Common.SecureConnect')}
            </div>
            <div className="MapInfoPanel-Row-Value" data-tid="map-info-panel-row-value">
              <RadioGroup
                data={radioGroupData}
                value={secureConnect ? 'on' : 'off'}
                format="horizontal"
                onChange={this.handleSecConnectChange}
              />
            </div>
          </div>
        </td>
      </tr>
    );
  }

  render() {
    const {info, type} = this.props.data;
    // allow traffic edit for: in-scope, global user, traffic target is writable, traffic target is global and traffic source is writable
    const providerIsWritable = info.targets && info.targets.length && info.targets[0].caps?.rulesets.includes('write');
    const providerHasNoCaps = !info.targets || !info.targets.length || !info.targets[0].caps;
    const sourceIsWritable = info.sources && info.sources.length && info.sources[0].caps?.rulesets.includes('write');

    const rulesetsIsWritable =
      !SessionStore.isSuperclusterMember() &&
      ((type === 'traffic' && (providerIsWritable || (providerHasNoCaps && sourceIsWritable))) ||
        info.caps?.rulesets.includes('write') ||
        SessionStore.isGlobalEditEnabled());

    const {merged, edit} = this.state;
    let title = intl('Rule.Add');
    let message;

    if (merged) {
      const endpoint = merged.consumer ? intl('Common.Consumers') : intl('Common.Providers');

      if (merged.removed.consumer || merged.removed.provider) {
        title = intl('Map.OverwriteEndpoint', {endpoint});
        message = (
          <span>
            {intl('Rule.MergeMessage', {endpoint})}
            <div className="FormPanel-Msg--warning">{intl('Map.OverwriteEndpointMessage', {endpoint})}</div>
          </span>
        );
      } else {
        title = intl('Rule.Merge');
        message = intl('Rule.MergeMessage', {endpoint});
      }
    }

    return (
      <div className="FormPanel">
        <div className="FormPanel-Title FormPanel-Draft" onClick={this.handleCancel}>
          <Icon name="caret-left" size="xlarge" data-tid="comp-icon-close" />
          {title}
        </div>
        {message ? <div className="FormPanel-Msg">{message}</div> : null}
        <table className="MapInfoPanel MapInfoPanel--Rule">
          <tbody>
            {this.renderRulesets()}
            {this.renderScopes()}
            {this.renderEndpoint('consumer')}
            {this.renderSecureConnect()}
            {this.renderServices()}
            {this.renderEndpoint('provider')}
          </tbody>
        </table>
        <div className="FormPanel-Button-Bar">
          <div className="FormPanel-Buttons">
            <Button text={intl('Common.Cancel')} type="secondary" onClick={this.handleCancel} tid="cancel" />
            <Button
              text={intl('Common.Save')}
              disabled={Boolean(edit) || !rulesetsIsWritable}
              onClick={this.handleSave}
              tid="save"
            />
          </div>
        </div>
      </div>
    );
  }
}
