/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import React from 'react';
import cx from 'classnames';
import intl from 'intl';
import SessionStore from '../../stores/SessionStore';
import actionCreators from '../../actions/actionCreators';
import {Navigation, State, Link} from 'react-router';
import {MapPageStore} from '../../stores';
import {Icon, ObjectSelector, Tooltip, Vulnerability} from '..';
import _ from 'lodash';
import {ServiceUtils} from '../../utils';
import UserMixin from '../../mixins/UserMixin';
import {Address6} from 'ip-address';
import {DomainCreateDialog} from '../../modals';
import RenderUtils from '../../utils/RenderUtils';

const MAX_ADDRESSES = 500;

export default React.createClass({
  mixins: [Navigation, UserMixin, State],

  getInitialState() {
    return {items: {}};
  },

  componentDidMount() {
    if (this.input) {
      this.input.refs.itemInput.click();
    }
  },

  handleClose() {
    actionCreators.closeActionItem();
  },

  renderMatchingService() {
    if (
      this.props &&
      this.props.data &&
      this.props.data.formData &&
      Array.isArray(this.props.data.formData.matchedServices)
    ) {
      return this.props.data.formData.matchedServices.map((service, index) => (
        <Link
          className="FormPanel-Details-Margin CommandPanel-Link"
          to="services.item"
          params={{id: service.href.split('/').pop(), pversion: 'draft'}}
          key={index}
        >
          <span> {service.name} </span>
        </Link>
      ));
    }
  },

  renderVulnerability(vulnerability, type) {
    if (type === 'vulnerability') {
      return (
        <div className="FormPanel-Details">
          <div className="FormPanel-Details-Row FormPanel-Details-Arrange">
            <span className="FormPanel-Details-Label">
              <Tooltip
                content={intl('Help.Desc.VulnerabilityName')}
                position="location-group"
                width={372}
                location="fixed"
              >
                {intl('Common.Name')}
              </Tooltip>
            </span>
            <span className="FormPanel-Details-Value-Wrap">{vulnerability.details.name}</span>
          </div>
          <div className="FormPanel-Details-Row FormPanel-Details-Arrange">
            <span className="FormPanel-Details-Label">
              <Tooltip content={intl('Help.Desc.VEScore')} position="location-group" width={372} location="fixed">
                {intl('Vulnerability.VEScore')}
              </Tooltip>
            </span>
            <span className="FormPanel-Details-Value">
              <Vulnerability vulnerability={vulnerability} />
            </span>
          </div>
          <div className="FormPanel-Details-Row FormPanel-Details-Arrange">
            <span className="FormPanel-Details-Label">
              <Tooltip
                content={intl('Help.Desc.VulnerabilityPortProtocol')}
                position="location-group"
                width={372}
                location="fixed"
              >
                {intl('Port.Protocol')}
              </Tooltip>
            </span>
            <span className="FormPanel-Details-Value">
              {vulnerability.port || vulnerability.protocol
                ? `${vulnerability.port ? vulnerability.port : ''} ${
                    vulnerability.protocol ? ServiceUtils.lookupProtocol(vulnerability.protocol) : ''
                  }`
                : intl('Common.NA')}
            </span>
          </div>
          {vulnerability.numWorkloads && (
            <div className="FormPanel-Details-Row FormPanel-Details-Arrange">
              <span className="FormPanel-Details-Label">
                <Tooltip
                  content={intl('Help.Desc.WorkloadCount')}
                  position="location-group"
                  width={372}
                  location="fixed"
                >
                  {intl('Common.Workloads')}
                </Tooltip>
              </span>
              <span className="FormPanel-Details-Value">{vulnerability.numWorkloads}</span>
            </div>
          )}
          <div className="FormPanel-Details-Row FormPanel-Details-Arrange">
            <span className="FormPanel-Details-Label">
              <Tooltip
                content={intl('Help.Desc.VulnerabilityScore')}
                position="location-group"
                width={372}
                location="fixed"
              >
                {intl('Vulnerability.Score')}
              </Tooltip>
            </span>
            <span className="FormPanel-Details-Value">
              {vulnerability.vulnerabilityScore === null
                ? intl('Common.NA')
                : Math.round(vulnerability.vulnerabilityScore * 10) / 10}
            </span>
          </div>
          <div className="FormPanel-Details-Row FormPanel-Details-Arrange">
            <span className="FormPanel-Details-Label">
              <Tooltip content={intl('Help.Desc.EWExposure')} position="location-group" width={372} location="fixed">
                {intl('Vulnerability.EWExposure')}
              </Tooltip>
            </span>
            <span className="FormPanel-Details-Value">
              {vulnerability.vulnerablePortExposure === null ? intl('Common.NA') : vulnerability.vulnerablePortExposure}
            </span>
          </div>
          <div className="FormPanel-Details-Row FormPanel-Details-Arrange">
            <span className="FormPanel-Details-Label">
              <Tooltip
                content={intl('Help.Desc.InternetExposure')}
                position="location-group"
                width={372}
                location="fixed"
              >
                {intl('Vulnerability.InternetExposure')}
              </Tooltip>
            </span>
            <span className="FormPanel-Details-Value">
              {!vulnerability.wideExposure ? (
                intl('Common.NA')
              ) : vulnerability.wideExposure.any || vulnerability.wideExposure.ip_list ? (
                <Icon name="internet" size="medium" />
              ) : (
                intl('Common.None')
              )}
            </span>
          </div>
          {vulnerability.details.cve_ids && vulnerability.details.cve_ids.length ? (
            <div className="FormPanel-Details-Row FormPanel-Details-Arrange">
              <span className="FormPanel-Details-Label">
                <Tooltip
                  content={intl('Help.Desc.CVEIds')}
                  position="location-group"
                  width={372}
                  location="fixed"
                  dataTid="cve-id"
                >
                  {intl('Vulnerability.CVEIds')}
                </Tooltip>
              </span>
              <span className="FormPanel-Details-Value">{vulnerability.details.cve_ids.map(id => id).join(', ')}</span>
            </div>
          ) : null}
        </div>
      );
    }
  },

  renderServiceVulnerabilities(service) {
    return service.vulnerabilities.instances
      .sort((a, b) => b.vulnerabilityExposureScore - a.vulnerabilityExposureScore)
      .map(instance => {
        const tooltipContent = (
          <div className="FormPanel-Details-Tooltip">
            <div className="FormPanel-Details-Tooltip-Vulnerability">
              {intl('Vulnerability.Score')} {Math.round(instance.vulnerabilityScore * 10) / 10}
            </div>
            <div className="FormPanel-Details-Tooltip-Exposure">
              {intl('Common.ExposureScore')}
              <span>
                {instance.vulnerablePortExposure === intl('Workloads.Status.Syncing') ? (
                  <Icon name="syncing" size="large" />
                ) : (
                  instance.vulnerablePortExposure
                )}
                {!instance.wideExposure && (instance.wideExposure.any || instance.wideExposure.ip_list) ? (
                  <Icon name="internet" size="medium" position="after" />
                ) : (
                  ''
                )}
              </span>
            </div>
          </div>
        );

        return (
          <div className="FormPanel-Details-Row--Plain FormPanel-Details-Arrange">
            {instance.details.name}
            <span className="FormPanel-Details-Value FormPanel-Vulnerability">
              <Tooltip content={tooltipContent} width={200} location="fixed-right">
                <Vulnerability vulnerability={{...instance}} />
              </Tooltip>
            </span>
          </div>
        );
      });
  },

  renderAction(service, type) {
    const matchingService = cx('FormPanel-Details-Row', {
      'FormPanel-Details-Align': service && service.matchedServices && !service.matchedServices.length,
      'FormPanel-Details-Set-Margin-Service-Exists':
        service && service.matchedServices && service.matchedServices.length,
    });

    if (type === 'detailAction') {
      let transmissionMode;

      switch (service.connectionClass) {
        case 'B':
          transmissionMode = 'Broadcast';
          break;
        case 'M':
          transmissionMode = 'Multicast';
          break;
        case 'U':
          transmissionMode = 'Unicast';
          break;
      }

      return (
        <div className="FormPanel-Details">
          <div className="FormPanel-Details-Row FormPanel-Details-Arrange">
            <span className="FormPanel-Details-Label">{intl('Port.Protocol')}</span>
            <span className="FormPanel-Details-Value">
              {service.friendlyProtocol === 'ICMP' ? null : service.port}{' '}
              {ServiceUtils.lookupProtocol(service.protocol)}
            </span>
          </div>
          <div className="FormPanel-Details-Row FormPanel-Details-Arrange">
            <span className="FormPanel-Details-Label">{intl('Common.TransmissionMode')}</span>
            <span className="FormPanel-Details-Value">{transmissionMode}</span>
          </div>
          <div className="FormPanel-Details-Row FormPanel-Details-Arrange">
            <span className="FormPanel-Details-Label">{intl('Common.Flows')}</span>
            <span className="FormPanel-Details-Value">{service.sessions}</span>
          </div>
          {service && service.processName && service.processName !== 'unknown' ? (
            <div className="FormPanel-Details-Row FormPanel-Details-Arrange">
              <span className="FormPanel-Details-Label">{intl('Common.Process')}</span>
              <span className="FormPanel-Details-Value-Wrap">{service.processName}</span>
            </div>
          ) : null}
          {service && service.serviceName && service.serviceName !== 'unknown' ? (
            <div className="FormPanel-Details-Row FormPanel-Details-Arrange">
              <span className="FormPanel-Details-Label">{intl('Common.WinService')}</span>
              <span className="FormPanel-Details-Value">{service.serviceName}</span>
            </div>
          ) : null}
          {MapPageStore.getAppMapVersion() === 'vulnerability' &&
          service.vulnerabilities &&
          service.vulnerabilities.instances.length ? (
            <div className="FormPanel-Details-Row FormPanel-Details-Set-Margin-Service-Exists">
              <span className="FormPanel-Details-Label">
                {intl('Map.PortVulnerabilities', {count: service.vulnerabilities.instances.length})}
              </span>
              <div className="FormPanel-Details-Vulnerability-Values">{this.renderServiceVulnerabilities(service)}</div>
            </div>
          ) : null}
          <div className={matchingService}>
            <span className="FormPanel-Details-Label">
              {intl('Common.MatchingServices', {count: service.matchedServices.length})}
            </span>
            {service && service.matchedServices && service.matchedServices.length ? (
              <div className="FormPanel-Details-Matching-Service-Value">{this.renderMatchingService()}</div>
            ) : null}
          </div>
        </div>
      );
    }
  },

  handleIpAddress(address) {
    const {form, formData} = this.props.data;
    // Remove the dns name from the address
    const params = {address: address.split(' - ').shift()};

    if (address.includes(' - ')) {
      params.dnsName = address.split(' - ').pop();
    } else if (
      (form.info.type === 'fqdn' && RenderUtils.isHrefFqdn(formData.href)) ||
      (formData.type === 'pb' && form.info.href.includes('fqdn') && RenderUtils.isHrefFqdn(formData.source?.href))
    ) {
      params.dnsName = formData.name || formData.source.name;
    }

    this.transitionTo('workloadCreate', null, params);
    actionCreators.updateTrafficForAll();
    this.handleClose();
    sessionStorage.setItem('rebuild_map', 'true');

    if (this.props.data.info.parent && this.props.data.info.parent.labels) {
      // Ensure we have definitely left illumination before clearing out the data
      // because we don't want the reload to happen until after the new workload has been created
      setTimeout(() => {
        actionCreators.updateTrafficForScopes([this.props.data.info.parent.labels.map(label => label.href)]);
      }, 1000);
    }
  },

  customListItem({text, props, item}) {
    if (props && props.key) {
      const {type, info, formData} = this.props.data;
      const InternetWorkloadsIsWritable =
        info.caps?.workloads.includes('write') ||
        info.caps?.workloads.includes('add') ||
        (info.targets &&
          info.sources &&
          info.targets.length &&
          info.sources.length &&
          (info.targets[0].caps?.workloads.includes('write') ||
            info.sources[0].caps?.workloads.includes('write') ||
            info.targets[0].caps?.workloads.includes('add') ||
            info.sources[0].caps?.workloads.includes('add')));
      const userHasGlobalWritability = SessionStore.isGlobalEditEnabled();

      if (props.key === 'dropdownValueNumMatches') {
        return (
          <li key={props.key} {...props}>
            {intl('ObjectSelector.NoMatchingResults')}
          </li>
        );
      }

      if (props.key.includes('Total')) {
        return (
          <li key={props.key} {...props}>
            <span className="MapSubInfoPanel-Row--NoPointer">{text}</span>
          </li>
        );
      }

      const primary = InternetWorkloadsIsWritable ? (
        <Tooltip
          content={intl('Map.CreateUnmanagedWorkload')}
          position="bottomright-fixed"
          width={225}
          location="fixed"
        >
          <span className="CommandPanel-Link" onClick={_.partial(this.handleIpAddress, item)}>
            {item.split(' - ')[0]}
          </span>
        </Tooltip>
      ) : (
        <span className="CommandPanel-Name">{item.split(' - ')[0]}</span>
      );

      let secondary = null;

      if (item.split(' - ').length > 1) {
        secondary =
          type === 'fqdn' || (type === 'traffic' && formData && formData.source && formData.source.type === 'fqdn') ? (
            <span className="CommandPanel-Space">
              -<span className="CommandPanel-Space">{item.split(' - ')[1]}</span>
            </span>
          ) : userHasGlobalWritability ? (
            <Tooltip content={intl('Map.Fqdn.Manage')} position="location-group" width={150} location="fixed">
              <span className="CommandPanel-Space">-</span>
              <span
                className="CommandPanel-Link"
                onClick={_.partial(this.handleCreateSingleDomain, item.split(' - ')[1])}
              >
                {item.split(' - ')[1]}
              </span>
            </Tooltip>
          ) : null;
      }

      return (
        <li key={props.key} {...props}>
          {primary}
          {secondary}
        </li>
      );
    }
  },

  renderPbUb(formData, type) {
    if (type === 'pbUbAction' || type === 'internetAction') {
      const {items} = this.state;
      const selectedItem = items[Object.keys(items)[0]];
      const {customListItem} = this;
      const placeHolder = intl('Map.SearchIpAddresses');
      let addresses;
      const {info} = this.props.data;
      const InternetWorkloadsIsWritable =
        info.caps?.workloads.includes('write') ||
        info.caps?.workloads.includes('add') ||
        (info.targets &&
          info.sources &&
          info.targets.length &&
          info.sources.length &&
          (info.targets[0].caps?.workloads.includes('write') ||
            info.sources[0].caps?.workloads.includes('write') ||
            info.targets[0].caps?.workloads.includes('add') ||
            info.sources[0].caps?.workloads.includes('add')));

      if (type === 'pbUbAction') {
        addresses = formData ? formData.source.addresses : [];
      } else if (type === 'internetAction') {
        addresses = formData ? formData.addresses : [];
      }

      if (!addresses.length) {
        return <div className="FormPanel-Search FormPanel-No-Addresses">{intl('Map.NoIPAddressesForAny')}</div>;
      }

      const singleValues = _.compact(addresses).reduce((acc, cur) => {
        let address;

        /** Need try/catch block when checking newAddress[4,6] */
        try {
          const addressPtr = new Address6(cur);

          address = cur.includes(':') ? addressPtr.correctForm() : cur;
        } catch {
          address = cur;
        }

        acc[address] = address;

        return acc;
      }, {});

      const props = {
        alwaysOpen: true,
        items,
        addItem: _.noop,
        removeItem: _.noop,
        customListItem,
        facetMap: {},
        dropdownValues: {},
        initialValues: [],
        allowOne: true,
        singleValues,
        placeholder: _.isEmpty(items) ? placeHolder : '',
        getFacetValues: _.noop,
        returnValue: _.noop,
        maxResults: MAX_ADDRESSES,
        itemNotClickable: !InternetWorkloadsIsWritable,
        ref: node => (this.input = node),
        footerValues: [
          {
            footer: true,
            text: `${intl('Common.Total')} ${intl('IPLists.IPAddresses')}: ${Object.keys(singleValues).length}`,
            className: 'ObjectSelector-dd-values-item--hint',
          },
        ],
      };

      let ipAddresses = (
        <div className="FormPanel-Search">
          <div className="FormPanel-Search-Icon">
            <Icon name="search" size="large" data-tid="comp-icon-search" />
          </div>
          <span>
            <ObjectSelector {...props} />
          </span>
        </div>
      );

      if (selectedItem && selectedItem.length > 40) {
        ipAddresses = (
          <Tooltip content={selectedItem} width={400} position="formpanel" location="bottomright">
            {ipAddresses}
          </Tooltip>
        );
      }

      return ipAddresses;
    }
  },

  handleCreateSingleDomain(domain) {
    actionCreators.openDialog(<DomainCreateDialog providedName={domain} />);
  },

  handleCreateDomain() {
    actionCreators.openDialog(
      <DomainCreateDialog
        providedName={
          this.props.data.formData.source ? this.props.data.formData.source.name : this.props.data.formData.name
        }
      />,
    );
  },

  render() {
    const {handleClose} = this;
    const {form, formData} = this.props.data;
    const userHasGlobalWritability = SessionStore.isGlobalEditEnabled();
    const type = form.type;
    const title = (type => {
      switch (type) {
        case 'detailAction':
          return intl('Common.Services');
        case 'pbUbAction':
          return formData && formData.source && formData.source.name;
        case 'internetAction':
          return formData && formData.name;
        case 'vulnerability':
          return `${_.startCase(this.props.data.type)} ${intl('Common.Vulnerability')}`;
        default:
          return;
      }
    })(type);

    let ipListCreation = null;

    if (
      (form.info && formData && form.info.type === 'fqdn' && RenderUtils.isHrefFqdn(formData.href)) ||
      (formData &&
        formData.type === 'pb' &&
        form.info.href.includes('fqdn') &&
        RenderUtils.isHrefFqdn(formData.source?.href))
    ) {
      ipListCreation = userHasGlobalWritability && (
        <div className="CommandPanelTitle-Link" onClick={this.handleCreateDomain}>
          {intl('Map.Fqdn.Manage')}
        </div>
      );
    }

    const policyVersion = MapPageStore.getPolicyVersion();
    const classes = `FormPanel-Title FormPanel-Title-Name FormPanel-Margin-Unset FormPanel-${
      policyVersion === 'draft' ? 'Draft' : 'Reported'
    }`;
    const ipListClasses = `FormPanel-Title FormPanel-Title-Link FormPanel-Margin-Unset FormPanel-${
      policyVersion === 'draft' ? 'Draft' : 'Reported'
    }`;
    const nameLinkable =
      formData && formData.href && !RenderUtils.isHrefFqdn(formData.href) && formData.href.includes('ip_list');

    return (
      <div className="FormPanel">
        <div className="FormTitle">
          <div className={classes} onClick={handleClose}>
            <Icon name="caret-left" size="xlarge" data-tid="comp-icon-close" />
            {nameLinkable ? (
              <Link
                className="CommandPanelTitle-Link"
                to="iplists.item"
                params={{id: formData.href.split('/').pop(), pversion: 'draft'}}
                data-tid="form-panel-title"
              >
                {title}
              </Link>
            ) : (
              <span data-tid="form-panel-title">{title}</span>
            )}
          </div>
          <span className={ipListClasses}>{ipListCreation}</span>
        </div>
        {this.renderAction(formData, type)}
        {this.renderPbUb(formData, type)}
        {this.renderVulnerability(formData, type)}
      </div>
    );
  },
});
