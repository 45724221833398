/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import React from 'react';
import intl from 'intl';
import {Navigation, Link} from 'react-router';
import actionCreators from '../../actions/actionCreators';
import {Navbar, Button, Grid, Spinner, SpinnerOverlay, Pagination, Banner, ConfirmationDialog} from '../../components';
import {ToolBar, ToolGroup} from '../../components/ToolBar';
import {StoreMixin, UserMixin, PolicyGeneratorMixin} from '../../mixins';
import {
  TrafficStore,
  MapSpinnerStore,
  SessionStore,
  OrgStore,
  FilterStore,
  GeneralStore,
  MapPageStore,
} from '../../stores';
import {RestApiUtils, RenderUtils, GraphDataUtils, GridDataUtils, GroupDataUtils} from '../../utils';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import Session from '../../lib/session';

const MAX_RESULTS_PER_PAGE = 50;

function getStateFromStores() {
  const org = OrgStore.getOrg();
  let appGroups = [];
  const count = {total: 0, matched: 0};
  const appGroupsDefaultSet = org && org.app_groups_default && org.app_groups_default.length;
  // This api will always return all the app groups, so we need to filter on the frontend
  const filters = this.getFilters(appGroupsDefaultSet);

  if (appGroupsDefaultSet) {
    appGroups = TrafficStore.getAllAppGroupNodes().filter(node => node.type);
    count.total = appGroups.length;
    count.matches = appGroups.length;

    if (filters.length) {
      appGroups = appGroups.filter(appGroup =>
        filters.every(filter => appGroup.labels && appGroup.labels.some(label => filter.href === label.href)),
      );
      count.matches = appGroups.length;
    }
  }

  return {
    appGroups,
    count,
    appGroupsDefaultSet,
    isFiltered: Boolean(filters.length),
    status: [OrgStore.getStatus()],
    typeMatching: TrafficStore.isAppGroupTypeMatching(),
    appGroupSpinner: MapSpinnerStore.getAppGroupSummarySpinner(),
    isRuleCoverageLoaded: TrafficStore.isPolicyGeneratorCoverageLoaded(),
    appGroupSpinnerHref: false,
    appGroupsLoaded: TrafficStore.isTrafficLoaded('appGroups'),
    totalWorkloads: MapPageStore.getTotalWorkloads(),
  };
}

export default Object.assign(
  React.createClass({
    mixins: [
      Navigation,
      UserMixin,
      PolicyGeneratorMixin,
      StoreMixin([TrafficStore, FilterStore, OrgStore, MapPageStore], getStateFromStores),
    ],

    getInitialState() {
      return {
        sorting:
          GeneralStore.getSorting('appGroupList') || SessionStore.areVulnerabilitiesEnabled
            ? [{key: 'vulnerabilityExposureScore', direction: true}]
            : [{key: 'workloads', direction: true}],
        mapHover: false,
        currentPage: 1,
      };
    },

    componentWillMount() {
      if (!SessionStore.isIlluminationApiEnabled()) {
        this.replaceWith('landing');
      }

      this.rows = {};
      this.currentPage = 1;

      sessionStorage.setItem('app_group_list', 'list');
    },

    async componentDidMount() {
      RestApiUtils.user.orgs({representation: 'org_permissions'}, SessionStore.getUserId(), true);
      this.handleLoadAppGroups();
      RestApiUtils.orgs.getInstance(Session.getOrgId());
      OrgStore.addChangeListener(this.forceLoadAppGroups);
      GraphDataUtils.getMapLevelByTotalWorkloads();

      if (SessionStore.isTrafficEnabled()) {
        const rulesets = await this.getAllPolicyGeneratorRulesets();

        this.setState({rulesets});
      }
    },

    componentWillUnmount() {
      OrgStore.removeChangeListener(this.forceLoadAppGroups);
      this.currentPage = 0;
    },

    getFilters(appGroupsDefaultSet) {
      const labels = FilterStore.getScopeFiltersNoRole();

      if (appGroupsDefaultSet === 2) {
        return labels.filter(label => label.key !== 'loc');
      }

      return labels;
    },

    forceLoadAppGroups() {
      _.defer(() => this.handleLoadAppGroups());
    },

    handleSetAppGroupType() {
      this.transitionTo('appGroupType');
    },

    handleSegmentAppGroups() {
      this.transitionTo('ringFenceChoose');
    },

    handleSort(key, direction) {
      const sorting = [];

      if (key) {
        sorting.push({key, direction});
      }

      setTimeout(this.handleRuleCoverageForPage, 2000);

      actionCreators.updateGeneralSorting('appGroupList', sorting);
      this.setState({sorting});
    },

    handlePageChange(page) {
      this.rows = {};
      this.currentPage = page;

      setTimeout(this.handleRuleCoverageForPage, 2000);

      this.setState({
        currentPage: page,
      });
    },

    summaryTimeout(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    async handleLoadAppGroups() {
      // Only poll 20 times
      let mismatch = 20;

      if (!TrafficStore.isTrafficRequested('appGroups')) {
        while (mismatch) {
          // We no longer have a collection get available, so use the traffic store
          await GraphDataUtils.getTraffic('appGroup', {route: 'appGroups'});

          if (TrafficStore.isAppGroupTypeMatching()) {
            mismatch = 0;
          } else {
            // If we are waiting for an app group type change
            mismatch -= 1;
            await this.summaryTimeout(10_000);
          }
        }

        if (SessionStore.isTrafficEnabled()) {
          if (TrafficStore.getLoadRulesForAppGroups()) {
            RestApiUtils.appGroupRules.startAppGroupRulesTimer();
          }

          setTimeout(this.handleRuleCoverageForPage, 3000);
        }
      } else if (SessionStore.isTrafficEnabled()) {
        if (TrafficStore.isTrafficLoaded('appGroups') && TrafficStore.getLoadRulesForAppGroups()) {
          RestApiUtils.appGroupRules.startAppGroupRulesTimer();
        }

        setTimeout(this.handleRuleCoverageForPage, 3000);
      }

      if (SessionStore.areVulnerabilitiesEnabled()) {
        await RestApiUtils.appGroups.getCollection();
      }
    },

    handleRowClick(row) {
      if (row.caps.rulesets.includes('read')) {
        actionCreators.updateMapRoute({type: 'focused', id: row.href});
        this.transitionTo('appMapLevel', {type: 'focused', id: row.href});
      } else {
        const memberTabTo = GroupDataUtils.calculateMemberTabTo(row);

        this.transitionTo(memberTabTo, {id: row.href});
      }
    },

    handleRefresh(evt, appGroup) {
      evt.stopPropagation();
      actionCreators.openDialog(
        <ConfirmationDialog
          title={intl('AppGroupCoverage.Recalculate')}
          message={intl('AppGroupCoverage.RecalculateConfirm')}
          onConfirm={_.partial(this.handleForceRefresh, appGroup)}
        />,
      );
    },

    handleForceRefresh(appGroup) {
      this.setState({appGroupSpinnerHref: appGroup});
      // From the Policy Generator Mixin
      this.updateAppGroupRules(appGroup);
    },

    async handleRuleCoverageForPage() {
      const loadingPage = this.currentPage;

      if (!this.state.isRuleCoverageLoaded && TrafficStore.getIncrementallyLoadRulesForAppGroups()) {
        for (const rowChunk of _.chunk(Object.values(this.rows), 2)) {
          await Promise.all(
            rowChunk.map(async appGroupHref => {
              if (this.currentPage !== loadingPage) {
                return;
              }

              const appGroup = TrafficStore.getAppGroupNode(appGroupHref);

              if (!appGroup || appGroup.intraGroupCoverage === undefined) {
                await RestApiUtils.appGroupRules.get(appGroupHref);
              }
            }),
          );
        }
      }
    },

    handleGoToPolicyGenerator(appGroup) {
      this.transitionTo('appGroupPolicyGenerator', {id: appGroup.href});
    },

    render() {
      const {appGroups, rulesets, appGroupSpinnerHref, appGroupsLoaded, appGroupsDefaultSet, totalWorkloads} =
        this.state;

      if (!totalWorkloads) {
        // if there are no workloads paired or in scope, send them to the dashboard
        this.transitionTo('landing');
      }

      const config = (
        <div className="AppGroup-Padding">
          <Button
            autoFocus={true}
            text={intl('AppGroups.SetAppGroupType')}
            disabled={!this.isUserOwner() || SessionStore.isSuperclusterMember()}
            onClick={this.handleSetAppGroupType}
          />
        </div>
      );
      const columns = [
        {
          key: 'labels',
          label: intl('Common.Name'),
          sortable: true,
          sortValue: (value, row) => row.name,
          style: 'app-group-name',
          format: value => {
            const orderedLabels = _.orderBy(value, 'key')
              .map(label => label.value)
              .join(' | ');

            return RenderUtils.truncateAppGroupName(orderedLabels, 90, value.length === 2 ? [45, 45] : [30, 30, 30]);
          },
        },
        {
          key: 'entityCounts',
          label: intl('Common.Members'),
          style: 'app-group-center',
          sortable: true,
          sortValue: value => value,
          format: (value, row) => {
            const memberTabTo = GroupDataUtils.calculateMemberTabTo(row);

            return (
              <Link to={memberTabTo} params={{id: row.href}}>
                {value}
              </Link>
            );
          },
        },
        {
          key: 'mode',
          label: intl('Common.Enforcement'),
          style: 'app-group-enforcement',
          sortable: true,
          sortValue: (value, row) => RenderUtils.getPolicyState(row, 'group'),
          format: (value, row) => RenderUtils.getEnforcementString(RenderUtils.getPolicyState(row, 'group')),
        },
      ];

      if (SessionStore.isTrafficEnabled()) {
        columns.push(
          {
            key: 'coverage',
            style: 'app-group-coverage',
            label: intl('PolicyGenerator.Grid.Coverage'),
            format: (value, row) => {
              if (!row.caps.rulesets.includes('read')) {
                return null;
              }

              this.rows[row.href] = row.href;

              if (appGroupSpinnerHref === row.href) {
                return <Spinner size="small" />;
              }

              let refreshable = false;

              if (row.lastCalculated < row.updated || row.stale || row.coverageStale) {
                refreshable = true;
              }

              const refreshButton = (
                <span>
                  <Button
                    type={refreshable ? 'linky' : 'secondary'}
                    content="icon-only"
                    icon="refresh"
                    onClick={evt => this.handleRefresh(evt, row.href)}
                  />
                </span>
              );

              if (!row.intraGroupCoverage) {
                return [refreshButton];
              }

              if (!row.connections) {
                return [refreshButton, intl('AppGroupCoverage.NoConnections')];
              }

              return [
                <span className="AppGroupGrid-countBar">
                  {refreshButton}
                  <div
                    className={`AppGroupGrid-countBarContent ${
                      refreshable ? 'AppGroupGrid-countBarContent-faded' : ''
                    }`}
                    onMouseEnter={() => this.setState({mapHover: true})}
                    onMouseLeave={() => this.setState({mapHover: false})}
                    onClick={evt => {
                      if (row.caps.rulesets.includes('read')) {
                        evt.stopPropagation();
                        this.handleGoToPolicyGenerator(row);
                      } else {
                        this.handleRowClick(row);
                      }
                    }}
                  >
                    <div className="AppGroupCoverage-card-info-count-bar">
                      <div className="AppGroupCoverage-card-info-count-bar-fill" style={{width: `${value * 100}%`}}>
                        <ReactCSSTransitionGroup
                          transitionName="AppGroupCoverage-card-info-count-bar-fill-value"
                          transitionAppear={true}
                          transitionAppearTimeout={5000}
                          transitionEnterTimeout={0}
                          transitionLeaveTimeout={0}
                        >
                          <div className="AppGroupCoverage-card-info-count-bar-fill-value" />
                        </ReactCSSTransitionGroup>
                      </div>
                    </div>
                  </div>
                </span>,
              ];
            },
            sortable: true,
          },
          {
            key: 'name',
            label: intl('Common.Rules'),
            style: 'app-group-enforcement',
            format: (value, row) =>
              row.caps.rulesets.includes('read') ? (
                <div
                  className="Link Center"
                  onMouseEnter={() => this.setState({mapHover: true})}
                  onMouseLeave={() => this.setState({mapHover: false})}
                  onClick={evt => {
                    evt.stopPropagation();
                    this.transitionTo('appGroupRules', {id: row.href});
                  }}
                >
                  {intl('Common.View')}
                </div>
              ) : null,
          },
          {
            key: 'href',
            label: intl('Common.Map'),
            style: 'app-group-small',
            format: (value, row) =>
              row.caps.rulesets.includes('read') ? (
                <div
                  className="Link Center"
                  onMouseEnter={() => this.setState({mapHover: true})}
                  onMouseLeave={() => this.setState({mapHover: false})}
                  onClick={evt => {
                    evt.stopPropagation();
                    actionCreators.updateMapRoute({type: 'focused', id: value});
                    this.transitionTo('appMapLevel', {type: 'focused', id: value});
                  }}
                >
                  {intl('Common.View')}
                </div>
              ) : null,
          },
        );
      }

      if (SessionStore.areVulnerabilitiesEnabled()) {
        columns.unshift({
          key: 'vulnerabilityExposureScore',
          label: intl('Vulnerability.VEScore'),
          style: 'vulnerability',
          format: (value, row) => GridDataUtils.formatVulnerability({...row.vulnerability, policy: 'applicable'}),
          sortFunction: (rowA, rowB) => GridDataUtils.sortVulnerability(rowA.vulnerability, rowB.vulnerability),
          sortable: true,
        });
      }

      const appEnvOnly = this.state.appGroupsDefaultSet === 2;

      if (appGroupsDefaultSet && (this.state.appGroupSpinner || !appGroupsLoaded)) {
        return (
          <div className="ListPage AppGroupList" data-tid="page-app-groups-list">
            <SpinnerOverlay />
            <Navbar search={true} noRoleSearch={true} appEnvOnly={appEnvOnly} />
          </div>
        );
      }

      let data = appGroups;

      if (appGroups.length) {
        data = appGroups.map(appGroup => {
          let coverage = 0;
          let connections = 0;
          let connectionsWithRules = 0;

          if (appGroup.intraGroupCoverage) {
            connections =
              appGroup.intraGroupCoverage.num_services +
              appGroup.extraGroupCoverage.num_services +
              appGroup.ipListCoverage.num_services;
            connectionsWithRules =
              appGroup.intraGroupCoverage.num_rules +
              appGroup.extraGroupCoverage.num_rules +
              appGroup.ipListCoverage.num_rules;
            coverage = connectionsWithRules / connections;

            if (coverage > 0) {
              // Always show at least 10% if any coverage exists
              coverage = Math.max(coverage, 0.1);
            }
          }

          const result = {
            ...appGroup,
            coverage,
            connectionsWithRules,
            connections,
          };

          if (this.state.rulesets && this.state.rulesets[appGroup.name]) {
            // Add Ruleset information
            return {...result, ...rulesets[appGroup.name]};
          }

          return result;
        });
      }

      const appGroupsExist =
        this.state.appGroupsDefaultSet &&
        this.state.typeMatching &&
        this.state.appGroups &&
        this.state.appGroups.length;

      return (
        <div className="ListPage AppGroupList" data-tid="page-app-groups-list">
          <Navbar search={true} noRoleSearch={true} appEnvOnly={appEnvOnly} />
          <ToolBar>
            <ToolGroup>
              {this.state.appGroupsDefaultSet && this.isUserOwner() && !SessionStore.isSuperclusterMember() ? (
                <Button
                  text={intl('AppGroups.EditAppGroupType')}
                  onClick={this.handleSetAppGroupType}
                  type="secondary"
                  tid="add"
                />
              ) : null}
              {appGroupsExist && SessionStore.isTrafficEnabled() && !SessionStore.isSuperclusterMember() ? (
                <Button
                  text={intl('PolicyGenerator.SegmentMultipleAppGroups', {count: this.state.appGroups.length})}
                  onClick={this.handleSegmentAppGroups}
                  type="secondary"
                  tid="segment"
                />
              ) : null}
            </ToolGroup>
            {this.state.appGroups.length ? (
              <ToolGroup tid="pagination">
                <Pagination
                  page={this.state.currentPage}
                  totalRows={this.state.typeMatching ? this.state.appGroups.length : 0}
                  count={this.state.typeMatching ? this.state.count : 0}
                  isFiltered={this.state.isFiltered}
                  pageLength={MAX_RESULTS_PER_PAGE}
                  onPageChange={this.handlePageChange}
                />
              </ToolGroup>
            ) : null}
          </ToolBar>
          {appGroupsExist ? (
            <Grid
              columns={columns}
              data={data}
              sortable={true}
              selectable={false}
              onRowClick={this.state.mapHover ? null : this.handleRowClick}
              idField="href"
              sorting={this.state.sorting}
              onSort={this.handleSort}
              resultsPerPage={MAX_RESULTS_PER_PAGE}
              currentPage={this.state.currentPage}
            />
          ) : !this.state.appGroupsDefaultSet ? (
            <div className="Graph-no-data">
              <Banner type="notice" header={intl('Map.AppGroupDisabled')} content={config} />
            </div>
          ) : !this.state.typeMatching ? (
            <div className="Graph-no-data">
              <Banner type="progress" header={intl('Map.AppGroupConfigurationNotComplete')} />
            </div>
          ) : this.state.appGroupsDefaultSet && appGroupsLoaded && !SessionStore.isUserScoped() ? (
            <div className="Graph-no-data">
              <Banner type="notice" header={intl('Map.NoLabeledWorkloads')} />
            </div>
          ) : this.state.appGroupsDefaultSet && appGroupsLoaded && totalWorkloads > 0 ? (
            <div className="Graph-no-data">
              <Banner type="notice" header={intl('Map.NoAppGroupsInScope')} />
            </div>
          ) : null}
          <ToolBar justify="right">
            {this.state.appGroups.length > MAX_RESULTS_PER_PAGE ? (
              <ToolGroup tid="pagination">
                <Pagination
                  page={this.state.currentPage}
                  totalRows={this.state.typeMatching ? this.state.appGroups.length : 0}
                  count={this.state.typeMatching ? this.state.count : 0}
                  isFiltered={this.state.isFiltered}
                  pageLength={MAX_RESULTS_PER_PAGE}
                  onPageChange={this.handlePageChange}
                />
              </ToolGroup>
            ) : null}
          </ToolBar>
        </div>
      );
    },
  }),
  {
    viewName: () => intl('Common.AppGroupsList'),
    isAvailable: () => SessionStore.isTrafficEnabled() && !SessionStore.isEdge(),
  },
);
