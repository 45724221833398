/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import React from 'react';
import cx from 'classnames';
import intl from 'intl';
import {findDOMNode} from 'react-dom';
import update from 'react-addons-update';
import Icon from '../../components/Icon.jsx';
import actionCreators from '../../actions/actionCreators';
import NodeComponent from '../../components/graph/Node.jsx';
import LinkComponent from '../../components/graph/Link.jsx';
import ClusterComponent from '../../components/graph/Cluster.jsx';
import {UserStore} from '../../stores';
import MapPageStore from '../../stores/MapPageStore';

const blue = '#BDC3C7';
const green = '#81CB47';
const orange = '#F17D00';
const red = '#E80000';
const crimson = '#9F1009';
const infoblue = '#007cc5';

export default React.createClass({
  getInitialState() {
    return {
      hidden: true,
    };
  },

  handleHideLegend(evt) {
    const relatedTarget = evt.relatedTarget || evt.nativeEvent.explicitOriginalTarget || document.activeElement;

    if (findDOMNode(this).contains(relatedTarget)) {
      findDOMNode(this).focus();

      return;
    }

    this.setState({hidden: true});
  },

  toggleLegend() {
    const hidden = !this.state.hidden;

    if (!hidden) {
      actionCreators.setTrafficFilterHidden(true);
    }

    this.setState({hidden});

    // if we're supposed to show the panel, then also focus in
    if (!hidden) {
      findDOMNode(this).focus();
    }
  },

  renderTrafficSection() {
    const greenThreshold = localStorage.getItem('green') || 100;
    const svgStyle = {width: 94, height: 30};
    const notRuleReady = {
      source: {x: 7, y: 32},
      target: {x: 110, y: 40},
      type: 'gray',
      isLegend: true,
      colorBlind: 'normal',
      method: 'legend',
    };

    const ruleNotAllow = update(notRuleReady, {
      $merge: {type: 'red', colorBlind: UserStore.getColorBlindMode()},
    });
    const ruleAllow = update(notRuleReady, {
      $merge: {type: 'green', colorBlind: UserStore.getColorBlindMode()},
    });
    const potentiallyBlocked = update(notRuleReady, {
      $merge: {type: 'orange'},
    });
    const rulePartial = update(notRuleReady, {
      $merge: {type: 'lightGreen', colorBlind: UserStore.getColorBlindMode()},
    });
    const ruleLoading = update(notRuleReady, {
      $merge: {type: 'unknown'},
    });
    const discovered = update(notRuleReady, {
      $merge: {type: 'discovered'},
    });
    const gray = update(notRuleReady, {
      $merge: {type: 'gray'},
    });
    const vulnerability = update(notRuleReady, {
      $merge: {type: 'vulnerability'},
    });
    const potentiallyVulnerable = update(notRuleReady, {
      $merge: {type: 'potentiallyVulnerable'},
    });

    const vulnerabilityMap = MapPageStore.getAppMapVersion() === 'vulnerability';

    const svgVulnerabilityStyle = {width: 34, height: 30};
    const buildingWorkload = {x: 16, y: 16, fill: blue, isLegend: true};
    const VulnerabilityLow = {x: 16, y: 16, fill: green};
    const VulnerabilityInfo = {x: 16, y: 16, fill: infoblue};
    const VulnerabilityMedium = {x: 16, y: 16, fill: orange};
    const VulnerabilityHigh = {x: 16, y: 16, fill: red};
    const VulnerabilityCritical = {x: 16, y: 16, fill: crimson};

    const legendPanelTrafficsClass = cx({
      'LegendPanel-Body-Row LegendPanel-Workloads--column': true,
      'LegendPanel-Traffics': MapPageStore.getMapType() === 'app' || MapPageStore.getMapType() === 'loc',
    });

    const trafficPanelClass = cx({
      'LegendPanel-Body-Column': true,
      'LegendPanel-Traffic-Padding': vulnerabilityMap,
    });

    return (
      <div className={legendPanelTrafficsClass}>
        {vulnerabilityMap ? (
          <div className="LegendPanel-Vul-Padding LegendPanel-Body-Column">
            <h4 className="LegendPanel-body-title LegendPanel-body-title--column">
              <div className="LegendPanel-body-groups">{intl('Vulnerability.MaxSeverity')}</div>
            </h4>
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <svg style={svgVulnerabilityStyle}>
                        <NodeComponent data={buildingWorkload} />
                      </svg>
                    </td>
                    <td className="LegendPanel-workloadslabel">{intl('Common.None')}</td>
                  </tr>
                  <tr>
                    <td>
                      <svg style={svgVulnerabilityStyle}>
                        <NodeComponent data={VulnerabilityInfo} />
                      </svg>
                    </td>
                    <td className="LegendPanel-workloadslabel">{intl('Common.Info')}</td>
                  </tr>
                  <tr>
                    <td>
                      <svg style={svgVulnerabilityStyle}>
                        <NodeComponent data={VulnerabilityLow} />
                      </svg>
                    </td>
                    <td className="LegendPanel-workloadslabel">{intl('Common.Low')}</td>
                  </tr>
                  <tr>
                    <td>
                      <svg style={svgVulnerabilityStyle}>
                        <NodeComponent data={VulnerabilityMedium} />
                      </svg>
                    </td>
                    <td className="LegendPanel-workloadslabel">{intl('Common.Medium')}</td>
                  </tr>
                  <tr>
                    <td>
                      <svg style={svgVulnerabilityStyle}>
                        <NodeComponent data={VulnerabilityHigh} />
                      </svg>
                    </td>
                    <td className="LegendPanel-workloadslabel">{intl('Common.High')}</td>
                  </tr>
                  <tr>
                    <td>
                      <svg style={svgVulnerabilityStyle}>
                        <NodeComponent data={VulnerabilityCritical} />
                      </svg>
                    </td>
                    <td className="LegendPanel-workloadslabel">{intl('Common.Critical')}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        ) : null}
        <div className={trafficPanelClass}>
          <h4 className="LegendPanel-body-title LegendPanel-body-title--column">{intl('Map.TrafficLinks')}</h4>
          <div className="LegendPanel-Body--TrafficLinksTable">
            <table>
              {vulnerabilityMap ? (
                <tbody>
                  <tr>
                    <td>
                      <svg style={svgStyle}>
                        <LinkComponent data={gray} />
                      </svg>
                    </td>
                    <td className="LegendPanel-trafficslabel">{intl('Map.LegendPanel.BlockedOrNotVulnerabile')}</td>
                  </tr>
                  <tr>
                    <td>
                      <svg style={svgStyle}>
                        <LinkComponent data={potentiallyVulnerable} />
                      </svg>
                    </td>
                    <td className="LegendPanel-trafficslabel">
                      {intl('Map.LegendPanel.PotentiallyBlockedVulnerableTraffic')}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <svg style={svgStyle}>
                        <LinkComponent data={vulnerability} />
                      </svg>
                    </td>
                    <td className="LegendPanel-trafficslabel">{intl('Map.LegendPanel.VulnerableTraffic')}</td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td>
                      <svg style={svgStyle}>
                        <LinkComponent data={ruleLoading} />
                      </svg>
                    </td>
                    <td className="LegendPanel-trafficslabel">{intl('Map.LegendPanel.LoadingData')}</td>
                  </tr>
                  <tr>
                    <td>
                      <svg style={svgStyle}>
                        <LinkComponent data={notRuleReady} />
                      </svg>
                    </td>
                    <td className="LegendPanel-trafficslabel">{intl('Map.LegendPanel.UnknownRuleStatus')}</td>
                  </tr>
                  <tr>
                    <td>
                      <svg style={svgStyle}>
                        <LinkComponent data={ruleNotAllow} />
                      </svg>
                    </td>
                    <td className="LegendPanel-trafficslabel">{intl('Common.Blocked')}</td>
                  </tr>
                  {greenThreshold < 100 ? (
                    <tr>
                      <td>
                        <svg style={svgStyle}>
                          <LinkComponent data={rulePartial} />
                        </svg>
                      </td>
                      <td className="LegendPanel-trafficslabel">{intl('Map.LegendPanel.PartiallyAllowed')}</td>
                    </tr>
                  ) : null}
                  <tr>
                    <td>
                      <svg style={svgStyle}>
                        <LinkComponent data={ruleAllow} />
                      </svg>
                    </td>
                    <td className="LegendPanel-trafficslabel">{intl('Common.Allowed')}</td>
                  </tr>
                  <tr>
                    <td>
                      <svg style={svgStyle}>
                        <LinkComponent data={potentiallyBlocked} />
                      </svg>
                    </td>
                    <td className="LegendPanel-trafficslabel">{intl('Common.PotentiallyBlocked')}</td>
                  </tr>
                  <tr>
                    <td>
                      <svg style={svgStyle}>
                        <LinkComponent data={discovered} />
                      </svg>
                    </td>
                    <td className="LegendPanel-trafficslabel">{intl('Map.LegendPanel.Discovered')}</td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
    );
  },

  renderWorkloadsSection() {
    const svgStyle = {width: 40, height: 40};
    const buildingWorkload = {x: 20, y: 20, fill: blue, isLegend: true};

    const testingWorkload = update(buildingWorkload, {
      $merge: {policyState: 'selective'},
    });
    const enforcedWorkload = update(buildingWorkload, {
      $merge: {policyState: 'enforced'},
    });
    const unmanagedWorkload = update(buildingWorkload, {
      $merge: {type: 'workload', unmanaged: true},
    });
    const idleWorkload = update(buildingWorkload, {
      $merge: {type: 'workload', policyState: 'idle'},
    });

    const svgGroupStyle = {width: 60, height: 34};
    const buildingCluster = {width: 44, height: 20, x: 32, y: 15, isLegend: true};
    const testingCluster = update(buildingCluster, {
      $merge: {policyState: 'selective'},
    });
    const enforcedCluster = update(testingCluster, {
      $merge: {policyState: 'enforced', type: null},
    });

    return (
      <div className="LegendPanel-Body-Row LegendPanel-Workloads LegendPanel-Workloads--column">
        <div className="LegendPanel-Body-Column">
          <h4 className="LegendPanel-body-title LegendPanel-body-title--column">
            <div>{intl('Common.Workloads')}</div>
          </h4>
          <div>
            <table>
              <tbody>
                <tr>
                  <td>
                    <svg style={svgStyle}>
                      <NodeComponent data={buildingWorkload} />
                    </svg>
                  </td>
                  <td className="LegendPanel-workloadslabel">{intl('Common.VisibilityOnly')}</td>
                </tr>
                <tr>
                  <td>
                    <svg style={svgStyle}>
                      <NodeComponent data={testingWorkload} />
                    </svg>
                  </td>
                  <td className="LegendPanel-workloadslabel">{intl('EnforcementBoundaries.SelectiveEnforcement')}</td>
                </tr>
                <tr>
                  <td>
                    <svg style={svgStyle}>
                      <NodeComponent data={enforcedWorkload} />
                    </svg>
                  </td>
                  <td className="LegendPanel-workloadslabel">{intl('Workloads.Full')}</td>
                </tr>
                <tr>
                  <td>
                    <svg style={svgStyle}>
                      <NodeComponent data={unmanagedWorkload} />
                    </svg>
                  </td>
                  <td className="LegendPanel-workloadslabel">{intl('Common.Unmanaged')}</td>
                </tr>
                <tr>
                  <td>
                    <svg style={svgStyle}>
                      <NodeComponent data={idleWorkload} />
                    </svg>
                  </td>
                  <td className="LegendPanel-workloadslabel">{intl('Common.Idle')}</td>
                </tr>
                <tr>
                  <td />
                  <td className="LegendPanel-workloadslabel" />
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="LegendPanel-Body-Column">
          <h4 className="LegendPanel-body-title LegendPanel-body-title--column">
            <div className="LegendPanel-body-groups">{intl('Common.Groups')}</div>
          </h4>
          <div>
            <table>
              <tbody>
                <tr>
                  <td>
                    <svg style={svgGroupStyle}>
                      <ClusterComponent data={buildingCluster} />
                    </svg>
                  </td>
                  <td className="LegendPanel-clusterslabel">{intl('Common.VisibilityOnly')}</td>
                </tr>
                <tr>
                  <td>
                    <svg style={svgGroupStyle}>
                      <ClusterComponent data={testingCluster} />
                    </svg>
                  </td>
                  <td className="LegendPanel-clusterslabel">{intl('EnforcementBoundaries.SelectiveEnforcement')}</td>
                </tr>
                <tr>
                  <td>
                    <svg style={svgGroupStyle}>
                      <ClusterComponent data={enforcedCluster} />
                    </svg>
                  </td>
                  <td className="LegendPanel-clusterslabel">{intl('Workloads.Full')}</td>
                </tr>
                <tr>
                  <td />
                  <td className="LegendPanel-workloadslabel" />
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  },

  renderOtherWorkloadsSection() {
    const svgStyle = {width: 40, height: 40};
    const containerWorkload = {
      x: 20,
      y: 20,
      fill: blue,
      isLegend: true,
      type: 'workload',
      subType: 'container',
      caps: {workloads: ['read']},
    };

    const testingContainerWorkload = update(containerWorkload, {
      $merge: {policyState: 'selective'},
    });
    const enforcedContainerWorkload = update(containerWorkload, {
      $merge: {policyState: 'enforced'},
    });

    const virtualService = {
      x: 16,
      y: 16,
      fill: blue,
      isLegend: true,
      type: 'virtualService',
    };

    return (
      <div className="LegendPanel-Body-Row LegendPanel-Others LegendPanel-Workloads--column">
        <div className="LegendPanel-Body-Column LegendPanel-Body-halfColumn">
          <h4 className="LegendPanel-body-title LegendPanel-body-title--column">
            <div>{intl('Common.ContainerWorkloads')}</div>
          </h4>
          <div>
            <table>
              <tbody>
                <tr>
                  <td>
                    <svg style={svgStyle}>
                      <NodeComponent data={containerWorkload} />
                    </svg>
                  </td>
                  <td className="LegendPanel-workloadslabel">{intl('Common.VisibilityOnly')}</td>
                </tr>
                <tr>
                  <td>
                    <svg style={svgStyle}>
                      <NodeComponent data={testingContainerWorkload} />
                    </svg>
                  </td>
                  <td className="LegendPanel-workloadslabel">{intl('EnforcementBoundaries.SelectiveEnforcement')}</td>
                </tr>
                <tr>
                  <td>
                    <svg style={svgStyle}>
                      <NodeComponent data={enforcedContainerWorkload} />
                    </svg>
                  </td>
                  <td className="LegendPanel-workloadslabel">{intl('Workloads.Full')}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="LegendPanel-VS-Padding LegendPanel-Body-Column">
          <h4 className="LegendPanel-body-title LegendPanel-body-title--column">
            <div className="LegendPanel-body-groups">{intl('Common.VirtualServices')}</div>
          </h4>
          <div>
            <table>
              <tbody>
                <tr>
                  <td>
                    <svg style={svgStyle}>
                      <NodeComponent data={virtualService} />
                    </svg>
                  </td>
                  <td className="LegendPanel-workloadslabel">{intl('Common.Node')}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  },

  render() {
    const {policyVersion, disabled} = this.props;

    const classes = cx({
      'LegendPanel': true,
      'LegendPanel-draft': policyVersion === 'draft',
    });

    const bodyClasses = cx({
      'LegendPanel-body': true,
      'hidden': this.state.hidden,
    });

    const buttonClasses = cx({
      'LegendPanel-icon': true,
      'LegendButton--active': !this.state.hidden,
      'LegendPanel--disabled': disabled,
    });

    return (
      <div className={classes} tabIndex="-1" onBlur={this.handleHideLegend}>
        <div className={buttonClasses} onClick={!disabled && this.toggleLegend} data-tid="legend-button">
          {intl('Map.LegendPanel.Legend')}&nbsp;
          <Icon name="dropdown" />
        </div>
        <div className={bodyClasses}>
          {this.renderWorkloadsSection()}
          {this.renderOtherWorkloadsSection()}
          {this.renderTrafficSection()}
        </div>
      </div>
    );
  },
});
