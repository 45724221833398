/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import React, {PropTypes} from 'react';
import Icon from '../components/Icon.jsx';
import Dialog from '../components/Dialog.jsx';
import actionCreators from '../actions/actionCreators';
import Checkbox from '../components/Checkbox.jsx';
import StoreMixin from '../mixins/StoreMixin';
import {GraphStore, SessionStore} from '../stores';
import RestApiUtils from '../utils/RestApiUtils';

function getStateFromStore() {
  return {
    hideMessage: GraphStore.getHideMessage(),
  };
}

export default React.createClass({
  propTypes: {
    message: PropTypes.any.isRequired,
    chainAction: PropTypes.string,
    onChain: PropTypes.func,
    confirm: PropTypes.string,
    onConfirm: PropTypes.func,
    tid: PropTypes.string,
    confirmTid: PropTypes.string,
  },

  mixins: [StoreMixin(GraphStore, getStateFromStore)],

  getDefaultProps() {
    return {
      confirm: intl('Common.Done'),
      onConfirm: _.noop,
      confirmTid: 'done',
    };
  },

  getActions() {
    if (this.props.chainAction) {
      return [
        {
          text: this.props.chainAction,
          tid: this.props.tid || this.props.chainAction.toLowerCase(),
          type: 'secondary',
          content: 'text-only',
          onClick: () => {
            this.handleChain();
          },
        },
        {
          text: this.props.confirm,
          tid: this.props.confirmTid,
          autoFocus: true,
          onClick: () => {
            this.props.onConfirm();
            this.handleClose();
          },
        },
      ];
    }

    return [
      {
        text: this.props.confirm,
        tid: this.props.confirmTid,
        content: 'text-only',
        autoFocus: true,
        onClick: () => {
          this.props.onConfirm();
          this.handleClose();
        },
      },
    ];
  },

  handleMessageDisplay() {
    let messageDisplay = {};

    switch (this.props.message) {
      case 'click':
        messageDisplay = {hideMoveToolAlert: !this.state.hideMessage.hideMoveToolAlert};
        break;
      case 'drag':
        messageDisplay = {hideSelectionToolAlert: !this.state.hideMessage.hideSelectionToolAlert};
        break;
      case 'resolveDomains':
        messageDisplay = {hideResolveDomainsAlert: !this.state.hideMessage.hideResolveDomainsAlert};
        break;
      case 'dragOnFull':
        messageDisplay = {hideFullmapSelectionToolAlert: !this.state.hideMessage.hideFullmapSelectionToolAlert};
        break;
    }

    this.setState({
      hideMessage: {
        ...this.state.hideMessage,
        ...messageDisplay,
      },
    });
  },

  handleChain() {
    this.handleClose();
    this.props.onChain();
  },

  handleClose() {
    actionCreators.notShowingMessage({
      hideMessage: this.state.hideMessage,
    });
    actionCreators.closeDialog();

    RestApiUtils.kvPair.update(SessionStore.getUserId(), 'hide_message', this.state.hideMessage);
  },

  render() {
    const chain = null;
    const cancel = null;

    let hideAlertChecked = false;
    let alertMessage = '';
    let alertTitle = '';

    switch (this.props.message) {
      case 'click':
        hideAlertChecked = this.state.hideMessage.hideMoveToolAlert;
        alertMessage = [intl('Common.MoveModeAlert'), ' (', <Icon name="selection-tool" size="large" />, ')'];
        alertTitle = intl('Common.NoObjectSelected');
        break;
      case 'drag':
        hideAlertChecked = this.state.hideMessage.hideSelectionToolAlert;
        alertMessage = [
          intl('Common.SelectModeAlert'),
          ' (',
          <Icon name="move-tool" size="large" />,
          ')',
          <br />,
          intl('Common.SelectModeAlertShortcut', {className: 'Text-bold'}, {html: true}),
        ];
        alertTitle = intl('Common.NoObjectSelected');
        break;
      case 'resolveDomains':
        hideAlertChecked = this.state.hideMessage.hideResolveDomainsAlert;
        alertMessage = intl('Common.ResolveDomainsAlert');
        alertTitle = intl('Explorer.ResolveUnknownFqdns');
        break;
      case 'dragOnFull':
        hideAlertChecked = this.state.hideMessage.hideFullmapSelectionToolAlert;
        alertMessage = [intl('Common.FullmapSelectModeAlert'), ' (', <Icon name="move-tool" size="large" />, ')'];
        alertTitle = intl('Common.NoObjectSelected');
        break;
    }

    return (
      <Dialog ref="dialog" type="detail" className="ModeAlert" title={alertTitle} actions={this.getActions()}>
        <div className="ModeAlert-message-box">
          <span className="ModeAlert-message">{alertMessage}</span>
        </div>
        {/* This should be fixed in later than 19.3.2, but for now, let's not frustrate customers by prentending to
        stop showing the dialog*/}
        <div className="Not-show-dialog">
          <Checkbox
            checked={hideAlertChecked}
            onChange={this.handleMessageDisplay}
            label={intl('Common.DoNotShowMessage')}
          />
        </div>
        {chain}
        {cancel}
      </Dialog>
    );
  },
});
