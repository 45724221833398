/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import React, {PropTypes} from 'react';
import Button from './Button.jsx';
import ComponentUtils from '../utils/ComponentUtils';

const defaultTid = 'comp-pagination';

export default React.createClass({
  propTypes: {
    tid: PropTypes.string,
    page: PropTypes.number,
    totalRows: PropTypes.number,
    pageLength: PropTypes.number,
    onPageChange: PropTypes.func,
    count: PropTypes.object,
    isFiltered: PropTypes.bool,
    type: PropTypes.string,
  },

  getDefaultProps() {
    return {
      page: 1,
      totalRows: 0,
      pageLength: 1,
      onPageChange: _.noop,
    };
  },

  handleDecrementPage() {
    this.props.onPageChange(Math.max(this.props.page - 1, 1));
  },

  handleIncrementPage() {
    const numPages = Math.ceil(this.props.totalRows / this.props.pageLength);

    this.props.onPageChange(Math.min(this.props.page + 1, numPages));
  },

  render() {
    let type;
    let total;

    if (this.props.count) {
      // Just in case...
      if (this.props.isFiltered) {
        type = 'matched';
        total = this.props.count.matched;
      } else {
        total = this.props.count.total;
      }
    }

    total = Number(total || this.props.totalRows);

    const numPages = Math.ceil(this.props.totalRows / this.props.pageLength);
    const tids = ComponentUtils.tid(defaultTid, this.props.tid);
    const max = Math.min(this.props.totalRows, this.props.pageLength * this.props.page);
    let paginationString = intl(
      'Common.Pagination',
      {
        total,
        type: this.props.type ?? type ?? 'total',
        plus: Boolean(this.props.count && total > 100_000),
        notZero: max !== 0,
        min: 1 + this.props.pageLength * (this.props.page - 1),
        max,
      },
      {html: true},
    );

    paginationString = total ? paginationString : null;

    return (
      <div className="Pagination" data-tid={ComponentUtils.tidString(tids)}>
        <span data-tid="comp-pagination-counts" className="Pagination-Counts">
          {paginationString}
        </span>
        {numPages > 1 && (
          <Button
            icon="back"
            content="icon-only"
            type="secondary"
            disabled={this.props.page <= 1}
            onClick={this.handleDecrementPage}
            tid="previous"
          />
        )}
        {numPages > 1 && (
          <Button
            icon="next"
            content="icon-only"
            type="secondary"
            disabled={this.props.page >= numPages}
            onClick={this.handleIncrementPage}
            tid="next"
          />
        )}
      </div>
    );
  },
});
