/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */

import React from 'react';
import {OrgStore, RuleSearchStore} from '../../stores';
import {StoreMixin} from '../../mixins';
import {Navigation, State} from 'react-router';
import {GridDataUtils} from '../../utils';
import intl from '../../../../app/intl';
import Icon from '../../components/Icon';
import cx from 'classnames';

function getStateFromStore() {
  return {
    providerConsumerOrder: OrgStore.providerConsumerOrder(),
  };
}

export default React.createClass({
  mixins: [Navigation, State, StoreMixin([RuleSearchStore, OrgStore], getStateFromStore)],

  componentDidMount() {
    this.setArrowRotation();
    window.addEventListener('resize', this.setArrowRotation);
  },

  componentWillUnmount() {
    window.removeEventListener('resize', this.setArrowRotation);
  },

  getInitialState() {
    return {
      rotateArrow: false,
    };
  },

  setArrowRotation() {
    if (window.innerWidth <= 780) {
      this.setState({rotateArrow: true});
    } else {
      this.setState({rotateArrow: false});
    }
  },

  showOrdering() {
    const {providerConsumerOrder, rotateArrow} = this.state;
    const {enforcementLabels = {}} = this.props;

    let consumersElements = null;
    const providersServices = [];

    if (enforcementLabels?.providers) {
      const providers = GridDataUtils.formatRuleEntities(enforcementLabels.providers);

      const providerElements = (
        <div className="EnforcementBoundaries-display" data-tid="providers">
          <div className="EnforcementBoundaries-lineheight EnforcementBoundaries-marginright">
            {intl('Common.Providers')}:
          </div>
          <div className="EnforcementBoundaries-group-category EnforcementBoundaries-group-category-groupcolumn">
            <div className="EnforcementBoundaries-categoryMain">{providers}</div>
          </div>
        </div>
      );

      providersServices.push(providerElements);
    }

    if (enforcementLabels?.ingress_services) {
      const services = GridDataUtils.formatRuleService(enforcementLabels.ingress_services);

      const serviceElements = (
        <div className="EnforcementBoundaries-display" data-tid="providingservices">
          <div className="EnforcementBoundaries-lineheight EnforcementBoundaries-marginright">
            {intl('EnforcementBoundaries.ProvidingServices')}:
          </div>
          <div className="EnforcementBoundaries-group-category EnforcementBoundaries-providing-services">
            {services}
          </div>
        </div>
      );

      providersServices.push(serviceElements);
    }

    if (enforcementLabels?.consumers) {
      const consumers = GridDataUtils.formatRuleEntities(enforcementLabels.consumers);

      consumersElements = (
        <div className="EnforcementBoundaries-display" data-tid="comsumers">
          <div className="EnforcementBoundaries-lineheight EnforcementBoundaries-marginright">
            {intl('Common.Consumers')}:
          </div>
          <div className="EnforcementBoundaries-group-category EnforcementBoundaries-group-category-groupcolumn">
            <div className="EnforcementBoundaries-categoryMain">{consumers}</div>
          </div>
        </div>
      );
    }

    const arrowClass = providerConsumerOrder === 'providerFirst' ? 'boundary-left' : 'boundary-right';

    const mainClass = cx('EnforcementBoundaries-main EnforcementBoundaries-body');

    return (
      <div>
        {consumersElements || providersServices.length ? (
          <div className={mainClass}>
            {providerConsumerOrder === 'providerFirst' ? providersServices : consumersElements}
            <Icon
              name={arrowClass}
              size="xlarge"
              styleClass={rotateArrow ? 'boundary-arrow-rotate' : 'boundary-arrow'}
              tid="arrowicon"
            />
            {providerConsumerOrder === 'providerFirst' ? consumersElements : providersServices}
          </div>
        ) : null}
      </div>
    );
  },

  render() {
    return this.showOrdering();
  },
});
