/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import React from 'react';
import _ from 'lodash';
import {State} from 'react-router';
import actionCreators from '../../actions/actionCreators';
import {Icon, Navbar, SpinnerOverlay} from '../../components';
import {RulesetForm} from '../../components/Forms';
import Constants from '../../constants';
import intl from 'intl';
import {getSessionUri, getInstanceUri} from '../../lib/api';
import {RouterMixin, StoreMixin, UserMixin, UnsavedChangesMixin} from '../../mixins';
import {RulesetStore, SessionStore} from '../../stores';
import {RestApiUtils} from '../../utils';

function getStateFromStores() {
  const href = getSessionUri(getInstanceUri('rule_sets'), {
    pversion: 'draft',
    rule_set_id: this.getParams().id,
  });

  return {
    ruleset: RulesetStore.getSpecified(href),
    status: [RulesetStore.getStatus()],
  };
}

export default React.createClass({
  mixins: [State, RouterMixin, UserMixin, UnsavedChangesMixin, StoreMixin(RulesetStore, getStateFromStores)],

  componentWillMount() {
    if (SessionStore.isUserWithReducedScope() || SessionStore.isEdge()) {
      this.replaceWith('landing');
    }
  },

  componentDidMount() {
    if (SessionStore.isUserWithReducedScope()) {
      return;
    }

    this.getRuleset();
  },

  componentDidUpdate() {
    if (this.getParams().id !== this.rulesetId) {
      this.getRuleset();
    }
  },

  async getRuleset() {
    this.rulesetId = this.getParams().id;

    try {
      const {body} = await RestApiUtils.ruleSets.getInstance(this.rulesetId, 'draft', true);

      if (!body || _.isEmpty(body.caps) || this.isUserReadOnlyOrProvisioner()) {
        this.replaceWith('rulesetSummary', {id: this.getParams().id, pversion: 'draft'});
      }
    } catch (error) {
      if (error.status === 404) {
        this.replaceWith('rulesets.list');
      }
    }
  },

  handleClose() {
    this.transitionTo('rulesetSummary', {id: this.getParams().id, pversion: 'draft'});
  },

  handleSave(model) {
    if (!this.hasChanged()) {
      this.handleClose();

      return;
    }

    RestApiUtils.ruleSet.update(this.getParams().id, model).then(() => {
      RestApiUtils.secPolicies.dependencies();
      actionCreators.resetFacetValues('rulesetList');
      this.ignoreChanges = true;
      this.transitionTo('rulesetSummary', {id: this.getParams().id, pversion: 'draft'});
    });
  },

  hasChanged() {
    return this.refs.form ? this.refs.form.hasChanged() : false;
  },

  render() {
    if (!this.state.ruleset) {
      return this.state.status.includes(Constants.STATUS_BUSY) ? <SpinnerOverlay /> : null;
    }

    let label = this.state.ruleset.enabled ? null : (
      <div>
        <Icon name="cancel" styleClass="Warning" position="before" />
        {intl('Rulesets.Disabled')}
      </div>
    );

    label ||= intl('Common.Rulesets');

    return (
      <div className="RulesetSummaryEdit" data-tid="page-ruleset-edit">
        {this.state.status.includes(Constants.STATUS_BUSY) ? <SpinnerOverlay /> : null}
        <Navbar title={this.state.ruleset.name} label={label} type="edit" onUp={this.handleClose} />
        <RulesetForm model={this.state.ruleset} onSubmit={this.handleSave} onCancel={this.handleClose} ref="form" />
      </div>
    );
  },
});
