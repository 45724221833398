/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import React from 'react';
import intl from 'intl';
import cx from 'classnames';
import {Icon, RouteLink} from '.';
import {ProvisionStore, HealthStore} from '../stores';
import {StoreMixin} from '../mixins';
import {State, Navigation, HashLocation} from 'react-router';
import * as webStorageUtils from 'utils/webStorage';

function getStateFromStores() {
  const {red, total, timeout} = ProvisionStore.getProvisionProgress();

  return {
    red,
    total,
    timeout,
    provisionStatus: ProvisionStore.getProvisionStatus(),
    listenOnlyMode: HealthStore.getLocalListenOnlyEnableTime(),
  };
}

export default React.createClass({
  mixins: [State, Navigation, StoreMixin(ProvisionStore, getStateFromStores)],

  getInitialState() {
    //Initialize hide and show with session entry from legacy page pop-up
    const provisionProgress = webStorageUtils.getSessionItem('ProvisionProgress', {remove: true}) || {
      hide: false,
      show: false,
      complete: {},
    };

    return {
      hide: provisionProgress.hide,
      show: provisionProgress.show,
      complete: provisionProgress.complete,
      timeoutFromSession: provisionProgress.timeout,
    };
  },

  componentDidMount() {
    HashLocation.addChangeListener(this.handlePathChange);
    this.handlePathChange();
    webStorageUtils.setSessionItem('ProvisionProgress', this.state);
  },

  componentDidUpdate(prevProps, prevState) {
    if (prevState.provisionStatus === 'pending' && this.state.provisionStatus !== 'pending') {
      if (this.state.listenOnlyMode) {
        this.setState({show: false});
      } else {
        this.setState({show: !this.state.hide});
      }
    } else if (prevState.provisionStatus !== 'pending' && this.state.provisionStatus === 'pending') {
      this.setState({show: true, hide: false});
    }

    //To enable state sharing between old and new page provision pop-up
    //Comparing hide and show state is enough as Old leagacy pages do not have provision modal
    if (this.state !== prevState) {
      webStorageUtils.setSessionItem('ProvisionProgress', this.state);
    }
  },

  componentWillUnmount() {
    HashLocation.removeChangeListener(this.handlePathChange);
  },

  handleCloseClick(evt) {
    evt.preventDefault();
    this.setState({hide: true});
  },

  handlePathChange() {
    if (!this.isMounted()) {
      // Due to some bug in react-router this method is called even after removeChangeListener in componentWillUnmount
      return;
    }

    if (this.getPathname().includes('provisioning')) {
      this.setState({show: false});
    } else if (this.state.provisionStatus === 'pending') {
      this.setState({show: !this.state.hide});
    }
  },

  render() {
    const {red, total, timeout, timeoutFromSession, hide, show, listenOnlyMode} = this.state;

    if (!total || hide || !show) {
      return null;
    }

    let redAgentTimeout = timeout;

    //Check if it previously timeout but now is in progress
    if (timeoutFromSession && !red) {
      redAgentTimeout = timeoutFromSession;
    }

    const complete = !redAgentTimeout && !red;
    const classNames = cx('ProvisionProgress', {
      'ProvisionProgress--Complete': complete && !listenOnlyMode,
    });
    const query = {};

    const contentClassNames = cx('ProvisionProgress-Content', {
      'ProvisionProgress-Content-NoBar': listenOnlyMode || timeout,
    });

    if (complete) {
      query.isComplete = true;
      query.workloadsAffected = this.state.complete ? this.state.complete.workloadsAffected : null;
    }

    return (
      <RouteLink to="provisioning" query={query} className={classNames} data-tid="provisionprogress">
        <div className="ProvisionProgress-CloseIcon" onClick={this.handleCloseClick}>
          <Icon size="large" name="close" />
        </div>

        <div className={contentClassNames}>
          {!listenOnlyMode &&
            !timeout &&
            (complete ? (
              <div className="ProvisionProgress-CompleteIcon">
                <Icon size="xxlarge" name="check" />
              </div>
            ) : (
              <div className="ProvisionProgress-Status-Bar" data-tid="statusbar" />
            ))}

          {listenOnlyMode
            ? intl('Provision.ListenOnlyModeSynchronizeCount', {count: red})
            : complete
            ? intl('Provision.ProvisioningComplete')
            : redAgentTimeout
            ? intl('Provision.ProvisioningInProgress', {ellipsis: true})
            : intl('Provision.SynchronizingCount', {count: red})}
        </div>
      </RouteLink>
    );
  },
});
