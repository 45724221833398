/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */

import type {SyntheticEvent, MouseEvent as ReactMouseEvent} from 'react';

export type ScrollToTopElement = (ele: {element: Element; offsetElements?: Element[]; behavior?: ScrollBehavior}) => {
  scroller: Element;
  moveBy: number;
};

/**
 * loosely describe either DOM native events or React SyntheticEvent that have a partial sub-structure of MouseEvent
 */
export type MouseEventLike<T = Element> = (SyntheticEvent | Event) &
  Partial<Pick<ReactMouseEvent<T>, 'ctrlKey' | 'metaKey' | 'shiftKey' | 'altKey' | 'button'>>;
export type MouseEventLikeHandler<T = Element> = {bivarianceHack(event: MouseEventLike<T>): void}['bivarianceHack'];

export type OpenHref = (options: {
  href: string;
  evt: MouseEventLike;
  target?: string;
  pageName?: string;
  download?: boolean;
}) => boolean;

export interface ElementClear {
  element: HTMLElement;
  clear: () => void;
}

export type OnScrollEnd = (config: {
  onDone: () => void;
  scroller: Element | Window;
  debounce?: number;
  startWaitingImmediately?: boolean;
}) => () => void;

/**
 * Get supported name of mouse wheel event
 * https://stackoverflow.com/questions/25204282/mousewheel-wheel-and-dommousescroll-in-javascript
 */
export const wheelEventName =
  'onwheel' in document.createElement('div')
    ? 'wheel' // Modern browsers support 'wheel'
    : 'mousewheel'; // Webkit and IE support at least 'mousewheel'

export const CSSSupports =
  typeof CSS !== 'undefined' && CSS !== null && typeof CSS.supports === 'function' ? CSS.supports : false;

/**
 * If sticky positioning is supported
 * @type {boolean}
 */
export const isStickySupported =
  CSSSupports && (CSSSupports('position', 'sticky') || CSSSupports('position', '-webkit-sticky'));

/**
 * If IntersectionObserver is supported
 * @type {boolean}
 */
export const isIntersectionObserverSupported = typeof IntersectionObserver === 'function';

/**
 * If scroll-behavior and options object in scrollTo method are supported
 * @type {boolean}
 */
export const isSmoothScrollSupported = 'scrollBehavior' in document.documentElement.style;

/**
 * Method to check if the user prefers reduced-motion
 * https://developers.google.com/web/updates/2019/03/prefers-reduced-motion
 */
export const isMotionReduced = (() => {
  let reduced = false;

  if (window.matchMedia) {
    const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');

    reduced = mediaQuery.matches;

    if (mediaQuery.addEventListener) {
      mediaQuery.addEventListener('change', () => {
        reduced = mediaQuery.matches;
      });
    }
  }

  return () => reduced;
})();

/**
 * If display:grid is supported
 * @type {boolean}
 */
export const isGridSupported =
  CSSSupports &&
  CSSSupports('display', 'grid') &&
  // Only Edge 16+, where normal grid spec is supported
  // So check for position:sticky, and check -webkit-sticky for Safari
  isStickySupported;

/**
 * Prevent default event and stop its propagation
 *
 * @param evt
 * @returns
 */
export const preventEvent = (evt: Pick<Event, 'preventDefault' | 'stopPropagation'>): boolean => {
  try {
    evt.preventDefault();
    evt.stopPropagation();
  } catch (error) {
    console.log('Event failed to be prevented', error);
  }

  return false;
};

/**
 * Prevent browser's back/forward history navigation on element's scroll using touchpad/mouse,
 * Originally it was a long-living hack, but badly affects performance and produce warnings in modern browsers about non-passive handlers
 * So if overscroll-behavior is supported, this method does nothing and overscroll-behavior must be used since it's native
 *
 * @param element - dom element with contain scroll
 * @returns
 */
export const preventHistoryChangeOnScroll = (function (): (element: HTMLElement) => ElementClear {
  let isOverscrollBehaviourPreventNavigation;

  if (browser.os.name === 'Windows') {
    // On it is windows there is no touchpad gestures (but screen touch), so no need to prevent it
    isOverscrollBehaviourPreventNavigation = true;
  } else {
    // CSS property overscroll-behavior Firefox 59+, Chrome 65+
    // https://caniuse.com/#feat=css-overscroll-behavior
    // https://developers.google.com/web/updates/2017/11/overscroll-behavior
    isOverscrollBehaviourPreventNavigation = CSSSupports && CSSSupports('overscroll-behavior', 'none');
  }

  if (isOverscrollBehaviourPreventNavigation) {
    return (element: HTMLElement): ElementClear => ({
      element,
      clear: () => {},
    });
  }

  return (element: HTMLElement) => {
    let preventedLeft = false;
    let preventedRight = false;

    const preventHistoryChange = (evt: WheelEvent | Event): ElementClear | void => {
      /** Note: wheelDeltaX is deprecated */
      const delta = 'deltaX' in evt ? evt.deltaX : 0;

      if (delta === 0) {
        return;
      }

      if (delta < 0) {
        if (preventedLeft || element.scrollLeft <= 0) {
          preventedLeft = true;
          evt.preventDefault();
        }

        if (preventedRight) {
          preventedRight = false;
        }
      } else {
        if (preventedRight || element.scrollLeft + element.offsetWidth >= element.scrollWidth) {
          preventedRight = true;
          evt.preventDefault();
        }

        if (preventedLeft) {
          preventedLeft = false;
        }
      }
    };

    element.addEventListener(wheelEventName, preventHistoryChange);

    return {
      element,
      clear() {
        element.removeEventListener(wheelEventName, preventHistoryChange);
      },
    };
  };
})();

/**
 * Check that user clicked on element (link, for example) without pressing some keys expecting page opening in new tab
 * (new browsing context in terms of HTML5)
 *
 * If link's target other than '_self' or empty, let browser decide what to do
 * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#attr-target
 *
 * Ctrl/Cmd + Shift + Click --> Open a link in a new tab in foreground
 * Ctrl/Cmd + Click --> Open a link in a new tab in backgroud
 * Shift + Click --> Open a link in a new window
 * Alt + Click --> Save the target on disk (open the Save As dialog)
 * Middle mouse click --> Open a link in a new tab
 *
 * @param evt Click event
 * @param target Anchor target attribute value
 * @returns
 */
export const isClickInBrowsingContext = (evt: MouseEventLike, target = '_self'): boolean =>
  target === '_self' &&
  (!evt ||
    (!evt.ctrlKey &&
      !evt.metaKey &&
      !evt.shiftKey &&
      !evt.altKey &&
      // https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/button#return_value
      ((evt.type !== 'click' && evt.button !== 1) || evt.button === 0)));

/**
 * Emulate click on element.
 * If original event is passed and MouseEvent constructor is supported, attributes are copied from specified event
 *
 * @param element
 * @param originalNativeEvent
 * @param options
 * @returns
 */
export const clickElement = (
  element: HTMLElement,
  originalNativeEvent: MouseEventInit,
  options: Partial<MouseEventInit> = {},
): boolean => {
  if (typeof MouseEvent === 'function') {
    element.dispatchEvent(
      new MouseEvent(
        'click',
        originalNativeEvent || {
          view: window,
          bubbles: true,
          cancelable: true,
          ...options,
        },
      ),
    );
  } else if (typeof element.click === 'function') {
    element.click();
  } else if (document.createEvent) {
    const eventObj = document.createEvent('MouseEvents');

    eventObj.initEvent('click', true, true);
    element.dispatchEvent(eventObj);
  } else {
    return false;
  }

  return true;
};

/**
 * Open href in new tab/window or download
 * Uses temporary anchor element to avoid 'window.open' blocking and treat open type according to pressed key
 *
 * @param href
 * @param evt Original click event to get pressed keys from it. Will be just opened in new tab if omitted. Use React's Synthetic Event
 * @param target Anchor target attribute value
 * @param pageName Page name, to create file name, if user want to download page (pressed Alt)
 * @param download
 * @returns
 */
export const openHref: OpenHref = ({href, evt, target = '_self', pageName = 'download', download = false}) => {
  let result = true;

  try {
    const a = document.createElement('a');

    a.setAttribute('href', href);

    if (target !== '_self') {
      // Any custom target
      a.setAttribute('target', target);
    } else if (!evt || evt.ctrlKey || evt.metaKey || evt.shiftKey || ('button' in evt && evt.button === 1)) {
      // New tab by pressed meta keys
      a.setAttribute('target', '_blank');
    } else if (download || (evt && evt.altKey)) {
      // If is download action
      a.setAttribute('download', `${pageName}.html`);
    }

    // For Firefox clicked ahref must be on the page, even invisible
    a.style.display = 'none';
    document.body.append(a);

    // Simply call click event on the link,
    // and the browser will take into account meta keys pressed during that call
    // to open the link either on the current page or in a new tab/window
    a.click();
    a.remove();
  } catch (error) {
    console.error(error);
    result = false;
  }

  return result;
};

/**
 * Get first scroll parent of element
 * https://stackoverflow.com/questions/35939886/find-first-scrollable-parent/42543908#42543908
 *
 * @param element Document
 * @param includeHidden Find scroll parent if there is no overflow yet
 * @returns scroll parent and sticky offset elements
 */
export const getScrollParentAndOffsetElements = (
  element: Element,
  includeHidden = true,
): {parent: Element; offsetElements: Element[]} => {
  const overflowRegex = includeHidden ? /(auto|scroll|hidden)/ : /(auto|scroll)/;
  const excludeStaticParent = getComputedStyle(element).position === 'absolute';
  let parent: Element | null = element;
  const offsetElements: Element[] = [];

  while (true) {
    parent = parent.parentElement;

    if (parent === document.body || !parent) {
      return {parent: document.documentElement, offsetElements};
    }

    parent.childNodes.forEach(cNode => {
      const child = cNode as Element;

      if (child.className?.includes('StickyShadow_sticky')) {
        offsetElements.push(child);
      }
    });

    const style = getComputedStyle(parent);

    if (style.position === 'fixed') {
      return {parent: document.documentElement, offsetElements};
    }

    if (excludeStaticParent && style.position === 'static') {
      continue;
    }

    if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX)) {
      return {parent, offsetElements};
    }
  }
};

/**
 * Scroll to top of document element
 *
 * @param element Document element
 * @param offsetElements Find element position relative to offsetElements
 * @param behavior Scrolling behavior
 * @returns
 */
export const scrollToTopOfElement: ScrollToTopElement = ({element, offsetElements = [], behavior = 'smooth'}) => {
  const {parent: scroller, offsetElements: stickyElements} = getScrollParentAndOffsetElements(element);
  const {top: scrollerTop, height: scrollerHeight} = scroller.getBoundingClientRect();
  let {top: elementTop, height: elementHeight} = element.getBoundingClientRect();

  if (scroller !== document.documentElement) {
    elementTop -= scrollerTop;
  }

  const bottom = elementTop + elementHeight;
  const offsetHeight = [...offsetElements, ...stickyElements].reduce(
    (acc, element) => acc + element.getBoundingClientRect().height,
    0,
  );

  let moveBy = 0;

  if (elementTop - offsetHeight < 0) {
    // If top border of the element is above the bottom border of the offset, scroll up by the differentce (negative)
    moveBy = elementTop - offsetHeight;
  } else if (elementHeight > scrollerHeight - offsetHeight) {
    // Othersize, if top of the element is below the bottom border of the offset, but its total height is bigger the available space,
    // then just scroll to the top of the element
    moveBy = offsetHeight - elementTop;
  } else if (bottom > scrollerHeight) {
    // Othersize, if top of the element is below the bottom border of the offset, but bottom of the element is below the visible area,
    // and element's height can fit into the available space,
    // then scroll to align bottom of the element with the bottom of the scrollable
    moveBy = bottom - scrollerHeight;
  }

  // Scroll only if absolute value of delta is 1 or greater
  if (Math.floor(Math.abs(moveBy))) {
    scroller.scrollBy({top: moveBy, behavior});
  } else {
    moveBy = 0;
  }

  return {scroller, moveBy};
};

/**
 * Detect when scroll ends, by debouncing the onDone call by the given debounce timeout.
 * Returns a function to unsubscribe from the event.
 *
 * @param callback
 * @param scroller
 * @param debounce
 * @param startWaitingImmediately If scrolling has started already, start a countdown immediately
 * @returns
 */
export const onScrollEnd: OnScrollEnd = function ({onDone, scroller, debounce = 100, startWaitingImmediately = true}) {
  let scrollingTimeout: null | ReturnType<typeof setTimeout> = null;

  if (!scroller || scroller === document.documentElement || scroller === document.body) {
    scroller = window;
  }

  function checkScroll(): void {
    window.clearTimeout(Number(scrollingTimeout));

    scrollingTimeout = setTimeout(() => {
      scroller.removeEventListener('scroll', checkScroll);
      onDone();
    }, debounce);
  }

  if (startWaitingImmediately) {
    checkScroll();
  }

  // Listen to scroll event
  scroller.addEventListener('scroll', checkScroll);

  // Return unsubscribe function
  return function unsubscribeScrollEnd() {
    window.clearTimeout(Number(scrollingTimeout));
    scroller.removeEventListener('scroll', checkScroll);
  };
};

export const scrollToElement = ({element, offsetElements}: {element: Element; offsetElements: Element[]}): void => {
  // Scroll to the element if needed
  const {scroller, moveBy} = scrollToTopOfElement({element, offsetElements});

  if (moveBy) {
    const preventMouseOver = (evt: MouseEvent) => preventEvent(evt);

    // If we need to scroll to the focused element,
    // then we need to prevent handleMouseOver on all other elements, to prevent mouse over them in the middle of scrolling.
    // So first, intercept onMouseoverEvent on the document level, and stop propagating it
    document.addEventListener('mouseover', preventMouseOver, {capture: true});

    // Remove handleMouseOver prevention when scrolling is done
    onScrollEnd({
      scroller,
      debounce: 500,
      onDone() {
        document.removeEventListener('mouseover', preventMouseOver, {capture: true});
      },
    });
  }
};

/**
 * Checks to see if mouse cursor is within a DOM element
 *
 * @param element DOM element that we call getBoundingClientRect on
 * @param mouseEvent - can be a mouse event or an object, as long as it has clientX & clientY properties
 * @param margin - margins which will expand/shrink the area element
 * @returns Boolean
 */
export const isCursorWithinElement = function (
  element: Element,
  mouseEvent: {clientX: number; clientY: number},
  margin?: {top?: number; left?: number; right?: number; bottom?: number},
): boolean {
  let {top, right, bottom, left} = element.getBoundingClientRect();
  const {clientX, clientY} = mouseEvent;

  if (margin) {
    top -= margin.top ?? 0;
    left -= margin.left ?? 0;
    right += margin.right ?? 0;
    bottom += margin.bottom ?? 0;
  }

  return clientX >= left && clientX <= right && clientY >= top && clientY <= bottom;
};
