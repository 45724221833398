/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import React from 'react';
import _ from 'lodash';
import intl from 'intl';
import {getSessionUri, getInstanceUri} from '../../lib/api';
import RulesetStore from '../../stores/RulesetStore';
import VersionStore from '../../stores/VersionStore';
import SessionStore from '../../stores/SessionStore';
import {RouterMixin, StoreMixin, UserMixin, PolicyObjectAlertMixin} from '../../mixins';
import {Link, State} from 'react-router';
import Constants from '../../constants';
import actionCreators from '../../actions/actionCreators';
import {
  AttributeList,
  Badge,
  Button,
  Navbar,
  SpinnerOverlay,
  ConfirmationDialog,
  TabTo,
  Icon,
  AlertDialog,
  Notification,
} from '../../components';
import {RestApiUtils, RulesetUtils} from '../../utils';
import {ToolBar, ToolGroup} from '../../components/ToolBar';
import {deepPluck} from '../../utils/GeneralUtils';

function getStateFromStore() {
  let currentActive;
  let currentDraft;
  const policyVersion = this.getParams().pversion;
  const href = getSessionUri(getInstanceUri('rule_sets'), {
    pversion: policyVersion,
    rule_set_id: this.getParams().id,
  });
  let previousPolicyVersion;

  if (policyVersion === 'draft') {
    previousPolicyVersion = 'active';
  } else {
    previousPolicyVersion = parseInt(policyVersion, 10) - 1;

    const activeHref = getSessionUri(getInstanceUri('rule_sets'), {
      pversion: 'active',
      rule_set_id: this.getParams().id,
    });
    const draftHref = getSessionUri(getInstanceUri('rule_sets'), {
      pversion: 'draft',
      rule_set_id: this.getParams().id,
    });

    currentActive = RulesetStore.getSpecified(activeHref);
    currentDraft = RulesetStore.getSpecified(draftHref);
  }

  const diffHref = getSessionUri(getInstanceUri('rule_sets'), {
    pversion: previousPolicyVersion,
    rule_set_id: this.getParams().id,
  });

  return {
    ruleset: RulesetStore.getSpecified(href),
    diffRuleset: RulesetStore.getSpecified(diffHref),
    draftAndActive: {currentActive, currentDraft},
    status: RulesetStore.getStatus(),
  };
}

export default React.createClass({
  mixins: [
    State,
    RouterMixin,
    UserMixin,
    PolicyObjectAlertMixin,
    StoreMixin([RulesetStore, RulesetStore, VersionStore], getStateFromStore),
  ],

  componentWillMount() {
    if (SessionStore.isUserWithReducedScope() || SessionStore.isEdge()) {
      this.replaceWith('landing');
    }
  },

  componentDidMount() {
    RestApiUtils.user.orgs({representation: 'org_permissions'}, SessionStore.getUserId(), true);
    this.getRuleset();
    RestApiUtils.secPolicies.getCollection();
    RestApiUtils.ruleSets.getInstance(this.rulesetId, 'active', true, true).catch(_.noop);
    RestApiUtils.ruleSets.getInstance(this.rulesetId, 'draft', true, true).catch(_.noop);
  },

  componentDidUpdate() {
    if (this.getParams().id !== this.rulesetId || this.getParams().pversion !== this.rulesetVersion) {
      this.getRuleset();
      this.getHistory();
    }
  },

  getHistory() {
    if (this.getParams().pversion !== 'draft' && this.getParams().pversion !== 'active') {
      RestApiUtils.secPolicies.getCollection();
      RestApiUtils.ruleSets.getInstance(this.rulesetId, 'active', true, true).catch(_.noop);
      RestApiUtils.ruleSets.getInstance(this.rulesetId, 'draft', true, true).catch(_.noop);
    }
  },

  async getRuleset() {
    this.rulesetId = this.getParams().id;
    this.rulesetVersion = this.getParams().pversion;

    const policyVersion = this.getParams().pversion;
    let previousPolicyVersion;

    if (policyVersion === 'draft') {
      previousPolicyVersion = 'active';
    } else if (policyVersion === 'active') {
      previousPolicyVersion = 'draft';
    } else {
      previousPolicyVersion = Number(policyVersion) - 1;
    }

    try {
      await Promise.all([
        RestApiUtils.users.getCollection(),
        RestApiUtils.ruleSets.getInstance(this.rulesetId, policyVersion, true),
        RestApiUtils.ruleSets.getInstance(this.rulesetId, previousPolicyVersion, true).catch(err => {
          if (err.status !== 404) {
            // Mute 404 on this resource
            throw err;
          }
        }),
      ]);
    } catch (err) {
      if (err.status === 404) {
        return this.replaceWith('rulesets.list');
      }

      console.error('getRuleset', err);
    }

    if (this.isMounted()) {
      this.setState({ready: true});
    }
  },

  handleDelete() {
    RestApiUtils.ruleSet.delete(this.state.ruleset.href).then(() => {
      RestApiUtils.secPolicies.dependencies();
      this.transitionTo('rulesets.list');
    });
  },

  handleDeleteConfirm() {
    const messages = [];

    if (RulesetUtils.applicationsInRuleset(this.state.ruleset)) {
      messages.push({
        type: 'warning',
        text: intl('Rulesets.RulesetWarning'),
      });
    }

    messages.push(intl('Rulesets.DeleteRuleset'));
    actionCreators.openDialog(
      <ConfirmationDialog
        title={intl('Rulesets.Delete', {count: 1})}
        message={messages}
        onConfirm={this.handleDelete}
      />,
    );
  },

  handleDuplicate() {
    if (deepPluck(this.state.ruleset.rules, 'workload').some(workload => workload.deleted)) {
      actionCreators.openDialog(<AlertDialog message={intl('Rulesets.RuleWithUpairedWorkload')} />);

      return;
    }

    // Navigate with replace, so after cloning back button on detail page will lead to RulesetList page
    this.replaceWith('rulesetClone', {
      pversion: this.getParams().pversion,
      rule_set_id: this.rulesetId,
    });
  },

  handleEdit() {
    if (!this.isUserReadOnlyAll()) {
      this.transitionTo('rulesetSummaryEdit', {
        id: this.rulesetId,
      });
    }
  },

  handleBack() {
    this.transitionTo('rulesets.list');
  },

  render() {
    if (!this.state || !this.state.ruleset || !this.state.ruleset.rules) {
      return <SpinnerOverlay />;
    }

    const ruleset = this.state.ruleset;
    const diffRuleset = this.state.diffRuleset;
    const readOnly =
      !Array.isArray(ruleset.caps) || !ruleset.caps.includes('write') || ruleset.update_type === 'delete';
    const title = ruleset ? ruleset.name : null;
    let label =
      ruleset && ruleset.enabled ? null : (
        <div>
          <Icon name="cancel" styleClass="Warning" position="before" />
          {intl('Rulesets.Disabled')}
        </div>
      );

    label ||= intl('Common.Rulesets');

    const routeParams = {
      id: this.getParams().id,
      pversion: this.getParams().pversion,
    };

    const attributes = ruleset
      ? [
          {
            key: intl('Common.Name'),
            value:
              diffRuleset && ruleset.name !== diffRuleset.name ? (
                <div>
                  <div className="text-updated">{ruleset.name}</div>
                  <div className="text-deleted">{diffRuleset.name}</div>
                </div>
              ) : (
                ruleset.name
              ),
            state: diffRuleset && ruleset.name !== diffRuleset.name ? 'new' : 'old',
          },
          {
            key: intl('Common.Description'),
            value:
              diffRuleset && ruleset.description !== diffRuleset.description ? (
                <div>
                  <div className="text-updated">{ruleset.description}</div>
                  <div className="text-deleted">{diffRuleset.description}</div>
                </div>
              ) : (
                ruleset.description
              ),
            state: diffRuleset && ruleset.description !== diffRuleset.description ? 'new' : 'old',
          },
          {
            key: intl('Common.Status'),
            value: intl(ruleset.enabled ? 'Common.Enabled' : 'Common.Disabled'),
            state: diffRuleset && ruleset.enabled !== diffRuleset.enabled ? 'new' : 'old',
          },
          !ruleset.global
            ? {
                key: intl('Common.Scopes'),
                value: (
                  <Link to="rulesetRules" className="AttributeList-link" params={routeParams}>
                    {ruleset.scopes.length}
                  </Link>
                ),
                state: diffRuleset && RulesetUtils.hasScopesDiff(ruleset.scopes, diffRuleset.scopes) ? 'new' : 'old',
              }
            : null,
          {
            key: intl('Common.Rules'),
            value: (
              <Link to="rulesetRules" className="AttributeList-link" params={routeParams}>
                {ruleset.rules.length + ruleset.ip_tables_rules.length}
              </Link>
            ),
            state: RulesetUtils.hasRulesUpdate(ruleset.rules) ? 'new' : 'old',
          },
          {
            key: intl('Common.Created'),
            value: intl.utils.format.dateAtTimeBy(ruleset.created_at, ruleset.created_by_user),
          },
          ruleset.update_type !== 'create'
            ? {
                key: intl('Common.Updated'),
                value: intl.utils.format.dateAtTimeBy(ruleset.updated_at, ruleset.updated_by_user),
              }
            : null,
          ruleset.external_data_set !== undefined && ruleset.external_data_set !== null
            ? {
                tid: 'extdataset',
                key: intl('Common.ExternalSet'),
                value: ruleset.external_data_set,
              }
            : null,
          ruleset.external_data_reference !== undefined && ruleset.external_data_reference !== null
            ? {
                tid: 'extdataref',
                key: intl('Common.ExternalReference'),
                value: ruleset.external_data_reference.includes('\r') ? (
                  <div>
                    <span style={{paddingRight: '10px'}}>
                      <Badge type="updated">{intl('Common.Edited')}</Badge>
                    </span>
                    <span>{ruleset.external_data_reference}</span>
                  </div>
                ) : (
                  ruleset.external_data_reference
                ),
              }
            : null,
        ]
      : [];

    return (
      <div className="RulesetSummary">
        {this.state.status === Constants.STATUS_BUSY ? <SpinnerOverlay /> : null}
        <Navbar title={title} label={label} type="detail" onUp={this.handleBack} />
        <div className="NavMenu" data-tid="comp-navmenu">
          <div className="NavMenu-item NavMenu-item--active" data-tid="comp-navmenu-item comp-navmenu-item-summary">
            <TabTo to="rulesetSummary" params={routeParams}>
              {intl('Common.Summary')}
            </TabTo>
          </div>
          <div className="NavMenu-item" data-tid="comp-navmenu-item comp-navmenu-item-scope">
            <TabTo to="rulesetRules" params={routeParams}>
              {intl('Rulesets.ScopesAndRules')}
            </TabTo>
          </div>
          <div className="RulesetScopesAndRules-Duplicate">
            <Button
              text={intl('Rulesets.DuplicateRuleset')}
              icon="duplicate"
              type="secondary"
              onClick={this.handleDuplicate}
              disabled={readOnly}
              tid="duplicate"
            />
          </div>
        </div>
        {SessionStore.isUserScoped() && (
          <Notification
            type="instruction"
            message={intl(
              'Rulesets.ScopedUserRuleset',
              {
                pageName: (
                  <Link to="ruleSearch" params={{pversion: 'draft'}} className="RuleSearch-link">
                    {intl('Common.Rulesets')}
                  </Link>
                ),
              },
              {jsx: true},
            )}
          />
        )}
        {SessionStore.isUserScoped() &&
          (ruleset.update_type === 'update' || _.isNumber(routeParams.pversion)) &&
          !diffRuleset && (
            <Notification type="instruction" message={intl('Rulesets.SomeInformationNotInAssignedScopes')} />
          )}
        {this.getPolicyObjectAlert({
          policyObject: ruleset,
          routeTo: 'rulesetSummary',
          shaded: true,
          readOnly,
          isRuleset: true,
          hasRulesetPriv: this.state.ruleset.caps.includes('provision') && !SessionStore.isSuperclusterMember(),
          draftAndActive: this.state.draftAndActive,
        })}
        <ToolBar>
          {this.rulesetVersion === 'draft' && !readOnly ? (
            <ToolGroup>
              <Button text={intl('Common.Edit')} icon="edit" autoFocus={true} onClick={this.handleEdit} tid="edit" />
              <Button
                text={intl('Common.Remove')}
                icon="remove"
                type="secondary"
                onClick={this.handleDeleteConfirm}
                tid="remove"
              />
            </ToolGroup>
          ) : null}
        </ToolBar>
        <div className="RulesetSummary-attributes">
          <AttributeList data={attributes} />
        </div>
      </div>
    );
  },
});
