/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {createStore} from '../lib/store';
import Constants from '../constants';
import TrafficStore from './TrafficStore';
import UserStore from './UserStore';
import dispatcher from '../actions/dispatcher';
import * as webStorageUtils from 'utils/webStorage';

const UPDATE_EVENT = 'update';
// mapRoute is route params
// mapRouteName is route
const storedMapData = webStorageUtils.getSessionItem('MapPageStore');
let mapRoute = storedMapData && storedMapData.mapRoute ? storedMapData.mapRoute : {};
let mapRouteName = storedMapData && storedMapData.mapRouteName ? storedMapData.mapRouteName : null;
let mapType = 'app'; // was null earlier
let appMapRoute = storedMapData && storedMapData.appMapRoute ? storedMapData.appMapRoute : {};
let appMapRouteName = storedMapData && storedMapData.appMapRouteName ? storedMapData.appMapRouteName : null;
let mapLevel = storedMapData && storedMapData.mapLevel ? storedMapData.mapLevel : null; // include: location, cluster, workload, full
let appMapLevel = storedMapData && storedMapData.appMapLevel ? storedMapData.appMapLevel : null; // include: globalAppGroup, focusedAppGroup, connectedAppGroup
let loadMapPageStore = false;
let totalWorkloads = -1;
let noLocationWorkloads = -1;
let policyVersion = sessionStorage.getItem('policy_version');
let appMapVersion = storedMapData && storedMapData.appMapVersion ? storedMapData.appMapVersion : 'policy';

const mapNotification = {};

function setMapRoute(data) {
  if (data.type === 'cluster') {
    data.type = 'group';
  }

  if (data.prevType === 'cluster') {
    data.prevType = 'group';
  }

  if (mapType === 'loc' || !mapType) {
    mapRoute = data;
    mapRouteName = 'mapLevel';

    if (_.isEmpty(mapRoute)) {
      mapRouteName = 'map';
    } else if (!_.isEmpty(mapRoute.prevtype)) {
      mapRouteName = 'prevMapLevel';
    }
  } else if (mapType === 'app') {
    appMapRoute = data;
    appMapRouteName = 'appMapLevel';

    if (_.isEmpty(appMapRoute)) {
      appMapRouteName = 'appMap';
    } else if (!_.isEmpty(appMapRoute.prevtype)) {
      appMapRouteName = 'prevAppMapLevel';
    }
  }

  updateWebStorage();
}

function setMapLevel() {
  if (mapType === 'app') {
    if (_.isEmpty(appMapRoute)) {
      appMapLevel = 'globalAppGroup';
    } else if (appMapRoute.type === 'focused') {
      appMapLevel = 'focusedAppGroup';
    } else if (appMapRoute.type === 'consuming' || appMapRoute.type === 'providing') {
      appMapLevel = 'connectedAppGroup';
    }
  }

  // only loc map need to check totalWorkloads
  if (mapType === 'loc') {
    const locationThreshold = localStorage.getItem('location_view') || 50;

    if (_.isEmpty(mapRoute) && totalWorkloads > -1 && totalWorkloads >= locationThreshold) {
      mapLevel = 'location';
    } else if (mapRoute.type === 'location') {
      mapLevel = 'group';
    } else if (mapRoute.type === 'group') {
      mapLevel = 'workload';
    } else if (
      (totalWorkloads > -1 && mapRoute.type === 'full') ||
      (_.isEmpty(mapRoute) && totalWorkloads > -1 && totalWorkloads < locationThreshold)
    ) {
      mapLevel = 'full';
    }
  }

  updateWebStorage();
}

// To share data to the new pages
function updateWebStorage() {
  webStorageUtils.setSessionItem('MapPageStore', {
    mapLevel,
    mapRoute,
    mapRouteName,
    appMapLevel,
    appMapRoute,
    appMapRouteName,
    appMapVersion,
  });
}

export default createStore({
  dispatchHandler(action) {
    switch (action.type) {
      case Constants.UPDATE_MAP_ROUTE:
        dispatcher.waitFor([TrafficStore.dispatchToken]);
        setMapRoute(action.data);
        break;

      case Constants.USERS_LOGIN_SUCCESS:
        this.emitUpdateChange();
        this.emitChange();

        return true;

      case Constants.SET_TOTAL_WORKLOADS:
        totalWorkloads = action.data.totalWorkloads;
        noLocationWorkloads = action.data.noLocationWorkloads;
        this.emitUpdateChange();
        break;

      case Constants.UPDATE_MAP_TYPE:
        dispatcher.waitFor([TrafficStore.dispatchToken]);
        mapType = action.data;
        break;

      case Constants.SET_MAP_POLICY_VERSION:
        policyVersion = action.data;
        sessionStorage.setItem('policy_version', policyVersion);
        this.emitUpdateChange();
        this.emitChange();

        return true;

      case Constants.SET_APP_MAP_VERSION:
        appMapVersion = action.data;
        updateWebStorage();
        this.emitUpdateChange();
        this.emitChange();

        return true;

      case Constants.SET_MAP_NOTIFICATION:
        mapNotification[mapType] = action.data;
        break;

      case Constants.LOCATION_SUMMARY_GET_SUCCESS:
      case Constants.APP_GROUP_SUMMARY_GET_SUCCESS:
        dispatcher.waitFor([TrafficStore.dispatchToken]);
        break;

      default:
        return true;
    }

    setMapLevel();
    loadMapPageStore = true;
    this.emitChange();

    return true;
  },

  emitUpdateChange() {
    this.emit(UPDATE_EVENT);
  },

  addUpdateListener(callback) {
    this.on(UPDATE_EVENT, callback);
  },

  removeUpdateListener(callback) {
    this.removeListener(UPDATE_EVENT, callback);
  },

  isLoaded: () => loadMapPageStore,

  getMapLevel: () => {
    if (mapType === 'loc') {
      return mapLevel;
    }

    if (mapType === 'app') {
      return appMapLevel;
    }
  },

  getMapRoute: type => {
    const getType = type || mapType;

    if (getType === 'loc') {
      return mapRoute;
    }

    if (getType === 'app') {
      return appMapRoute;
    }
  },

  getMapRouteName: () => {
    if (mapType === 'loc') {
      return mapRouteName;
    }

    if (mapType === 'app') {
      return appMapRouteName;
    }
  },

  getLocMapType: () => {
    if (totalWorkloads > -1 && totalWorkloads < (localStorage.getItem('location_view') || 50)) {
      return 'full';
    }

    if (totalWorkloads > -1 && totalWorkloads > (localStorage.getItem('total_workload_threshold') || 100_000)) {
      return 'none';
    }

    return 'location';
  },

  getMapNotification: () => mapNotification[mapType],

  getTotalWorkloads: () => totalWorkloads,

  getNoLocationWorkloads: () => noLocationWorkloads,

  getPolicyVersion: () => policyVersion || UserStore.getDefaultPolicyVersion() || 'reported',

  // Temporary Fix For appMapVersion being set to a event-like object. Need a permanent fix for this.
  getAppMapVersion: () => (mapType === 'app' ? appMapVersion : 'policy'),

  getMapType: () => mapType,

  getMapLoadingOption: () => {
    // Only always rebuild if configured
    if (totalWorkloads > -1 && totalWorkloads < (localStorage.getItem('rebuild_always') || 0)) {
      return 'rebuildAlways';
    }

    // Rebuild on demand > 50
    if (totalWorkloads === -1 || totalWorkloads > (localStorage.getItem('rebuild_on_demand') || 50)) {
      return 'rebuildOnDemand';
    }

    // Default rebuild stale
    return 'rebuildStale';
  },

  getWorkloadLoadingOption: () =>
    totalWorkloads > -1 && totalWorkloads > (localStorage.getItem('rebuild_expanded_workloads') || 500),
});
