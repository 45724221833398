/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import he from 'he';
import {createElement, Fragment, isValidElement, type ReactElement} from 'react';
import {selectUnit} from '@formatjs/intl-utils';
import intlMessageFormat, {type PrimitiveType} from 'intl-messageformat';
import memoizeFormatConstructor from 'intl-format-cache';
import {locale, lang} from './locale';
import config from 'config';
import formats from './formats';
import langs, {type LangKeys} from './langs';
import * as utils from './utils';
import type {ReactStrictNode} from 'utils/types';

let messages = langs(config)[lang];

// TODO: intl-format-cache is deprecated and should be removed once formatjs is upgraded
declare module 'intl-format-cache' {
  // patching intl-format-cache to get better type inference
  interface MemoizeFormatConstructorFn {
    <
      T extends {
        new (...args: any[]): unknown;
      },
    >(
      constructor: T,
      cache?: Record<string, CacheValue>,
    ): (...args: ConstructorParameters<T>) => InstanceType<T>;
  }
}

const getListFormat = memoizeFormatConstructor(Intl.ListFormat);
const getNumberFormat = memoizeFormatConstructor(Intl.NumberFormat);
const getDateTimeFormat = memoizeFormatConstructor(Intl.DateTimeFormat);
const getMessageFormat = memoizeFormatConstructor(intlMessageFormat);
const getRelativeFormat = memoizeFormatConstructor(Intl.RelativeTimeFormat);

type FormatType = 'list' | 'date' | 'number';

const LIST = 'list';
const DATE = 'date';
const NUMBER = 'number';

function getOptionsFromFormats(type: string, value: string | number, format?: string | unknown) {
  let options = format;

  if (typeof format === 'string') {
    try {
      options = (formats[type] as Record<string, unknown>)[format];
    } catch {
      options = undefined;
    }

    if (options === undefined) {
      throw new ReferenceError(`No '${format}' found in intl/formats.js for the '${type}' type (value ${value})`);
    }
  }

  return options;
}

// Main format function
function format(
  type: 'list',
  value: Parameters<Intl.ListFormat['format']>[0],
  optionsOrFormat?: string | Intl.ListFormatOptions,
): string;
function format(
  type: 'date',
  value: Parameters<Intl.DateTimeFormat['format']>[0],
  optionsOrFormat?: string | Intl.DateTimeFormatOptions,
): string;
function format(
  type: 'number',
  value: Parameters<Intl.NumberFormat['format']>[0],
  optionsOrFormat?: string | Intl.NumberFormatOptions,
): string;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function format(type: FormatType, value: any, optionsOrFormat: any): string {
  const options = getOptionsFromFormats(type, value, optionsOrFormat);

  switch (type) {
    case DATE:
      return getDateTimeFormat(locale, options as Intl.DateTimeFormatOptions).format(value);
    case NUMBER:
      return getNumberFormat(locale, options as Intl.NumberFormatOptions).format(value);
    case LIST:
      return getListFormat(locale, options as Intl.ListFormatOptions).format(value);
    default:
      throw new Error(`Unrecognized format type: ${type}. Value: ${value}`);
  }
}

function formatDate(date: utils.LooserDate, options?: string | Intl.DateTimeFormatOptions) {
  date = new Date(date);

  if (!isFinite(Number(date))) {
    throw new TypeError(`A date or timestamp must be provided to formatDate(). Now it: ${date}`);
  }

  return format(DATE, date, options);
}

// Returns relative string with specified unit using Intl.RelativeTimeFormat
function formatRelative(
  num: number,
  unit: Intl.RelativeTimeFormatUnit,
  optionsOrFormat?: string | Intl.RelativeTimeFormatOptions,
) {
  if (__DEV__) {
    if (typeof num !== NUMBER) {
      throw new TypeError(`A number must be provided to formatRelative(). Now it is: ${num}`);
    }

    if (typeof unit !== 'string' || !utils.TIME_UNITS.includes(unit)) {
      throw new TypeError(`A valid string must be provided as unit to formatNumber(). Now it is: ${unit}`);
    }
  }

  const options = getOptionsFromFormats('relative', num, optionsOrFormat) as Intl.RelativeTimeFormatOptions;

  return getRelativeFormat(locale, options).format(num, unit);
}

// Returns relative string with automatically found unit
// For example, '4 days ago', 'tomorrow', 'in one minute'
function formatRelativeBestFit(date: utils.LooserDate, options?: Intl.RelativeTimeFormatOptions) {
  const timestamp = typeof date === 'number' ? date : (date instanceof Date ? date : new Date(date)).getTime();

  if (__DEV__ && isNaN(timestamp)) {
    throw new TypeError(`A date, string or timestamp must be provided to formatRelativeAutoUnit(). Now it is: ${date}`);
  }

  const diff = selectUnit(timestamp);

  return formatRelative(diff.value, diff.unit, options ? {numeric: 'auto', ...options} : {numeric: 'auto'});
}

function formatNumber(num: number, options?: string | Intl.NumberFormatOptions) {
  if (typeof num !== NUMBER) {
    throw new TypeError(`A number must be provided to formatNumber(). Now it: ${num}`);
  }

  return format(NUMBER, num, options);
}

function formatList(array: Parameters<Intl.ListFormat['format']>[0], options?: string | Intl.ListFormatOptions) {
  if (!Array.isArray(array)) {
    throw new TypeError(`An array must be provided to formatList(). Now it is: ${array}`);
  }

  return format(LIST, array, options);
}

// If message contains react elements, replace them with this elements
const reactifyParams = (function () {
  const matchCache = new Map();
  const injectReactElement = (
    messagePart: string,
    params: Record<string, PrimitiveType | ReactElement>,
    keys: string[],
    index: number,
  ): ReactStrictNode[] => {
    const param = keys[index];

    if (!param) {
      return [messagePart];
    }

    let match = matchCache.get(messagePart + param);

    if (!match) {
      // Render of the same string can be invoked several times, so it's better to
      // cache match result for each message part, because creating regexp and matching are not for free
      match = messagePart.match(new RegExp(`^(.*)({${param}})(.*)$`));
      matchCache.set(messagePart + param, match);
    }

    if (!match || !match.length) {
      return [messagePart];
    }

    const result: ReactStrictNode[] = [];
    const left = match[1];
    const right = match[3];

    if (left) {
      result.push(...injectReactElement(left, params, keys, index + 1));
    }

    // This is a safe type assertion because only valid react elements are access here
    // so we don't need to worry about the value being Date or undefined
    result.push(params[param] as ReactStrictNode);

    if (right) {
      result.push(...injectReactElement(right, params, keys, index + 1));
    }

    return result;
  };

  return (message: string, params: Record<string, PrimitiveType | ReactElement>) => {
    const normalParams: Record<string, PrimitiveType> = {};
    const normalParamsKeys: string[] = [];
    const reactParamsKeys: string[] = [];

    for (const [param, value] of Object.entries(params)) {
      if (isValidElement(value)) {
        normalParams[param] = `{${param}}`;
        reactParamsKeys.push(param);
      } else {
        normalParams[param] = value;
        normalParamsKeys.push(param);
      }
    }

    const formatted = normalParamsKeys.length
      ? getMessageFormat(message, locale, formats).format(normalParams)
      : message;

    // Create Fragment and spread result array over it to avoid the need to specify react key for each item
    // Fallback to span while we support react 14
    return createElement(
      Fragment || 'span',
      {},
      ...(reactParamsKeys.length ? injectReactElement(formatted, params, reactParamsKeys, 0) : [formatted]),
    );
  };
})();

type KeyMapper = (key: string) => string;
type ValueMapper = (value: string) => string;

// Optional mapper function that takes key as an argument and can return another key. By default returns the same key
let intlKeyMapper: KeyMapper = key => key;
// Setter to change default intlKeyMapper, for example, can be called in case of Edge in its index.js
export const setIntlKeyMapper = (mapper: KeyMapper): KeyMapper => (intlKeyMapper = mapper);

let intlValueMapper: ValueMapper = value => value;
// Setter to change default intlValueMapper, for example, can be called in case of Edge in its index.js
export const setIntlValueMapper = (mapper: ValueMapper): ValueMapper => (intlValueMapper = mapper);

// Wrap a key into intlKey call to count it by plugin-transform-intl-inline. Key gets inlined (unwrapped from intlKey call).
// Useful in conjunction with intlKeyMapper, to map original key with with the desired one without calling real intl().
// For example, {'Common.Workload': intlKey('Edge.Endpoint')} transformed to {'Common.Workload': 'Edge.Endpoint'}
export const intlKey: KeyMapper = key => key;

interface FormatMessageOptions {
  //
  /**
   * Params contain jsx (react elements)
   * we have html markup in message, but some of tags are react elements and we can't avoid it,
   * example we must wrap some param of message in Link, like 'You have <Link>{count}</Link> rules`,
   * can make like this:
   * You have {count} {countNumber, plural, =1 {rule} other {rules}}'
   * ('somekey', {count: <Link>{count}</Link>, countNumber: count})
   */
  jsx?: boolean;

  /**
   * Message contains html tags, so must not be escaped.
   * It useful when you have markup in your string (yeah, in some cases you can't avoid it because of ux-design).
   * For example, if string contains '<br>' and you put such string into your component
   * will escape it, and <br> will be as text on your page.
   * this flag method returns span with message as unescaped content, and '<br>' will remain line-break
   */
  html?: boolean;

  /**
   * Props that will be passed to html wrapper
   */
  htmlProps?: Record<string, unknown>;
}

// FIXME: instead of inline export default, this is a workaround.
// see https://github.com/import-js/eslint-plugin-import/issues/1590
export default formatMessage;

/**
 * Returns string, format it by ICU with intl-messageformat if params passed
 * @param key - Language key to find message in language bundle
 * @param params - ICU params
 * @param options - Options:
 * @param options.html - Message contains html tags, so must not be escaped
 *                                   It useful when you have markup in your string (yeah, in some cases you can't avoid it because of ux-design).
 *                                   For example, if string contains '<br>' and you put such string into your component
 *                                   react will escape it, and <br> will be as text on your page.
 *                                   With this flag method returns span with message as unescaped content, and '<br>' will remain line-break
 * @param options.htmlProps - Props that will be passed to html wrapper
 * @param options.jsx - Params contain jsx (react elements)
 *                                  If we have html markup in message, but some of tags are react elements and we can't avoid it,
 *                                  for example we must wrap some param of message in Link, like 'You have <Link>{count}</Link> rules`,
 *                                  we can make like this:
 *                                  'You have {count} {countNumber, plural, =1 {rule} other {rules}}'
 *                                  intl('somekey', {count: <Link>{count}</Link>, countNumber: count})
 */
function formatMessage(
  key: LangKeys,
  params: Record<string, PrimitiveType | ReactElement> | undefined,
  options: FormatMessageOptions & ({jsx: true} | {html: true}),
): ReactElement;
function formatMessage(
  key: LangKeys,
  params?: Record<string, PrimitiveType>,
  options?: FormatMessageOptions & {jsx?: false; html?: false},
): string;
function formatMessage(
  key: LangKeys,
  params?: Record<string, PrimitiveType | ReactElement>,
  {jsx = false, html = false, htmlProps}: FormatMessageOptions = {},
): string | ReactElement {
  const mappedKey = intlKeyMapper(key) as LangKeys;
  let message = messages[mappedKey];

  if (!message) {
    // Warn about keys that are not found in language bundle
    // Release task fails if it doesn't find some keys in bundle (plugin-transform-intl-inline)
    // This line exists in dev only, and is eliminated in release task
    if (__DEV__) {
      console.error('Intl not found key:', key);
    }

    return key;
  }

  if (params) {
    if (jsx) {
      return reactifyParams(message, params);
    }

    if (html) {
      params = Object.entries(params).reduce((result: Exclude<typeof params, undefined>, [name, value]) => {
        // need to encode(value) for cross-site scripting vulnerability
        // e.g. value = '<img src onerror=alert(1)'> convert to '&#x3C;img src=z onerror=alert(1)&#x3E';
        result[name] = typeof value === 'string' ? he.encode(value) : value;

        return result;
      }, {});
    }

    // we know params do not have ReactElements at this point
    message = getMessageFormat(message, locale, formats).format(params as Record<string, PrimitiveType>);
  }

  // Map message substrings _after_ it was formatted, to make sure we don't replace parameter names and other meta symbols
  message = intlValueMapper(message);

  return html ? createElement('span', {dangerouslySetInnerHTML: {__html: message}, ...htmlProps}) : message;
}

formatMessage.lang = lang;
formatMessage.locale = locale;

formatMessage.utils = utils;
formatMessage.format = format;
formatMessage.formats = formats;
formatMessage.list = formatList;
formatMessage.date = formatDate;
formatMessage.num = formatNumber;
formatMessage.rel = formatRelative;
formatMessage.relBestFit = formatRelativeBestFit;

// Dev only. Accept HMR updates of language bundle, rerequire it and invoke force update of current react tree
if (module.hot) {
  module.hot.accept('./langs', () => /*updatedDependencies*/ {
    // TODO: use dynamic import();
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    const langs = require('./langs').default;

    messages = langs(config)[lang];
  });
}
