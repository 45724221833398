/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import {createStore} from '../lib/store';
import Constants from '../constants';
import DialogStore from './DialogStore';
import RoutesStore from './RoutesStore';
import {slugToCamelCase} from '../utils/GeneralUtils';

let isOpen = false;
const history = [];

const getValidatedHistory = (routesMap, item) =>
  routesMap.get(item)?.isAvailable?.() ?? routesMap.get(item)?.handler?.isAvailable?.() ?? true;

export default createStore({
  dispatchHandler(action) {
    switch (action.type) {
      case Constants.OPEN_INSTANT_SEARCH:
        DialogStore.openDialog(action.data);
        isOpen = true;
        break;

      case Constants.CLOSE_INSTANT_SEARCH:
        DialogStore.closeDialog();
        isOpen = false;
        break;

      case Constants.KVPAIRS_GET_INSTANCE_SUCCESS:
      case Constants.KVPAIR_UPDATE_SUCCESS:
        if (action.options.params.key === 'instant_search_history' && action.data) {
          const routesMap = RoutesStore.getRoutesMap();

          for (const item of action.data) {
            if (item.collectionName) {
              item.collectionName = slugToCamelCase(item.collectionName, '_');
            }

            if (getValidatedHistory(routesMap, item.name)) {
              history.push(item);
            }
          }
        }

        break;

      default:
        return true;
    }

    this.emitChange();

    return true;
  },

  isOpen: () => isOpen,

  getHistory: () => history,
});
