/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import {createApiStore} from '../lib/store';
import OrgStore from './OrgStore';
import {StoreUtils} from '../utils';
import Constants from '../constants';

const rulesets = new Map(); // Object for storing rulesets returned by GET collection
const expandQueue = []; // Array to store non expanded rulesets info
const count = {};
const countByVersion = {};

function groupRulesetRule(ruleset) {
  if (!ruleset.rules) {
    return;
  }

  // Sort Rules on RAEL label types
  ruleset.rules.forEach(rule => {
    ['providers', 'consumers'].forEach(entityType => {
      const {role, app, env, loc, rest} = rule[entityType].reduce(
        (result, entity) => {
          result[entity.label && entity.label.key ? entity.label.key : 'rest'].push(entity);

          return result;
        },
        {role: [], app: [], env: [], loc: [], rest: []},
      );

      rule[entityType] = [...role, ...app, ...env, ...loc, ...rest];
    });
  });
}

// When the org GET collection finishes after the rulesets GET collection
function lazyExpand() {
  expandQueue.forEach(([rep, version]) => {
    rulesets.get(rep)[version].forEach(fillUserInfo);
  });
}

/**
 * Whether the href is a service account
 * @param href
 * @returns {boolean}
 */
const isServiceAccountHref = href => typeof href === 'string' && href.includes('/service_accounts/');

function fillUserInfo(ruleset) {
  const updatedByUser = ruleset.updated_by
    ? isServiceAccountHref(ruleset.updated_by.href)
      ? {full_name: ruleset.updated_by.name}
      : OrgStore.getUserFromHref(ruleset.updated_by.href)
    : null;
  const createdByUser = ruleset.created_by
    ? isServiceAccountHref(ruleset.created_by.href)
      ? {full_name: ruleset.created_by.name}
      : OrgStore.getUserFromHref(ruleset.created_by.href)
    : null;

  Object.assign(ruleset, {
    updated_by_user: (updatedByUser && updatedByUser.full_name) || '--',
    created_by_user: (createdByUser && createdByUser.full_name) || '--',
  });
}

function setRulesets(data, version, representation) {
  const usersLoaded = OrgStore.areUsersLoaded();
  const newRulesets = data.map(ruleset => {
    groupRulesetRule(ruleset);

    if (representation !== 'rule_set_scopes') {
      ruleset.rules.sort((a, b) => (b.href > a.href ? 1 : -1));
    }

    if (usersLoaded) {
      fillUserInfo(ruleset);
    }

    return ruleset;
  });

  // Expand it later when the Users Store is loaded
  if (!usersLoaded) {
    expandQueue.push([representation, version]);
  }

  if (!rulesets.has(representation)) {
    rulesets.set(representation, {});
  }

  StoreUtils.setObjects(rulesets.get(representation), {}, newRulesets, version || 'draft');
}

function setRuleset(data, version) {
  const usersLoaded = OrgStore.areUsersLoaded();
  const ruleset = data;

  if (usersLoaded) {
    fillUserInfo(ruleset);
  }

  groupRulesetRule(ruleset);

  // the GET instance has an implicit default representation of 'rule_set_services_labels_and_names',
  // which is a superset of 'rule_set_scopes'.
  ['rule_set_services_labels_and_names', 'rule_set_scopes'].forEach(representation => {
    if (!rulesets.has(representation)) {
      rulesets.set(representation, {});
    }

    if (!rulesets.get(representation)[version]) {
      rulesets.get(representation)[version] = [];
    }

    StoreUtils.setObjectByVersion(ruleset, version || 'draft', rulesets.get(representation));

    if (version === 'active') {
      const pversion = StoreUtils.getPolicyVersionFromHref(ruleset.href);

      StoreUtils.setObjectByVersion(ruleset, pversion || 'draft', rulesets.get(representation));
    }

    // Expand it later when the Users Store is loaded
    if (!usersLoaded) {
      expandQueue.push([representation, version]);
    }
  });
}

function deleteRuleset(href) {
  for (const ruleset of rulesets.values()) {
    const draftIndex = ruleset.draft && ruleset.draft.findIndex(ruleset => ruleset.href === href);

    if (draftIndex > -1) {
      ruleset.draft.splice(draftIndex, 1);
    }
  }
}

function setCount(data, version) {
  Object.assign(count, data);
  countByVersion[version] = data;
}

function getAll(version = 'draft', rep = 'rule_set_services_labels_and_names') {
  /**
   * DEFAULT ('') = Scope hrefs + Rules with service hrefs (not expanded)
   * RULE_SET_SCOPES = DEFAULT + Expanded scopes - Rules
   * RULE_SET_SERVICES_LABELS_AND_NAMES = Everything
   * RULE_SET_SCOPES is only used in the List page (for performance/speed reasons)
   *
   * So, if the user has specified representation and version, give the Rulesets back for those.
   * Else, reconstruct the rulesets such that if we got the rulesets with RULE_SET_SCOPES but we also got it with blank representation,
   * then we merge the rules (which we got back from blank representation) into the rulesets that we got back from RULE_SET_SCOPES representation.
   */
  if (rulesets.has(rep) && rulesets.get(rep)[version]) {
    return rulesets.get(rep)[version];
  }

  // Default/blank representation doesn't have expanded scopes, and scopes representation doesn't have rules
  if (
    rulesets.has('') &&
    rulesets.get('')[version] &&
    rulesets.has('rule_set_scopes') &&
    rulesets.get('rule_set_scopes')[version]
  ) {
    // create a map to store ruleset's rules
    const rulesetRulesMap = new Map();

    rulesets.get('')[version].forEach(ruleset => {
      rulesetRulesMap.set(ruleset.href, ruleset.rules);
    });

    return rulesets
      .get('rule_set_scopes')
      .version.map(ruleset => ({...ruleset, rules: rulesetRulesMap.get(ruleset.href).rules}));
  }

  // default
  if (rulesets.has('') && rulesets.get('')[version]) {
    return rulesets.get('')[version];
  }

  // this representation doesn't have rules
  if (rulesets.has('rule_set_scopes') && rulesets.get('rule_set_scopes')[version]) {
    return rulesets.get('rule_set_scopes')[version];
  }

  return [];
}

function getCount() {
  return count;
}

function getCountByVersion(version) {
  return countByVersion[version] || {};
}

function dispatchHandler(action) {
  const params = action.options && action.options.params;
  const version = (params && params.pversion) || 'draft';
  const query = (action.options && action.options.query) || {};
  let representation = '';

  // query representation is case sensitive string coming from the backend
  if (query.representation === 'rule_set_services_labels_and_names') {
    representation = 'rule_set_services_labels_and_names';
  } else if (query.representation === 'rule_set_scopes') {
    representation = 'rule_set_scopes';
  }

  switch (action.type) {
    case Constants.RULE_SETS_GET_COLLECTION_SUCCESS:
      setRulesets(action.data, version, representation);
      setCount(action.count, version);
      break;

    case Constants.RULE_SETS_GET_INSTANCE_SUCCESS:
      setRuleset(action.data, version);
      break;

    case Constants.USERS_GET_COLLECTION_SUCCESS:
      if (expandQueue.length) {
        lazyExpand();
      }

      break;

    case Constants.RULESET_STORE_ADD:
      setRuleset(action.data, 'draft');
      count.total++;
      break;

    case Constants.RULESET_STORE_DELETE:
      deleteRuleset(action.data);
      count.total--;
      break;

    default:
      return true;
  }

  this.emitChange();

  return true;
}

function getSpecified(href, rep) {
  const rulesetWithRep = rep && StoreUtils.findObjectByHref(href, rulesets.get(rep));

  if (rulesetWithRep) {
    return rulesetWithRep;
  }

  // This representation has everything
  const rulesetAll = StoreUtils.findObjectByHref(href, rulesets.get('rule_set_services_labels_and_names'));

  if (rulesetAll) {
    return rulesetAll;
  }

  const rulesetEmpty = StoreUtils.findObjectByHref(href, rulesets.get(''));
  const rulesetScopes = StoreUtils.findObjectByHref(href, rulesets.get('rule_set_scopes'));

  if (rulesetEmpty && rulesetScopes) {
    // 'rule_set_scopes' has expanded scoped but doesn't have rules (but default or no representation does)
    return {
      ...rulesetEmpty,
      scopes: rulesetScopes.scopes,
      rules: rulesetScopes.rules,
    };
  }

  if (rulesetEmpty) {
    return rulesetEmpty;
  }

  if (rulesetScopes) {
    return rulesetScopes;
  }
}

export default createApiStore(['RULE_SETS_', 'RULE_SET_', 'USERS_'], {
  getAll,
  getCount,
  getCountByVersion,
  getSpecified,
  dispatchHandler,
});
