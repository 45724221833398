/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import React, {PropTypes} from 'react';
import cx from 'classnames';

export default React.createClass({
  propTypes: {
    content: PropTypes.any, // Means content of popup tooltip
    subContent: PropTypes.any, // sub content of popup tooltip
    dataTid: PropTypes.any,
    location: PropTypes.oneOf([
      'topleft',
      'top',
      'topright',
      'left',
      'fixed-right',
      'fixed-right-table',
      'bottomleft',
      'bottom',
      'bottomright',
      'fixed',
      'services', //Position fixed is when tooltip needs to sit in the command panel.
    ]),
    width: PropTypes.number,
    isHeader: PropTypes.bool,
    delayed: PropTypes.bool,
  },

  getDefaultProps() {
    return {
      location: 'topright',
      width: 300,
      isHeader: false,
      delayed: false,
    };
  },

  render() {
    let cn = `TooltipBody Tooltip--${this.props.location}`;

    if (this.props.position) {
      cn += ` Tooltip--${this.props.position}`;
    }

    if (this.props.delayed) {
      cn += ' TooltipDelayed';
    }

    const tooltipClass = cx('TooltipListener', {
      'TooltipCursor': Boolean(this.props.content),
      'Tooltip--header': this.props.isHeader,
    });

    return (
      <div className={tooltipClass}>
        <span data-tid={this.props.dataTid}>{this.props.children}</span>
        {this.props.content ? (
          <div className={cn} style={{width: this.props.width}}>
            <div className={`TooltipContent ${this.props.subContent ? 'Map-Illumination-Tooltip-Illumination' : null}`}>
              {this.props.content}
            </div>
            <div className="TooltipArrow" />
            {this.props.subContent ? <div className="TooltipSubContent">{this.props.subContent}</div> : null}
          </div>
        ) : null}
      </div>
    );
  },
});
