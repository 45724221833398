/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import d3 from 'd3';
import _ from 'lodash';
import intl from 'intl';
import RenderUtils from './RenderUtils';
import {minmaxFor} from './GeneralUtils';
import ServiceUtils from './ServiceUtils';
import VersionStore from '../stores/VersionStore';
import MapPageStore from '../stores/MapPageStore';
import {getSessionUri, getInstanceUri} from '../lib/api';
import TrafficFilterStore from '../stores/TrafficFilterStore';
import TrafficStore from '../stores/TrafficStore';

export function filterBeforeCalculatingGraph(nodes, links, clusters, mapRoute, filters, trafficFilters) {
  // Always Full Map
  clearHiddenFlags(links);
  filterNodesByMapLevel(nodes, clusters, mapRoute);
  filterNodesByScope(nodes, filters);
  filterNodesByPolicyState(nodes, trafficFilters);
  filterNodesByPolicyState(clusters, trafficFilters);
  filterLinksByNodeHrefs(links, _.map(nodes, 'href'));
  ignoreSelectedServices(trafficFilters, links);
  hideAllowedTraffic(trafficFilters, links);
  hideBlockedTraffic(trafficFilters, links);
  hidePotentiallyBlockedTraffic(trafficFilters, links);
  hideUnknownTraffic(trafficFilters, links);
  supressBlockedTraffic(trafficFilters, links);
  supressNonVulnerableTraffic(trafficFilters, links);
  hideInternetTraffic(trafficFilters, links);
  hideIpListTraffic(trafficFilters, links);
  hideFqdnTraffic(trafficFilters, links);
  hideOutboundTraffic(trafficFilters, links);
  hideInboundTraffic(trafficFilters, links);
  hideOldTraffic(trafficFilters, links);
  filterTrafficVolume(trafficFilters, links);
  hideIcmpTraffic(trafficFilters, links);
  hideBroadcastTraffic(trafficFilters, links);
  hideMulticastTraffic(trafficFilters, links);
}

export function filterAfterCalculatingGraph(nodes, links, clusters, filters, trafficFilters) {
  // Always full map
  hideIntraAppTraffic(trafficFilters, null, clusters);
  filterEmptyClustersByScope(clusters, filters);
  ignoreInterAppTraffic(trafficFilters, links);
}

export function filterLinks(links, trafficFilters, ruleCoverageTruncated) {
  clearHiddenFlags(links);
  ignoreSelectedServices(trafficFilters, links);
  hideAllowedTraffic(trafficFilters, links, ruleCoverageTruncated);
  hideBlockedTraffic(trafficFilters, links, ruleCoverageTruncated);
  hideUnknownTraffic(trafficFilters, links);
  hidePotentiallyBlockedTraffic(trafficFilters, links);
  supressBlockedTraffic(trafficFilters, links);
  supressNonVulnerableTraffic(trafficFilters, links);
  hideInternetTraffic(trafficFilters, links);
  hideIpListTraffic(trafficFilters, links);
  hideFqdnTraffic(trafficFilters, links);
  hideOutboundTraffic(trafficFilters, links);
  hideInboundTraffic(trafficFilters, links);
  filterTrafficVolume(trafficFilters, links);
  ignoreInterAppTraffic(trafficFilters, links);
  hideIntraAppTraffic(trafficFilters, links);
  hideOldTraffic(trafficFilters, links);
  hideIcmpTraffic(trafficFilters, links);
  hideBroadcastTraffic(trafficFilters, links);
  hideMulticastTraffic(trafficFilters, links);
}

export function filterClustersByPolicyState(groups, policyStateFilters) {
  const newGroups = {...groups};
  let removedGroups = 0;

  _.forOwn(newGroups, (group, index) => {
    const groupData = group.data && group.data.mode ? group.data : group;
    const filteredWorkloads = RenderUtils.getPolicyStateGroupWorkloads(groupData || group, policyStateFilters);
    const filteredContainers = RenderUtils.getPolicyStateGroupContainerWorkloads(
      groupData || group,
      policyStateFilters,
    );

    group.entityCounts = groupData.entityCounts - filteredWorkloads - filteredContainers;
    group.workloadsNum = groupData.workloadCounts - filteredWorkloads;
    group.containerWorkloadsNum = groupData.containerWorkloadCounts - filteredContainers;

    if (!group.entityCounts) {
      if (Array.isArray(groups)) {
        // Remove this group from the list and keep track of the index
        groups.splice(index - removedGroups, 1);
        removedGroups += 1;
      } else {
        delete groups[group.href];
      }
    }
  });
}

export function filterNodesByPolicyState(nodes, trafficFilters) {
  let nodePolicyState;
  const policyStateFilters = TrafficFilterStore.getHiddenPolicyStates();

  if (!policyStateFilters.length) {
    return;
  }

  for (const [key, node] of Object.entries(nodes)) {
    if (node.type !== 'workload' && node.type !== 'virtualService' && node.type !== 'role' && node.type !== 'group') {
      continue;
    }

    if (node.type === 'role' || node.type === 'group') {
      const filteredWorkloads = RenderUtils.getPolicyStateGroupWorkloads(node.data, policyStateFilters);
      const filteredContainers = RenderUtils.getPolicyStateGroupContainerWorkloads(node.data, policyStateFilters);

      nodes[key].entityCounts = node.data.entityCounts - filteredWorkloads - filteredContainers;
      nodes[key].workloadsNum = node.data.workloadCounts - filteredWorkloads;
      nodes[key].containerWorkloadsNum = node.data.containerWorkloadCounts - filteredContainers;

      if (nodes[key].entityCounts === 0) {
        delete nodes[key];
      }
    } else {
      nodePolicyState = RenderUtils.getPolicyState(node.data, node.type);

      if (nodePolicyState && !trafficFilters[nodePolicyState]) {
        delete nodes[key];
      }

      if (!trafficFilters.unmanaged && node.data.unmanaged) {
        delete nodes[key];
      }
    }
  }
}

export function filterNodesByMapLevel(nodes, clusters, mapRoute) {
  if (mapRoute.type === 'location') {
    const locHref = getSessionUri(getInstanceUri('labels'), {
      label_id: mapRoute.id,
    });

    for (const [key, cluster] of Object.entries(clusters)) {
      const labels = RenderUtils.getLabels(cluster.data && cluster.data.labels);
      const keepCluster = labels.loc && labels.loc.href === locHref;

      if (!keepCluster) {
        delete clusters[key];
      }
    }
  } else if (mapRoute.type === 'group') {
    // remove any nodes that don't belong to the clusters we want to show
    for (const node of Object.values(nodes)) {
      if (!clusters[node.clusterParent || node.data.clusterParent]) {
        delete nodes[node.href];
      }
    }
  } else if (mapRoute.id === 'discovered') {
    // remove any nodes that have app, env OR loc label
    for (const [key, node] of Object.entries(nodes)) {
      const labels = RenderUtils.getLabels(node.data.labels);

      if ((labels && labels.app) || labels.env || labels.loc) {
        delete nodes[key];
      }
    }

    for (const [key, cluster] of Object.entries(clusters)) {
      const labels = RenderUtils.getLabels(cluster.data && cluster.data.labels);

      if ((labels && labels.app) || labels.env || labels.loc) {
        delete clusters[key];
      }
    }
  } else if (mapRoute.id === 'no_location') {
    // remove nodes with app and/or env labels but no loc labels
    // nodes may also have role labels, but we don't care about that
    for (const [key, node] of Object.entries(nodes)) {
      const labels = RenderUtils.getLabels(node.data.labels);
      const keepNode = labels && !labels.loc && (labels.app || labels.env);

      if (!keepNode) {
        delete nodes[key];
      }
    }

    for (const [key, cluster] of Object.entries(clusters)) {
      const labels = RenderUtils.getLabels(cluster.data && cluster.data.labels);
      const keepCluster = labels && !labels.loc && (labels.app || labels.env);

      if (!keepCluster) {
        delete clusters[key];
      }
    }
  }
}
export function filterLinksByScope(links, filters, focusedHref) {
  const policyStateFilters = TrafficFilterStore.getHiddenPolicyStates();

  for (const [key, link] of Object.entries(links)) {
    const clusterHref = link.data.source.href === focusedHref ? link.data.target.href : link.data.source.href;
    const clusterLabelIds = clusterHref.split('x');
    const cluster = TrafficStore.getCluster(clusterHref);
    const keepNode =
      _.every(filters, filterLabel => clusterLabelIds.includes(_.last(filterLabel.href.split('/')))) &&
      cluster &&
      cluster.entityCounts > RenderUtils.getPolicyStateGroupWorkloads(cluster, policyStateFilters);

    if (!keepNode) {
      delete links[key];
    }
  }
}

export function filterNodesByScope(nodes, filters) {
  if (!filters.length) {
    return;
  }

  for (const [key, node] of Object.entries(nodes)) {
    if (node.type === 'group') {
      filters = _.filter(filters, filter => filter.key !== 'role');
    }

    // the node has to have all the filters' labels to not be removed
    const nodeLabelHrefs = _.map(node.data.labels, 'href');
    const keepNode = _.every(filters, filterLabel => nodeLabelHrefs.includes(filterLabel.href));

    if (!keepNode) {
      delete nodes[key];
    }
  }
}

export function filterLinksByNodeHrefs(links, hrefs) {
  // if link is to/from the internet, make sure the
  // workload on the otherside is within the subset
  // of workloads we've kept from the scope-based filter
  // if link is between workloads, make sure both
  // are within the subset.
  for (const [key, link] of Object.entries(links)) {
    let keepLink = true;

    if (RenderUtils.isInternetIpList(link.data.source)) {
      keepLink = hrefs.includes(link.data.target.href);
    } else if (RenderUtils.isInternetIpList(link.data.target)) {
      keepLink = hrefs.includes(link.data.source.href);
    } else {
      keepLink = hrefs.includes(link.data.source.href) && hrefs.includes(link.data.target.href);
    }

    if (!keepLink) {
      delete links[key];
    }
  }
}

export function filterEmptyClustersByScope(clusters, filters) {
  for (const [key, cluster] of Object.entries(clusters)) {
    const clusterLabelHrefs = _.map(cluster.labels, 'href');
    // if even one of the filter labels isn't in the cluster, remove it
    const removeCluster = _.some(filters, filterLabel => !clusterLabelHrefs.includes(filterLabel.href));

    cluster.entityCounts = cluster.nodes.reduce((result, node) => result + (node.entityCounts || 1), 0);

    // but only remove if it's an empty cluster
    if (RenderUtils.isEmptyCluster(cluster) && removeCluster) {
      delete clusters[key];
    }
  }
}

// ignore the intra app links in the connected app group
export function hideAppGroupLinks(links, focusedHref, connectedHref, direction) {
  const vulnerability = MapPageStore.getAppMapVersion() === 'vulnerability';

  for (const key in links) {
    const link = links[key];

    if (
      vulnerability &&
      ((link.source.appGroupParent === connectedHref && direction === 'providing') ||
        (link.target.appGroupParent === connectedHref && direction === 'consuming'))
    ) {
      delete links[key];
    }

    if (
      !vulnerability &&
      ((link.source.appGroupParent === focusedHref && link.target.appGroupParent === focusedHref) ||
        (RenderUtils.isInternetIpList(link.source) && link.target.appGroupParent === focusedHref) ||
        (RenderUtils.isInternetIpList(link.target) && link.source.appGroupParent === focusedHref))
    ) {
      links[key].isHidden = true;
    }

    if (
      !vulnerability &&
      ((link.source.appGroupParent === connectedHref && link.target.appGroupParent === connectedHref) ||
        (RenderUtils.isInternetIpList(link.source) && link.target.appGroupParent === connectedHref) ||
        (RenderUtils.isInternetIpList(link.target) && link.source.appGroupParent === connectedHref) ||
        (link.source.appGroupParent === connectedHref && direction === 'providing') ||
        (link.target.appGroupParent === connectedHref && direction === 'consuming'))
    ) {
      delete links[key];
    }
  }
}

export function ignoreSelectedServices(trafficFilters, links) {
  const coreServicesPortProto = ['53 TCP', '53 UDP', '5353 TCP', '5353 UDP', '67 UDP', '68 UDP', '500 UDP', '4500 UDP'];

  for (const [key, link] of Object.entries(links)) {
    for (const [c, connection] of Object.entries(link.connections)) {
      const key = connection.port + ' ' + connection.friendlyProtocol;

      if (!trafficFilters.ignoreServices && coreServicesPortProto.includes(key)) {
        link.connections[c].isHidden = true;
      } else if (trafficFilters.ignoreServices && trafficFilters.services[key]) {
        if (MapPageStore.getMapType() === 'loc' || link.source.type === 'appGroup') {
          delete link.connections[c];
        } else {
          link.connections[c].isHidden = true;
        }
      }
    }

    if (_.isEmpty(link.connections)) {
      delete links[key];
    } else if (_.every(link.connections, connection => connection.isHidden)) {
      link.isHidden = true;
    }
  }
}

export function ignoreInterAppTraffic(trafficFilters, links) {
  if (trafficFilters.allowInterAppTraffic) {
    return;
  }

  const mapType = MapPageStore.getMapType();

  if (mapType === 'app') {
    return;
  }

  for (const [key, link] of Object.entries(links)) {
    if (
      !RenderUtils.isInternetIpList(link.source) &&
      !RenderUtils.isInternetIpList(link.target) &&
      ((mapType === 'loc' &&
        (link.source.clusterParent !== link.target.clusterParent ||
          !link.source.clusterParent ||
          !link.target.clusterParent)) ||
        (mapType === 'app' &&
          (link.source.appGroupParent !== link.target.appGroupParent ||
            !link.source.appGroupParent ||
            !link.target.appGroupParent)))
    ) {
      delete links[key];
    }
  }
}

export function hideIntraAppTraffic(trafficFilters, links, clusters) {
  if (trafficFilters.allowIntraAppTraffic) {
    return;
  }

  // TODO(swu): refactor full map calculation so that we can just pass in links
  // instead of doing this if statement in the future?
  if (clusters) {
    for (const cluster of Object.values(clusters)) {
      for (const link of Object.values(cluster.links)) {
        if (!RenderUtils.isInternetIpList(link.source) && !RenderUtils.isInternetIpList(link.target)) {
          link.isHidden = true;
        }
      }
    }
  } else {
    const mapType = MapPageStore.getMapType();

    for (const link of Object.values(links)) {
      if (
        link.source.type !== 'group' &&
        link.source.type !== 'appGroup' &&
        link.target.type !== 'group' &&
        link.target.type !== 'appGroup' &&
        !RenderUtils.isInternetIpList(link.source) &&
        !RenderUtils.isInternetIpList(link.target) &&
        ((mapType === 'loc' && link.source.clusterParent === link.target.clusterParent) ||
          (mapType === 'app' && link.source.appGroupParent === link.target.appGroupParent))
      ) {
        link.isHidden = true;
      }
    }
  }
}

export function clearHiddenFlags(links) {
  for (const link of Object.values(links)) {
    link.isHidden = false;

    for (const connection of Object.values(link.connections)) {
      connection.isHidden = false;
    }
  }
}

function areRulesLoaded(link) {
  let loadedRules = TrafficStore.isLinkLoadedForRules(link.href);

  if ((link.source || link.data.source)?.type === 'appGroup') {
    loadedRules = (link.data.childrenTraffics || []).reduce((loaded, traffic) => {
      loaded &= TrafficStore.isLinkLoadedForRules(traffic.href);

      return loaded;
    }, true);
  }

  return loadedRules;
}

export function hideAllowedTraffic(trafficFilters, links, ruleCoverageTruncated) {
  if (trafficFilters.allowAllowedTraffic) {
    return;
  }

  const mapType = MapPageStore.getMapType();
  const policyVersion = MapPageStore.getPolicyVersion();
  const appMapVersion = MapPageStore.getAppMapVersion();

  if (mapType === 'app' && appMapVersion === 'vulnerability') {
    return;
  }

  for (const link of Object.values(links)) {
    let hideAllConnections = true;
    const rulesLoaded = areRulesLoaded(link) || ruleCoverageTruncated;

    for (const connection of Object.values(link.connections)) {
      // If the filter is on, wait for the rules to be loaded before showning the link
      if (
        (policyVersion === 'draft' && !rulesLoaded) ||
        (RenderUtils.isConnectionAllowed(connection, policyVersion) &&
          (!RenderUtils.isConnectionPotentiallyBlocked(connection, policyVersion) ||
            !trafficFilters.allowPotentiallyBlockedTraffic) &&
          (!RenderUtils.isConnectionUnknown(connection, policyVersion) ||
            !trafficFilters.allowUnknownTraffic ||
            policyVersion === 'draft') &&
          (!RenderUtils.isConnectionBlocked(connection, policyVersion) || !trafficFilters.allowBlockedTraffic))
      ) {
        connection.isHidden = true;
      } else if (!connection.isHidden) {
        // Test for the connection being hidden by a separtate filter
        hideAllConnections = false;
      }
    }

    // if all the connections were filtered out, it must mean that
    // they were all allowed, so hide the link itself too
    if (hideAllConnections) {
      link.isHidden = true;
    }
  }
}

export function hideBlockedTraffic(trafficFilters, links, ruleCoverageTruncated) {
  if (trafficFilters.allowBlockedTraffic) {
    return;
  }

  const mapType = MapPageStore.getMapType();
  const appMapVersion = MapPageStore.getAppMapVersion();
  const policyVersion = MapPageStore.getPolicyVersion();

  if (mapType === 'app' && appMapVersion === 'vulnerability') {
    return;
  }

  for (const link of Object.values(links)) {
    let hideAllConnections = true;

    if (policyVersion === 'draft' && link.source?.type === 'group') {
      continue;
    }

    for (const connection of Object.values(link.connections)) {
      if (
        RenderUtils.isConnectionBlocked(connection, policyVersion) &&
        (!RenderUtils.isConnectionPotentiallyBlocked(connection, policyVersion) ||
          !trafficFilters.allowPotentiallyBlockedTraffic) &&
        (!RenderUtils.isConnectionAllowed(
          connection,
          policyVersion,
          ruleCoverageTruncated && link.data.ruleCoverageToBeLoaded,
        ) ||
          !trafficFilters.allowAllowedTraffic) &&
        (!RenderUtils.isConnectionUnknown(connection, policyVersion) ||
          !trafficFilters.allowUnknownTraffic ||
          policyVersion === 'draft')
      ) {
        connection.isHidden = true;
      } else if (!connection.isHidden) {
        // Test for the connection being hidden by a separtate filter
        hideAllConnections = false;
      }
    }

    // if all the connections were filtered out, it must mean that
    // they were all allowed, so hide the link itself too
    if (hideAllConnections) {
      link.isHidden = true;
    }
  }
}

export function hidePotentiallyBlockedTraffic(trafficFilters, links) {
  if (trafficFilters.allowPotentiallyBlockedTraffic) {
    return;
  }

  const mapType = MapPageStore.getMapType();
  const appMapVersion = MapPageStore.getAppMapVersion();
  const policyVersion = MapPageStore.getPolicyVersion();

  if ((mapType === 'app' && appMapVersion === 'vulnerability') || policyVersion === 'draft') {
    return;
  }

  for (const link of Object.values(links)) {
    let hideAllConnections = true;

    for (const connection of Object.values(link.connections)) {
      if (
        RenderUtils.isConnectionPotentiallyBlocked(connection, 'reported') &&
        (!RenderUtils.isConnectionUnknown(connection, 'reported') || !trafficFilters.allowUnknownTraffic) &&
        (!RenderUtils.isConnectionAllowed(connection, 'reported') || !trafficFilters.allowAllowedTraffic) &&
        (!RenderUtils.isConnectionBlocked(connection, 'reported') || !trafficFilters.allowBlockedTraffic)
      ) {
        connection.isHidden = true;
      } else if (!connection.isHidden) {
        // Test for the connection being hidden by a separtate filter
        hideAllConnections = false;
      }
    }

    // if all the connections were filtered out, it must mean that
    // they were all allowed, so hide the link itself too
    if (hideAllConnections) {
      link.isHidden = true;
    }
  }
}

export function hideUnknownTraffic(trafficFilters, links) {
  if (trafficFilters.allowUnknownTraffic) {
    return;
  }

  const mapType = MapPageStore.getMapType();
  const appMapVersion = MapPageStore.getAppMapVersion();
  const policyVersion = MapPageStore.getPolicyVersion();

  if ((mapType === 'app' && appMapVersion === 'vulnerability') || policyVersion === 'draft') {
    return;
  }

  for (const link of Object.values(links)) {
    let hideAllConnections = true;

    for (const connection of Object.values(link.connections)) {
      if (
        RenderUtils.isConnectionUnknown(connection, 'reported') &&
        (!RenderUtils.isConnectionPotentiallyBlocked(connection, 'reported') ||
          !trafficFilters.allowPotentiallyBlockedTraffic) &&
        (!RenderUtils.isConnectionAllowed(connection, 'reported') || !trafficFilters.allowAllowedTraffic) &&
        (!RenderUtils.isConnectionBlocked(connection, 'reported') || !trafficFilters.allowBlockedTraffic)
      ) {
        connection.isHidden = true;
      } else if (!connection.isHidden) {
        // Test for the connection being hidden by a separtate filter
        hideAllConnections = false;
      }
    }

    // if all the connections were filtered out, it must mean that
    // they were all allowed, so hide the link itself too
    if (hideAllConnections) {
      link.isHidden = true;
    }
  }
}

export function supressBlockedTraffic(trafficFilters, links) {
  if (MapPageStore.getAppMapVersion() !== 'vulnerability' || trafficFilters.showVulnerableBlockedTraffic) {
    return;
  }

  for (const link of Object.values(links)) {
    let supressAllConnections = true;
    let potentiallyBlockedConnection = false;

    for (const connection of Object.values(link.connections)) {
      if (RenderUtils.isConnectionPotentiallyBlocked(connection, MapPageStore.getPolicyVersion())) {
        potentiallyBlockedConnection = true;
        supressAllConnections = false;
      } else if (!RenderUtils.isConnectionAllowed(connection, MapPageStore.getPolicyVersion())) {
        connection.isSupressed = true;
      } else {
        supressAllConnections = false;
      }
    }

    // if all the connections were filtered out, it must mean that
    // they were all allowed, so hide the link itself too
    if (supressAllConnections) {
      link.isSupressed = true;
      link.type = 'gray';
    } else if (potentiallyBlockedConnection) {
      link.type = 'potentiallyVulnerable';
    }
  }
}

export function supressNonVulnerableTraffic(trafficFilters, links) {
  if (MapPageStore.getAppMapVersion() !== 'vulnerability') {
    return;
  }

  for (const link of Object.values(links)) {
    if (
      RenderUtils.getVulnerabilityForRender(
        !RenderUtils.isInternetIpList(link.target) && trafficFilters.exposedVulnerabilities
          ? link.maxExpSeverity
          : link.maxSeverity,
        'key',
      ) < trafficFilters.vulnerabilitySeverity
    ) {
      link.isSupressed = true;
      link.type = 'gray';
    }
  }
}

export function hideOutboundTraffic(trafficFilters, links) {
  if (trafficFilters.allowOutboundTraffic) {
    return;
  }

  // The function to put 'isHidden' for each outbound traffic
  const mapRoute = MapPageStore.getMapRoute();
  const mapType = MapPageStore.getMapType();

  if (mapType === 'app') {
    return;
  }

  const focusedNodes = mapRoute.type === 'group' && [mapRoute.id];

  for (const [key, link] of Object.entries(links)) {
    if (focusedNodes && RenderUtils.isInterappTraffic(link)) {
      const type = link.target.type;

      if (type === 'group' && focusedNodes.includes(link.source.href)) {
        delete links[key];
      }

      if (
        (type === 'role' || type === 'workload') &&
        focusedNodes.includes(mapType === 'app' ? link.source.appGroupParent : link.source.clusterParent) &&
        (mapType === 'loc' || !focusedNodes.includes(link.target.appGroupParent))
      ) {
        delete links[key];
      }
    } else if (!RenderUtils.isInternetIpList(link.data.source) && RenderUtils.isInternetIpList(link.data.target)) {
      link.isHidden = true;
    }
  }
}

export function hideInboundTraffic(trafficFilters, links) {
  if (trafficFilters.allowInboundTraffic) {
    return;
  }

  const mapRoute = MapPageStore.getMapRoute();
  const mapType = MapPageStore.getMapType();

  if (mapType === 'app') {
    return;
  }

  // The function to put 'isHidden' for each inbound traffic
  const focusedNodes = mapRoute.type === 'group' && [mapRoute.id];

  for (const [key, link] of Object.entries(links)) {
    if (focusedNodes && RenderUtils.isInterappTraffic(link)) {
      const type = link.target.type;

      if (type === 'group' && focusedNodes.includes(link.target.href)) {
        delete links[key];
      }

      if (
        (type === 'role' || type === 'workload') &&
        focusedNodes.includes(mapType === 'app' ? link.target.appGroupParent : link.target.clusterParent) &&
        (mapType === 'loc' || !focusedNodes.includes(link.source.appGroupParent))
      ) {
        delete links[key];
      }
    } else if (RenderUtils.isInternetIpList(link.data.source) && !RenderUtils.isInternetIpList(link.data.target)) {
      link.isHidden = true;
    }
  }
}

export function hideFqdnTraffic(trafficFilters, links) {
  if (trafficFilters.allowFqdnTraffic) {
    return;
  }

  // The function to put 'isHidden' for each fqdn traffic
  for (const link of Object.values(links)) {
    if (link.data.target.type === 'fqdn') {
      link.isHidden = true;
    }
  }
}

export function hideInternetTraffic(trafficFilters, links) {
  if (trafficFilters.allowInternetTraffic) {
    return;
  }

  // The function to put 'isHidden' for each internet traffic
  for (const link of Object.values(links)) {
    if (link.data.source.type === 'internet' || link.data.target.type === 'internet') {
      link.isHidden = true;
    }
  }
}

export function hideIpListTraffic(trafficFilters, links) {
  if (trafficFilters.allowIpListTraffic) {
    return;
  }

  // The function to put 'isHidden' for each ipList traffic
  for (const link of Object.values(links)) {
    if (link.data.source.type === 'ipList' || link.data.target.type === 'ipList') {
      link.isHidden = true;
    }
  }
}

const thresholdScale = d3.scale.linear().domain([1, 100]);

export function filterTrafficVolume(trafficFilters, links) {
  if (_.isEmpty(links)) {
    return;
  }

  const {max} = minmaxFor(links, 'data.totalSessions');

  thresholdScale.range([0, max + 1]);

  const threshold = thresholdScale(trafficFilters.trafficVolume);

  for (const [key, link] of Object.entries(links)) {
    if (link.data.totalSessions < threshold) {
      delete links[key];
    }
  }
}

export function matchServiceName(connection) {
  let name = intl('Common.Unknown');
  const matchedServices = ServiceUtils.matchConnectionWithService(connection);

  if (matchedServices.length) {
    name = matchedServices[0].name;
  } else if (RenderUtils.trafficHasProcessName(connection)) {
    // if there's no matched services, default to process name
    name = connection.service;
  }

  return name;
}

export function hideOldTraffic(trafficFilters, links) {
  if (!trafficFilters.timeFilter) {
    return;
  }

  const filterTime = RenderUtils.getTimeFromFilter(trafficFilters.timeFilter, VersionStore.getLatestProvisionTime());

  if (filterTime === 'anytime') {
    return;
  }

  for (const [key, link] of Object.entries(links)) {
    for (const [c, connection] of Object.entries(link.connections)) {
      if (!connection.timestamp || connection.timestamp < filterTime) {
        if (MapPageStore.getMapType() === 'loc') {
          delete link.connections[c];
        } else {
          link.connections[c].isHidden = true;
        }
      }
    }

    if (_.isEmpty(link.connections)) {
      delete links[key];
    } else if (_.every(link.connections, connection => connection.isHidden)) {
      link.isHidden = true;
    }
  }
}

export function hideIcmpTraffic(trafficFilters, links) {
  if (trafficFilters.allowIcmpTraffic) {
    return;
  }

  for (const [key, link] of Object.entries(links)) {
    for (const [c, connection] of Object.entries(link.connections)) {
      if ([intl('Protocol.ICMP'), intl('Protocol.ICMPv6')].includes(connection.friendlyProtocol)) {
        if (MapPageStore.getMapType() === 'loc') {
          delete link.connections[c];
        } else {
          link.connections[c].isHidden = true;
        }
      }
    }

    if (_.isEmpty(link.connections)) {
      delete links[key];
    } else if (_.every(link.connections, connection => connection.isHidden)) {
      link.isHidden = true;
    }
  }
}

export function hideBroadcastTraffic(trafficFilters, links) {
  if (trafficFilters.allowBroadcastTraffic || !TrafficStore.isBroadcastTrafficLoaded()) {
    return;
  }

  for (const [key, link] of Object.entries(links)) {
    for (const [c, connection] of Object.entries(link.connections)) {
      if (connection.connectionClass === 'B') {
        if (MapPageStore.getMapType() === 'loc') {
          delete link.connections[c];
        } else {
          link.connections[c].isHidden = true;
        }
      }
    }

    if (_.isEmpty(link.connections)) {
      delete links[key];
    } else if (_.every(link.connections, connection => connection.isHidden)) {
      link.isHidden = true;
    }
  }
}

export function hideMulticastTraffic(trafficFilters, links) {
  if (trafficFilters.allowMulticastTraffic || !TrafficStore.isMulticastTrafficLoaded()) {
    return;
  }

  for (const [key, link] of Object.entries(links)) {
    for (const [c, connection] of Object.entries(link.connections)) {
      if (connection.connectionClass === 'M') {
        if (MapPageStore.getMapType() === 'loc') {
          delete link.connections[c];
        } else {
          link.connections[c].isHidden = true;
        }
      }
    }

    if (_.isEmpty(link.connections)) {
      delete links[key];
    } else if (_.every(link.connections, connection => connection.isHidden)) {
      link.isHidden = true;
    }
  }
}

export function findUnconnectedClusters(links, selectionKey, isSelected, clusters) {
  const connectedClusters = {};
  const unconnectedClusters = {};

  for (const key in clusters) {
    connectedClusters[key] = [];
    unconnectedClusters[key] = [];
  }

  //Find all connected clusters for each cluster
  for (const key in links) {
    const sourceGroup = links[key].source.cluster && links[key].source.cluster.href;
    const destGroup = links[key].target.cluster && links[key].target.cluster.href;

    if (sourceGroup && !connectedClusters[sourceGroup].includes(destGroup) && sourceGroup !== destGroup) {
      connectedClusters[sourceGroup].push(destGroup);
    }

    if (destGroup && !connectedClusters[destGroup].includes(sourceGroup) && destGroup !== sourceGroup) {
      connectedClusters[destGroup].push(sourceGroup);
    }
  }

  //Find all unconnected clusters for each cluster
  for (const key in connectedClusters) {
    for (const clusterKey in clusters) {
      if (!connectedClusters[key].includes(clusterKey) && key !== clusterKey) {
        unconnectedClusters[key].push(clusterKey);
      }
    }
  }

  if (selectionKey) {
    if (isSelected) {
      unconnectedClusters[selectionKey].forEach(item => {
        clusters[item].reduceOpacity = true;
      });

      for (const key in links) {
        const sourceGroup = links[key].source.cluster && links[key].source.cluster.href;
        const destGroup = links[key].target.cluster && links[key].target.cluster.href;

        if (
          unconnectedClusters[selectionKey].includes(sourceGroup) ||
          unconnectedClusters[selectionKey].includes(destGroup)
        ) {
          links[key].reduceOpacity = true;
        }
      }
    } else {
      for (const key in clusters) {
        clusters[key].reduceOpacity = false;
      }

      for (const key in links) {
        links[key].reduceOpacity = false;
      }
    }
  }
}

export default {
  filterLinks,
  matchServiceName,
  hideIpListTraffic,
  hideInboundTraffic,
  hideAllowedTraffic,
  supressBlockedTraffic,
  hideAppGroupLinks,
  filterLinksByScope,
  filterNodesByScope,
  filterTrafficVolume,
  hideOldTraffic,
  hideIcmpTraffic,
  hideBroadcastTraffic,
  hideMulticastTraffic,
  hideInternetTraffic,
  hideOutboundTraffic,
  hideIntraAppTraffic,
  filterNodesByMapLevel,
  ignoreInterAppTraffic,
  filterLinksByNodeHrefs,
  ignoreSelectedServices,
  filterNodesByPolicyState,
  supressNonVulnerableTraffic,
  filterEmptyClustersByScope,
  filterAfterCalculatingGraph,
  filterBeforeCalculatingGraph,
  filterClustersByPolicyState,
  findUnconnectedClusters,
};
