/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import intl from '.';
import _ from 'lodash';
import {selectUnit} from '@formatjs/intl-utils';
import formats from './formats';
import {locale} from './locale';

const currentYear = new Date().getFullYear();

export const SECOND = 1000;
export const MINUTE = 60 * SECOND;
export const HOUR = 60 * MINUTE;
export const DAY = 24 * HOUR;
export const WEEK = 7 * DAY;

export type LooseDate = Date | number;
export type LooserDate = LooseDate | string;

export type TimeUnitFullName = 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year' | 'millisecond';
export type TimeUnitFullNamePlural = `${TimeUnitFullName}s`;
export type TimeUnitShortHand = 's' | 'm' | 'h' | 'd' | 'M' | 'y' | 'ms';
export type TimeUnit = TimeUnitFullNamePlural | TimeUnitShortHand;

export const TIME_UNITS = ['second', 'minute', 'hour', 'day', 'week', 'month', 'year'];

export const SHORTHAND_TO_DATE_UNIT_MAP = new Map([
  ['y', 'years'],
  ['M', 'months'],
  ['d', 'days'],
  ['h', 'hours'],
  ['m', 'minutes'],
  ['s', 'seconds'],
  ['ms', 'milliseconds'],
]);

export const DATE_UNIT_TO_SHORTHAND_MAP = new Map([
  ['years', 'y'],
  ['months', 'M'],
  ['days', 'd'],
  ['hours', 'h'],
  ['minutes', 'm'],
  ['seconds', 's'],
  ['milliseconds', 'ms'],
]);

const isTimeUnitOrShorthand = (unit: string): unit is TimeUnit =>
  SHORTHAND_TO_DATE_UNIT_MAP.has(unit) || DATE_UNIT_TO_SHORTHAND_MAP.has(unit);

export const addTime = (oldDate: Date, unit: TimeUnit, amount: number, options = {immutable: false}): Date => {
  const newDate = options.immutable ? new Date(oldDate.getTime()) : oldDate;

  switch (unit) {
    case 'ms':
    case 'milliseconds':
      newDate.setMilliseconds(newDate.getMilliseconds() + amount);
      break;
    case 's':
    case 'seconds':
      newDate.setSeconds(newDate.getSeconds() + amount);
      break;
    case 'm':
    case 'minutes':
      newDate.setMinutes(newDate.getMinutes() + amount);
      break;
    case 'h':
    case 'hours':
      newDate.setHours(newDate.getHours() + amount);
      break;
    case 'd':
    case 'days':
      newDate.setDate(newDate.getDate() + amount);
      break;
    case 'M':
    case 'months':
      newDate.setMonth(newDate.getMonth() + amount);
      break;
    case 'y':
    case 'years':
      newDate.setFullYear(newDate.getFullYear() + amount);
      break;
  }

  return newDate;
};

export const subtractTime = (oldDate: Date, unit: TimeUnit, amount: number, options = {immutable: false}): Date =>
  addTime(oldDate, unit, -amount, options);

export const addTimeFromObj = (
  oldDate: Date,
  values: Partial<Record<TimeUnit, number>>,
  options = {immutable: false},
): Date => {
  let newDate = options.immutable ? new Date(oldDate.getTime()) : oldDate;

  Object.keys(values).forEach(unit => {
    if (isTimeUnitOrShorthand(unit)) {
      newDate = addTime(newDate, unit, values[unit]!);
    }
  });

  return newDate;
};

export const subtractTimeFromObj = (
  oldDate: Date,
  values: Partial<Record<TimeUnit, number>>,
  options = {immutable: false},
): Date => {
  const negativeValues: Partial<Record<TimeUnit, number>> = {};

  Object.keys(values).forEach(unit => {
    if (isTimeUnitOrShorthand(unit)) {
      negativeValues[unit] = -values[unit]!;
    }
  });

  return addTimeFromObj(oldDate, negativeValues, options);
};

// Get number of hours between two dates
export const diffInHours = (d1: Date, d2 = new Date()): number => Math.floor((d1.getTime() - d2.getTime()) / HOUR);

// Get number of days between two dates
export const diffInDays = (d1: Date, d2 = new Date()): number => {
  // Discard the time and time-zone information
  const utc1 = Date.UTC(d1.getFullYear(), d1.getMonth(), d1.getDate());
  const utc2 = Date.UTC(d2.getFullYear(), d2.getMonth(), d2.getDate());

  return Math.floor((utc1 - utc2) / DAY);
};

// Helper functions for formatting
export const format = {
  /**
   * For current year returns dd Month, for another - dd Month Year
   *
   * @example
   *   ('2016-02-17T11:00:00.000Z') => 17 February
   * @example
   *   ('2010-02-17T11:00:00.000Z') => 17 February 2000
   */
  dayMonthMaybeYear(date: LooseDate): string {
    const formatOptions: Intl.DateTimeFormatOptions = {day: 'numeric', month: 'long'};

    if (new Date(date).getFullYear() !== currentYear) {
      formatOptions.year = formats.types.NUMERIC;
    }

    return intl.format('date', date, formatOptions);
  },

  /**
   * Returns 'Date at Time by User' for given date. If user is omitted, returns just 'Date at Time'
   * @param  when - Can be Date object or number/string that can be cast to Date object
   * @param  [user] - Optional user
   * @param  [namePath] - If user is passed and it's object, this parameter is path of object to get user name
   *
   * @example
   *   (new Date()) => 03/18/2016 at 15:48:06
   * @example
   *   (1458343013829, 'John Smith') => 03/18/2016 at 15:48:06 by John Smith
   * @example
   *   ('2016-03-18T23:16:53.829Z', {full_name: 'John Smith'}, 'full_name') => 03/18/2016 at 15:48:06 by John Smith
   * @example
   *   ('2016-03-18T23:16:53.829Z', {info: {name: 'John Smith'}}, 'info.name') => 03/18/2016 at 15:48:06 by John Smith
   */
  dateAtTimeBy(
    when?: LooserDate,
    user?: Record<string, string> | string | number,
    namePath?: string,
    ...rest: any[]
  ): string {
    if (!when) {
      return '';
    }

    let name: string | undefined;

    if (user) {
      if (typeof user === 'object' && namePath) {
        name = user[namePath];

        if (!name) {
          name = _.get(user, namePath);
        }
      } else {
        name = user as string;
      }
    }

    return intl(name ? 'Common.DateAtTimeBy' : 'Common.DateAtTime', {when: new Date(when), name}, ...rest);
  },

  // Returns 'best fit' duration string between specified dates with automatically found unit
  // For example, '1 day', '12 minutes', '4 seconds'
  durationBestFit: (function () {
    const intlPerUnit = TIME_UNITS.reduce(
      (result, unit) => result.set(unit, new Intl.NumberFormat(locale, {notation: 'standard', style: 'unit', unit})),
      new Map(),
    );

    return (from: LooseDate, to: LooseDate = Date.now()) => {
      const timestamp = typeof from === 'number' ? from : (from instanceof Date ? from : new Date(from)).getTime();

      if (__DEV__ && isNaN(timestamp)) {
        throw new TypeError(`A date, string or timestamp must be provided to formatDuration(). Now it is: ${from}`);
      }

      const diff = selectUnit(timestamp, to);

      return intlPerUnit.get(diff.unit)?.format(Math.abs(diff.value)) ?? new Date(timestamp).toLocaleString();
    };
  })(),
};

const regexpWhiteSpace = /\s+/;

/**
 * Method to replace substrings of a string with new values
 *
 * @param  originalString        - original intl string, for example: 'Confirm Workload Visibility Change',
 * @param  valuesMap - mapping of values to be replaced, for example, {Workload: 'Endpoint', Foo: 'Bar'}
 * @param  matchingRegexp        - Regexp to match keys of valuesMap in the originalString
 *
 * @returns Translated string
 */
export const getTranslatedValue = (
  originalString: string,
  valuesMap: Map<string, string | {value: string; exactReplace?: boolean; exactMatch?: boolean}>,
  matchingRegexp: RegExp,
): string =>
  originalString.replace(matchingRegexp, matchedValue => {
    let newValue = valuesMap.get(matchedValue);

    if (typeof newValue !== 'string' && typeof newValue !== 'object') {
      const newValueNormalized = valuesMap.get(matchedValue.toLowerCase());

      if (
        typeof newValueNormalized === 'string' ||
        (typeof newValueNormalized === 'object' && newValueNormalized.exactMatch !== true)
      ) {
        newValue = newValueNormalized;
      } else {
        return matchedValue;
      }
    }

    //Ex: {
    // [intl('Common.Workload')]: intl('Common.Endpoint'),
    // [intl('Common.ActiveDirectory')]: {
    //   value: intl('Common.AccessRestrictions'),
    //   exactReplace: true,
    //  }
    // }
    if (typeof newValue === 'object') {
      if (newValue.exactReplace === true) {
        return newValue.value;
      }

      newValue = newValue.value;
    }

    //Get matched string for input string. Ex:
    // productDictionary = {
    //   [intl('Common.Workload')]: intl('Edge.Endpoint'),
    //   [intl('Common.Workloads')]: intl('Edge.Endpoints'),
    //   [intl('Common.ActiveDirectory')]: intl('Common.AccessRestriction'),
    // }
    //Input:
    // {
    // VisibilityChangeConfirm: 'Confirm Workload Visibility Change',
    // ManageWarning: 'You will not be able to manage this workloads after this action.',
    // ActiveDirectory: 'Active Directory',
    // ActiveDirectoryString: 'This is an active Directory'
    // }

    // Obtain final string from product Dictionary using key converted to TitleCase
    //Ex {
    // VisibilityChangeConfirm: 'Confirm Endpoint Visibility Change',
    // ManageWarning: 'You will not be able to manage this endpoints after this action.',
    // ActiveDirectory: 'Access Restriction',
    // ActiveDirectoryString: 'This is an Access Restriction'
    // }
    const matchedValueWords = matchedValue.split(regexpWhiteSpace);
    const newValueWords = newValue.split(regexpWhiteSpace);

    // If the original and new values have different number of words, then return the new value as is.
    // Like with exactReplace: true, because we can't capitalize them correctly in that case
    if (matchedValueWords.length !== newValueWords.length) {
      return newValue;
    }

    // Change return string to original format: For example if we received Workload keep final string as Endpoint,
    // If you received workload, then change final string to endpoint
    return matchedValueWords
      .map((matchedWord, i) => {
        const newWord = newValueWords[i];

        //If original matched string was lowercase, convert final string to lowercase
        if (matchedWord === matchedWord.toLowerCase()) {
          return newWord.toLowerCase();
        }

        //If original matched string was UPPERCASE, convert final string to UPPERCASE
        if (matchedWord === matchedWord.toUpperCase()) {
          return newWord.toUpperCase();
        }

        // If first letter of the original matched string is upper case (Title Case), then capitalize new word as well
        if (matchedWord.charAt(0) === matchedWord.charAt(0).toUpperCase()) {
          return newWord.charAt(0).toUpperCase() + newWord.slice(1);
        }

        //Otherwise return mapped word string as is
        return newWord;
      })
      .join(' ');
  });

export const getIntlValueMapper = (
  valuesObject: Record<string, string | {value: string; exactReplace?: boolean; exactMatch?: boolean}>,
): ((value: string) => string) => {
  const valuesMap = new Map(Object.entries(valuesObject).map(([key, value]) => [key.toLowerCase(), value]));
  const matchingRegexp = new RegExp(
    Object.keys(valuesObject)
      .map(key => key.replace(/[$()*+./?[\\\]^{|}-]/g, '\\$&').toLowerCase())
      .join('|'),
    'gi',
  );

  return value => getTranslatedValue(value, valuesMap, matchingRegexp);
};
