/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import React from 'react';
import {State} from 'react-router';
import _ from 'lodash';
import actionCreators from '../../actions/actionCreators';
import {AlertDialog, Navbar, SpinnerOverlay} from '../../components';
import {RulesetForm} from '../../components/Forms';
import Constants from '../../constants';
import intl from 'intl';
import {getSessionUri, getInstanceUri} from '../../lib/api';
import {RouterMixin, StoreMixin, UserMixin, UnsavedChangesMixin} from '../../mixins';
import {RulesetStore, SessionStore} from '../../stores';
import {RestApiUtils, GridDataUtils, RulesetUtils} from '../../utils';
import {fixServicePortForApi} from '../../utils/RestApiUtils';

function getStateFromStore() {
  const href = this.isActive('rulesetClone')
    ? getSessionUri(getInstanceUri('rule_sets'), {
        pversion: this.getParams().pversion,
        rule_set_id: this.rulesetId,
      })
    : null;
  const state = {
    status: [RulesetStore.getStatus()],
  };
  const ruleset = RulesetStore.getSpecified(href, this.getParams().pversion);

  if (ruleset) {
    state.ruleset = {...ruleset, name: intl('Rulesets.CreatePage.CopyOf', {name: ruleset.name})};
    delete state.ruleset.href; //delete href so form knows to hide enabled option
  }

  if (!_.isEmpty(this.getQuery())) {
    const initialValues = getInitialValues(this.getQuery());

    // Both initialValues.scopes[0] and initialValues.name needs to have values for query parameter to be relevant
    if (initialValues.scopes[0].length && initialValues.name) {
      state.ruleset = initialValues;
    }
  }

  return state;
}

function getInitialValues(query) {
  const scopes = [];
  let name = '';
  const keys = ['app', 'env', 'loc'];

  keys.forEach(key => {
    if (query[key]) {
      scopes.push({
        label: {
          href: getSessionUri(getInstanceUri('labels'), {
            label_id: _.last(query[key].split(':')),
          }),
        },
      });

      if (name !== '') {
        name += ' | ';
      }

      name += _.first(query[key].split(':'));
    }
  });

  return {
    name,
    description: '',
    scopes: [scopes],
  };
}

export default React.createClass({
  mixins: [State, RouterMixin, UserMixin, UnsavedChangesMixin, StoreMixin(RulesetStore, getStateFromStore)],

  componentWillMount() {
    if (SessionStore.isUserWithReducedScope() || SessionStore.isEdge()) {
      this.replaceWith('landing');
    }

    if (this.isUserReadOnlyOrProvisioner()) {
      this.replaceWith('rulesets.list');
    }
  },

  componentDidMount() {
    if (this.isActive('rulesetClone')) {
      this.getRuleset();
    }
  },

  componentWillReceiveProps(nextProps) {
    if (this.rulesetId !== nextProps.params.rule_set_id) {
      if (this.refs.form) {
        this.refs.form.clearForm();
      }

      if (this.isActive('rulesetClone')) {
        this.getRuleset();
      }
    }

    // nextProps.query is empty when UnsavedChangesMixin is triggered,
    // which results in the form (name, description) clearing out
    if (this.query !== nextProps.query && !_.isEmpty(nextProps.query)) {
      this.query = nextProps.query;

      const initialValues = getInitialValues(nextProps.query);

      // Both initialValues.scopes[0] and initialValues.name needs to have values for query parameter to be relevant
      if (initialValues.scopes[0].length && initialValues.name) {
        this.setState({ruleset: initialValues});
      }
    }
  },

  getRuleset() {
    this.rulesetId = this.getParams().rule_set_id;
    RestApiUtils.ruleSets.getInstance(this.rulesetId, this.getParams().pversion, true).catch(() => {
      this.transitionTo('rulesets.list');
    });
  },

  handleClose() {
    if (this.rulesetId) {
      // If this is a cloning page, navigate back to original ruleset
      this.replaceWith('rulesetRules', {id: this.rulesetId, pversion: 'draft'});
    } else {
      // If it's a simple create page, go back to list page
      this.transitionTo('rulesets.list');
    }
  },

  handleSave(model) {
    if (this.isActive('rulesetClone')) {
      model.enabled = this.state.ruleset.enabled;
      model.scopes = this.state.ruleset.scopes.map(scope => RulesetUtils.fixRulesetArr(scope));
      model.rules = this.state.ruleset.rules.map(rule => {
        const ingressServices = (rule.ingress_services || []).map(service =>
          service.href ? {href: service.href} : fixServicePortForApi(service),
        );

        return {
          enabled: rule.enabled,
          providers: RulesetUtils.fixRulesetArr(rule.providers),
          sec_connect: rule.sec_connect,
          consumers: RulesetUtils.fixRulesetArr(rule.consumers),
          ingress_services: ingressServices,
          resolve_labels_as: rule.resolve_labels_as,
          consuming_security_principals: rule.consuming_security_principals.map(csp => ({href: csp.href})),
          unscoped_consumers: rule.unscoped_consumers,
          machine_auth: rule.machine_auth,
          stateless: rule.stateless,
          description: rule.description || '',
        };
      });
      model.ip_tables_rules = this.state.ruleset.ip_tables_rules.map(rule => ({
        enabled: rule.enabled,
        statements: rule.statements,
        description: rule.description || '',
        actors: RulesetUtils.fixRulesetArr(rule.actors),
        ip_version: rule.ip_version,
      }));
    }

    if (!_.isEmpty(this.getQuery()) && this.state.ruleset) {
      model.enabled = true;
      model.scopes = this.state.ruleset.scopes;
    }

    RestApiUtils.ruleSets
      .create(model)
      .then(response => {
        RestApiUtils.secPolicies.dependencies();
        actionCreators.resetFacetValues('rulesetList');
        this.ignoreChanges = true;
        // Navigate with replace, so back button will lead to RulesetList from new detail page
        this.replaceWith('rulesetRules', {id: GridDataUtils.getIdFromHref(response.body.href), pversion: 'draft'});
      })
      .catch(error => {
        if (error.status === 406 && error.parsedBody[0].token !== 'rule_set_name_in_use') {
          actionCreators.openDialog(
            <AlertDialog alert={true} title={intl('Common.ValidationError')} message={error.parsedBody[0].message} />,
          );
        }
      });
  },

  hasChanged() {
    return this.refs.form ? this.refs.form.hasChanged() : false;
  },

  handleBack() {
    this.transitionTo('rulesets.list');
  },

  render() {
    if (this.isActive('rulesetClone') && !this.state.ruleset) {
      return this.state.status.includes(Constants.STATUS_BUSY) ? <SpinnerOverlay /> : null;
    }

    const title = this.isActive('rulesetClone') ? intl('Rulesets.DuplicateRuleset') : intl('Rulesets.Add');

    return (
      <div className="RulesetCreate" data-tid="page-ruleset-create">
        {this.state.status.includes(Constants.STATUS_BUSY) ? <SpinnerOverlay /> : null}
        <Navbar label={intl('Common.Rulesets')} type="edit" subTitle={title} onUp={this.handleBack} />
        <RulesetForm
          onSubmit={this.handleSave}
          onCancel={this.handleClose}
          ref="form"
          model={
            this.isActive('rulesetClone') || !_.isEmpty(this.getQuery() && this.state.ruleset)
              ? this.state.ruleset
              : undefined
          }
        />
      </div>
    );
  },
});
