/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import d3 from 'd3';
import _ from 'lodash';
import intl from 'intl';
import WorkloadStore from '../stores/WorkloadStore';
import ContainerWorkloadStore from '../stores/ContainerWorkloadStore';
import TrafficStore from '../stores/TrafficStore';
import IpListStore from '../stores/IpListStore';
import MapPageStore from '../stores/MapPageStore';
import UserStore from '../stores/UserStore';
import GraphStore from '../stores/GraphStore';
import CommandPanelStore from '../stores/CommandPanelStore';
import TrafficFilterStore from '../stores/TrafficFilterStore';
import HealthStore from '../stores/HealthStore';
import VirtualServiceStore from '../stores/VirtualServiceStore';
import SessionStore from '../stores/SessionStore';
import RenderUtils from './RenderUtils';
import WorkloadUtils from './WorkloadUtils';
import ServiceUtils from './ServiceUtils';
import IpUtils from './IpUtils';
import GridDataUtils from './GridDataUtils';
import JoinedVirtualServerStore from '../stores/JoinedVirtualServerStore';
import {HealthUtils} from '.';

// Calculates traffic panel info for all types of links
function getTrafficServices(link, selectedService) {
  let linkConnections = _.orderBy(link.connections, ['timestamp'], ['desc']);

  // if link is a internetLinks, we need get all connections
  if (link.internetLinks) {
    linkConnections = [];
    _.each(link.internetLinks, internetLink => {
      _.each(internetLink.connections, connection => {
        linkConnections.push(connection);
      });
    });
  }

  const allConnections = _.transform(
    linkConnections,
    (result, connection) => {
      if (!connection.isHidden) {
        let name = intl('Common.Unknown');
        const matchedServices = ServiceUtils.matchConnectionWithService(connection);

        if (matchedServices.length > 1) {
          name = matchedServices[0].name + ', +' + (matchedServices.length - 1);
        } else if (matchedServices.length) {
          name = matchedServices[0].name;
        } else if (RenderUtils.trafficHasProcessName(connection)) {
          // If there's no matched services, default to process name
          name = connection.service;
        }

        const newConnection = {...connection, name, matchedServices};
        const key = [
          newConnection.name,
          newConnection.port,
          newConnection.protocol,
          newConnection.connectionClass,
        ].join(',');

        if (!result[key]) {
          result[key] = [];
        }

        result[key].push(newConnection);
      }
    },
    {},
  );

  const allSessions = _.map(allConnections, connections => _.reduce(connections, (memo, c) => c.sessions + memo, 0));
  const minTraffic = _.min(allSessions);
  const maxTraffic = _.max(allSessions);
  const connectionScale = d3.scale.linear().domain([minTraffic, maxTraffic]).range([3, 50]);
  const policyVersion = MapPageStore.getPolicyVersion();
  const appMapVersion = MapPageStore.getAppMapVersion();
  const trafficFilters = TrafficFilterStore.getAll();
  const allConnectionsLength = Object.values(allConnections).length;

  const trafficServices = _.transform(
    allConnections,
    (result, connections) => {
      const sessions = _.reduce(connections, (memo, c) => c.sessions + memo, 0);
      // Only highlight a connection as selected if there's more than one connection.
      const isSelected =
        allConnectionsLength > 1 && selectedService && RenderUtils.isSelectedService(connections[0], selectedService);
      // Use the rule for the connection with the most recent timestamp

      const pdCounts = connections.reduce(
        (counts, connection) => {
          if (trafficFilters.allowBlockedTraffic && RenderUtils.isConnectionBlocked(connection, policyVersion)) {
            counts.blocked += 1;
          } else if (
            trafficFilters.allowPotentiallyBlockedTraffic &&
            RenderUtils.isConnectionPotentiallyBlocked(connection, policyVersion)
          ) {
            counts.potentiallyBlocked += 1;
          } else if (trafficFilters.allowAllowedTraffic && RenderUtils.isConnectionAllowed(connection, policyVersion)) {
            counts.allowed += 1;
          } else if (trafficFilters.allowUnknownTraffic && RenderUtils.isConnectionUnknown(connection, policyVersion)) {
            counts.unknown += 1;
          }

          // Aggregate connection vulnerabilities
          // This is only needed for app group to app group traffic which could have
          // different vulnerabilities on the same port
          if (appMapVersion !== 'policy' && connection.vulnerabilities) {
            if (!counts.vulnerabilities) {
              counts.vulnerabilities = {};
            }

            const aggregatedVulnerability = RenderUtils.aggregateVulnerabilityValues(
              counts.vulnerabilities.aggregatedValues,
              connection.vulnerabilities.aggregatedValues,
              connection.key,
            );

            counts.vulnerabilities.aggregatedValues = {
              ...aggregatedVulnerability,
              ...RenderUtils.getEmptyExposures(aggregatedVulnerability, counts.policyState),
            };

            counts.vulnerabilities.instances = (counts.vulnerabilities.instances || []).concat(
              connection.vulnerabilities.instances,
            );

            if (!counts.policyState) {
              counts.policyState = link.target?.policyState;
            } else if (counts.policyState !== link.target?.policyState) {
              counts.policyState = 'mixed';
            }
          }

          return counts;
        },
        {
          allowed: 0,
          potentiallyBlocked: 0,
          unknown: 0,
          blocked: 0,
          vulnerabilities: null,
          policyState: link.target?.policyState,
        },
      );

      result.push({
        ...connections[0],
        processNames: new Set(connections.map(connection => connection.processName)),
        serviceNames: new Set(connections.map(connection => connection.serviceName)),
        friendlyProtocol: connections[0].friendlyProtocol,
        trafficWeight: minTraffic === maxTraffic ? connectionScale.range()[3] : connectionScale(sessions),
        trafficPotentiallyBlocked: pdCounts.potentiallyBlocked && !pdCounts.blocked,
        trafficAllowed: pdCounts.allowed && !pdCounts.potentiallyBlocked && !pdCounts.blocked,
        trafficUnknown: pdCounts.unknown && !pdCounts.allowed && !pdCounts.blocked,
        isSelected,
        policyState: pdCounts.policyState,
        vulnerabilities: pdCounts.vulnerabilities,
      });
    },
    [],
  );

  if (MapPageStore.getAppMapVersion() === 'vulnerability') {
    return trafficServices.sort(
      (a, b) =>
        ((b.vulnerabilities && b.vulnerabilities.aggregatedValues.vulnerabilityExposureScore) || 0) -
        ((a.vulnerabilities && a.vulnerabilities.aggregatedValues.vulnerabilityExposureScore) || 0),
    );
  }

  return trafficServices.sort((a, b) =>
    a.trafficWeight > b.trafficWeight ? -1 : a.trafficWeight < b.trafficWeight ? 1 : 0,
  );
}

function getTrafficPbUb(link, selectedService, selectedPb, selectedUb) {
  const policyVersion = MapPageStore.getPolicyVersion();
  const trafficFilters = TrafficFilterStore.getAll();
  // If the traffic link has a connection matching selected service, return it
  // so that we can calculate its pb/ub
  let pb = {};
  let ub = {};
  let timestamp = {};

  if (link.internetLinks) {
    _.each(link.internetLinks, internetLink => {
      const matchedConnections = Object.values(internetLink.connections).filter(connection =>
        RenderUtils.isSelectedService(connection, selectedService),
      );

      if (!matchedConnections.length) {
        return;
      }

      const trafficBlocked =
        trafficFilters.allowBlockedTraffic &&
        matchedConnections.some(conn => RenderUtils.isConnectionBlocked(conn, policyVersion));
      const trafficPotentiallyBlocked =
        trafficFilters.allowPotentiallyBlockedTraffic &&
        !trafficBlocked &&
        matchedConnections.some(conn => RenderUtils.isConnectionPotentiallyBlocked(conn, policyVersion));
      const trafficAllowed =
        trafficFilters.allowAllowedTraffic &&
        !trafficBlocked &&
        matchedConnections.some(conn => RenderUtils.isConnectionAllowed(conn, policyVersion));
      const trafficUnknown =
        trafficFilters.allowUnknownTraffic &&
        !trafficBlocked &&
        !trafficAllowed &&
        matchedConnections.some(conn => RenderUtils.isConnectionUnknown(conn, policyVersion));

      // calculate ub
      let sources = [link.source];
      let sourceAddresses = [];

      if (RenderUtils.isInternetIpList(link.source)) {
        // if source is internet icon, then find all the corresponding internet/ipList object that belong to the link
        sources = _.filter(
          link.source.internets,
          internet =>
            internetLink.href === internet.linkHref &&
            (trafficBlocked || trafficPotentiallyBlocked || trafficAllowed || trafficUnknown),
        );
        sourceAddresses = [
          ...new Set(matchedConnections.reduce((result, connection) => [...result, ...connection.sourceAddresses], [])),
        ];
      }

      // an internet link may have multiple internet/ipList matches, so make sure to add them all
      _.each(sources, source =>
        getPbUbFromData(
          ub,
          source,
          selectedUb,
          sourceAddresses,
          trafficAllowed,
          trafficUnknown,
          trafficPotentiallyBlocked,
          matchedConnections[0].ruleSources,
        ),
      );

      // calculate pb
      let targets = [link.target];
      let targetAddresses = [];

      if (RenderUtils.isInternetIpList(link.target)) {
        targets = link.target.internets.filter(
          internet =>
            internetLink.href === internet.linkHref &&
            (trafficBlocked || trafficAllowed || trafficUnknown || trafficPotentiallyBlocked),
        );

        // Add Any to the options for all fqdns in the provider
        if (
          targets.every(target => RenderUtils.isHrefFqdn(target.href)) &&
          (trafficBlocked || trafficAllowed || trafficUnknown || trafficPotentiallyBlocked)
        ) {
          const any = IpListStore.getAnyIpList();

          targets.push({
            ...any,
            linkHref: [internetLink.href.split(',')[0], 'internet'].join(','),
            type: 'fqdn',
          });
        }

        targetAddresses = [
          ...new Set(matchedConnections.reduce((result, connection) => [...result, ...connection.targetAddresses], [])),
        ];
      }

      _.each(targets, target =>
        getPbUbFromData(
          pb,
          target,
          selectedPb,
          targetAddresses,
          trafficAllowed,
          trafficUnknown,
          trafficPotentiallyBlocked,
          matchedConnections[0].ruleTargets,
        ),
      );

      // calculate timestamp for each ub and pb
      timestamp = getTimestamp(ub, pb, timestamp, matchedConnections);
    });
  } else {
    const sortedConnections = _.orderBy(link.connections, ['timestamp'], ['desc']);
    const matchedConnection = Object.values(sortedConnections).find(connection =>
      RenderUtils.isSelectedService(connection, selectedService),
    );
    const trafficBlocked = RenderUtils.isConnectionBlocked(matchedConnection, policyVersion);
    const trafficPotentiallyBlocked =
      !trafficBlocked && RenderUtils.isConnectionPotentiallyBlocked(matchedConnection, policyVersion);
    const trafficAllowed = !trafficBlocked && RenderUtils.isConnectionAllowed(matchedConnection, policyVersion);
    const trafficUnknown =
      !trafficBlocked && !trafficAllowed && RenderUtils.isConnectionUnknown(matchedConnection, policyVersion);

    // calculate ub
    const sourceAddresses = [];

    getPbUbFromData(
      ub,
      link.source,
      selectedUb,
      sourceAddresses,
      trafficAllowed,
      trafficUnknown,
      trafficPotentiallyBlocked,
    );

    // calculate pb
    const targetAddresses = [];

    getPbUbFromData(
      pb,
      link.target,
      selectedPb,
      targetAddresses,
      trafficAllowed,
      trafficUnknown,
      trafficPotentiallyBlocked,
    );
    // calculate timestamp
    timestamp = getTimestamp(ub, pb, timestamp, [matchedConnection]);
  }

  pb = Object.values(pb);
  ub = Object.values(ub);

  // If pb or ub is only length 1, then we don't care about
  // showing the traffic allowed/blocked circle indicator.
  // We also don't care about showing the selection.
  if (pb.length === 1) {
    delete pb[0].trafficAllowed;
    delete pb[0].isSelected;
  }

  if (ub.length === 1) {
    delete ub[0].trafficAllowed;
    delete ub[0].isSelected;
  }

  ub.linkType = getLinkTypeFromSubType(link.source.subType, link.source.type);
  pb.linkType = getLinkTypeFromSubType(link.target.subType, link.target.type);

  return {pb, ub, timestamp};
}

function getLinkTypeFromSubType(subType, type) {
  if (subType === 'container') {
    return 'containerWorkload';
  }

  if (subType === 'virtual_server') {
    return 'virtualServer';
  }

  return type;
}

// The input pb here represents pb/ub
// target represents target/source
// selectedPb represents selectedPb/Ub
function getPbUbFromData(
  pb,
  target,
  selectedPb,
  targetAddresses,
  trafficAllowed,
  trafficUnknown,
  trafficPotentiallyBlocked,
  matchedConnection,
) {
  const targetHref = target.href;
  const policyVersion = MapPageStore.getPolicyVersion();

  if (!pb[targetHref]) {
    const role = RenderUtils.getLabelHrefFromNode(target, 'role');
    const href = role || target.href;
    const name = (role && role.value) || target.name || RenderUtils.getInternetName(target);
    const type = role ? 'label' : target.type;
    const isSelected = selectedPb && selectedPb.href === href;
    let labels;

    if (!_.isEmpty(target.labels)) {
      labels = target.labels;
    }

    pb[targetHref] = {
      href,
      type,
      name,
      addresses: [],
      trafficAllowed: true,
      trafficUnknown: true,
      trafficPotentiallyBlocked: true,
      labels, // for interapp link, the scope labels need to be displayed in pb/ub
      isSelected,
      fqdnIpListMatch: target.fqdnIpListMatch,
      caps: target.caps,
    };
  }

  const matchedAllowed =
    matchedConnection && policyVersion === 'draft'
      ? matchedConnection.hasOwnProperty(targetHref) || matchedConnection.hasOwnProperty('all')
      : true;

  pb[targetHref].addresses = _.union(pb[targetHref].addresses, targetAddresses);
  // trafficAllowed is the red/green indicator right by the pb/ub
  pb[targetHref].trafficAllowed = pb[targetHref].trafficAllowed && trafficAllowed && matchedAllowed;
  pb[targetHref].trafficUnknown = pb[targetHref].trafficUnknown && trafficUnknown;
  pb[targetHref].trafficPotentiallyBlocked = pb[targetHref].trafficPotentiallyBlocked && trafficPotentiallyBlocked;
}

function getTimestamp(ub, pb, timestamps, matchedConnections) {
  const newTimestamp = {...timestamps};
  const connectionTimestamp = matchedConnections.reduce((result, conn) => Math.max(result, conn?.timestamp), 0);

  _.each(ub, u => {
    _.each(pb, p => {
      const key = u.href + ',' + p.href;

      if (!newTimestamp[key] || (connectionTimestamp && connectionTimestamp > newTimestamp[key])) {
        newTimestamp[key] = connectionTimestamp;
      }
    });
  });

  return newTimestamp;
}

export default {
  calculateWorkloadPanel(node) {
    const data = RenderUtils.getWorkloadData(node) || node.data;
    const superclusterData = HealthStore.getClusters();
    const info = {};
    // Data for the info panel

    info.href = node.href;
    info.caps = node.caps;
    info.name = data.name || data.hostname;
    info.labels = node.labels;
    info.ipAddress = data.public_ip;
    info.policyState = node.policyState;
    info.group = node.cluster;
    info.subType = node.subType;
    info.workload =
      node.subType === 'container' && data.agent ? WorkloadStore.getWorkloadFromAgent(data.agent.href) : null;

    if (MapPageStore.getAppMapVersion() === 'vulnerability') {
      const vulnerability = TrafficStore.getNodeVulnerabilityByHref(node.href);
      const filterLevel = TrafficFilterStore.getAll().vulnerabilitySeverity;

      info.vulnerabilities = vulnerability
        ? Object.values(vulnerability.instances)
            .flat()
            .sort((a, b) => {
              const sevA = RenderUtils.getVulnerabilityForRender(a.severity, 'key');
              const sevB = RenderUtils.getVulnerabilityForRender(b.severity, 'key');

              // Filtered level vulnerabilies at the bottom
              if (filterLevel > sevA && filterLevel <= sevB) {
                return 1;
              }

              if (filterLevel > sevB && filterLevel <= sevA) {
                return -1;
              }

              return (b.vulnerabilityExposureScore || 0) - (a.vulnerabilityExposureScore || 0);
            })
        : [];

      if (TrafficFilterStore.getAll().exposedVulnerabilities) {
        info.vulnerabilities = info.vulnerabilities.filter(vulnerability =>
          Boolean(vulnerability.vulnerablePortExposure),
        );
      }

      info.vulnerabilities = info.vulnerabilities.map(vulnerability => ({
        ...RenderUtils.getEmptyExposures(vulnerability, node.policyState || 'unmanaged'),
      }));
    } else {
      info.policyState = node.policyState;

      if (info.policyState === 'enforced') {
        info.visibility = RenderUtils.getWorkloadVisibility(!node.subType === 'container' && data);
      }

      info.agent = data.agent;
      info.online = data.online;
      info.status = WorkloadUtils.getWorkloadStatus(data);
      info.services = RenderUtils.getWorkloadServices(data);

      if (data.agent && data.agent.active_pce_fqdn && superclusterData) {
        const cluster = superclusterData.find(cluster => cluster.fqdn === data.agent.active_pce_fqdn);
        // If active_pce_fqdn is unavailable/down, show Error status.
        // For GSLB, active_pce_fqdn should switch to a running PCE,
        // and a non null cluster status would exist.
        // The string 'Error' is a non intlized API response string.
        const clusterStatus = _.get(cluster, 'status', 'critical');

        info.pce = data.agent.active_pce_fqdn;
        info.pceHealth = HealthUtils.getPceHealth(clusterStatus);
      }
    }

    return info;
  },

  calculateLocationSearch() {
    const locationsInfo = {};

    // shouldn't show discovered and no_location locations in location search panel in any level
    locationsInfo.locations = _.transform(
      TrafficStore.getLocations(),
      (result, location, key) => {
        if (location.href !== 'discovered' && location.href !== 'no_location') {
          result[key] = location;
        }
      },
      {},
    );
    locationsInfo.groups = TrafficStore.getGroupSearch();

    return locationsInfo;
  },

  calculateSearch(mapRoute, type) {
    const searchInfo = {};

    searchInfo.searchData = [...TrafficStore.getAllAppGroupNodes(), ...TrafficStore.getAllPhantomAppGroupNodes()];

    if (type) {
      searchInfo.connectedType = type;

      const superGroups = GraphStore.getLocationNodes();

      searchInfo.connectedSearchData =
        superGroups &&
        superGroups[type]?.connectedAppGroups.filter(appgroup => !appgroup.isHidden)?.length > 1 &&
        superGroups[type]?.connectedAppGroups.filter(appgroup => !appgroup.isHidden);
    }

    return searchInfo;
  },

  calculateConnectedAppGroupCounts() {
    const superGroups = GraphStore.getLocationNodes();

    const consumingSuperGroup = superGroups && superGroups.consuming;
    const providingSuperGroup = superGroups && superGroups.providing;

    return {
      consuming: consumingSuperGroup
        ? consumingSuperGroup.connectedAppGroups.filter(appgroup => !appgroup.isHidden).length
        : 0,
      providing: providingSuperGroup
        ? providingSuperGroup.connectedAppGroups.filter(appgroup => !appgroup.isHidden).length
        : 0,
    };
  },

  calculateLocationPanel(location) {
    const info = {};

    // Data for the info panel
    info.href = location.href;
    info.name = location.name;
    info.groups = TrafficStore.getGroupSearch();
    info.locationGroups = _.transform(
      TrafficStore.getAllClusterNodes(),
      (result, cluster) => {
        const labels = RenderUtils.getLabels(cluster.labels);

        if (cluster.entityCounts && labels.loc && labels.loc.href === location.href) {
          result.push({
            href: cluster.href,
            id: cluster.clusterId,
            workloadsNum: cluster.entityCounts,
            labels: _.sortBy(cluster.labels, 'key').map(label => label.value),
            name: _.isEmpty(cluster.labels)
              ? cluster.href
              : _.sortBy(cluster.labels, 'key')
                  .map(label => label.value)
                  .join(' | '),
          });
        }
      },
      [],
    );

    info.virtualServicesNum = location.virtualServicesNum ? location.virtualServicesNum : 0;
    info.workloadsNum = location.workloadsNum ? location.workloadsNum : 0;
    info.virtualServersNum = location.virtualServersNum ? location.virtualServersNum : 0;
    info.containerWorkloadsNum = location.containerWorkloadsNum ? location.containerWorkloadsNum : 0;
    info.locationLabel = {
      href: location.href,
      value: location.name,
      key: 'loc',
    };
    // shouldn't show discovered and no_location locations in location search panel in any level
    info.locations = _.transform(
      TrafficStore.getLocations(),
      (result, location, key) => {
        if (location.href !== 'discovered' && location.href !== 'no_location') {
          result[key] = location;
        }
      },
      {},
    );

    return info;
  },

  calculateRolePanel(node) {
    const info = {};
    let roleLabels = null;

    // Data for the info panel
    info.href = node.href;
    info.name = node.data.name;
    info.labels = node.labels;
    info.caps = node.caps;
    roleLabels = _.sortBy(node.labels, 'href').map(label => label.href);

    if (MapPageStore.getAppMapVersion() === 'vulnerability') {
      const vulnerability = TrafficStore.getNodeVulnerabilityByHref(node.href);
      const filterLevel = TrafficFilterStore.getAll().vulnerabilitySeverity;

      info.vulnerabilities = vulnerability
        ? Object.values(vulnerability.instances)
            .flat()
            .sort((a, b) => {
              const sevA = RenderUtils.getVulnerabilityForRender(a.severity, 'key');
              const sevB = RenderUtils.getVulnerabilityForRender(b.severity, 'key');

              // Filtered level vulnerabilies at the bottom
              if (filterLevel > sevA && filterLevel <= sevB) {
                return 1;
              }

              if (filterLevel > sevB && filterLevel <= sevA) {
                return -1;
              }

              return (b.vulnerabilityExposureScore || 0) - (a.vulnerabilityExposureScore || 0);
            })
        : [];

      if (TrafficFilterStore.getAll().exposedVulnerabilities) {
        info.vulnerabilities = info.vulnerabilities.filter(vulnerability =>
          Boolean(vulnerability.vulnerablePortExposure),
        );
      }

      info.vulnerabilities = info.vulnerabilities.map(vulnerability => ({
        ...RenderUtils.getEmptyExposures(vulnerability, node.policyState),
      }));
    } else {
      const policyStateFilters = TrafficFilterStore.getHiddenPolicyStates();

      info.workloads = _.transform(
        WorkloadStore.getAll(),
        (result, workload) => {
          const labels = _.sortBy(workload.labels, 'href').map(label => label.href);
          const name = workload.name || workload.hostname;
          const id = _.last(workload.href.split('/'));
          const policyState = RenderUtils.getPolicyState(workload, 'workload') || 'unmanaged';

          if (_.isEqual(labels, roleLabels) && (!policyState || !policyStateFilters.includes(policyState))) {
            result.push({name, id, policyState});
          }
        },
        [],
      ).sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0));

      if (node.containerWorkloadsNum) {
        info.containerWorkloads = _.transform(
          ContainerWorkloadStore.getAll(),
          (result, workload) => {
            const labels = _.sortBy(workload.labels, 'href').map(label => label.href);
            const name = workload.name;
            const id = _.last(workload.href.split('/'));
            const policyState = RenderUtils.getPolicyState(workload, 'workload');

            if (_.isEqual(labels, roleLabels) && (!policyState || !policyStateFilters.includes(policyState))) {
              result.push({name, id, policyState});
            }
          },
          [],
        ).sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0));
      }

      if (node.virtualServicesNum) {
        info.virtualServices = _.transform(
          VirtualServiceStore.getAll().draft,
          (result, vs) => {
            const labels = _.sortBy(vs.labels, 'href').map(label => label.href);
            const name = vs.name;
            const id = _.last(vs.href.split('/'));

            if (_.isEqual(labels, roleLabels)) {
              result.push({name, id});
            }
          },
          [],
        ).sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0));
      }
    }

    info.entityNum = node.entityCounts;
    info.workloadsNum = node.workloadsNum;
    info.virtualServersNum = node.virtualServersNum;
    info.virtualServicesNum = node.virtualServicesNum;
    info.containerWorkloadsNum = node.containerWorkloadsNum;

    info.policyState = node.policyState;

    return info;
  },

  calculateVirtualServerPanel(node) {
    const info = {};
    const data = JoinedVirtualServerStore.getSpecified(node.href) || node.data;

    info.href = node.href;
    info.caps = node.caps;
    info.name = data.name || data.hostname;
    info.labels = node.labels;
    info.policyState = data.mode;
    info.port = data.dvs ? data.dvs.vip_port.port : null;
    info.protocol = data.dvs ? ServiceUtils.lookupProtocol(data.dvs.vip_port.protocol) : null;
    info.device = data.slb ? data.slb.name : null;
    info.addresses = data.dvs ? data.dvs.vip_port.vip : null;
    info.caps = node.data.caps || node.caps;

    return info;
  },

  calculateVirtualServicePanel(node) {
    const data = RenderUtils.getVirtualServiceData(node) || node.data;
    const info = {};
    const virtualService = VirtualServiceStore.getSpecified(node.href) || {};

    // If the caps come from the virtual service itself, add those to the workload caps
    // We will not care how the backend chose to fill this caps (workloads/rulesets)
    // we will use them as workload caps so all our logic will just work.

    // in case it assumes ‘read’ but does not have 'read' in caps
    info.caps = Array.isArray(data.caps) ? {...node.caps, workloads: [...data.caps, 'read']} : node.caps;

    //TBD What info do we need here?
    info.href = node.href;
    info.vs = virtualService;
    info.name = data.name || data.hostname;
    info.labels = node.labels;
    info.policyState = data.mode;
    info.port = data.dvs ? data.dvs.vip_port.port : null;
    info.protocol = data.dvs ? ServiceUtils.lookupProtocol(data.dvs.vip_port.protocol) : null;
    info.device = data.slb ? data.slb.name : null;
    info.addresses = data.dvs ? data.dvs.vip_port.vip : null;
    info.group = node.cluster;
    info.ipOverrides = data.ip_overrides ? data.ip_overrides : [];
    info.service = virtualService.service || null;
    info.boundWorkloads = virtualService.bound_workloads || [];
    info.id = _.last(node.href.split('/'));

    return info;
  },

  calculateInternetPanel(selections) {
    const info = {};

    info.name = RenderUtils.getNodeTitle(selections[0]);
    info.linkedWorkloads = new Set();
    info.linkedRoles = new Set();
    info.type = selections[0].type;
    info.caps = (
      TrafficStore.getNode(selections[0].nodeHref) ||
      TrafficStore.getCluster(selections[0].clusterHref) ||
      TrafficStore.getAppGroupNode(selections[0].clusterHref)
    )?.caps;

    const internets = _.transform(
      selections[0].internetLinks,
      (result, selection) => {
        if (
          (selection.type !== 'ipList' && selection.type !== 'fqdn') ||
          ((selection.type !== 'fqdn' || selection.fqdnIpListMatch || RenderUtils.isHrefFqdn(selection.href)) &&
            (!IpListStore.getAnyIpList() || IpListStore.getAnyIpList().href !== selection.href))
        ) {
          const traffic = TrafficStore.getTraffic(selection.linkHref);

          if (!result[selection.href]) {
            result[selection.href] = {
              href: selection.href,
              name: selection.name || RenderUtils.getInternetName(selection),
              any:
                selection.href === 'internet' ||
                (selection.type === 'ipList' && IpListStore.getAnyIpList().href === selection.href),
              addresses: [],
            };
          }

          if (traffic) {
            let address;

            if (RenderUtils.isInternetIpList(traffic.source)) {
              address = _.map(traffic.connections, 'sourceAddresses');

              // Get the href of the connected nodes
              if (traffic.target.type === 'workload') {
                info.linkedWorkloads.add(traffic.target.href);
              } else if (traffic.target.type === 'role') {
                info.linkedRoles.add(traffic.target.href);
              }
            } else {
              address = _.map(traffic.connections, 'targetAddresses');

              if (traffic.source.type === 'workload') {
                info.linkedWorkloads.add(traffic.source.href);
              } else if (traffic.source.type === 'role') {
                info.linkedRoles.add(traffic.source.href);
              }
            }

            address = address.flat();

            if (address.length) {
              result[selection.href].addresses = _.union(result[selection.href].addresses, address).sort((a, b) => {
                // This >>> 0 does nothing but convert this to an unsigned integer
                const aIp = IpUtils.convertIpv4ToInt(a.split(' - ').shift()) >>> 0;
                const bIp = IpUtils.convertIpv4ToInt(b.split(' - ').shift()) >>> 0;

                return aIp > bIp ? 1 : aIp < bIp ? -1 : 0;
              });
            }
          }
        }
      },
      {},
    );

    // change objects and sets to arrays for current usages in the other files.
    info.internets = Object.values(internets).sort((a, b) => {
      if (a.href === 'internet') {
        return -1;
      }

      if (b.href === 'internet') {
        return 1;
      }

      return a.href > b.href ? 1 : a.href < b.href ? -1 : 0;
    });
    info.linkedWorkloads = [...info.linkedWorkloads];
    info.linkedRoles = [...info.linkedRoles];

    // The second option here is for the unconnected nodes, which should only have one linked workload
    info.parent = TrafficStore.getNode(selections[0].clusterHref || info.linkedWorkloads[0]);

    return info;
  },

  calculateAppGroupPanel(appGroup) {
    const info = {};

    info.href = appGroup.href;
    info.labels = appGroup.labels;
    info.caps = appGroup.caps;
    info.entities = {};
    info.entityNum = 0;

    if (appGroup.nodes) {
      if (MapPageStore.getAppMapVersion() === 'vulnerability') {
        const vulnerability = TrafficStore.getNodeVulnerabilityByHref(appGroup.href);

        info.vulnerabilities = vulnerability
          ? Object.values(vulnerability.instances)
              .flat()
              .sort((a, b) => b.vulnerabilityExposureScore - a.vulnerabilityExposureScore)
          : [];

        if (TrafficFilterStore.getAll().exposedVulnerabilities) {
          info.vulnerabilities = info.vulnerabilities.filter(vulnerability =>
            Boolean(vulnerability.vulnerablePortExposure),
          );
        }

        info.vulnerabilities = info.vulnerabilities.map(vulnerability => ({
          ...RenderUtils.getEmptyExposures(vulnerability, appGroup.policyState),
        }));
      }

      info.entities = {};
      info.entityNum = 0;
      info.virtualServicesNum = 0;
      info.workloadsNum = 0;
      info.virtualServersNum = 0;
      info.containerWorkloadsNum = 0;

      appGroup.nodes.forEach(node => {
        const key = node.labels && node.labels.role ? node.labels.role.value : undefined;

        if (!info.entities[key]) {
          info.entities[key] = 0;
        }

        info.entities[key] += node.type === 'role' ? node.entityCounts : 1;
        info.entityNum += node.type === 'role' ? node.entityCounts : 1;
        info.virtualServicesNum +=
          node.type === 'role' ? node.virtualServicesNum : node.type === 'virtualService' ? 1 : 0;
        info.workloadsNum +=
          node.type === 'role' ? node.workloadsNum : node.type === 'workload' && node.subType !== 'container' ? 1 : 0;
        info.virtualServersNum += node.type === 'role' ? node.virtualServersNum : node.type === 'virtualServer' ? 1 : 0;
        info.containerWorkloadsNum +=
          node.type === 'role' ? node.containerWorkloadsNum : node.subType === 'container' ? 1 : 0;
      });

      info.roleNum = _.size(info.entities);
      info.policyState = appGroup.policyState;

      if (info.policyState === 'enforced') {
        // calculate nodes visibility
        const nodesVisibility = _.transform(
          appGroup.nodes,
          (result, node) => {
            if (node.type === 'workload') {
              result.push({
                visibility: RenderUtils.getWorkloadVisibility(node.data),
              });
            }
          },
          [],
        );

        info.visibility = RenderUtils.getAppVisibility(nodesVisibility);
      }
    }

    info.connectedSearchType = CommandPanelStore.getConnectedSearchType();

    return Object.assign(info, this.calculateConnectedAppGroupCounts(appGroup));
  },

  calculateGroupPanel(cluster) {
    const info = {};
    const href = cluster.href;

    if (cluster.caps) {
      // If we are on a supercluster or everything is a container or a virtual service remove the workload write
      if (
        cluster.caps.workloads.length === 2 &&
        (SessionStore.isSuperclusterMember() ||
          cluster.containerWorkloadCounts + cluster.virtualServicesNum === cluster.entityCounts)
      ) {
        cluster.caps.workloads = ['read'];
      }
    }

    info.href = href;
    info.labels = cluster.labels;
    info.caps = cluster.caps;

    // get workloads num
    info.entities = {};
    info.entityNum = 0;
    // need test
    _.each(cluster.nodes, node => {
      const key = node.labels && node.labels.role ? node.labels.role.value : undefined;

      if (!info.entities[key]) {
        info.entities[key] = 0;
      }

      info.entities[key] += node.type === 'role' ? node.entityCounts : 1;
      info.entityNum += node.type === 'role' ? node.entityCounts : 1;
    });
    info.roleNum = _.size(info.entities);
    info.nodes = cluster.nodes;
    info.virtualServicesNum = cluster.virtualServicesNum ? cluster.virtualServicesNum : 0;
    info.workloadsNum = cluster.workloadsNum ? cluster.workloadsNum : 0;
    info.virtualServersNum = cluster.virtualServersNum ? cluster.virtualServersNum : 0;
    info.containerWorkloadsNum = cluster.containerWorkloadsNum ? cluster.containerWorkloadsNum : 0;
    info.policyState = cluster.policyState;

    if (info.policyState === 'enforced') {
      // calculate nodes visibility
      const nodesVisibility = _.transform(
        cluster.nodes,
        (result, node) => {
          if (node.type === 'workload') {
            result.push({
              visibility: RenderUtils.getWorkloadVisibility(node.data),
            });
          }
        },
        [],
      );

      info.visibility = RenderUtils.getAppVisibility(nodesVisibility);
    }

    info.connectedClusters = {};
    _.forEach(GraphStore.getClusters(), cluster => {
      info.connectedClusters[cluster.href] = TrafficStore.getNode(cluster.href);
    });

    // Remove the center cluster
    delete info.connectedClusters[href];
    info.connectedClusterNum = _.size(info.connectedClusters);
    info.currentExpanded = GraphStore.getExpandedClusters();

    return info;
  },

  // Pass in optional second parameter, selectedService (an object of service name, port, protocol and connectionClass).
  // If there is no selectedService, default to first service
  calculateTrafficPanel(link, trafficSelection) {
    const info = {};

    info.href = link.identifier;
    info.sourceName = RenderUtils.getNodeTitle(link.source);
    info.targetName = RenderUtils.getNodeTitle(link.target);
    info.colorBlind = UserStore.getColorBlindMode();
    info.interapp = RenderUtils.isInterappTraffic(link);

    const selectedService = trafficSelection.service;
    const selectedPb = trafficSelection.pb;
    const selectedUb = trafficSelection.ub;

    info.services = getTrafficServices(link, selectedService);

    const matchedConnection = Object.values(link.connections).some(connection => {
      return RenderUtils.isSelectedService(connection, selectedService);
    });

    // if it is a cluster link
    // Reduce by the number of consolidated ports
    info.serviceNum = link.serviceNum - (_.size(link.connections) - _.size(info.services));
    info.updateSelectedService = !matchedConnection;
    info.selectedService = selectedService;

    const pbub = getTrafficPbUb(link, selectedService, selectedPb, selectedUb);

    info.sources = pbub.ub;
    info.targets = pbub.pb;

    let key;

    if (selectedPb && selectedUb) {
      key = selectedUb.href + ',' + selectedPb.href;
    } else if (pbub.ub && pbub.ub[0] && pbub.pb && pbub.pb[0]) {
      // Pick the first pb ub until the selection is determined to prevent the filter flickering
      key = pbub.ub[0].href + ',' + pbub.pb[0].href;
    }

    if (key) {
      // in API, the time is in seconds, so multiply 1000 to use this function
      info.timestamp = pbub.timestamp[key] ? GridDataUtils.formatDate(pbub.timestamp[key] * 1000) : null;
    }

    return info;
  },

  calculateAppGroupTrafficPanel(links) {
    const info = {serviceNum: 0};

    const combined = _.transform(
      links,
      (result, link) => {
        // Some of the services might be duplicated when we aggregate, so we will reduce this value later
        info.serviceNum += link.serviceNum;

        _.forOwn(link.connections, (connection, key) => {
          if (!connection.isHidden) {
            const resultConn = result.connections && result.connections[key];

            if (resultConn) {
              resultConn.sessions += connection.sessions;
              resultConn.isSessionAllowed &= connection.isSessionAllowed;

              // If the old or the new connection is missing a rule then the connection is blocked
              if (resultConn.rules.length && connection.rules.length) {
                resultConn.rules = [...resultConn.rules, ...connection.rules];
              } else {
                resultConn.rules = [];
              }

              //For duplicate connections, reduce the total serviceNum
              info.serviceNum -= 1;
            } else {
              if (!result.connections) {
                result.connections = {};
              }

              const nextConnection = {...connection};

              delete nextConnection.vulnerabilities;

              result.connections[key] = {...nextConnection};
            }

            if (connection.vulnerabilities) {
              if (!result.target.policyState) {
                result.target.policyState = link.target && link.target.policyState;
              } else if (link.target && result.target.policyState !== link.target.policyState) {
                result.target.policyState = 'mixed';
              }

              if (!result.connections[key].hasOwnProperty('vulnerabilities')) {
                result.connections[key].vulnerabilities = {};
              }

              const aggregatedVulnerability = RenderUtils.aggregateVulnerabilityValues(
                result.connections[key].vulnerabilities.aggregatedValues,
                connection.vulnerabilities.aggregatedValues,
                key,
              );

              result.connections[key].vulnerabilities.aggregatedValues = {
                ...aggregatedVulnerability,
                ...RenderUtils.getEmptyExposures(aggregatedVulnerability, link.target.policyState),
              };

              result.connections[key].vulnerabilities.instances = (
                result.connections[key].vulnerabilities.instances || []
              ).concat(connection.vulnerabilities.instances);
            }
          }
        });
      },
      {target: {}},
    );

    info.colorBlind = UserStore.getColorBlindMode();
    info.services = getTrafficServices(combined);

    return info;
  },
};
