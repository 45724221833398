/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import {createApiStore} from '../lib/store';
import Constants from '../constants';

const DEFAULT_TIMESPAN_DAYS = 90;
let maxExplorerQueryTimespanDays = DEFAULT_TIMESPAN_DAYS;

export default createApiStore(['SETTINGS_GET_'], {
  dispatchHandler(action) {
    switch (action.type) {
      case Constants.SETTINGS_GET_SUCCESS:
        maxExplorerQueryTimespanDays = action?.data?.max_explorer_query_timespan_days ?? DEFAULT_TIMESPAN_DAYS;
        break;

      default:
        return true;
    }

    this.emitChange();

    return true;
  },

  getExplorerMaxQueryTimespan() {
    return maxExplorerQueryTimespanDays;
  },
});
