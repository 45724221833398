/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {Component} from 'react';
import {Link} from 'react-router';
import intl from 'intl';
import actionCreators from '../../../actions/actionCreators';
import {ServiceStore, SessionStore} from '../../../stores';
import {RestApiUtils, RenderUtils, ServiceUtils} from '../../../utils';
import {Button, Label, LabelGroup, Icon, Badge, ConfirmationDialog, Spinner} from '../..';
import {getHrefParams} from '../../../lib/api';

export default class ViewRulePanel extends Component {
  constructor(props) {
    super(props);

    this.getRules(0);

    this.handlePrevRule = this.handlePrevRule.bind(this);
    this.handleNextRule = this.handleNextRule.bind(this);
    this.handleDeleteRule = this.handleDeleteRule.bind(this);
    this.handleDeleteConfirm = this.handleDeleteConfirm.bind(this);

    this.state = {
      index: 0,
      rule: null,
      ruleset: null,
    };
  }

  getRules(index) {
    const ruleHref = this.props.data.form.rules[index];
    const hrefParams = getHrefParams(ruleHref);

    _.defer(async () => {
      try {
        let ruleset;
        let rule;

        if (ruleHref.includes('enforcement')) {
          const response = await RestApiUtils.enforcementBoundaries.get(
            hrefParams.enforcement_boundary_id,
            hrefParams.pversion,
            true,
            {
              representation: 'labels_workloads_count',
            },
          );

          rule = response.body;
        } else {
          const response = await RestApiUtils.ruleSets.getInstance(hrefParams.rule_set_id, hrefParams.pversion, true);

          ruleset = response.body;
          rule = ruleset?.rules.find(rule => rule.href === ruleHref);
        }

        if (rule) {
          // Find specific rule in the ruleset
          this.setState({rule, ruleset});
        } else {
          this.handleClose();
        }
      } catch (error) {
        if (error.status === 404) {
          this.setState({error: 'permission'});
        } else {
          this.handleClose();
        }
      }
    });
  }

  handleClose() {
    actionCreators.closeActionItem();
    actionCreators.closeDialog();
  }

  async handleDeleteConfirm() {
    const ruleHref = this.props.data.form.rules[this.state.index];
    const hrefParams = getHrefParams(ruleHref);

    if (ruleHref.includes('enforcement')) {
      await RestApiUtils.enforcementBoundaries.delete(hrefParams.enforcement_boundary_id);
    } else {
      await RestApiUtils.secRule.delete(hrefParams.rule_set_id, hrefParams.sec_rule_id);
    }

    actionCreators.updateRuleCoverageForAll();
    actionCreators.updateRingFenceRules();

    this.handleClose();
  }

  handleDeleteRule() {
    actionCreators.openDialog(
      <ConfirmationDialog
        title={intl('Explorer.DeletePolicyTitle')}
        message={intl('Explorer.DeletePolicyMessage')}
        onConfirm={this.handleDeleteConfirm}
      />,
    );
  }

  handlePrevRule() {
    const index = this.state.index - 1;

    this.getRules(index);
    this.setState({index});
  }

  handleNextRule() {
    const index = this.state.index + 1;

    this.getRules(index);
    this.setState({index});
  }

  renderEndpoint(type, edge) {
    const {rule} = this.state;

    if (rule) {
      // If the Ruleset has any scope, and the traffic is extrascope, the consumers are extrascope
      let title =
        type === 'consumers'
          ? rule.unscoped_consumers
            ? intl('Map.ExtraScopeConsumers')
            : intl('Common.Consumers')
          : intl('Common.Providers');

      if (edge) {
        title = type === 'consumers' ? intl('Common.Source') : intl('Common.Destination');
      }

      let items = rule[type];

      items = _.sortBy(items, item => item.label && item.label.key);

      const endpoint = items.reduce((result, item) => {
        if (item.label) {
          const {key, value} = item.label;

          if (key === 'role') {
            result.unshift(
              edge ? (
                <span className="MapInfoPanel-Group">{value}</span>
              ) : (
                <Label key={value} text={value} type={key} />
              ),
            );
          } else {
            result.push(<Label key={value} text={value} type={key} />);
          }
        } else if (item.workload) {
          const name = item.workload.name || item.workload.hostname;

          result.push(<Label key={name} text={name} icon="workload" />);
        } else if (item.ip_list) {
          const {name} = item.ip_list;

          result.push(<Label key={name} text={name} icon="ip-list" />);
        } else if (item.virtual_server) {
          const {name} = item.virtual_server;

          result.push(<Label key={name} text={name} icon="virtual-server" />);
        } else if (item.virtual_service) {
          const {name} = item.virtual_service;

          result.push(<Label key={name} text={name} icon="virtual-service" />);
        } else if (item.actors) {
          result.push(<Label icon="all-workloads" text="All Workloads" />);
        } else if (item.label_group) {
          const {key, name} = item.label_group;
          const labelGroup = <LabelGroup key={name} text={name} type={key} />;

          if (key === 'role') {
            result.unshift(labelGroup);
          } else {
            result.push(labelGroup);
          }
        }

        return result;
      }, []);

      let usesVirtualServices;

      if (rule.resolve_labels_as) {
        if (rule.resolve_labels_as[type].includes('virtual_services')) {
          usesVirtualServices = <div>{intl('Common.UsesVirtualServicesWorkloads')}</div>;
        }

        if (_.isEqual(rule.resolve_labels_as[type], ['virtual_services'])) {
          usesVirtualServices = <div>{intl('Common.UsesVirtualServices')}</div>;
        }
      }

      return (
        <tr className="MapInfoPanel-Row" data-tid="map-info-panel-row">
          <td>
            <div className="MapFormPanel-Row">
              <div className="MapInfoPanel-Row-Label" data-tid="map-info-panel-row-label">
                {title}
              </div>
              <div className="MapInfoPanel-Row-Value MapFormPanel-Wrap" data-tid="map-info-panel-row-value">
                {endpoint}
                {usesVirtualServices}
              </div>
            </div>
          </td>
        </tr>
      );
    }
  }

  renderRuleset(edge) {
    const {ruleset, rule} = this.state;
    const orgPolicy = (edge && !ruleset) || ruleset?.name === '__IllumioEdgeDefault';
    const hrefParams = getHrefParams(ruleset?.href);

    let title = intl('Common.Ruleset');
    let route = 'rulesetRules';
    let params = {id: hrefParams.rule_set_id, pversion: hrefParams.pversion};
    let query = {};

    if (edge) {
      if (orgPolicy) {
        title = '';
        route = 'outboundpolicy';
        params = {};
      } else {
        const roleLabelHref = ruleset?.scopes[0][0]?.label?.href;

        title = intl('Common.Group');
        route = roleLabelHref && 'groups.view';
        params = {group: roleLabelHref && getHrefParams(roleLabelHref).label_id};
        query = {tab: 'inboundpolicy', pversion: hrefParams.pversion};
      }
    } else if (rule?.href.includes('enforcement')) {
      const ruleParams = getHrefParams(rule.href);

      title = intl('Workloads.EnforcementBoundary');
      route = 'boundaries.item';
      params = {id: ruleParams.enforcement_boundary_id, pversion: 'draft'};
    }

    const rulesetName = orgPolicy
      ? intl('Policy.Organization')
      : RenderUtils.truncateAppGroupName((ruleset || rule).name, 40, [20, 10, 10]);

    return ruleset?.name !== 'Default' ? (
      <tr className="MapInfoPanel-Row" data-tid="map-info-panel-row">
        <td>
          <div className="MapFormPanel-Row">
            <div className="MapInfoPanel-Row-Label" data-tid="map-info-panel-row-label">
              {title}
            </div>
            <div className="MapInfoPanel-Row-Value" title={title} data-tid="map-info-panel-row-value">
              {route ? (
                <Link to={route} className="Grid-link" params={params} query={query}>
                  {rulesetName}
                </Link>
              ) : (
                rulesetName
              )}
            </div>
          </div>
        </td>
      </tr>
    ) : null;
  }

  renderScopes() {
    const {ruleset} = this.state;

    if (ruleset && ruleset.scopes) {
      let scopes = null;

      // Show all, all, all for global rulesets
      if (!ruleset.scopes[0].length) {
        scopes = [
          <Label type="app" text="all" key="app" />,
          <Label type="env" text="all" key="env" />,
          <Label type="loc" text="all" key="loc" />,
        ];
      } else {
        scopes = ruleset.scopes.map(scope =>
          _.sortBy(scope, item => (item.label_group ? item.label_group.key : item.label.key)).map(item => {
            if (item.label_group) {
              return <LabelGroup key={item.label_group.key} type={item.label_group.key} text={item.label_group.name} />;
            }

            return <Label key={item.label.key} type={item.label.key} text={item.label.value} />;
          }),
        );
      }

      return (
        <tr className="MapInfoPanel-Row" data-tid="map-info-panel-row">
          <td>
            <div className="MapFormPanel-Row">
              <div className="MapInfoPanel-Row-Label" data-tid="map-info-panel-row-label">
                {intl('Common.Scopes')}
              </div>
              <div className="MapInfoPanel-Row-Value MapFormPanel-Wrap" data-tid="map-info-panel-row-value">
                {scopes}
              </div>
            </div>
          </td>
        </tr>
      );
    }
  }

  renderService() {
    const {rule} = this.state;

    if (rule) {
      if (!rule.ingress_services.length) {
        return (
          <tr className="MapInfoPanel-Row" data-tid="map-info-panel-row">
            <td>
              <div className="MapFormPanel-Row">
                <div className="MapInfoPanel-Row-Label" data-tid="map-info-panel-row-label">
                  {intl('Common.Services')}
                </div>
                <div className="MapInfoPanel-Row-Value" data-tid="map-info-panel-row-value">
                  {intl('Rulesets.Rules.DerivedFromProviderVirtualServices')}
                </div>
              </div>
            </td>
          </tr>
        );
      }

      const services = rule.ingress_services.map(service => {
        let content;

        if (service.href) {
          content = (
            <Link
              to="services.item"
              className="Grid-link"
              params={{id: service.href.split('/').pop(), pversion: 'draft'}}
            >
              {(ServiceStore.getSpecified(service.href) || service).name}
            </Link>
          );
        } else if (service.port) {
          content = `${service.port}${service.to_port ? ` - ${service.to_port}` : ''} ${ServiceUtils.lookupProtocol(
            service.proto,
          )}`;
        }

        return (
          <div className="MapInfoPanel-Row-Value-Service" key={service.href || service.port}>
            {content}
          </div>
        );
      });

      return (
        <tr className="MapInfoPanel-Row" data-tid="map-info-panel-row">
          <td>
            <div className="MapFormPanel-Row">
              <div className="MapInfoPanel-Row-Label" data-tid="map-info-panel-row-label">
                {intl('Common.Service')}
              </div>
              <div
                className="MapInfoPanel-Row-Value MapInfoPanel-Row-Value-Services"
                data-tid="map-info-panel-row-value"
              >
                {services}
              </div>
            </div>
          </td>
        </tr>
      );
    }
  }

  renderSecureConnect() {
    return this.state.rule && this.state.rule.sec_connect ? (
      <tr className="MapInfoPanel-Row" data-tid="map-info-panel-row">
        <td>
          <div className="MapFormPanel-Row">
            <div className="MapInfoPanel-Row-Label" data-tid="map-info-panel-row-label">
              {intl('Common.SecureConnect')}
            </div>
            <div className="MapInfoPanel-Row-Value MapInfoPanel-Row-Value--Badge" data-tid="map-info-panel-row-value">
              <Badge type="new">On</Badge>
            </div>
          </div>
        </td>
      </tr>
    ) : null;
  }

  render() {
    const edge = SessionStore.isEdge();
    const {info} = this.props.data;
    const {index, rule, ruleset, error} = this.state;
    const type = rule?.href.includes('enforcement') ? 'deny' : 'allow';
    const numRules = this.props.data.form.rules.length;
    const isReadOnly = this.props.data.form.readOnly;
    const {noDelete, modal} = this.props;
    const caps =
      info && (info.caps || (info.targets && info.targets[0].caps) || (info.sources && info.sources[0].caps));
    const trafficTargetRulesetsIsWritable = caps?.rulesets.includes('write') || SessionStore.isGlobalEditEnabled();
    let action;
    const spinner = !rule || this.props.data.form.rules[index] !== rule.href;

    if (error === 'permission') {
      return (
        <div className={modal ? 'FormModal' : 'FormPanel'}>
          <div className="FormPanel-Error">
            <div className="FormPanel-Message">{intl('Map.NoPermission')}</div>
            <div className="FormPanel-Button-Bar">
              <Button
                text={modal ? intl('Common.Done') : intl('Common.OK')}
                icon="confirm"
                onClick={this.handleClose}
                tid="close"
              />
            </div>
          </div>
        </div>
      );
    }

    if (spinner) {
      return (
        <div className="Explorer-Loading">
          <Spinner size="xxlarge" />
        </div>
      );
    }

    if (edge) {
      action = (
        <tr className="MapInfoPanel-Row" data-tid="map-info-panel-row">
          <td>
            <div className="MapFormPanel-Row">
              <div className="MapInfoPanel-Row-Label" data-tid="map-info-panel-row-label">
                {intl('Common.Action')}
              </div>
              <div className="MapInfoPanel-Row-Value MapInfoPanel-Row-Value--Badge" data-tid="map-info-panel-row-value">
                {type === 'deny' ? (
                  <div>
                    <Icon name="deny" styleClass="Warning" size="medium" />
                    <span className="ActionTitle">{intl('Common.Deny')}</span>
                  </div>
                ) : (
                  <div>
                    <Icon name="success" styleClass="Success" size="medium" />
                    <span className="ActionTitle">{intl('Common.Allow')}</span>
                  </div>
                )}
              </div>
            </div>
          </td>
        </tr>
      );
    }

    return (
      <div className={modal ? 'FormModal' : 'FormPanel'}>
        {modal ? null : (
          <div className="FormPanel-Title FormPanel-Draft" onClick={this.handleClose} data-tid="comp-icon-close">
            <Icon name="caret-left" size="xlarge" />
            {intl('Rule.View')}
          </div>
        )}
        {rule ? (
          <table className="MapInfoPanel MapInfoPanel-NoEdit">
            <tbody>
              {this.renderRuleset(edge)}
              {!edge && this.renderScopes()}
              {this.renderEndpoint('consumers', edge)}
              {!edge && this.renderSecureConnect()}
              {this.renderService()}
              {this.renderEndpoint('providers')}
              {rule && action}
            </tbody>
          </table>
        ) : (
          <Spinner />
        )}

        {(numRules > 1 || !isReadOnly || trafficTargetRulesetsIsWritable) && (ruleset || type === 'deny') ? (
          <div className={numRules > 1 || modal ? 'FormPanel-Buttons-Bar' : 'FormPanel-Button-Bar'}>
            {!noDelete && numRules && this.state.rule && (!isReadOnly || trafficTargetRulesetsIsWritable) ? (
              <Button
                text={intl('Common.Delete')}
                type="secondary"
                onClick={this.handleDeleteRule}
                tid="view-rule-delete"
              />
            ) : (
              <div />
            )}
            <span className="FormPanel-Right-Buttons">
              {numRules > 1 && (
                <span className="FormPanel-Buttons">
                  <Button
                    text={intl('Common.Prev')}
                    disabled={index === 0}
                    onClick={this.handlePrevRule}
                    tid="view-rule-previous"
                  />
                  <Button
                    text={intl('Common.Next')}
                    disabled={this.state.index === numRules - 1}
                    onClick={this.handleNextRule}
                    tid="view-rule-next"
                  />
                </span>
              )}
              {modal ? (
                <span className="FormPanel-Buttons">
                  <Button
                    text={modal ? intl('Common.Done') : intl('Common.OK')}
                    icon="confirm"
                    onClick={this.handleClose}
                    tid="close"
                  />
                </span>
              ) : null}
            </span>
          </div>
        ) : null}
      </div>
    );
  }
}
