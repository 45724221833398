/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import cx from 'classnames';
import Icon from './Icon.jsx';
import LabelUtils from '../utils/LabelUtils.js';
import {findDOMNode} from 'react-dom';
import React, {PropTypes} from 'react';

const API_MAX = 25;
const API_NUM_CAP = 2500;
const DEBOUNCE_WAIT = 175;
const DEFAULT_SELECTED_MAX_ITEMS = 5;
const NORMAL_MAX_ITEMS = 10;

const COMP_STRINGS = () => ({
  loading: intl('ObjectSelector.LoadingValues'),
  showAll: intl('ObjectSelector.ShowAllCategories'),
  hintText: intl('ObjectSelector.TypeToShowMore') + ' ',
  results: ' ' + intl('ObjectSelector.MatchingResults'),
  resultsSingle: ' ' + intl('ObjectSelector.MatchingResult'),
  resultsDisabled: intl('ObjectSelector.MatchingResultsDisabled'),
});
const COMP_STRINGS_VALUES = _.values(COMP_STRINGS());

// Escape Regex http://stackoverflow.com/questions/3115150/#answer-9310752
const ESCAPE = str => str.replace(/[\s#$()*+,.?[\\\]^{|}-]/g, '\\$&');
const collator = new Intl.Collator(intl.lang, {sensitivity: 'base'});

export default React.createClass({
  propTypes: {
    addItem: PropTypes.func.isRequired,
    dropdownValues: PropTypes.object.isRequired,
    facetMap: PropTypes.object.isRequired,
    getFacetValues: PropTypes.func.isRequired,
    initialValues: PropTypes.array.isRequired,
    items: PropTypes.object.isRequired,
    placeholder: PropTypes.string.isRequired,
    removeItem: PropTypes.func.isRequired,
    returnValue: PropTypes.func.isRequired,
    returnTooltip: PropTypes.func,
    allowCreateItems: PropTypes.array,
    allowCreateHint: PropTypes.object,
    allowOne: PropTypes.bool,
    autoLoadCustom: PropTypes.bool,
    autoFocus: PropTypes.bool,
    alwaysOpen: PropTypes.bool,
    customCaretIcon: PropTypes.element,
    customClassItems: PropTypes.array,
    customListItem: PropTypes.func,
    customValuesComp: PropTypes.objectOf(PropTypes.element),
    customValuesKeys: PropTypes.array,
    customPlaceholders: PropTypes.object,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    footerValues: PropTypes.array,
    getCustomClass: PropTypes.func,
    hasTitle: PropTypes.array,
    hasWildcard: PropTypes.array,
    hideItems: PropTypes.array,
    hideItemsList: PropTypes.bool,
    // Disallow specific item to not be deleted from the Selector input bar
    disableSpecificItems: PropTypes.arrayOf(
      PropTypes.shape({href: PropTypes.string, key: PropTypes.string, value: PropTypes.string}),
    ),
    maxResults: PropTypes.number,
    multiItems: PropTypes.array,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onClose: PropTypes.func,
    onCreate: PropTypes.func,
    onFocus: PropTypes.func,
    onMouseOver: PropTypes.func,
    partialItems: PropTypes.array,
    partialOnZeroMatches: PropTypes.bool,
    removeMulti: PropTypes.func,
    defaultSelected: PropTypes.string,
    showAllSingleValues: PropTypes.bool,
    showCountOnFilter: PropTypes.bool,
    showFacetItems: PropTypes.array,
    showSingleMatches: PropTypes.bool,
    showInput: PropTypes.bool,
    showSingleValuesFirst: PropTypes.bool,
    singleSelect: PropTypes.string,
    singleValues: PropTypes.object,
    singleValuesTruncated: PropTypes.bool,
    statics: PropTypes.object,
    staticsKeys: PropTypes.array,
    tabIndex: PropTypes.string,
    tid: PropTypes.string,
    // to show the name only: e.g. '21 TCP' and NOT 'Port Protocols': '21 TCP'
    showNameOnly: PropTypes.array,
    forceNotEmptyPlaceholder: PropTypes.string,
    enableScroll: PropTypes.bool,
    switchToActiveForThese: PropTypes.array,
    hintText: PropTypes.object, // Replace Type to show more hint text with something custom
    hintTextOptionType: PropTypes.array, //Supports which facet's hint text can be altered.
    loadingHints: PropTypes.array, // Supports replacing loading message
  },

  getInitialState() {
    const stateObj = {
      active: 'initial',
      activeIdx: -1,
      inputValue: '',
      showDropdown: this.props.alwaysOpen || false,
      showAll: false,
      lastItem: this.props.defaultSelected, //this state value helps maintain current selected facet value at all items, if none selected it defaults to defaultSelected
    };

    if (this.props.enableScroll) {
      stateObj.showUpCaret = false;
      stateObj.showDownCaret = true;
    }

    return stateObj;
  },

  componentDidMount() {
    if (this.props.autoFocus) {
      // The focusInput is deferred here since we want it to fire after component gets mounted AND the initial state has been set
      // the order here is not guaranteed, but this seems to work
      _.defer(this.focusInput);
    }

    // Debounce for API typeahead and for onChange as well
    this.getFacetValues = _.debounce(value => {
      if (this.props.defaultSelected && this.state.active === 'initial') {
        this.props.getFacetValues(this.props.facetMap[this.props.defaultSelected], value);
      } else {
        this.props.getFacetValues(this.props.facetMap[this.props.singleSelect || this.state.active], value);
      }
    }, DEBOUNCE_WAIT);

    this.onChange = _.noop;

    if (this.props.onChange) {
      this.onChange = _.debounce(() => {
        this.props.onChange();
      }, DEBOUNCE_WAIT);
    }

    if (this.props.maxResults) {
      this.maxResults = this.props.maxResults;
    } else if (this.props.defaultSelected) {
      this.maxResults = DEFAULT_SELECTED_MAX_ITEMS;
    } else {
      this.maxResults = NORMAL_MAX_ITEMS;
    }

    document.addEventListener('click', this.handleCompClick);
  },

  componentWillUnmount() {
    document.removeEventListener('click', this.handleCompClick);

    if (this.ddValuesOptions) {
      this.ddValuesOptions.removeEventListener('scroll', this.showCarets);
    }
  },

  showCarets() {
    // Show "up" or "down" caret for the scrolling region
    if (!this.ddValuesOptions) {
      return;
    }

    const boundingClientRect = this.ddValuesOptions.getBoundingClientRect();
    const childNodes = this.ddValuesOptions.childNodes;
    let hiddenAbove = false;
    let hiddenBelow = false;

    Array.from(childNodes).forEach(node => {
      const nodeRect = node.getBoundingClientRect();

      if (nodeRect.bottom < boundingClientRect.top) {
        hiddenAbove = true;
      }

      if (nodeRect.top > boundingClientRect.bottom) {
        hiddenBelow = true;
      }
    });

    const stateObj = {};
    let stateChange;

    if (this.state.showUpCaret !== hiddenAbove) {
      stateObj.showUpCaret = hiddenAbove;
      stateChange = true;
    }

    if (this.state.showDownCaret !== hiddenBelow) {
      stateObj.showDownCaret = hiddenBelow;
      stateChange = true;
    }

    if (stateChange) {
      this.setState(stateObj);
    }
  },

  async scrollToHighlightedNode(prevProps, prevState) {
    if (prevState.showUpCaret !== this.state.showUpCaret) {
      return;
    }

    if (prevState.showDownCaret !== this.state.showDownCaret) {
      return;
    }

    const osNode = findDOMNode(this);

    if (!osNode) {
      return;
    }

    const active = osNode.querySelector('.ObjectSelector-dd-values-item--active');

    if (!active) {
      return;
    }

    const boundingClientRect = active.getBoundingClientRect();
    const parentBoundingClientRect = active.parentNode.getBoundingClientRect();
    const hiddenAbove = boundingClientRect.top < parentBoundingClientRect.top - 1;
    const hiddenBelow = boundingClientRect.bottom > parentBoundingClientRect.bottom + 1;

    if (hiddenAbove || hiddenBelow) {
      let offsetTop;

      if (hiddenAbove) {
        let nodeToScrollTo = active;
        let i = 0;

        while (nodeToScrollTo.previousSibling && i < 4) {
          nodeToScrollTo = nodeToScrollTo.previousSibling;
          i++;
        }

        offsetTop = nodeToScrollTo.offsetTop;
      } else {
        offsetTop = (active.previousSibling ? active.previousSibling : active).offsetTop;
      }

      for (let i = active.parentNode.scrollTop; hiddenAbove ? i > offsetTop : i < offsetTop; hiddenAbove ? i-- : i++) {
        await new Promise(resolve => setTimeout(resolve, 0));
        active.parentNode.scrollTop = i;
      }

      this.showCarets();
      setTimeout(this.showCarets, 75);
      setTimeout(this.showCarets, 150);
      setTimeout(this.showCarets, 300);
    }
  },

  componentDidUpdate(prevProps, prevState) {
    if (this.props.enableScroll && !this.state.disableScroll) {
      this.scrollToHighlightedNode(prevProps, prevState);
    }
  },

  handleCreate() {
    this.props.onCreate(this.state.inputValue, this.state.active, () => {
      // This callback function is passed to parent component and is called once
      // everything is done
      this.setState({
        active: 'initial',
        activeIdx: -1,
        inputValue: '',
        showDropdown: true,
      });
    });
  },

  cleanItems(active = 'initial') {
    const itemsKeys = Object.keys(this.props.items);

    itemsKeys.forEach(item => {
      if (!this.props.items[item]) {
        this.deleteItem(item);
      }
    });

    if (this.props.defaultSelected) {
      active = this.props.items[this.state.active] && this.state.active;
    }

    if (this.props.onClose) {
      this.props.onClose();
    }

    this.setState({
      activeIdx: -1,
      showDropdown: this.props.alwaysOpen || false,
      inputValue: '',
      active,
    });

    if (this.props.onClose) {
      this.props.onClose();
    }
  },

  deleteItem(item) {
    if (this.props.disabled) {
      return;
    }

    const value = this.props.items[item];

    /** Don't allow delete of 'All Services, All Workloads' */
    if (
      [intl('Common.AllServices'), intl('Workloads.All')].includes(item) &&
      this.props.disableSpecificItems &&
      value
    ) {
      const allServices = value[0] ?? {};
      const checkAllServices = this.props.disableSpecificItems.some(ele => {
        if (allServices?.allServices && ele?.allServices) {
          return allServices.allServices === ele?.allServices;
        }

        if (allServices?.allWorkloads && ele?.allWorkloads) {
          return allServices.allWorkloads === ele.allWorkloads;
        }

        return false;
      });

      if (checkAllServices) {
        // Return empty to disallow delete
        return;
      }
    }

    const stateObj = {
      active: 'initial',
      activeIdx: -1,
    };

    this.props.removeItem(item);

    if (this.props.autoLoadCustom && this.refs.itemInput) {
      this.setState(stateObj);
      this.refs.itemInput.blur();
    } else {
      this.setState(stateObj, this.focusInput);
    }

    if (value) {
      this.onChange();
    }
  },

  deleteMulti(item, singleItem, options) {
    if (this.props.disabled) {
      return;
    }

    // singleItem is optional (like for backspace) and is passed when the user clicks on the delete icon
    const singleSelect = this.props.singleSelect;
    const stateObj = {activeIdx: -1};

    if (options.defaultSelected) {
      this.props.removeMulti(item, singleItem);
      this.onChange();
    } else if (this.state.active === 'initial' || singleItem || singleSelect || this.state.active === item) {
      this.props.removeMulti(item, singleItem);
      stateObj.active = 'initial';
      this.onChange();
    }

    this.setState(stateObj, this.focusInput);
  },

  filterValues(filterOn = [], additional, options) {
    // Filters the array on the user typed input and already selected items (additional)
    const inputValue = this.state.inputValue;
    const isInitial = options.active === 'initial';
    const singleSelect = this.props.singleSelect;
    const isSingleMulti = singleSelect && this.props.multiItems && this.props.multiItems.includes(singleSelect);
    const isStatic = this.props.statics && this.props.staticsKeys.includes(options.active);
    // Single and SingleMulti select always have 'initial' active state, so they come here
    // however, they are not filtered on the value in the input element, but items already selected
    const uiFilterable = isInitial && !isSingleMulti && !singleSelect;
    let regex;

    // regex object is not needed if not initial
    if (uiFilterable || isStatic) {
      regex = new RegExp(ESCAPE(inputValue), 'i');
    }

    if (additional) {
      // If initial facet, then also filter on the input contents
      if (uiFilterable) {
        return filterOn.filter(value => {
          // Do not remove multi items, even though they exist
          if (this.props.multiItems && this.props.multiItems.includes(value)) {
            return value.match(regex);
          }

          return !additional.includes(value) && value.match(regex);
        });
      }

      // If not initial, just return the ones which don't already exist
      // SingleSelect has just one item selected, that is why additional is an obj and not an array of objects
      return filterOn.filter(value => {
        if (singleSelect && !isSingleMulti) {
          // If href is present, use that to filter the value, otherwise fallback to returnValue function
          if (value.href && additional.href) {
            return value.href !== additional.href;
          }

          return this.props.returnValue(value) !== this.props.returnValue(additional);
        }

        return !additional.includes(value.href);
      });
    }

    // Override filter to use returnValue function instead of string
    if (options.isObj) {
      return filterOn.filter(value => this.props.returnValue(value, options.active).match(regex));
    }

    return filterOn.filter(value => value.match(regex));
  },

  focusInput() {
    if (this.refs.itemInput) {
      this.refs.itemInput.focus();
    }

    if (this.props.onFocus) {
      this.props.onFocus();
    }

    if (this.props.enableScroll && !this.state.disableScroll) {
      this.showCarets();
    }
  },

  generateDdList(dropdownValues, numMatches, options) {
    const activeIdx = this.state.activeIdx;
    let dropdownList = [];

    if (this.props.enableScroll && options.isInitial) {
      dropdownValues.sort();
    }

    dropdownValues.forEach((value, idx) => {
      let text;
      let props;

      if (this.props.footerValues && this.props.footerValues.includes(value)) {
        text = value.text;
        props = {
          'key': text + idx,
          'className': this.props.itemNotClickable
            ? `ObjectSelector-dd-values-item--not-clickable ObjectSelector-dd-values-item--footer ${
                value.className || ''
              }`
            : `ObjectSelector-dd-values-item ObjectSelector-dd-values-item--footer ${value.className || ''}`,
          'onClick': value.onClick || _.noop,
          'data-tid': 'comp-select-results-item',
        };
      } else if (value.loading) {
        text = value.loading;
        props = {
          'key': text + idx,
          'className': 'ObjectSelector-dd-values-item--result',
          'data-tid': 'comp-select-results-loading-values',
        };
      } else if (value.partial) {
        text = this.state.inputValue;

        props = {
          'key': `dropdownValuePartial${text}${idx}`,
          'className': `ObjectSelector-dd-values-item--partial${activeIdx === 0 ? '--active' : ''}`,
          'onClick':
            dropdownValues.length === 0 && !options.singleSelect
              ? _.noop
              : _.partial(this.handleValueClick, this.state.inputValue, options),
          'data-tid': 'comp-select-results-item',
        };
      } else {
        if (options.activeIdx) {
          idx += options.activeIdx;
        }

        if (value.create) {
          text = [
            <span
              key={'dropdownValueCreate' + this.state.inputValue}
              className="ObjectSelector-dd-values-item--create-item"
            >
              {this.state.inputValue}
            </span>,
            <span key={`ObjectSelector-dd-values-item--create-hint${this.state.inputValue}`}>
              {' ' + this.props.allowCreateHint[options.active]}
            </span>,
          ];
          props = {
            'key': this.state.inputValue + idx,
            'className': 'ObjectSelector-dd-values-item--create',
            'onClick': this.handleCreate,
            'data-tid': 'comp-select-results-item',
          };
        } else {
          text = this.props.returnValue(value, options.active) || value;
          props = {
            'key': value.href || text || idx,
            'className': this.props.itemNotClickable
              ? 'ObjectSelector-dd-values-item--not-clickable'
              : 'ObjectSelector-dd-values-item',
            'onClick': _.partial(this.handleValueClick, value, options),
            'data-tid': 'comp-select-results-item',
            //TODO: Remove this code. Scar Tissue. This property needs to be removed later. Used specifically in Find Group Panel.
            'name':
              (this.props.singleValues && this.props.singleValues[text] && this.props.singleValues[text].name) || '',
          };
        }

        props.icon = value.icon || LabelUtils.displayIconLabels[value.key];
        props.type = ['role', 'app', 'env', 'loc'].includes(value.key) ? value.key : undefined;

        if (value.href) {
          props.group = options.active;
        }

        // Make sure the text is a string
        if (typeof text !== 'string' && !value.create) {
          text = String(text);
        }

        // Add a className modifier to the defaultSelected OS
        if (options.defaultSelected && !options.isInitial && !value.create) {
          props.className += '--result';
        }

        // Add modifier if the current item is active (for CSS emphasis)
        // But not for the initial items list in defaultSelected OS
        if (activeIdx === idx) {
          props.className += '--active';
        }

        // Some items (like Description) have the text clipped and need title
        if (this.props.hasTitle && this.props.hasTitle.includes(options.active)) {
          props.title = text;
        }

        if (this.props.onMouseOver) {
          props.onMouseOver = _.partial(this.props.onMouseOver, {
            active: (options.isInitial && 'initial') || options.active,
            value,
          });
        }

        if (props && props.key) {
          // Remove special caracters from key, otherwise react can fail
          props.key = props.key.replace(/\s/gim, '');
        }
      }

      // Highlight the typed text in the search results
      if (this.state.inputValue && !COMP_STRINGS_VALUES.includes(text)) {
        text = this.highlightText(text);
      }

      dropdownList.push({props, text});
    });

    if (this.state.showDropdown) {
      if (!options.defaultSelected || this.state.inputValue) {
        dropdownList = dropdownList.map(item => {
          if (
            (item.text.includes(COMP_STRINGS().results) || item.text.includes(COMP_STRINGS().resultsSingle)) &&
            this.props.showSingleMatches
          ) {
            item.props.className = 'ObjectSelector-dd-values-item--num-matches';
          }

          return item;
        });

        if (!options.isInitial || options.singleSelect || (options.showCountOnFilter && this.state.inputValue)) {
          dropdownList.push({
            props: {
              'key': `dropdownValueNumMatches-${dropdownList.length}`,
              'className': `ObjectSelector-dd-values-item--num-matches${!dropdownList.length ? '--zero' : ''}`,
              'data-tid': 'comp-select-results-item',
            },
            text: options.isWildcard
              ? COMP_STRINGS().resultsDisabled
              : numMatches +
                (numMatches >= API_NUM_CAP ? '+' : '') +
                (numMatches > 1 ? COMP_STRINGS().results : COMP_STRINGS().resultsSingle),
          });
        }
      }
    }

    return dropdownList;
  },

  handleBlur() {
    this.setState({lastItem: this.props.defaultSelected});
  },

  generateDdValues(options) {
    const itemKeys = Object.keys(this.props.items);
    const isStatic = this.props.statics && this.props.staticsKeys.includes(options.active);
    const singleValueKeys = this.props.singleValues && Object.keys(this.props.singleValues);
    const isSingle = singleValueKeys && singleValueKeys.includes(options.active);
    const isMulti = this.props.multiItems && this.props.multiItems.includes(options.active);
    const isSingleMulti =
      options.singleSelect && this.props.multiItems && this.props.multiItems.includes(options.singleSelect);
    const ddKeys = Object.keys(this.props.dropdownValues);
    const valuesLoadedKey = this.props.facetMap[options.singleSelect || options.active] + '-' + this.state.inputValue;

    const valuesLoaded = ddKeys.includes(valuesLoadedKey);

    let dropdownValues = [];
    let numMatches = 0;
    let filterOn;

    if (
      (!options.isInitial || options.singleSelect) &&
      !valuesLoaded &&
      !isStatic &&
      !isSingle &&
      this.state.showDropdown
    ) {
      if (!options.isWildcard) {
        dropdownValues = [
          {
            loading: (options.loadingHints && options.loadingHints[options.active]) || COMP_STRINGS().loading,
          },
        ];
      }
    } else if (this.state.showDropdown) {
      if (options.isInitial && !isSingleMulti) {
        // Join the singleValues (if any) to the initial list
        if (this.props.singleValues) {
          const filteredInitValues = this.filterValues(this.props.initialValues, itemKeys, options);
          let filteredSingleValues = this.filterValues(singleValueKeys, itemKeys, options);

          if (this.props.showCountOnFilter || this.props.showSingleMatches) {
            numMatches = filteredSingleValues.length;
          }

          // If filtered singleValues are more than max results, slice them
          if (
            filteredSingleValues.length &&
            filteredSingleValues.length > this.maxResults &&
            !this.props.showAllSingleValues
          ) {
            filteredSingleValues = filteredSingleValues.slice(0, this.maxResults);
          }

          if (this.props.showSingleMatches) {
            filteredSingleValues.push(
              `${numMatches} ${numMatches === 1 ? COMP_STRINGS().resultsSingle : COMP_STRINGS().results}`,
            );
          }

          if (this.props.showSingleValuesFirst) {
            dropdownValues = filteredSingleValues.concat(filteredInitValues);
          } else {
            dropdownValues = filteredInitValues.concat(filteredSingleValues);
          }
        } else if (options.singleSelect) {
          // For singleSelect, the default values are initial values
          const singleSelectRes =
            this.props.dropdownValues[this.props.facetMap[options.singleSelect] + '-' + this.state.inputValue];
          const singleSelectVal = this.props.items[options.singleSelect];

          if (singleSelectRes) {
            dropdownValues = singleSelectRes.matches;
            numMatches = singleSelectRes.num_matches;
          }

          if (singleSelectVal) {
            dropdownValues = this.filterValues(dropdownValues, singleSelectVal, options);
            numMatches = singleSelectRes ? dropdownValues.length : 0;
          }
        } else {
          dropdownValues = this.filterValues(this.props.initialValues, itemKeys, options);
        }
      } else if (isStatic) {
        const isObj = this.props.statics[options.active].some(item => typeof item === 'object');

        if (isObj) {
          dropdownValues = this.filterValues(this.props.statics[options.active], null, {...options, isObj});
        } else {
          dropdownValues = this.filterValues(this.props.statics[options.active], null, options);
        }

        numMatches = dropdownValues.length;
      } else if (valuesLoaded) {
        const data =
          this.props.dropdownValues[
            this.props.facetMap[options.singleSelect || options.active] + '-' + this.state.inputValue
          ];
        const activeItems = this.props.items[options.singleSelect || options.active];

        if ((isMulti || isSingleMulti) && activeItems && !this.props.hideLabel) {
          filterOn = data.matches.slice();

          if (filterOn.length > this.maxResults + activeItems.length) {
            filterOn = filterOn.slice(0, this.maxResults + activeItems.length);
          }

          dropdownValues = this.filterValues(filterOn, _.map(activeItems, 'href'), options);

          numMatches =
            data.num_matches - (this.state.inputValue ? filterOn.length - dropdownValues.length : activeItems.length);
        } else {
          dropdownValues = data.matches;
          numMatches = data.num_matches;

          // Hide a label type if the prop is passed (like the scope selector filter doesn't show role labels)
          if (
            this.props.hideLabel &&
            !options.isInitial &&
            (options.active === intl('Common.Labels') || options.active === intl('Labels.Groups'))
          ) {
            dropdownValues = dropdownValues.filter(value => value.key !== this.props.hideLabel);

            // Remove already selected items
            if (activeItems) {
              dropdownValues = this.filterValues(dropdownValues, _.map(activeItems, 'href'), options);
            }
          }
        }
      }

      if (this.props.footerValues && (options.defaultSelected ? options.isInitial : true)) {
        dropdownValues = dropdownValues.concat(this.props.footerValues);
      }
    }

    // Sometimes when things (like workloads) are removed but are already applied in items (like for rule grid),
    // the number goes below zero which doesn't make sense
    if (numMatches < 0) {
      numMatches = 0;
    }

    // Only show max (10) items, also dropdownValues still refers to data.matches, so no splice but slice
    // Also the Initial list can have more than ten items (like in the RuleWriter)
    if ((!options.isInitial || options.singleSelect) && dropdownValues.length > this.maxResults) {
      dropdownValues = dropdownValues.slice(0, this.maxResults);
    }

    const isLoading = dropdownValues.length ? dropdownValues[0].loading : false;

    if (
      !isLoading &&
      this.props.allowCreateItems &&
      this.props.allowCreateItems.includes(options.active) &&
      this.state.inputValue
    ) {
      const valueNotExists =
        dropdownValues.length && dropdownValues.every(value => this.props.returnValue(value) !== this.state.inputValue);
      const isAllowCreate =
        (options.defaultSelected ? !options.isInitial : true) && (!dropdownValues.length || valueNotExists);

      if (isAllowCreate) {
        dropdownValues = dropdownValues.concat([{create: true}]);
      }
    }

    if (this.isPartial(dropdownValues) && ((dropdownValues[0] && !dropdownValues[0].partial) || options.singleSelect)) {
      // There can be only one partial value
      const addPartial = this.props.partialOnZeroMatches ? !dropdownValues.length : true;

      if (addPartial && (this.props.defaultSelected ? !options.isInitial : true)) {
        // There can be only one partial value
        dropdownValues.unshift({
          partial: this.state.inputValue,
        });
      }
    } else if (options.isWildcard) {
      // Treat wildcard searches as partial searches
      dropdownValues.unshift({
        partial: this.state.inputValue,
      });
    }

    dropdownValues.sort((a, b) => collator.compare(a.value, b.value));

    return {dropdownValues, numMatches};
  },

  generateLabelIconElem(icon, item) {
    return (
      <div className="ObjectSelector-iconGroup">
        {[intl('Labels.Group'), intl('Labels.Groups')].includes(item) && (
          <div className={`ObjectSelector-icon-background Label-icon--${icon}`} />
        )}
        <div className={`Label-icon Label-icon--${icon}`}>
          <Icon name={icon} />
        </div>
      </div>
    );
  },

  generateItemsList(objectSelectorInputBlock, options) {
    const itemKeys = Object.keys(this.props.items);
    const itemsEmpty = itemKeys.length === 0;

    // This loop generates the items present/applied for the Object Selector
    return !itemsEmpty
      ? itemKeys.map((item, idx) => {
          const isMulti = this.props.multiItems && this.props.multiItems.includes(item);
          const isSingle =
            (this.props.singleValues && Object.keys(this.props.singleValues).includes(item)) ||
            this.props.singleValuesTruncated;
          const isStaticKey = this.props.staticsKeys && this.props.staticsKeys.includes(item);
          let isActive = this.state.showDropdown && options.active === item;
          let className;

          if (isMulti) {
            const multiReturn = this.props.items[item]
              ? this.props.items[item].map((singleItem, idx) => {
                  const icon = LabelUtils.iconNames[singleItem.key || item];
                  let multiClassName = 'ObjectSelector-item--applied';
                  const text = this.props.returnValue(singleItem, item, true);

                  if (this.props.customClassItems && this.props.customClassItems.includes(item)) {
                    multiClassName += ' ' + this.props.getCustomClass(singleItem, item);
                  }

                  // to show the name only: e.g. '21 TCP' and NOT 'Port Protocols': '21 TCP'
                  const isShowNameOnly =
                    Array.isArray(this.props.showNameOnly) && this.props.showNameOnly.includes(item);
                  const returnedItem = (
                    <div
                      key={`ObjectSelector-item-multi${idx}`}
                      className={multiClassName}
                      data-tid="comp-combobox-selected"
                    >
                      {icon ? this.generateLabelIconElem(icon, item) : null}
                      {options.singleSelect ||
                      isShowNameOnly ||
                      (options.defaultSelected && item === options.defaultSelected) ||
                      (this.props.showFacetItems && !this.props.showFacetItems.includes(item)) ? (
                        ''
                      ) : (
                        <span className="ObjectSelector-item-label" data-tid={'comp-combobox-selected-label-' + item}>
                          {LabelUtils.singularizeLabel(item)}:
                        </span>
                      )}
                      {[
                        <span
                          className="ObjectSelector-item-value"
                          key="item-value-multi"
                          data-tid="comp-combobox-selected-value"
                        >
                          {text}
                        </span>,
                        <Icon
                          name="close"
                          size="small"
                          styleClass="ObjectSelector-item-value"
                          key="item-delete-multi"
                          onClick={_.partial(this.deleteMulti, item, singleItem, options)}
                          data-tid="comp-icon comp-icon-remove comp-icon-close"
                        />,
                      ]}
                    </div>
                  );

                  return this.props.returnTooltip
                    ? this.props.returnTooltip(singleItem, item, text, returnedItem)
                    : returnedItem;
                })
              : [];

            if (options.defaultSelected) {
              isActive = options.active === item;
            }

            if (isActive) {
              multiReturn.push(
                <div
                  key="ObjectSelector-item-multi--active"
                  className={`ObjectSelector-item${!this.state.showDropdown ? '--verySmall' : ''}`}
                >
                  {options.singleSelect || options.defaultSelected ? (
                    ''
                  ) : (
                    <span className="ObjectSelector-item-label" data-tid={'comp-combobox-selected-label-' + item}>
                      {this.props.mapLabel ? this.props.mapLabel[item] : item}:
                    </span>
                  )}
                  {objectSelectorInputBlock}
                </div>,
              );
            }

            return multiReturn;
          }

          className = `ObjectSelector-item${isActive ? '' : '--applied'}`;

          // isActive works differently for non multi select default selected lists
          if (options.defaultSelected) {
            isActive = this.state.showDropdown && !this.props.items[item];

            if (
              !isSingle &&
              this.props.multiItems &&
              this.props.multiItems.includes(item) &&
              !this.props.items[options.active]
            ) {
              className += '--initial';
            }
          }

          // For defaultSelected the item would be 'initial' since the category is pre selected
          if (this.props.customClassItems && this.props.customClassItems.includes(item)) {
            className += ' ' + this.props.getCustomClass(this.props.items[item], item);
          }

          const icon = LabelUtils.iconNames[this.props.items[item] ? this.props.items[item].key : item];

          // TODO: Simplify the show label logic
          return (
            <div
              key={`ObjectSelector-item${idx}`}
              className={className}
              title={this.props.returnValue(this.props.items[item], item)}
              data-tid={isActive ? '' : 'comp-combobox-selected'}
            >
              {icon ? this.generateLabelIconElem(icon, item) : null}
              {options.singleSelect ||
              (options.defaultSelected &&
                (this.props.showFacetItems && this.props.showFacetItems.includes(item) ? false : !isSingle)) ||
              (options.defaultSelected &&
                this.props.showFacetItems &&
                !this.props.showFacetItems.includes(item) &&
                !isSingle &&
                !isStaticKey) ? (
                ''
              ) : (
                <span
                  className="ObjectSelector-item-label"
                  data-tid={
                    options.defaultSelected ? 'comp-combobox-selected-value' : 'comp-combobox-selected-label-' + item
                  }
                >
                  {item + (isSingle ? '' : ':')}
                </span>
              )}
              {isActive
                ? objectSelectorInputBlock
                : [
                    isSingle ? (
                      ''
                    ) : (
                      <span
                        className="ObjectSelector-item-value"
                        key="item-value"
                        data-tid="comp-combobox-selected-value"
                      >
                        {this.props.returnValue(this.props.items[item], item)}
                      </span>
                    ),
                    <Icon
                      name="close"
                      size="small"
                      styleClass="ObjectSelector-item-value"
                      key="item-delete"
                      onClick={_.partial(this.deleteItem, item, options)}
                      data-tid="comp-icon comp-icon-remove comp-icon-close"
                    />,
                  ]}
            </div>
          );
        })
      : [];
  },

  generateOsInputElem(dropdownValues, options) {
    const itemKeys = Object.keys(this.props.items);
    const {handleInputChange, handleInputClick, handleBlur, focusInput} = this;
    const inputProps = {
      'type': 'text',
      'className': 'ObjectSelector-input-elem',
      'ref': 'itemInput',
      'value': this.state.inputValue,
      'onChange': _.partial(handleInputChange, options),
      'onClick': handleInputClick,
      'onBlur': handleBlur,
      'onFocus': focusInput,
      'placeholder': !itemKeys.length || !this.state.showDropdown ? this.props.placeholder : '',
      'data-tid': `comp-combobox-input${this.props.tid ? ` comp-combobox-input-${this.props.tid}` : ''}`,
    };
    const notEmpty = itemKeys.length ? itemKeys.every(item => this.props.items[item]) : false;
    let ulClassName = 'ObjectSelector-dd-values';

    if (this.props.onBlur) {
      inputProps.onBlur = _.partial(this.props.onBlur, this.state.showDropdown);
    }

    if (this.props.disabled) {
      inputProps.disabled = true;
    }

    // If only one value is allowed to be selected, do not show the dropdown list after one value is selected
    // Still show the input type though (but smaller) so that the user can click on 'Backspace'
    // and do not change text in input type if user types something
    if (notEmpty && this.props.allowOne && (options.defaultSelected || options.isInitial)) {
      ulClassName = 'ObjectSelector-dd-values-hide';
      inputProps.className = 'ObjectSelector-input-elem-small';
      // Since a value is already selected, we don't want to update the inputValue, so an empty onChange
      // An onChange is a required function here since if the inputValue is set by state, it should be
      // accompanied by an onChange
      inputProps.onChange = _.noop;
      // Add the inputKeyDown anyway since we want the user to be able to use 'Backspace' to delete something
      inputProps.onKeyDown = _.partial(this.handleInputKeyDown, {...options, noSelect: true, dropdownValues});
    } else {
      inputProps.onKeyDown = _.partial(this.handleInputKeyDown, {...options, noSelect: false, dropdownValues});
    }

    if (this.props.tabIndex) {
      // Rather than having a default tabIndex (which might break some flow), only add it if it is present
      inputProps.tabIndex = this.props.tabIndex;
    }

    if (this.props.disabled) {
      inputProps.tabIndex = -1;
    }

    if (options.defaultSelected && !this.props.allowOne) {
      if (this.state.showDropdown) {
        if (this.props.customPlaceholders && this.props.customPlaceholders[options.active]) {
          inputProps.placeholder = this.props.customPlaceholders[options.active];
        } else {
          inputProps.placeholder = COMP_STRINGS().hintText + options.active;
        }
      } else if (notEmpty) {
        inputProps.className = 'ObjectSelector-input-elem-verySmall';
        inputProps.placeholder = '';

        if (this.props.forceNotEmptyPlaceholder) {
          inputProps.className = 'ObjectSelector-input-elem';
          inputProps.placeholder = this.props.forceNotEmptyPlaceholder;
        }
      }
    }

    return {oSInputElem: <input {...inputProps} />, ulClassName};
  },

  generateSelectedDdList(dropdownValues, numMatches, options) {
    const hintTextExists =
      Array.isArray(this.props.hintTextOptionType) &&
      this.props.hintTextOptionType.length &&
      this.props.hintText.hasOwnProperty(options.active);

    // is an array since used with concat
    const hintItem =
      numMatches <= 5
        ? []
        : [
            {
              props: {
                'className': 'ObjectSelector-dd-values-item--hint',
                'data-tid': 'comp-select-results-hint',
              },
              key:
                hintTextExists && this.props.hintTextOptionType.includes(options.active)
                  ? this.props.hintText[options.active] + options.active
                  : COMP_STRINGS().hintText + options.active,
              text:
                hintTextExists && this.props.hintTextOptionType.includes(options.active)
                  ? this.props.hintText[options.active] + options.active
                  : COMP_STRINGS().hintText + options.active,
            },
          ];
    const altOptions = {...options, isInitial: true, activeIdx: dropdownValues.length};
    const hideCount = dropdownValues.length ? dropdownValues[0].loading || dropdownValues[0].create : true;
    let fullDdValues = dropdownValues;
    let altDropdownValues = this.generateDdValues(altOptions).dropdownValues.filter(value => {
      if (this.props.multiItems && !this.props.multiItems.includes(value) && this.props.items[value]) {
        return;
      }

      if (this.state.inputValue) {
        if (value.text) {
          return value.text.match(new RegExp(ESCAPE(this.state.inputValue), 'ig'));
        }

        if (value.partial) {
          return value.partial.match(new RegExp(ESCAPE(this.state.inputValue), 'ig'));
        }

        return value.match(new RegExp(ESCAPE(this.state.inputValue), 'ig'));
      }

      return value !== options.active;
    });
    let dropdownList = [];
    let text;

    if (this.props.enableScroll && !this.state.disableScroll) {
      dropdownList = [[], []];
    }

    // When hiding a label type (currently only used with defaultSelected),
    // hide the count since it will no longer be correct
    if (!this.props.hideLabel && !hideCount) {
      text = intl(numMatches >= API_NUM_CAP ? 'ObjectSelector.ManyMatchCount' : 'ObjectSelector.MatchCount', {
        count: dropdownValues.length,
        name: options.active,
        total: numMatches,
      });
    } else {
      text = options.active;
    }

    const headerItem = {
      props: {
        'className': 'ObjectSelector-dd-values-item--type',
        'data-tid': 'comp-select-results-header',
      },
      key: text,
      text,
    };

    const dropdownValueWithIcons = dropdownValues.map(item =>
      typeof item === 'string' ? item : {icon: LabelUtils.displayIconLabels[item.key || options.active], ...item},
    );

    if (this.props.enableScroll && !this.state.disableScroll) {
      dropdownList[0].push(headerItem, ...this.generateDdList(dropdownValueWithIcons, numMatches, options));
    } else {
      dropdownList.push(headerItem);
      dropdownList = dropdownList.concat(this.generateDdList(dropdownValueWithIcons, numMatches, options));
    }

    if (this.props.singleValues) {
      Object.keys(this.props.singleValues).forEach(singleValueKey => {
        if (this.props.items[singleValueKey]) {
          altDropdownValues = altDropdownValues.filter(ddValue => ddValue !== singleValueKey);
        }
      });
    }

    // hideItems and enableScroll don't work together
    if (this.props.hideItems && !this.state.showAll) {
      const newAltDdValues = altDropdownValues.filter(value => !this.props.hideItems.includes(value));

      dropdownList = dropdownList.concat(hintItem, this.generateDdList(newAltDdValues, 0, altOptions), [
        {
          props: {
            'className': `ObjectSelector-dd-values-item${
              this.state.activeIdx === dropdownValues.length + newAltDdValues.length ? '--active' : ''
            }`,
            'data-tid': 'comp-select-show-all',
            'onClick': () => this.setState({showAll: true, activeIdx: -1}),
          },
          key: COMP_STRINGS().showAll,
          text: COMP_STRINGS().showAll,
        },
      ]);
      fullDdValues = fullDdValues.concat(newAltDdValues.concat([COMP_STRINGS().showAll]));
    } else {
      if (this.props.enableScroll && !this.state.disableScroll) {
        dropdownList[0].push(...hintItem);
        dropdownList[1].push(...this.generateDdList(altDropdownValues, 0, altOptions));
      } else {
        dropdownList = dropdownList.concat(hintItem, this.generateDdList(altDropdownValues, 0, altOptions));
      }

      fullDdValues = fullDdValues.concat(altDropdownValues);
    }

    return {dropdownList, fullDdValues};
  },

  handleCompClick(evt) {
    if (this.props.disabled) {
      return;
    }

    const tClass = evt.target.className;

    if (tClass.length) {
      if ((tClass.includes('Icon') && !this.state.showDropdown) || tClass.includes('Icon-ObjectSelector-item-value')) {
        return;
      }

      if (
        tClass.includes('ObjectSelector-dd-scroll-caretDown') ||
        tClass.includes('ObjectSelector-dd-scroll-caretUp')
      ) {
        return;
      }

      if (tClass.includes('ObjectSelector-item-title')) {
        return;
      }
    }

    /* Add Special case for ServiceSelect customListitem class declaration "tClass.includes('OSServiceSelectResultItem-Name')"
       to prevent dropdown from inconsistenly closing depending where DOM element is clicked */
    if (
      findDOMNode(this).contains(evt.target) ||
      (tClass.length &&
        (tClass.includes('ObjectSelector-dd-values-item') || tClass.includes('OSServiceSelectResultItem-Name')))
    ) {
      // If clicked anywhere inside the component, focus it
      this.handleInputClick(evt);

      if (evt.target.classList.contains('ObjectSelector-items') || evt.target.classList.contains('ObjectSelector')) {
        this.focusInput();
      }
    } else {
      // If clicked outside, unfocus it, and if there was an item without any value, remove it
      this.cleanItems();
    }
  },

  handleInputChange(options, evt) {
    // this gets called on onFocus in IE 11, so if the input has no value, return
    if (this.props.disabled || (!this.state.showDropdown && !evt.target.value)) {
      return;
    }

    const inputValue = evt.target.value || '';
    const isStatic = this.props.statics && this.props.staticsKeys.includes(this.state.active);
    const ddKeys = Object.keys(this.props.dropdownValues);
    const isInitial = this.state.active === 'initial';
    const facetMapActive = this.props.facetMap[this.state.active];
    const isDefault =
      options.defaultSelected &&
      isInitial &&
      !ddKeys.includes(this.props.facetMap[options.defaultSelected] + '-' + inputValue);

    if (isInitial && inputValue.includes(':')) {
      const regex = new RegExp('^' + ESCAPE(inputValue.slice(0, -1)), 'i');
      const item = this.props.initialValues.find(value => value.match(regex));

      if (item && ![intl('Common.Labels'), intl('Labels.Group')].includes(item)) {
        this.handleValueClick(item, options, evt);
        this.setState({activeIdx: -1, inputValue: ''});

        return;
      }
    }

    if (
      options.singleSelect ||
      (!isInitial && !isStatic && !ddKeys.includes(facetMapActive + '-' + inputValue)) ||
      isDefault
    ) {
      this.getFacetValues(inputValue);
    }

    this.setState({active: options.active, activeIdx: -1, inputValue, showDropdown: true}, this.showCarets);
  },

  handleInputClick(evt) {
    if (this.props.disabled) {
      return;
    }

    if (findDOMNode(this).contains(evt.target)) {
      evt.stopPropagation();
      this.showDropdown();
    }
  },

  showDropdown() {
    if (!this.props.autoLoadCustom) {
      this.setState({
        activeIdx: -1,
        showDropdown: true,
      });
    }
  },

  handleInputKeyDown(options, evt) {
    if (this.props.disabled) {
      return;
    }

    const inputValue = this.state.inputValue;
    const itemKeys = Object.keys(this.props.items);
    let activeIdx = this.state.activeIdx;

    if (
      evt.key === 'Tab' &&
      !this.state.inputValue &&
      activeIdx < 0 &&
      (options.isInitial || options.defaultSelected)
    ) {
      this.cleanItems();

      return;
    }

    // If not a valid key or if Backspace but there is some text in input, return
    if (
      (evt.key === 'Backspace' && (inputValue || itemKeys.length === 0)) ||
      !['ArrowDown', 'ArrowUp', 'Backspace', 'Tab', 'Enter'].includes(evt.key)
    ) {
      return;
    }

    const singleSelect = this.props.singleSelect;
    const isPartial = this.isPartial(options.dropdownValues);

    evt.preventDefault();

    // If customValues comp is active, the 'ArrowDown, 'Tab' and 'Enter' by default wont do anything as there's no dropDown list values
    const isCustom = this.props.customValuesKeys && this.props.customValuesKeys.includes(this.state.active);

    if (isCustom && ['ArrowDown', 'Tab', 'Enter'].includes(evt.key)) {
      // Pass on the focus to the input of the customValuesComp
      // Match either '.ObjectSelector-input-elem' or '.ObjectSelector-input-elem-small'
      const input = findDOMNode(this).querySelector(
        '.ObjectSelector-CustomDDElem input[class^="ObjectSelector-input-elem"]',
      );

      if (input) {
        input.focus();
      }

      return;
    }

    switch (evt.key) {
      case 'ArrowDown':
        if (!this.state.showDropdown) {
          this.setState({showDropdown: true});

          return;
        }

        const isCreate =
          options.dropdownValues &&
          options.dropdownValues[options.dropdownValues.length - 1] &&
          options.dropdownValues[options.dropdownValues.length - 1].create;
        const maxResults = Math.max(this.maxResults, options.dropdownValues.length);

        if (
          options.dropdownValues &&
          ++activeIdx < options.dropdownValues.length &&
          activeIdx < maxResults + (isPartial ? 1 : 0) + (isCreate ? 1 : 0)
        ) {
          this.setState({activeIdx});
        } else if (options.defaultSelected && activeIdx < options.fullDdValues.length) {
          this.setState({activeIdx});
        }

        break;
      case 'ArrowUp':
        if (--activeIdx > -1) {
          this.setState({activeIdx});
        }

        break;
      case 'Backspace':
        let toDelete = itemKeys[itemKeys.length - 1];
        const isSingle = this.props.singleValues && Object.keys(this.props.singleValues).includes(toDelete);

        if (!this.state.showDropdown) {
          this.setState({showDropdown: true});
        }

        if (this.props.items[toDelete] === null && itemKeys[itemKeys.length - 2]) {
          // it is possible that the this.props.items[toDelete] is null, in which case there is nothing to delete
          // so delete the one less previously added to that
          toDelete = itemKeys[itemKeys.length - 2];
        }

        if (options.defaultSelected && !this.props.allowOne && !isSingle) {
          let activeItem = options.active;

          if (this.props.staticsKeys && this.props.staticsKeys.includes(toDelete)) {
            activeItem = toDelete;
          }

          this.deleteMulti(activeItem, null, options);
        } else if (this.props.multiItems && !isSingle) {
          const deleteItem = this.props.items[toDelete];

          this.deleteMulti(toDelete, Array.isArray(deleteItem) ? deleteItem.pop() : toDelete, options);
        } else {
          this.deleteItem(itemKeys.pop(), options);
        }

        break;
      case 'Tab':
      case 'Enter':
        if (!this.state.showDropdown) {
          this.setState({showDropdown: true});

          return;
        }

        if (activeIdx < 0) {
          activeIdx++;
        }

        const active = options.dropdownValues[activeIdx];

        if (!options.dropdownValues) {
          return;
        }

        // Do not let users apply a filter when there are no matching results (unless it is a wildcard search)
        if (isPartial && options.dropdownValues.length === 1 && !singleSelect && !options.isWildcard) {
          return;
        }

        if (options.noSelect || (active && active.loading)) {
          return;
        }

        if (active && active.footer) {
          return;
        }

        if (active && active.noSelect) {
          return;
        }

        if (options.defaultSelected && !active) {
          if (options.fullDdValues[activeIdx] === COMP_STRINGS().showAll) {
            this.setState({showAll: true, activeIdx: options.dropdownValues.length});
          } else {
            this.handleValueClick(options.fullDdValues[activeIdx], {...options, isInitial: true}, evt);
          }
        } else if (active && active.create) {
          this.handleCreate();
        } else {
          this.handleValueClick((active && active.partial) || active, options, evt);
        }

        break;
    }
  },

  handleValueClick(selected, options, evt) {
    if (evt) {
      evt.stopPropagation();
    }

    if (this.props.disabled) {
      return;
    }

    if (COMP_STRINGS_VALUES.includes(selected) || !selected) {
      return;
    }

    const activeIdx = -1;
    const inputValue = '';
    let active = selected;

    const isStatic = this.props.statics && this.props.staticsKeys.includes(active);
    const isSingle = this.props.singleValues && Object.keys(this.props.singleValues).includes(active);
    const isMulti = this.props.multiItems && this.props.multiItems.includes(active);
    const isSingleMulti =
      options.singleSelect && this.props.multiItems && this.props.multiItems.includes(options.singleSelect);
    const isEmptySingleMulti = isSingleMulti && !Object.keys(this.props.items).length;
    const data =
      this.props.dropdownValues[this.props.facetMap[options.singleSelect || active] + '-' + this.state.inputValue];

    if (isSingle) {
      this.props.addItem(active, this.props.singleValues[active]);
      this.onChange();
      active = options.defaultSelected ? options.active : 'initial';
    } else if (options.defaultSelected) {
      if (this.props.initialValues.includes(selected)) {
        this.props.addItem(selected, '');
        active = selected;
      } else {
        this.props.addItem(options.active, selected);
        active = options.active;

        if (this.props.switchToActiveForThese && this.props.switchToActiveForThese.includes(options.active)) {
          active = options.defaultSelected;
        }
      }
    } else if (this.state.active === 'initial' && (!options.singleSelect || isEmptySingleMulti)) {
      if (isMulti || isSingleMulti) {
        if (isSingleMulti) {
          this.props.addItem(options.singleSelect, active);
          active = options.singleSelect;
        } else {
          this.props.addItem(active, null);
        }

        if (!data || this.props.refetchFacetValues) {
          this.props.getFacetValues(this.props.facetMap[active], '', API_MAX);
        }
      } else {
        this.props.addItem(active, '');

        if (!isStatic && !Object.keys(this.props.dropdownValues).includes(this.props.facetMap[active] + '-')) {
          this.props.getFacetValues(this.props.facetMap[active]);
        }
      }
    } else {
      // If component is options.singleSelect, use that, otherwise, use default
      this.props.addItem(options.singleSelect || this.state.active, active);
      this.onChange();
      active = 'initial';
    }

    this.setState({active, activeIdx, inputValue, disableScroll: false, lastItem: selected}, this.focusInput);
  },

  handleToggleDropdown() {
    if (!this.props.autoLoadCustom && !this.props.alwaysOpen) {
      if (this.state.showDropdown) {
        this.cleanItems();
      }

      this.setState({showDropdown: !this.state.showDropdown}, this.focusInput);
    } else {
      this.focusInput();
    }
  },

  highlightText(text) {
    const regex = new RegExp(ESCAPE(this.state.inputValue), 'ig');
    const indices = [];
    const newText = [];
    let matchedText = regex.exec(text);

    while (matchedText) {
      indices.push(matchedText.index);
      matchedText = regex.exec(text);
    }

    indices.forEach((index, idx) => {
      if (idx === 0) {
        newText.push(
          <span key="-1" className="ObjectSelector-dd-values-item-not-match">
            {text.slice(0, index)}
          </span>,
        );
      }

      // indices[idx + 1] would be undefined on the last time, so slice will go to the end (intended)
      newText.push(
        <span key={index} className="ObjectSelector-dd-values-item-match">
          {text.slice(index, index + this.state.inputValue.length)}
        </span>,
        <span key={index + 'b'} className="ObjectSelector-dd-values-item-not-match">
          {text.slice(index + this.state.inputValue.length, indices[idx + 1])}
        </span>,
      );
    });

    // In certain cases, the results may not have the actual search string
    // (like Workloads autocomplete searches on IP address)
    return newText.length ? newText : text;
  },

  isPartial(dropdownValues) {
    const active = this.props.singleSelect || this.state.active;

    // If the value is a partial item, i.e. users can select partially typed values (Name, Hostname, etc)
    return (
      this.props.partialItems &&
      this.props.partialItems.includes(active) &&
      this.state.inputValue &&
      !dropdownValues.includes(this.state.inputValue)
    );
  },

  handleCustomElemClick() {
    // If a customValues item is finished adding, hide the customValues component and show the initial list again
    if (this.props.customValuesKeys.includes(this.state.active) && this.props.items[this.state.active]) {
      this.setState({active: 'initial', activeIdx: -1, inputValue: ''}, this.focusInput);
    }
  },

  handleDisableScroll() {
    this.setState({disableScroll: true}, this.focusInput);
  },

  convertToList(listItem) {
    let onClick;

    if (listItem.props && listItem.props.onClick) {
      // Hacky Fix
      onClick = listItem.props.onClick;

      delete listItem.props.onClick;
    }

    return (
      <li key={listItem.text} {...listItem.props} onClick={onClick || _.noop}>
        {LabelUtils.generateLabelOrLabelgroupOrPlainElement({...listItem.props, text: listItem.text})}
      </li>
    );
  },

  generateOrList(items) {
    const lastInput =
      items[items.length - 1] &&
      items[items.length - 1].props &&
      items[items.length - 1].props.className === 'ObjectSelector-item--verySmall';

    return items.reduce((result, value, index) => {
      // Protect ourselves from a runaway loop
      if (Array.isArray(value) && value.length > 1 && index < 1000) {
        // Add or to inner arrays
        result.push(this.generateOrList(value));
      } else {
        result.push(value);
      }

      // If there is an input field as the last item, do not add the 'or' before it
      if (lastInput ? index < items.length - 2 : index !== items.length - 1) {
        result.push(<div className="ObjectSelector-or">or</div>);
      }

      return result;
    }, []);
  },

  render() {
    const isInitial = this.state.active === 'initial';
    const classes = cx({
      'ObjectSelector': true,
      'ObjectSelector--active': this.state.showDropdown,
      'ObjectSelector--defaultSelected': this.props.defaultSelected,
      'ObjectSelector--error': this.props.error,
      'ObjectSelector--disabled': this.props.disabled,
    });

    const options = {
      active: this.state.active,
      singleSelect: this.props.singleSelect,
      defaultSelected: this.props.defaultSelected,
      showCountOnFilter: this.props.showCountOnFilter,
      isWildcard:
        this.props.hasWildcard &&
        this.props.hasWildcard.includes(this.state.active) &&
        this.state.inputValue.includes('*'),
      isInitial,
      loadingHints: this.props.loadingHints,
    };
    const itemKeys = Object.keys(this.props.items);
    const lastItem = itemKeys[itemKeys.length - 1];
    const isSingleLast = this.props.singleValues && Object.keys(this.props.singleValues).includes(lastItem);
    const isSingleEvery =
      this.props.singleValues &&
      Object.keys(this.props.items).every(key => Object.keys(this.props.singleValues).includes(key));

    let showInput = isInitial || this.props.showInput;
    let dropdownList = [];
    let fullDdValues = [];
    let dropdownValues;

    if (options.defaultSelected) {
      if (options.isInitial) {
        options.isInitial = false;
        options.active = this.props.defaultSelected;
      }

      if (this.props.items[options.active] === undefined) {
        options.active =
          this.props.items[lastItem] && !isSingleLast
            ? lastItem
            : this.state.lastItem && isSingleLast
            ? this.state.lastItem
            : options.defaultSelected;
      }

      if (
        this.props.multiItems &&
        !this.props.multiItems.includes(options.active) &&
        this.props.items[options.active]
      ) {
        options.active = options.defaultSelected;
      }
    }

    if (this.state.showDropdown) {
      const ddValues = this.generateDdValues(options);
      const {numMatches} = ddValues;

      ({dropdownValues} = ddValues);

      if (options.defaultSelected) {
        ({dropdownList, fullDdValues} = this.generateSelectedDdList(dropdownValues, numMatches, options));
        options.fullDdValues = fullDdValues;
      } else {
        // Non defaultSelected doesn't support enableScroll
        dropdownList = this.generateDdList(dropdownValues, numMatches, options);
      }

      if (this.props.customListItem) {
        // No enableScroll with customListItem
        dropdownList = dropdownList.map((listItem, idx) =>
          this.props.customListItem({
            text: listItem.text,
            props: listItem.props,
            item: dropdownValues[options.defaultSelected ? idx - 1 : idx], // Really not sure if works
          }),
        );
      } else if (this.props.enableScroll && !this.state.disableScroll) {
        dropdownList = dropdownList.map(dropdownListItem => dropdownListItem.map(this.convertToList));
      } else {
        dropdownList = dropdownList.map(this.convertToList);
      }
    }

    const {oSInputElem, ulClassName} = this.generateOsInputElem(dropdownValues, options);
    let dropdownListElem = null;

    if (this.props.customValuesKeys && this.props.customValuesKeys.includes(this.state.active)) {
      dropdownListElem = (
        <div className="ObjectSelector-CustomDDElem" onClick={this.handleCustomElemClick}>
          {this.props.customValuesComp[this.state.active]}
        </div>
      );
    } else if (dropdownList.length) {
      if (this.props.enableScroll && !this.state.disableScroll) {
        dropdownListElem = (
          <ul ref="ddValues-results" key="ddValues-results" className={ulClassName} data-tid="comp-select-results-list">
            {dropdownList[0]}
            <li className="ObjectSelector-dd-scroll">
              <ul
                className={cx('ObjectSelector-dd-scroll-caretUp', {
                  'ObjectSelector-dd-scroll-caretUp--Show': this.state.showUpCaret,
                })}
                onClick={this.handleDisableScroll}
              >
                <li>
                  <Icon name="caret-up" size="small" />
                </li>
              </ul>
              <ul
                ref={node => {
                  if (node) {
                    node.addEventListener('scroll', this.showCarets);
                  }

                  this.ddValuesOptions = node;
                }}
                className={ulClassName}
                data-tid="comp-select-options-list"
              >
                {dropdownList[1]}
              </ul>
              <ul
                className={cx('ObjectSelector-dd-scroll-caretDown', {
                  'ObjectSelector-dd-scroll-caretDown--Show': this.state.showDownCaret,
                })}
                onClick={this.handleDisableScroll}
              >
                <li>
                  <Icon name="caret-down" size="small" />
                </li>
              </ul>
            </li>
          </ul>
        );
      } else {
        dropdownListElem = (
          <ul ref="ddValues" className={ulClassName} data-tid="comp-select-results-list">
            {dropdownList}
          </ul>
        );
      }
    }

    const objectSelectorInputBlock = (
      <div className="ObjectSelector-input-div">
        {oSInputElem}
        {dropdownListElem}
      </div>
    );

    let itemsList = !this.props.hideItemsList && this.generateItemsList(objectSelectorInputBlock, options);

    if (this.props.or) {
      itemsList = this.generateOrList(itemsList);
    }

    if (options.defaultSelected) {
      showInput =
        _.isEmpty(this.props.items) ||
        (isSingleLast && (Object.values(this.props.items).length === 1 || isSingleEvery));

      // Append an input, even though an item selected, for keyboard functionality (Backspace, etc)
      if (this.props.allowOne) {
        showInput = showInput || Boolean(this.props.items[options.active]);
      }

      if (
        !showInput &&
        this.props.multiItems &&
        !this.props.multiItems.includes(lastItem) &&
        this.props.items[lastItem]
      ) {
        showInput = true;
      }

      if (showInput && this.props.staticsKeys && this.props.staticsKeys.includes(lastItem)) {
        if (this.props.enableScroll) {
          // TODO: Remove this from within "if (this.props.enableScroll)" in future
          if (
            this.props.multiItems &&
            !Object.keys(this.props.items).every(
              itemKey =>
                this.props.staticsKeys.includes(itemKey) ||
                Object.keys(this.props.singleValues).includes(itemKey) ||
                !this.props.multiItems.includes(itemKey),
            )
          ) {
            showInput = false;
          }
        } else if (
          !Object.keys(this.props.items).every(
            itemKey =>
              this.props.staticsKeys.includes(itemKey) || Object.keys(this.props.singleValues).includes(itemKey),
          )
        ) {
          showInput = false;
        }
      }
    }

    let showIcon = true;

    if (
      (this.props.allowOne && (this.props.items[options.active] || isSingleLast)) ||
      this.props.alwaysOpen ||
      (this.props.singleSelect && this.props.items[this.props.singleSelect] && !this.props.multiItems)
    ) {
      showIcon = false;
    }

    let caretIcon;

    if (this.props.customCaretIcon) {
      caretIcon = <div onClick={this.handleToggleDropdown}>{this.props.customCaretIcon}</div>;
    } else {
      caretIcon = showIcon ? (
        <Icon
          name={`caret-${this.state.showDropdown ? 'up' : 'down'}`}
          size="medium"
          styleClass="ObjectSelector-down-arrow"
          onClick={this.handleToggleDropdown}
        />
      ) : null;
    }

    return (
      <div className={classes} data-tid="comp-combobox">
        <div className="ObjectSelector-items" data-tid="comp-combobox-selected-list">
          {itemsList.length ? itemsList : null}
          {showInput ? (
            <div
              className={`ObjectSelector-item--initial${
                itemsList.length && (this.props.allowOne || this.props.smallInput) ? '--small' : ''
              }`}
            >
              {objectSelectorInputBlock}
            </div>
          ) : null}
        </div>
        {caretIcon}
      </div>
    );
  },
});
