/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import cx from 'classnames';
import stringify from 'safe-stable-stringify';
import React from 'react';
import type {Primitive} from 'type-fest';

type PickDefined<T> = (obj: T, props: (keyof T)[]) => {[P in keyof T]: T[P]};

type RandomStringInnerFunction = (length: number, lowOnly: boolean) => string;

type FlattenObjectFilter = (value: unknown, key: string, newKey: string) => boolean;

/** Nominal Types */
type TypeOf = Primitive & {_brand: 'primitive_type'};
type PositiveInteger = (number | string) & {_brand: 'positive_integer'};
type NumberOrString = (number | string) & {_brand: 'number_string'};
type ValidNumber = (number | string) & {_brand: 'valid_number'};
type IsMac = string & {_brand: 'mac_os'};
type AreSetsEqual = Set<string> & {_brand: 'sets_equal'};
type ShallowEqual = {[data: string]: unknown} & {_brand: 'shallow_equal'};

export const int32 = Math.pow(2, 31) - 1;

/**
 * Given a value, check if it's typeof belongs in an array of primitive types
 *
 * @param value Primitive types
 * @param types A list of Primitive type
 */
export const isTypeof = (value: Primitive, types: Primitive[]): value is TypeOf => {
  if (!types) {
    return false;
  }

  return types.includes(typeof value);
};

/**
 * Function to check whether a given "string" or "number" is a valid number
 * (Optional) and is bounded within the parameters [including]
 *
 * @param num
 * @param min
 * @param max
 * @returns
 */
export const isValidNumber = (num: string | number, min = num, max = num): num is ValidNumber => {
  if (typeof num === 'number') {
    num = String(num);
  }

  const str = num.trim();
  const n = Math.trunc(Number(str));

  return String(n) === str && n >= min && n <= max;
};

/**
 * Function to check whether a given "string" or "number" is a valid positive integer
 *
 * @param value
 * @param lessOrEqualThan
 * @returns
 */
export const isPositiveInteger = (value: number | string, lessOrEqualThan = Infinity): value is PositiveInteger => {
  if (!value) {
    return false;
  }

  const n = Math.trunc(Number(value));

  return String(n) === value && n >= 0 && n <= lessOrEqualThan;
};

/**
 * Given a value, check if it's typeof belongs to "number" or "string"
 *
 * @param value
 */
export const isNumberOrString = (value: number | string): value is NumberOrString =>
  isTypeof(value, ['number', 'string']);

/**
 * Returns a new map composed of keys that are not in list to drop
 *
 * @param map - Original map
 * @param keysToDrop - keys to omit
 * @returns
 */
export const omitFromMap = (map: Map<unknown, unknown>, ...keysToDrop: string[]): Map<unknown, unknown> => {
  const mapClone = new Map(map);

  for (const key of keysToDrop) {
    mapClone.delete(key);
  }

  return mapClone;
};

/**
 * Given version to parse e.g. version = '20.2.0.UI1-2719' | '20.2.0+UI1-2719' | '20.2.0-UI1-2719'
 * @param version
 * @returns version
 */
export const getVersion = (version: string): undefined | string => {
  // version = "20.2.0.UI1-2719"
  // (\d+\.\d+\.\d+) - match 3 digits folowed by a dot(.) e.g. 18.3.0
  const regex = /^(\d+\.\d+\.\d+).*/;

  if (regex.test(version) && regex.exec(version) !== null) {
    return regex.exec(version)?.[1];
  }
};

export const parseVersion = (rawVersion: string): {[p: string]: number} | undefined => {
  const version = getVersion(rawVersion);

  if (version) {
    return _.mapValues(version.match(/^(?<major>\d+)\.(?<minor>\d+)\.(?<patch>\d+).*/)?.groups, Number);
  }
};

/**
 * Determine version mismatch between PCE and UI Version to determine whether PCE or UI is ahead or behind.
 * When PCE version(backend) and the UI version(package.json) do not match.
 * NOTE: Negative and positive integer results vary between browsers (as well as between browser versions)
 * because the W3C specification only mandates negative and positive values
 * e.g. pceVersion 18.2.0, uiVersion 18.3.0 : any negative e.g. -1, -2, etc...
 * e.g. pceVersion 18.3.0, uiVersion 18.2 : 1 any positive e.g. 1, 2, etc...
 * e.g. pceVersion 18.2.0, uiVersion 18.2 : 0 - match was made
 * e.g. PCE is a development parameterized build when isParameterizedBuild === true
 * localCompare() when versions match
 * @param pceVersion pce version number
 * @param parameterized determine if this is a parameterized build
 */
export const getVersionMismatch = ({
  pceVersion,
  parameterized = false,
}: {pceVersion?: string; parameterized?: boolean} = {}): number | string | undefined =>
  !__CHECK_VERSIONS_MATCH__ || parameterized === true
    ? 0
    : pceVersion?.localeCompare(getVersion(process.env.UI_VERSION) ?? '');

/**
 * Generate random float number greater or equal to given minimum and lower than maximum
 *
 * @param min
 * @param max
 * @returns
 */
export const randomInclusiveToExclusive = (min: number, max: number): number => Math.random() * (max - min) + min;

/**
 * Generate random float number between two exclusive numbers.
 * If you want randomInclusiveToInclusive use _.random(min, max, true)
 *
 * @param min
 * @param max
 * @returns random number
 */
export const randomExclusiveToExclusive = (min: number, max: number): number => {
  let rand = 0;

  do {
    rand = Math.random();
  } while (rand === 0);

  return rand * (max - min) + min;
};

/**
 * Returns an object composed of properties which exist in props array and which values are not undefined.
 * Similar to _.pick(), but checks for undefined instead of hasOwnProperty
 *
 * @param obj
 * @param props
 * @returns
 *
 */
export const pickDefined: PickDefined<Record<string, unknown>> = (obj, props) =>
  props.reduce((result: typeof obj, name: string) => {
    const prop = obj[name];

    if (prop !== undefined) {
      result[name] = prop;
    }

    return result;
  }, {});

/** Sort and stringify array as stringify does not support sorting
 *  @param arry Array to sort and stringify
 */
export const sortAndStringifyArray = (arr: string[], sorter: string[] = []): undefined | string =>
  Array.isArray(arr) ? stringify(_.sortBy(arr, sorter)) : undefined;

/**
 * Gets any number of arrays, sort, stringify and compare them
 *
 * @param arrays A two dimensional array [["type", "name", "username", "roles", "delete"]]
 * @returns Determine if arrays are equal
 */
export const areArraysEqualWhenSorted = (...arrays: string[][]): boolean => {
  const length = arrays[0].length;
  let stringified = null;

  for (let index = 1; index < arrays.length; index++) {
    if (arrays[index].length !== length) {
      return false;
    }

    // Lazily stringify first array only if lengths of the arrays are the same
    if (stringified === null) {
      stringified = sortAndStringifyArray(arrays[0]);
    }

    if (sortAndStringifyArray(arrays[index]) !== stringified) {
      return false;
    }
  }

  return true;
};

/**
 * Check any number of values for equality, if all arguments are arrays use areArraysEqualWhenSorted to compare
 *
 * @param values A two dimensional
 * @returns
 */
export const areSortedEqual = (...values: string[][]): boolean => {
  if (values.every(value => Array.isArray(value))) {
    return areArraysEqualWhenSorted(...values);
  }

  for (let i = 1; i < values.length; i++) {
    if (!_.isEqual(values[i - 1], values[i])) {
      return false;
    }
  }

  return true;
};

/**
 * Generates random string with a given length
 */
export const randomString = ((): RandomStringInnerFunction => {
  const charsAll = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
  const charsLow = '0123456789abcdefghijklmnopqrstuvwxyz'.split('');

  return (length, lowOnly): string => {
    const chars = lowOnly ? charsLow : charsAll;
    const charsLen = chars.length;
    let str = '';

    if (!length) {
      length = Math.trunc(Math.random() * charsLen + 1);
    }

    while (length--) {
      str += chars[Math.trunc(Math.random() * charsLen)];
    }

    return str;
  };
})();

/**
 * Splits a string of classes, then maps over each class returning the value of the class (key) found in the styles object
 * The result is passed to cx which joins the classes back together
 *
 * @param styles
 * @param classes
 * @returns css
 */
export const classSplitter = (styles: {[css: string]: string}, classes?: string): string | string[] =>
  classes ? cx(classes.split(' ').map(cl => styles[cl])) : '';

/**
 * Returns a promise that will be resolved after a specified timeout with an optional value
 *
 * @param [timeout=0]
 * @param [value]
 * @returns
 */
export const delay = (timeout = 0, value: unknown[]): Promise<unknown[]> =>
  new Promise(resolve => setTimeout(resolve, timeout, value));

/**
 * Insert injector between array items, returns new array
 * @param array A collection of React Nodes
 * @param injector
 * @returns
 */
export const intersperseArray = (array: React.ReactNode[], injector: string): (React.ReactNode | string)[] =>
  array.reduce((result: (React.ReactNode | string)[], item, index, array) => {
    if (result) {
      result.push(item);
    }

    if (index < array.length - 1) {
      result.push(injector);
    }

    return result;
  }, []);

export const areSetsEqual = (a: Set<string>, b: Set<string>): b is AreSetsEqual => {
  const unionSet = new Set([...a, ...b]);

  return unionSet.size === a.size && unionSet.size === b.size;
};

// Util to check if OS is Mac - useful for determining keyboard keys
export const isMac = (name = 'macOS'): name is IsMac => browser.os.name === name;

export const cmdOrCtrlPressed = (evt: Pick<KeyboardEvent, 'metaKey' | 'ctrlKey'>): boolean =>
  isMac() ? evt.metaKey : evt.ctrlKey;

/**
 * Creates new object (with specified depth) from transferred object by flattening its keys
 *
 * @param obj Object to flatten
 * @param  [options] Options
 * @param  [options.prefix=''] Prefix for all keys
 * @param  [options.separator='.'] Character to use as a separator
 * @param  [options.depth=0] Maximum depth to recurse to. Zero or null is unlimited
 * @param  [options.filter=false] Function to filter values. Has three parameters: value, key, newKey
 * @param resultObj
 *
 * @example
 *     flattenObject({
 *       a: {
 *         b: {
 *           c: 'test1',
 *           d: 'test2',
 *           e: { f: 1 }
 *         }
 *       },
 *       g: 1,
 *       h: null
 *     }, { depth: 3 });
 *     // returns
 *     {
 *       'a.b.c': 'test1',
 *       'a.b.d': 'test2',
 *       'a.b.e': { f: 1 },
 *       'g': 1,
 *       'h': null
 *     }
 *
 * @returns {*}
 */
const defaultSeparator = '.';
const defaultFilter = false;
const defaultPrefix = '';
const defaultDepth = 0;

export const flattenObject = (
  obj: Record<string, unknown>,
  options: {
    separator?: string;
    prefix?: string;
    depthCurrent?: number;
    filter?: boolean | FlattenObjectFilter;
    depth?: number;
  } = {},
  resultObj = Object.create(null) as Record<string, unknown>,
): Record<string, unknown> => {
  const {separator = defaultSeparator, filter = defaultFilter, prefix = defaultPrefix, depth = defaultDepth} = options;

  options.depthCurrent = Math.trunc(options.depthCurrent ?? 0) + 1;

  const isLastLevel = depth && options.depthCurrent >= depth;

  _.forOwn(obj, (value, key) => {
    const newKey = prefix + key;

    if (
      !isLastLevel &&
      _.isPlainObject(value) &&
      (!filter || (typeof filter !== 'boolean' && filter?.(value, key, newKey)))
    ) {
      options.prefix = newKey + separator;

      flattenObject(value as Record<string, unknown>, options, resultObj);
    } else {
      resultObj[newKey] = value;
    }
  });

  return resultObj;
};

/**
 * Get the symmetrical difference between two arrays.
 * e.g. [1,2,3] and [3,4] is [1,2,4]
 *
 * @param original
 * @param updated
 * @returns
 */
export const getSymmetricalDiff = (original: number[], updated: number[]): number[] => _.xor(original, updated);

// TODO: Replace with Object.hasOwn once understood by TypeScript
// https://github.com/tc39/proposal-accessible-object-hasownproperty
const hasOwn = Object.prototype.hasOwnProperty;

/** Very fast objects equality check
 *
 * @param objA
 * @param objB
 * @returns
 */
export const shallowEqual = (
  objA?: {[data: string]: unknown},
  objB?: {[data: string]: unknown},
): objA is ShallowEqual => {
  if (objA === objB) {
    return true;
  }

  if (!objA || !objB) {
    return false;
  }

  const keysA = Object.keys(objA);

  return (
    // Check that length is equal
    keysA.length === Object.keys(objB).length &&
    // And every key of A is contained in B and has the same value
    keysA.every(key => hasOwn.call(objB, key) && objA[key] === objB[key])
  );
};

/**
 * The same as above, but checks only given keys
 *
 * @param a
 * @param b
 * @param keys
 * @returns
 */
export const shallowEqualByProps = (
  a: {[data: string]: unknown},
  b: {[data: string]: unknown},
  keys: string[],
): a is ShallowEqual => keys.every(key => hasOwn.call(a, key) && hasOwn.call(b, key) && a[key] === b[key]);

/**
 * Loose version of shallowEqual
 * Assumes that two objects are always exists, never equals, has the same length and don't have prototypes.
 * Useful for comparing new and old props/state, if set of their properties is always the same
 *
 * @param a
 * @param b
 * @returns
 */
export const shallowEqualLoose = (a: {[data: string]: unknown}, b: {[data: string]: unknown}): a is ShallowEqual =>
  Object.keys(a).every(key => a[key] === b[key]);

/**
 * The same as above, but checks only given keys
 *
 * @param a
 * @param b
 * @param keys
 * @returns
 */
export const shallowEqualLooseByProps = (
  a: {[data: string]: unknown},
  b: {[data: string]: unknown},
  keys: string[],
): a is ShallowEqual => keys.every(key => a[key] === b[key]);

/**
 * Return item's true value or undefined. This converts empty string, null to undefined.
 * @param {*} item
 * @returns {undefined|*}
 */
export const getTrueValue = <T>(item: T): T | undefined => {
  if (typeof item === 'string') {
    item = item.trim() as unknown as T;
  }

  if (item || (typeof item === 'number' && item === 0)) {
    return item;
  }
};

/**
 * Verify whether the item is empty as initialized
 * @param {*} item The item to be checked
 * @param {Object} options The options: includes is considered before excludes if both are given
 * @param {Array} [options.includes=[]] Only check the composed keys in includes for the given path, if item is an object/array
 * @param {Array} [options.excludes=[]] Only check the composed keys not in excludes, if item is an object/array
 *     Example in WorkloadCreate: GeneralUtils.isDeepEmpty(this.state, {includes: ['interfaces.text'], excludes: ['status', 'newService.address']})
 *     Example in VirtualServiceCreate: isDeepEmpty(this.state.virtualService, {includes: ['ipRanges.text'], excludes: ['apply_to']})
 * @param {string} currentPath The current path for includes and excludes
 * @returns {boolean}
 */
export const isDeepEmpty = (
  item: unknown[],
  options: {includes?: string[]; excludes?: string[]} = {},
  currentPath = '',
): boolean => {
  const {includes = [], excludes = []} = options;

  if (Array.isArray(item)) {
    if (!item.length) {
      return true;
    }

    // sometimes, the array item is initialized, but no value assigned. Need to check everyone of them.
    for (const value of item) {
      if (!isDeepEmpty(value, options, currentPath)) {
        return false;
      }
    }

    return true;
  }

  if (_.isObject(item)) {
    const pathLength = currentPath === '' ? 0 : currentPath.length + 1; // include '.' if not empty
    let currentKeys: string[] = [];
    let useInclude = false;

    // if includes is specified
    if (includes.length > 0) {
      // check whether items in includes are applicable to the current path
      for (const composedKey of includes) {
        if (composedKey.startsWith(currentPath)) {
          const singleKey = composedKey.substring(pathLength);

          if (singleKey.length > 0 && !singleKey.includes('.')) {
            currentKeys.push(singleKey);
          }
        }
      }
    }

    if (currentKeys.length > 0) {
      useInclude = true;
    } else {
      // if no items includes are applicable, loop through all keys
      currentKeys = Object.keys(item);
    }

    // check applicable includes or object keys
    for (const singleKey of currentKeys) {
      const composedKey = pathLength === 0 ? singleKey : `${currentPath}.${singleKey}`;

      // excludes is considered only if not useInclude
      if (useInclude || !excludes.includes(composedKey)) {
        if (!isDeepEmpty(item[singleKey], options, composedKey)) {
          return false;
        }
      }
    }

    return true;
  }

  return typeof getTrueValue(item) === 'undefined';
};
