/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import cx from 'classnames';
import Icon from '../components/Icon.jsx';
import intl from 'intl';
import {TrafficStore, ServiceStore, IpListStore, SessionStore} from '../stores';
import {ServiceUtils, RulesetUtils, RenderUtils, GeneralUtils} from '.';

export function getPolicyGeneratorId(scope) {
  if (!scope || !Array.isArray(scope)) {
    return;
  }

  const appGroupsType = TrafficStore.getAppGroupsType();
  const idArray = scope
    .reduce((result, scopeItem) => {
      if (scopeItem.label) {
        const id = GeneralUtils.getId(scopeItem?.label?.href);

        if (id) {
          result.push(id);
        }
      }

      return result;
    }, [])
    .sort((a, b) => RenderUtils.collator.compare(a, b));

  // Verify the scope and the app group type have the same length
  if (idArray.length === appGroupsType.length) {
    return idArray.join('x');
  }
}

export function getTransmissionFilters(filters) {
  return Object.keys(filters).reduce((result, filter) => {
    if (filters[filter]) {
      result.push(filter);
    }

    return result;
  }, []);
}

export function getTransmissionFilterString(filters) {
  const filterArray = getTransmissionFilters(filters);

  if (!filterArray.length) {
    return intl('Common.No');
  }

  if (filterArray.length === 1) {
    return filterArray[0];
  }

  if (filterArray.length === 2) {
    return `${filterArray[0]} ${intl('Common.And')} ${filterArray[1]}`;
  }

  return `${filterArray[0]}, ${filterArray[1]}, ${intl('Common.And')} ${filterArray[2]}`;
}

export function getExtraConnectionsGridTitle(extraConfig) {
  if (extraConfig.level && extraConfig.level === 'appgroup') {
    return (
      <div className="PolicyGeneratorGrid-title">
        {extraConfig.type && extraConfig.type === 'tiertotier'
          ? `${intl('PolicyGenerator.Grid.Title')} ${intl('PolicyGenerator.Grid.TitleDetails.AppAll')}`
          : `${intl('PolicyGenerator.Grid.Title')} ${intl('PolicyGenerator.Grid.TitleDetails.AppSpec')}`}
      </div>
    );
  }

  if (extraConfig.level && extraConfig.level === 'role') {
    return (
      <div className="PolicyGeneratorGrid-title">
        {extraConfig.type && extraConfig.type === 'tiertotier'
          ? `${intl('PolicyGenerator.Grid.Title')} ${intl('PolicyGenerator.Grid.TitleDetails.RoleAll')}`
          : `${intl('PolicyGenerator.Grid.Title')} ${intl('PolicyGenerator.Grid.TitleDetails.RoleSpec')}`}
      </div>
    );
  }
}

function calculateRuleCoverage(traffics, total, exclusions) {
  let ruleCoverage = total;

  if (traffics.length === 1) {
    return ruleCoverage;
  }

  traffics.forEach(traffic => {
    if (exclusions.includes(traffic.key) || !traffic.source.length || !traffic.target.length) {
      ruleCoverage -= traffic.connections;
    }
  });

  return ruleCoverage;
}

function calculateExtraRuleCoverage(ExtraTraffics, total, exclusions, level) {
  return _.reduce(
    ExtraTraffics,
    (ruleCoverage, traffics) => {
      traffics.forEach(traffic => {
        if (
          exclusions.includes(traffic.key) ||
          (level === 'role' &&
            (!traffic.source.some(s => s.label.key === 'role') || !traffic.target.some(t => t.label.key === 'role')))
        ) {
          ruleCoverage -= traffic.connections;
        }
      });

      return ruleCoverage;
    },
    total,
  );
}

// We consider all non closed Vulnerabilities to be reduced exposure
// This calculates all the vulnerabilities without rules
function calculateClosedVulnerabilities(vulnerabilities, exclusions) {
  return _.reduce(
    vulnerabilities.vulnerabilityInstances,
    (closedCounts, vulnerability) => {
      // Find all the rules opening this vulnerability which are not excluded
      const rules = vulnerability.rules.reduce((ruleCount, rulekey) => {
        if (exclusions.includes(rulekey)) {
          ruleCount -= 1;
        }

        return ruleCount;
      }, vulnerability.rules.length);

      // If there are no non-excluded rules opening the vulnerability, count it closed
      if (!rules) {
        closedCounts = vulnerability.instances.reduce((result, vulnerability) => {
          result[RenderUtils.getVulnerabilityForRender(vulnerability.severity, 'key')] += 1;

          return result;
        }, closedCounts);
      }

      return closedCounts;
    },
    {1: 0, 2: 0, 3: 0, 4: 0, 5: 0},
  );
}

// Note all the vulnerabilities opened by this rule
// TODO: We might want to note the vulnerabilities which we closed be writing a specified service rule
function getVulnerabilityRuleNote(key, vulnerabilities) {
  return vulnerabilities.ruleVulnerabilities[key]
    ? _.reduce(
        vulnerabilities.ruleVulnerabilities[key],
        (result, instance) => {
          instance.forEach(
            vulnerability =>
              (result += `${vulnerability.port} ${ServiceUtils.lookupProtocol(vulnerability.protocol)} ${
                vulnerability.details.name
              } ${vulnerability.details.cve_ids.map(id => ` ${id}`)}\n`),
          );

          return result;
        },
        'Exposes:\n',
      )
    : String(vulnerabilities.ruleClosedVulnerabilities[key])
    ? vulnerabilities.ruleClosedVulnerabilities[key].reduce((result, vulnerability) => {
        result += `${vulnerability.port} ${ServiceUtils.lookupProtocol(vulnerability.protocol)} ${
          vulnerability.details.name
        } ${vulnerability.details.cve_ids.map(id => ` ${id}`)}\n`;

        return result;
      }, 'Closes:\n')
    : '';
}

function getRoleVulnerabilities(roles, expanded) {
  return roles
    .sort((a, b) => a.split('-')[1] - b.split('-')[1])
    .reduce((result, role) => {
      const node = TrafficStore.getNode(role);
      const vulnerabilities = TrafficStore.getNodeVulnerabilityByHref(role);

      if (vulnerabilities) {
        result.push({
          ...vulnerabilities.aggregatedValues,
          wideExposure: vulnerabilities.aggregatedValues.wideExposure,
          maxSeverity: vulnerabilities.aggregatedValues.maxExpSeverity,
          numVulnerabilities: Object.values(vulnerabilities.instances).flat().length,
          projected: vulnerabilities.projected && vulnerabilities.projected.aggregatedValues,
          role: node,
        });

        if (expanded.includes(role)) {
          result = result.concat(
            Object.values(vulnerabilities.instances)
              .flat()
              .reduce((result, vulnerability) => {
                if (vulnerability.port) {
                  result.push({
                    ...vulnerability,
                    projected:
                      vulnerabilities.projected &&
                      vulnerabilities.projected.instances[
                        [vulnerability.port, vulnerability.protocol, vulnerability.details.href].join(',')
                      ],
                  });
                }

                return result;
              }, []),
          );
        }
      }

      return result;
    }, []);
}

export function truncateProviderConsumerLink(
  provider,
  consumer,
  providerLimit,
  consumerLimit,
  providerConsumerOrder,
  included,
) {
  let truncProvider = provider;
  let truncConsumer = consumer;

  if (provider.length + consumer.length > providerLimit + consumerLimit) {
    if (provider.length > providerLimit) {
      truncProvider = `${provider.slice(0, providerLimit - 2)}...`;
    }

    if (consumer.length > consumerLimit) {
      truncConsumer = `${consumer.slice(0, consumerLimit - 2)}...`;
    }
  }

  const arrowClass = cx(
    'PolicyGeneratorGrid-arrow-bar',
    included ? 'PolicyGeneratorGrid-arrow--allowed' : 'PolicyGeneratorGrid-arrow--blocked',
  );

  if (providerConsumerOrder === 'consumerFirst') {
    return (
      <div>
        {truncConsumer}
        <span className={arrowClass}>
          <Icon name="arrow-right-long" size="small" position="inbetween" />
        </span>
        {truncProvider}
      </div>
    );
  }

  return (
    <div>
      {truncProvider}
      <span className={arrowClass}>
        <Icon name="arrow-left-long" size="small" position="inbetween" />
      </span>
      {truncConsumer}
    </div>
  );

  // return `${truncProvider} <-- ${truncConsumer}`;
}

function getRuleLabels(node, focusedAppGroup, level, extraScope) {
  if (node.ip_lists) {
    return node.ip_lists.map(list => {
      if (RenderUtils.isHrefFqdn(list.href)) {
        return {ip_list: {name: TrafficStore.getFqdn(list.href), href: list.href, fqdn: true, new: true}};
      }

      const fqdn = node.fqdn && IpListStore.getAnyIpList() && list.href !== IpListStore.getAnyIpList().href;

      return {ip_list: {...list, fqdn}};
    });
  }

  if (node.href && node.href.includes('ip_list')) {
    return [{ip_list: node}];
  }

  return node.labels.reduce((result, label) => {
    const labelId = _.last(label.href.split('/'));

    // Use labels not in the scope of the focused AppGroup
    if (
      (extraScope || !focusedAppGroup.split('x').includes(labelId)) &&
      // Use labels in the appGroup parent and the roles for role level
      (node.appGroupParent.split('x').includes(labelId) || (level === 'role' && label.key === 'role'))
    ) {
      result.push({label});
    }

    return result;
  }, []);
}

function getTableLabels(node, extraScope) {
  if (node.ip_lists) {
    return node.ip_lists.map(list => ({ip_list: list}));
  }

  if (!node.labels || _.isEmpty(node.labels)) {
    return [node];
  }

  const roles = node.labels.reduce((result, label) => {
    // Use all labels for extra - only the role label for intra
    if (label.key === 'role') {
      result.push({label});
    }

    return result;
  }, []);

  if (extraScope) {
    return [...roles, ...getAppGroupLabels(node)];
  }

  return roles;
}

function getAppGroupLabels(node) {
  const appGroupIds = node.appGroupParent.split('x');

  return appGroupIds.map(id => ({label: node.labels.find(label => label.href.split('/').pop() === id)}));
}

function getAllServices() {
  return ServiceStore.getAll().find(
    service =>
      service.service_ports &&
      service.service_ports.length === 1 &&
      service.service_ports[0] &&
      (service.service_ports[0].protocol === -1 || service.service_ports[0].proto === -1),
  );
}

function buildRule(traffic, level, extraScope) {
  const rule = {
    consuming_security_principals: [],
    enabled: true,
    sec_connect: false,
    resolve_labels_as: {
      providers: ['workloads'],
      consumers: ['workloads'],
    },
    unscoped_consumers: extraScope,
  };
  let focusedAppGroup;

  if (traffic) {
    focusedAppGroup = traffic.target.data.appGroupParent || traffic.source.data.appGroupParent;
  }

  switch (level) {
    case 'ring':
      rule.consumers = [{actors: 'ams'}];
      rule.providers = [{actors: 'ams'}];
      break;
    case 'psuedo':
      if (extraScope) {
        rule.consumers = getRuleLabels(traffic.source.data, focusedAppGroup, level, extraScope);
      } else {
        rule.consumers = [{actors: 'ams'}];
      }

      rule.providers = getRuleLabels(traffic.target.data, focusedAppGroup, 'role');
      break;
    case 'role':
      const fqdn = traffic.target.fqdnIpListMatch;

      // Get all labels not included in the scope
      rule.consumers = getRuleLabels(traffic.source.data, focusedAppGroup, level, extraScope);
      rule.providers = getRuleLabels({...traffic.target.data, fqdn}, focusedAppGroup, level);
      break;
    case 'appgroup':
      if (extraScope || traffic.source.data.ip_lists) {
        // Get all labels not included in the scope
        rule.consumers = getRuleLabels(traffic.source.data, focusedAppGroup, level, extraScope);
        // For AppGroup level providers are everything in the scope
        rule.providers = [{actors: 'ams'}];
      } else {
        // For AppGroup level consumers are everything in the scope
        rule.consumers = [{actors: 'ams'}];
        // Get all labels not included in the scope
        rule.providers = getRuleLabels(traffic.target.data, focusedAppGroup, level);
      }
  }

  return rule;
}

export function getOptimizedRules(
  traffics,
  level,
  rulesetId,
  workflow,
  exclusions,
  vulnerabilities,
  optimizeLevel,
  serviceType,
) {
  const {ringFenceLinks, tierToTierLinks, microsegmentationLinks} = optimizeVulnerabiltyTraffic(
    traffics,
    optimizeLevel,
    workflow,
  );

  return [
    ...getPsuedoRingFencingRule(ringFenceLinks, level, rulesetId, workflow === 'extra', exclusions, vulnerabilities),
    ...getAllServicesRules(tierToTierLinks, level, rulesetId, workflow === 'extra', exclusions, vulnerabilities),
    ...getMicroSegmentedRules(
      microsegmentationLinks,
      level,
      rulesetId,
      workflow === 'extra',
      exclusions,
      vulnerabilities,
      serviceType,
    ),
  ];
}

export function getRingFencingRule(exclusions, appGroup) {
  const exclusionLookup = _.countBy(exclusions);
  const rule = buildRule(null, 'ring', false);

  rule.services = [getAllServices()];

  if (appGroup && TrafficStore.getAppGroupNode(appGroup.href).virtualServiceCounts) {
    rule.resolve_labels_as = {
      providers: ['workloads', 'virtual_services'],
      consumers: ['workloads', 'virtual_services'],
    };
    rule.consumers.push({usesVirtualServicesWorkloads: true});
    rule.providers.push({usesVirtualServicesWorkloads: true});
  }

  const key = getRuleKey(rule);

  return exclusionLookup[key] ? [] : [rule];
}

// PsuedoRingFencing - Ringfences one role - allows all workloads to talk a specific role
export function getPsuedoRingFencingRule(traffics, level, rulesetId, extraScope, exclusions, vulnerabilities) {
  const exclusionLookup = _.countBy(exclusions);

  if (!traffics.length) {
    return [];
  }

  const rule = traffics.reduce((result, traffic) => {
    // If there is a rulesetId, subtract any connections covered by that ruleset
    if (
      traffic.totalConnectionRules - (traffic.totalConnectionRulesPerRuleset[rulesetId] || 0) <
      traffic.totalConnections
    ) {
      const newRule = buildRule(traffic, 'psuedo', extraScope);

      if (_.isEmpty(result)) {
        return newRule;
      }

      // merge providers as consumers will be 'ams'
      result.providers = _.uniqBy([...findEndpointLabels(result.providers, ['role']), ...newRule.providers], provider =>
        getHref(provider),
      );
    }

    return result;
  }, {});

  rule.services = [getAllServices()];

  const key = getRuleKey(rule);

  if (vulnerabilities && vulnerabilities.ruleVulnerabilities && vulnerabilities.ruleVulnerabilities[key]) {
    rule.description = getVulnerabilityRuleNote(key, vulnerabilities);
  }

  if (
    !exclusionLookup[key] &&
    (level === 'appgroup' || rule.providers.some(p => p.href || p.ip_list || (p.label && p.label.key === 'role')))
  ) {
    return [rule];
  }

  return [];
}

export function getAllServicesRules(traffics, level, rulesetId, extraScope, exclusions, vulnerabilities) {
  const exclusionLookup = _.countBy(exclusions);

  return Object.values(
    traffics.reduce((result, traffic) => {
      // If there is a rulesetId, subtract any connections covered by that ruleset
      if (
        traffic.totalConnectionRules - (traffic.totalConnectionRulesPerRuleset[rulesetId] || 0) <
        traffic.totalConnections
      ) {
        const rule = buildRule(traffic, level, extraScope);

        rule.services = [getAllServices()];

        if (traffic.source.data.virtualServiceCounts) {
          rule.resolve_labels_as.consumers = ['workloads', 'virtual_services'];
          rule.consumers.push({usesVirtualServicesWorkloads: true});
        }

        if (traffic.target.data.virtualServiceCounts) {
          rule.resolve_labels_as.providers = ['workloads', 'virtual_services'];
          rule.providers.push({usesVirtualServicesWorkloads: true});
        }

        const key = getRuleKey(rule);

        if (vulnerabilities && vulnerabilities.ruleVulnerabilities[key]) {
          rule.description = getVulnerabilityRuleNote(key, vulnerabilities);
        }

        if (
          !exclusionLookup[key] &&
          (level === 'appgroup' ||
            (rule.providers.some(p => p.href || p.ip_list || (p.label && p.label.key === 'role')) &&
              rule.consumers.some(c => c.href || c.ip_list || (c.label && c.label.key === 'role'))))
        ) {
          result[key] = rule;
        }
      }

      return result;
    }, {}),
  );
}

function getBroadServices() {
  return ServiceStore.getAll().reduce((result, service) => {
    if (
      (service.windows_services &&
        service.windows_services.length &&
        (service.windows_services.length > 1 || service.windows_services[0].to_port)) ||
      (service.service_ports &&
        service.service_ports.length &&
        (service.service_ports.length > 1 || service.service_ports[0].to_port))
    ) {
      result[service.href] = service;
    }

    return result;
  }, {});
}

export function getMicroSegmentedRules(
  traffics,
  level,
  rulesetId,
  extraScope,
  exclusions,
  vulnerabilities,
  serviceType,
  broadServiceMatch,
) {
  const exclusionLookup = _.countBy(exclusions);
  // Remove single port services before iterating through to find the broad matches
  const broadServices = broadServiceMatch && getBroadServices();
  let isBroad;

  return Object.values(
    traffics.reduce((result, traffic) => {
      // Walk through connections for each link
      _.forOwn(traffic.connections, connection => {
        if (_.isEmpty(connection.rules) || connection.rules.every(rule => rule.split('/')[6] === rulesetId)) {
          const rule = buildRule(traffic, level, extraScope);

          if (rule) {
            const matchedService = buildService(connection, broadServices && Object.values(broadServices));
            const portService = buildPortService(connection);
            const newService = buildNewService(connection);
            const icmp =
              portService && portService.length && ServiceUtils.isIcmp(portService[0].proto || portService[0].protocol);

            isBroad = broadServices && matchedService && matchedService.length && broadServices[matchedService[0].href];

            if (
              (traffic.source.data.virtualServiceCounts || traffic.source.data.virtualServerCounts) &&
              (traffic.source.data.workloadCounts || traffic.source.data.containerWorkloadCounts)
            ) {
              rule.resolve_labels_as.consumers = ['workloads', 'virtual_services'];
              rule.consumers.push({usesVirtualServicesWorkloads: true});
            }

            if (
              (traffic.target.data.virtualServiceCounts || traffic.target.data.virtualServerCounts) &&
              (traffic.target.data.workloadCounts || traffic.target.data.containerWorkloadCounts)
            ) {
              rule.resolve_labels_as.providers = ['workloads', 'virtual_services'];
              rule.providers.push({usesVirtualServicesWorkloads: true});
            }

            if (
              (traffic.source.data.virtualServiceCounts || traffic.source.data.virtualServerCounts) &&
              !traffic.source.data.workloadCounts &&
              !traffic.source.data.containerWorkloadCounts
            ) {
              rule.resolve_labels_as.consumers = ['virtual_services'];
              rule.consumers.push({usesVirtualServices: true});
            }

            let exclusionService = {};

            if (
              (traffic.target.data.virtualServiceCounts || traffic.target.data.virtualServerCounts) &&
              !traffic.target.data.workloadCounts &&
              !traffic.target.data.containerWorkloadCounts
            ) {
              rule.resolve_labels_as.providers = ['virtual_services'];
              rule.providers.push({usesVirtualServices: true});
              rule.service = null;
              exclusionService = {
                // ICMP cannot be added to a rule as a port based rule, a service must be created
                services: matchedService || (serviceType === 'port' && !icmp ? portService : [newService]),
                newService: [newService],
                port: {[[connection.port, connection.protocol].join(',')]: buildPort(newService, connection)},
                match: matchedService,
                portService,
              };
            }

            if (
              traffic.target.data.workloadCounts ||
              traffic.target.data.containerWorkloadCounts ||
              (!traffic.target.data.virtualServiceCounts &&
                !traffic.target.data.virtualServiceCounts &&
                !traffic.target.data.workloadCounts)
            ) {
              // Service will be edited, port, newService, and match will stay as references
              // ICMP cannot be added to a rule as a port based rule, a service must be created
              rule.services = matchedService || (serviceType === 'port' && !icmp ? portService : [newService]);
              rule.newService = [newService];
              rule.port = {[[connection.port, connection.protocol].join(',')]: buildPort(newService, connection)};
              rule.match = matchedService;
              rule.portService = portService;
            }

            const key = getRuleKey(rule, null, true);
            const exclusionKey = getRuleKey({...rule, ...exclusionService}, null, null, true, isBroad);

            if (vulnerabilities && vulnerabilities.ruleVulnerabilities[key]) {
              rule.description = getVulnerabilityRuleNote(key, vulnerabilities);
            }

            if (
              !exclusionLookup[exclusionKey] &&
              (level === 'appgroup' ||
                (rule.providers.some(p => p.href || p.ip_list || (p.label && p.label.key === 'role')) &&
                  rule.consumers.some(c => c.href || c.ip_list || (c.label && c.label.key === 'role'))))
            ) {
              result[key] = rule;
            }
          }
        }
      });

      return result;
    }, {}),
  );
}

function buildService(connection, broadServices) {
  if (!connection) {
    return;
  }

  const {port, protocol, protocolNum, osType, processName, serviceName} = connection;

  if (osType === 'linux' || osType === 'unknown') {
    const exactMatch = ServiceStore.getServiceFromObject([port, protocolNum || protocol, null, null, 'all'].join(','));

    if (exactMatch && exactMatch.service_ports) {
      return [exactMatch];
    }

    if (broadServices) {
      const matching = ServiceUtils.matchConnectionWithService(connection, broadServices);

      if (matching.length) {
        return [matching[0]];
      }
    }

    return;
  }

  // Match windows services from most specific (service Name) to least specific (port/protocol)
  let exactMatch = ServiceStore.getServiceFromObject([port, protocol, processName, serviceName, 'windows'].join(','));

  if (!exactMatch) {
    exactMatch = ServiceStore.getServiceFromObject([null, null, processName, serviceName, 'windows'].join(','));
  }

  if (!exactMatch) {
    exactMatch = ServiceStore.getServiceFromObject([port, protocol, null, serviceName, 'windows'].join(','));
  }

  if (!exactMatch) {
    exactMatch = ServiceStore.getServiceFromObject([null, null, null, serviceName, 'windows'].join(','));
  }

  if (!exactMatch) {
    exactMatch = ServiceStore.getServiceFromObject([port, protocol, processName, null, 'windows'].join(','));
  }

  if (!exactMatch) {
    exactMatch = ServiceStore.getServiceFromObject([null, null, processName, null, 'windows'].join(','));
  }

  if (!exactMatch) {
    exactMatch = ServiceStore.getServiceFromObject([port, protocol, null, null, 'windows'].join(','));
  }

  if (!exactMatch) {
    exactMatch = ServiceStore.getServiceFromObject([port, protocol, null, null, 'all'].join(','));
  }

  if (exactMatch) {
    return [exactMatch];
  }

  if (broadServices) {
    const matching = ServiceUtils.matchConnectionWithService(connection, broadServices);

    if (matching.length) {
      return [matching[0]];
    }
  }
}

function buildPortService(connection) {
  if (!connection) {
    return;
  }

  const {port, protocol, protocolNum, osType, processName, serviceName} = connection;

  if (osType === 'windows') {
    return [
      {
        port,
        proto: protocol,
        processName,
        serviceName,
        name: `${ServiceUtils.getPort(connection) || ''} ${ServiceUtils.lookupProtocol(protocolNum || protocol)}`,
      },
    ];
  }

  return [
    {port, proto: protocol, name: `${ServiceUtils.getPort(connection) || ''} ${ServiceUtils.lookupProtocol(protocol)}`},
  ];
}

function buildNewService(connection) {
  if (!connection) {
    return;
  }

  const {port, protocolNum, osType, processName, serviceName} = connection;
  const protocol = protocolNum || connection.protocol;
  const proto = protocol;

  if (osType === 'windows') {
    // Only use the port and protocol, but create a windows service, so the process/service can be added later
    return {
      name: intl('PolicyGenerator.WindowsServiceName', {
        port: ServiceUtils.getPort({port, protocol}),
        protocol: ServiceUtils.lookupProtocol(protocol),
      }),
      description: intl('PolicyGenerator.ServiceAutoDescription', {processName, serviceName}),
      windows_services: ServiceUtils.isIcmp(protocol) ? [{protocol, proto}] : [{port, protocol, proto}],
    };
  }

  return {
    name: intl('PolicyGenerator.ServiceName', {
      port: ServiceUtils.getPort({port, protocol}),
      protocol: ServiceUtils.lookupProtocol(protocol),
    }),
    description: intl('PolicyGenerator.AutoDescription'),
    service_ports: ServiceUtils.isIcmp(protocol) ? [{protocol, proto}] : [{port, protocol, proto}],
  };
}

function buildPort(service, connection) {
  return (
    service.service_ports ||
    service.windows_services.map(service => ({
      ...service,
      processName: connection.processName,
      serviceName: connection.serviceName,
      osType: 'windows',
    }))
  );
}

export function getScopes(appGroups) {
  return appGroups.map(appGroup => {
    const labels = [appGroup.labels.map(label => ({label: {...label}}))]; // Get labels in the format to be used in the grid
    const scopes = RulesetUtils.getScopesWithLabelKeys(labels);

    return scopes.pop();
  });
}

function findEndpointLabels(endpoint, type) {
  return endpoint.filter(ep => (ep.label && type.includes(ep.label.key)) || (!ep.label && type.includes('other')));
}

function getEndpointKey(endpoint) {
  return endpoint
    .map(ep => getHref(ep))
    .sort()
    .join(',');
}

function getEndpointScopeKey(endpoint) {
  return findEndpointLabels(endpoint, ['app', 'env', 'loc'])
    .map(ep => ep.label.href)
    .sort()
    .join(',');
}

function getServicesKey(services) {
  return (
    services &&
    services
      .map(service =>
        (service.hasOwnProperty('port')
          ? [service.port, service.protocol || service.proto]
          : (service.service_ports || service.windows_services).map(item => Object.values(item).join(','))
        )
          .concat(service.windows_services ? 'windows' : 'all')
          .join(','),
      )
      .join(',')
  );
}

function getMergedServices(services, portService, newService) {
  const mergedServiceObj =
    services &&
    services.reduce((result, service, index) => {
      const key = getServiceKey(service);

      if (result[key]) {
        result[key].portService = null;
        result[key].newService = null;
      } else {
        result[key] = {};

        if (portService) {
          result[key].portService = portService[index];
          result[key].newService = newService[index];
          result[key].index = index;
        }
      }

      result[key].service = service;

      return result;
    }, {});

  return (
    mergedServiceObj &&
    Object.values(mergedServiceObj)
      .sort((a, b) => a.index - b.index)
      .reduce(
        (result, service) => {
          result.services.push(service.service);
          result.portService.push(service.portService);
          result.newService.push(service.newService);

          return result;
        },
        {services: [], portService: [], newService: []},
      )
  );
}

export function getMergedRules(rules, mergeType) {
  const ruleObject = rules.reduce((result, rule) => {
    // Merge rule if provider/service/and the app/env/loc labels in the consumer match
    let key = [getServicesKey(rule.services), getEndpointKey(rule.providers), getEndpointScopeKey(rule.consumers)].join(
      ',',
    );

    if (mergeType === 'services') {
      key = [getEndpointKey(rule.providers), getEndpointKey(rule.consumers)].join(',');
    }

    let resultRule = result[key];

    if (resultRule) {
      if (mergeType === 'services') {
        if (rule.ingress_services) {
          resultRule.ingress_services = [...rule.ingress_services, ...resultRule.ingress_services];
        }

        if (rule.services) {
          resultRule.services = [...rule.services, ...resultRule.services];
        }

        resultRule.portService = [...rule.portService, ...(resultRule.portService || [])];
        resultRule.newService = [...rule.newService, ...(resultRule.newService || [])];

        if (rule.port) {
          resultRule.port = [...Object.values(rule.port)].reduce((resultPort, port) => {
            const key = [ServiceUtils.getPort(port[0]), port[0].protocol].join(',');

            if (resultPort[key]) {
              resultPort[key] = resultPort[key].concat(port);
            } else {
              resultPort[key] = port;
            }

            return resultPort;
          }, resultRule.port || {});
        }
      } else {
        resultRule.consumers = _.uniqBy(
          [...findEndpointLabels(rule.consumers, ['role']), ...resultRule.consumers],
          ep => getHref(ep),
        );
      }
    } else {
      resultRule = rule;
    }

    resultRule.key = getRuleKey(resultRule);
    result[key] = resultRule;

    return result;
  }, {});

  if (mergeType === 'services') {
    const maxServices = localStorage.getItem('services_per_rule') || 200;
    const mergedServicesRules = Object.values(ruleObject).map(rule => ({
      ...rule,
      ...getMergedServices(rule.services, rule.portService, rule.newService),
    }));

    const splitServiceRules = mergedServicesRules.reduce((splitRules, rule) => {
      // It is possible for a rule to not have services in the virtual services only case
      if (!rule.services || rule.services.length <= maxServices) {
        splitRules.push(rule);

        return splitRules;
      }

      const services = [...rule.services];
      const newService = [...rule.newService];
      const portService = [...rule.portService];
      let loopSafety = 0;

      while (services.length && loopSafety < 5000) {
        const nextPortServices = portService.splice(0, maxServices);

        splitRules.push({
          ...rule,
          services: services.splice(0, maxServices),
          newService: newService.splice(0, maxServices),
          portService: nextPortServices,
          port: nextPortServices.reduce((portResult, port) => {
            if (port) {
              const portKey = [port.port, port.proto].join(',');

              portResult[portKey] = rule.port && rule.port[portKey];
            }

            return portResult;
          }, {}),
        });

        loopSafety += 1;
      }

      return splitRules;
    }, []);

    return splitServiceRules;
  }

  return Object.values(ruleObject);
}

export function RemoveDuplicateRules(addRules, deleteRules) {
  const removeRules = [...deleteRules];
  const removalKeys = deleteRules.map(rule => getRuleKey(rule, true));

  // Remove duplicates from both arrays
  const rules = (addRules || []).filter(rule => {
    const removalIndex = removalKeys.indexOf(getRuleKey(rule, true, true));

    if (removalIndex > -1) {
      removeRules.splice(removalIndex, 1);
      removalKeys.splice(removalIndex, 1);

      return false;
    }

    return true;
  });

  return {rules, removeRules, numRules: addRules.length};
}

export function getServices(services, rules) {
  return Object.values(
    rules.reduce((result, rule, ruleIndex) => {
      Object.values(rule.services || {}).forEach((service, serviceIndex) => {
        if (!service.href && (service.service_ports || service.windows_services)) {
          const {port, protocol} = (service.service_ports || service.windows_services)[0];
          const os = service.service_ports ? 'linux' : 'windows';
          const key = [port, protocol, os].join(',');

          if (!result[key]) {
            result[key] = {
              service,
              // Keep track of applicable rules,
              // to inject the hrefs when the services are created.
              indexes: [],
            };
          }

          result[key].indexes.push({ruleIndex, serviceIndex});
        }
      });

      return result;
    }, services),
  );
}

function findFqdnIpList(fqdn) {
  return IpListStore.getAll().find(
    list => !list.ip_ranges.length && list.fqdns.length === 1 && list.fqdns[0].fqdn === fqdn,
  );
}

export function getIpLists(rules) {
  return Object.values(
    rules.reduce((result, rule, ruleIndex) => {
      Object.values(rule.providers || {}).forEach((provider, providerIndex) => {
        if (RenderUtils.isHrefFqdn(provider.ip_list && provider.ip_list.href)) {
          const fqdn = TrafficStore.getFqdn(provider.ip_list.href);
          const ipList = {name: fqdn, fqdns: [{fqdn}]};

          if (findFqdnIpList(fqdn)) {
            // If the IP List for that FQDN already exists, use that
            rules[ruleIndex].providers[providerIndex] = {ip_list: {...findFqdnIpList(fqdn), fqdn: true}};
          } else {
            if (!result[fqdn]) {
              result[fqdn] = {
                ipList,
                // Keep track of applicable rules,
                // to inject the hrefs when the services are created.
                indexes: [],
              };
            }

            result[fqdn].indexes.push({ruleIndex, providerIndex});
          }
        }
      });

      return result;
    }, {}),
  );
}

function getStrippedItem(item) {
  if (item.deleted) {
    return;
  }

  const itemKey = Object.keys(item)[0];

  if (item[itemKey].href) {
    return {[itemKey]: {href: item[itemKey].href}};
  }

  if (item.usesVirtualServicesWorkloads || item.usesVirtualServices) {
    return;
  }

  return item;
}

function getStrippedService(service) {
  if (!service) {
    return;
  }

  return service.href ? {href: service.href} : {port: service.port, proto: service.proto};
}

function getVesService(service) {
  if (!service) {
    return null;
  }

  if (service.href) {
    return {href: service.href};
  }

  const ports = service.service_ports || service.windows_services;

  return isNaN(service.port)
    ? {port: ports[0].port, proto: ports[0].protocol || ports[0].proto}
    : {port: service.port, proto: service.proto};
}

function getStrippedRule(rule) {
  return {
    consuming_security_principals: rule.consuming_security_principals,
    enabled: rule.enabled,
    sec_connect: rule.sec_connect,
    machine_auth: rule.machine_auth,
    resolve_labels_as: rule.resolve_labels_as,
    unscoped_consumers: rule.unscoped_consumers,
    network_type: rule.network_type,
    stateless: rule.stateless,
    consumers: _.compact(rule.consumers.map(item => getStrippedItem(item))),
    providers: _.compact(rule.providers.map(item => getStrippedItem(item))),
    ingress_services: _.compact(
      (rule.ingress_services || rule.services || [rule.service]).map(service => getStrippedService(service)),
    ),
    description: rule.description || '',
  };
}

function getStrippedRules(rules, force) {
  return rules.reduce((result, rule) => {
    if (
      (rule.href && !rule.href.includes('proposed') && !force && !rule.proposedUpdate) ||
      rule.hidden ||
      rule.proposedDelete ||
      rule.outOfScope
    ) {
      return result;
    }

    result.push(getStrippedRule(rule));

    return result;
  }, []);
}

function getStrippedDeletedRules(rules) {
  return rules.reduce((result, rule) => {
    if (!rule.href || (!rule.proposedUpdate && !rule.proposedDelete)) {
      return result;
    }

    result.push({href: rule.href});

    return result;
  }, []);
}

function getStrippedVesRules(rules) {
  return rules
    .filter(rule => !rule.providers[0].ip_list && (rule.ingress_services || rule.services || [rule.service])[0])
    .map(rule => ({
      unscoped_consumers: rule.unscoped_consumers,
      consumers: _.compact(rule.consumers.map(item => this.getStrippedItem(item))),
      providers: _.compact(rule.providers.map(item => this.getStrippedItem(item))),
      ingress_services: (rule.ingress_services || rule.services || [rule.service])
        .map(service => getVesService(service))
        .filter(service => !ServiceUtils.isIcmp(service.proto)),
    }));
}

function getStrippedRuleset(ruleset, create) {
  const strippedRuleset = {
    name: ruleset.name,
    description: ruleset.description,
    scopes: [
      ruleset.scopes[0].map(item => {
        if (item.label) {
          return {label: {href: item.label.href}};
        }

        if (item.label_group) {
          return {label_group: {href: item.label_group.href}};
        }

        return {};
      }),
    ],
    rules: this.getStrippedRules(ruleset.rules),
    ...(ruleset.ip_tables_rules || []),
    enabled: true,
    external_data_set: ruleset.external_data_set,
    external_data_reference: ruleset.external_data_reference,
    ...(create ? {} : {preserve_existing_rules: true}),
  };

  if (!strippedRuleset.description) {
    delete strippedRuleset.description;
  }

  return strippedRuleset;
}

function getServiceDetail(key, services, connection) {
  let service = services[key];

  if (service) {
    service.sessions += connection.sessions;
    service.class[connection.connectionClass] = true;
  } else {
    service = {
      port: connection.port,
      protocol: connection.protocol,
      service: connection.service.toLocaleLowerCase() === 'unknown' ? '' : connection.service,
      sessions: connection.sessions,
      timestamp: connection.timestamp,
      class: {[connection.connectionClass]: true},
    };
  }

  return service;
}

function getHref(endpoint) {
  return (
    endpoint.href ||
    (endpoint.ip_list && endpoint.ip_list.href) ||
    (endpoint.label && endpoint.label.href) ||
    (endpoint.workload && endpoint.workload.href) ||
    (endpoint.label_group && endpoint.label_group.href) ||
    'ams'
  );
}

function getAddresses(data, addresses, newAddresses) {
  if (data.ip_lists) {
    data.ip_lists.forEach(list => {
      addresses[list.href] = _.uniq([...(addresses[list.href] || []), ...(newAddresses || [])]);
    });
  }
}

export function getServicePortKey(service) {
  let ports;

  if (service.service_ports) {
    ports = service.service_ports[0];
  } else if (service.windows_services) {
    ports = service.windows_services[0];
  } else {
    ports = service;
  }

  if (!ports) {
    return;
  }

  const port = ports.port;
  const protocol = ports.protocol || ports.proto;

  return [ServiceUtils.getPort({port, protocol}), protocol].join(',');
}

export function getRuleKey(rule, noHref, noProcess, exclusion, isBroad) {
  if (rule.href && !noHref) {
    return rule.href;
  }

  const consumers = rule.consumers
    .filter(consumer => !consumer.usesVirtualServicesWorkloads && !consumer.usesVirtualServices)
    .map(
      consumer =>
        (consumer.label && consumer.label.href.split('/').pop()) ||
        (consumer.ip_list && (consumer.ip_list.href || consumer.ip_list).split('/').pop()) || ['ams'],
    )
    .sort((a, b) => (a > b ? 1 : a < b ? -1 : 0))
    .join('x');
  const providers = rule.providers
    .filter(providers => !providers.usesVirtualServicesWorkloads && !providers.usesVirtualServices)
    .map(
      providers =>
        (providers.label && providers.label.href.split('/').pop()) ||
        (providers.ip_list && (providers.ip_list.href || providers.ip_list).split('/').pop()) || ['ams'],
    )
    .sort((a, b) => (a > b ? 1 : a < b ? -1 : 0))
    .join('x');
  const service =
    (rule.services || rule.ingress_services) &&
    (rule.services || rule.ingress_services)
      .map(service => getServiceKey(service, rule.portService, noProcess, exclusion, isBroad))
      .sort((a, b) => (a > b ? 1 : a < b ? -1 : 0))
      .join('x');

  return [consumers, providers, service].join(',');
}

function getServiceKey(service, portService, noProcess, exclusion, isBroad) {
  // Handle ICMP specially because the broad ICMP forced, and cannot be written as a port
  if (service.href && (!isBroad || service.name === 'ICMP')) {
    // Using regex matching for better performance
    return service.href.match('[^/]+$')[0];
  }

  if (
    (service.service_ports && !(Array.isArray(service.service_ports) && service.service_ports.length)) ||
    (service.windows_services && !(Array.isArray(service.windows_services) && service.windows_services.length))
  ) {
    return 'port';
  }

  // For Broad services, to find the exclusion key we must use the portService,
  // otherwise the first port could be anything
  if (service.service_ports && (!exclusion || !isBroad)) {
    const details = service.service_ports[0];

    const port = details.port;
    const protocol = details.protocol || details.proto;

    return _.compact(['port', ServiceUtils.getPort({port, protocol}), protocol]).join('x');
  }

  let details = service.windows_services ? service.windows_services[0] : service;

  if (exclusion && isBroad && Array.isArray(portService) && portService.length) {
    details = portService[0];
  }

  const port = details.port;
  const protocol = details.protocol || details.proto;

  if (noProcess) {
    return _.compact([
      !exclusion && (service.windows_services || service.service_ports) ? 'service' : 'port',
      ServiceUtils.getPort({port, protocol}),
      protocol,
    ]).join('x');
  }

  return _.compact([
    !exclusion && (service.windows_services || service.service_ports) ? 'service' : 'port',
    ServiceUtils.getPort({port, protocol}),
    protocol,
    portService ? portService[0].processName : details.processName,
    portService ? portService[0].serviceName : details.serviceName,
  ]).join('x');
}

function getFilterKey(row) {
  const source = row.source.map(s => s.name || (s.ip_list && s.ip_list.name) || s.label.value).join(' ');
  const target = row.target.map(t => t.name || (t.ip_list && t.ip_list.name) || t.label.value).join(' ');
  const service = _.map(row.services, s =>
    [
      s.port,
      ServiceUtils.lookupProtocol(s.protocol),
      Object.keys(s.class).map(c => RenderUtils.getTransmissionMode(c)),
    ].join(' '),
  );
  const sourceAddresses = _.map(row.sourceAddresses, addresses => addresses.join(' ')).join(' ');
  const targetAddresses = _.map(row.targetAddresses, addresses => addresses.join(' ')).join(' ');

  return [source, target, service, sourceAddresses, targetAddresses].join(' ').toLocaleLowerCase();
}

function getConnectionDetail(link, data, level, rulesetId, extraScope, closedVulnerabilities) {
  const nodeVulnerabilities = TrafficStore.getNodeVulnerabilityByHref(link.target.data.href);
  const targetVulnerabilities = nodeVulnerabilities ? {...nodeVulnerabilities.instances} : null;

  let fqdnType = 'iplist';

  if (link.target.fqdnIpListMatch) {
    fqdnType = 'fqdnIpList';
  } else if (RenderUtils.isHrefFqdn(link.target.href)) {
    fqdnType = 'fqdn';
  }

  return _.reduce(
    link.connections,
    (result, connection) => {
      if (!connection.rules.length || connection.rules.every(rule => rule.split('/')[6] === rulesetId)) {
        const source = getTableLabels(link.source.data, extraScope);
        const target = getTableLabels(link.target.data);
        const connKey = [connection.port, connection.protocol, connection.service].join(',');
        const key = source.map(s => getHref(s)).join(',') + ',' + target.map(t => getHref(t)).join(',') + ',' + connKey;
        let addresses = (result[key] && result[key].addresses) || [];
        const href = link.href.replaceAll(/class_a|class_b|class_c/g, 'internet');

        if (!result[key]) {
          result[key] = {
            type: 'microsegmentation',
            services: {},
            sourceAddresses: {},
            targetAddresses: {},
            connectionKeys: {},
            serviceKeys: {},
            class: {},
            source,
            target,
            connections: 0,
            totalServices: 0,
            sessions: 0,
            fqdnType,
            missingConnections: 0,
            sessionsWithRules: 0,
            connectionsWithRules: 0,
            vulnerabilities: {},
          };
        }

        if (connection.sourceAddresses && connection.sourceAddresses.length) {
          getAddresses(link.source.data, result[key].sourceAddresses, connection.sourceAddresses);
          addresses = _.compact(_.uniq(addresses.concat(connection.sourceAddresses)));
        }

        if (connection.targetAddresses && connection.targetAddresses.length) {
          getAddresses(link.target.data, result[key].targetAddresses, connection.targetAddresses);
          addresses = _.compact(_.uniq(addresses.concat(connection.targetAddresses)));
        }

        const oldAddressCount = result[key].addresses ? result[key].addresses.length : 0;
        const newAddressCount = addresses.length - oldAddressCount;

        result[key].services[connKey] = getServiceDetail(connKey, result[key].services, connection);
        result[key].rule = buildRule(link, level, extraScope);
        result[key].rule.services = buildService(connection) || buildPortService(connection);
        result[key].rule.port = {
          [[connection.port, connection.protocol].join(',')]: buildPort(buildNewService(connection), connection),
        };
        result[key].key = getRuleKey(result[key].rule);
        result[key].class[connection.connectionClass] = true;
        result[key].filterKey = getFilterKey(result[key]);
        result[key].sessions += connection.sessions;
        result[key].connections += newAddressCount || 1;
        result[key].addresses = addresses;
        result[key].totalServices += result[key].serviceKeys[connection.key] ? 0 : 1;
        result[key].connectionKeys[[href, connection.key].join(',')] = connection.key;
        result[key].serviceKeys[connection.key] = connection.key;
        result[key].missingConnections += link.missingConnections;

        if (connection.rules.length) {
          result[key].sessionsWithRules += connection.sessions;
          result[key].connectionsWithRules += _.compact(addresses).length || 1;
        }

        const portProto = [connection.port, connection.protocol].join(',');

        if (targetVulnerabilities && targetVulnerabilities[portProto]) {
          result[key].vulnerabilities[[getHref(link.target.data), portProto].join(',')] =
            targetVulnerabilities[portProto];
          result[key].closedVulnerabilities = closedVulnerabilities;
        }
      }

      return result;
    },
    data,
  );
}

function getConnectionGroupDetail(link, data, level, extraScope) {
  const nodeVulnerabilities = TrafficStore.getNodeVulnerabilityByHref(link.target.data.href);
  const targetVulnerabilities = nodeVulnerabilities ? {...nodeVulnerabilities.instances} : null;
  const href = link.href.replaceAll(/class_a|class_b|class_c/g, 'internet');

  return _.reduce(
    link.connections,
    (result, connection, key) => {
      if (!connection.rules.length) {
        let addresses = (result[key] && result[key].addresses) || [];

        if (!result[key]) {
          result[key] = {
            type: 'microsegmentation',
            source: [],
            target: [],
            sourceAddresses: {},
            targetAddresses: {},
            services: {},
            connectionKeys: {},
            serviceKeys: {},
            totalServices: 0,
            connections: 0,
            sessions: 0,
            missingConnections: 0,
            vulnerabilities: {},
          };
        }

        result[key].source = _.uniqBy([...result[key].source, ...getTableLabels(link.source.data, extraScope)], s =>
          getHref(s),
        );
        result[key].target = _.uniqBy([...result[key].target, ...getTableLabels(link.target.data)], t => getHref(t));

        if (connection.sourceAddresses && connection.sourceAddresses.length) {
          getAddresses(link.source.data, result[key].sourceAddresses, connection.sourceAddresses);
          addresses = _.compact(_.uniq(addresses.concat(connection.sourceAddresses)));
        }

        if (connection.targetAddresses && connection.targetAddresses.length) {
          getAddresses(link.target.data, result[key].targetAddresses, connection.targetAddresses);
          addresses = _.compact(_.uniq(addresses.concat(connection.targetAddresses)));
        }

        const oldAddressCount = result[key].addresses ? result[key].addresses.length : 0;
        const newAddressCount = addresses.length - oldAddressCount;

        result[key].services[key] = getServiceDetail(key, result[key].services, connection);
        result[key].rule = buildRule(link, level, extraScope) || buildPortService(connection);
        result[key].rule.services = buildService(connection);
        result[key].rule.port = {
          [[connection.port, connection.protocol].join(',')]: buildPort(buildNewService(connection), connection),
        };
        result[key].key = getRuleKey(result[key].rule);
        result[key].filterKey = getFilterKey(result[key]);
        result[key].sessions += connection.sessions;
        result[key].connections += newAddressCount || 1;
        result[key].addresses = addresses;
        result[key].totalServices += result[key].serviceKeys[connection.key] ? 0 : 1;
        result[key].connectionKeys[[href, connection.key].join(',')] = connection.key;
        result[key].serviceKeys[connection.key] = connection.key;
        result[key].missingConnections += link.missingConnections;

        const portProto = [connection.port, connection.protocol].join(',');

        if (targetVulnerabilities && targetVulnerabilities[portProto]) {
          result[key].vulnerabilities[[getHref(link.target.data), portProto].join(',')] =
            targetVulnerabilities[portProto];
        }
      }

      return result;
    },
    data,
  );
}

function getTierDetail(link, data, rulesetId) {
  const target = link.target.data;
  const nodeVulnerabilities = TrafficStore.getNodeVulnerabilityByHref(target.href);
  const targetVulnerabilities = nodeVulnerabilities ? {...nodeVulnerabilities.instances} : null;
  const href = link.href.replaceAll(/class_a|class_b|class_c/g, 'internet');

  return _.reduce(
    link.connections,
    (result, connection) => {
      if (!connection.rules.length || connection.rules.every(rule => rule.split('/')[6] === rulesetId)) {
        const key = [connection.port, connection.protocol, connection.service].join(',');
        let addresses = (result && result.addresses) || [];

        if (connection.sourceAddresses && connection.sourceAddresses.length) {
          getAddresses(link.source.data, result.sourceAddresses, connection.sourceAddresses);
          addresses = _.compact(_.uniq(addresses.concat(connection.sourceAddresses)));
        }

        if (connection.targetAddresses && connection.targetAddresses.length) {
          getAddresses(link.target.data, result.targetAddresses, connection.targetAddresses);
          addresses = _.compact(_.uniq(addresses.concat(connection.targetAddresses)));
        }

        const oldAddressCount = result.addresses ? result.addresses.length : 0;
        const newAddressCount = addresses.length - oldAddressCount;

        result.connections += newAddressCount || 1;
        result.services[key] = getServiceDetail(key, result.services, connection);
        result.sessions += connection.sessions;
        result.totalServices += result.serviceKeys[connection.key] ? 0 : 1;
        result.connectionKeys[[href, connection.key].join(',')] = connection.key;
        result.serviceKeys[connection.key] = connection.key;
        result.missingConnections += link.missingConnections;

        if (connection.rules.length) {
          result.sessionsWithRules += connection.sessions;
          result.connectionsWithRules += _.compact(addresses).length || 1;
        }
      }

      result.vulnerabilities = getFilteredVulnerabilities(targetVulnerabilities, target, result.vulnerabilities);

      return result;
    },
    data,
  );
}

function getRingDetail(link, data, rulesetId, extraScope) {
  const target = link.target.data;
  const source = link.source.data;
  const nodeVulnerabilities = TrafficStore.getNodeVulnerabilityByHref(target.href);
  const targetVulnerabilities = nodeVulnerabilities ? {...nodeVulnerabilities.instances} : null;

  return _.reduce(
    link.connections,
    (result, connection) => {
      if (!connection.rules.length || connection.rules.every(rule => rule.split('/')[6] === rulesetId)) {
        const key = [connection.port, connection.protocol, connection.service].join(',');
        const connectionKey = [link.href, connection.key].join(',');
        let addresses = [];

        result.services[key] = getServiceDetail(key, result.services, connection);
        result.source = _.uniqBy([...result.source, ...getTableLabels(source, extraScope)], s => getHref(s));
        result.target = _.uniqBy([...result.target, ...getTableLabels(target)], t => getHref(t));

        if (
          link.source.type !== 'internet' &&
          !link.target.href.includes('internet') &&
          !link.target.href.includes('class')
        ) {
          getAddresses(source, result.sourceAddresses, connection.sourceAddresses);
          getAddresses(target, result.targetAddresses, connection.targetAddresses);
          addresses = _.uniq(addresses.concat(connection.sourceAddresses));
          addresses = _.uniq(addresses.concat(connection.targetAddresses));
        }

        result.sessions += connection.sessions;
        result.connections += result.connectionKeys[connectionKey] ? 0 : _.compact(addresses).length || 1;
        result.totalServices += result.serviceKeys[connection.key] ? 0 : 1;
        result.connectionKeys[connectionKey] = connectionKey;
        result.serviceKeys[connection.key] = connection.key;
        result.missingConnections += link.missingConnections;

        if (connection.rules.length) {
          result.sessionsWithRules += connection.sessions;
          result.connectionsWithRules += _.compact(addresses).length || 1;
        }
      }

      result.vulnerabilities = getFilteredVulnerabilities(targetVulnerabilities, target, result.vulnerabilities);

      return result;
    },
    data,
  );
}

// Ignore any vulnerability without a port and protocol
function getFilteredVulnerabilities(targetVulnerabilities, target, vulnerabilities) {
  return _.reduce(
    targetVulnerabilities,
    (result, vulnerabilities, key) => {
      if (key !== ',') {
        result[[getHref(target), key].join(',')] = vulnerabilities;
      }

      return result;
    },
    vulnerabilities,
  );
}

function getRoleLevelTableData(traffics, ruleType, rulesetId, extraScope, closedVulnerabilities = {}) {
  return Object.values(
    traffics.reduce((result, traffic) => {
      switch (ruleType) {
        case 'ringfencing':
        case 'psuedoRingfencing':
          const ringInitialData = _.isEmpty(result)
            ? {
                type: ruleType,
                services: {},
                source: [],
                target: [],
                sourceAddresses: {},
                targetAddresses: {},
                connectionKeys: {},
                serviceKeys: {},
                totalServices: 0,
                connections: 0,
                missingConnections: 0,
                sessions: 0,
                sessionsWithRules: 0,
                connectionsWithRules: 0,
                vulnerabilities: {},
              }
            : result.ring;

          result.ring = getRingDetail(traffic, ringInitialData, rulesetId, extraScope);
          result.ring.rule = buildRule(traffic, ruleType === 'ringfencing' ? 'ring' : 'psuedo', extraScope);
          result.ring.rule.services = [getAllServices()];
          result.ring.key = getRuleKey(result.ring.rule);
          result.ring.filterKey = getFilterKey(result.ring);

          if (!traffic.allServicesRule) {
            result.ring.connections += traffic.missingConnections;
          }

          if (!result.ring.sessions) {
            result = {};
          }

          break;

        case 'tiertotier':
          const source = getTableLabels(traffic.source.data, extraScope);
          const target = getTableLabels(traffic.target.data);
          let fqdnType = 'iplist';

          if (traffic.target.fqdnIpListMatch) {
            fqdnType = 'fqdnIpList';
          } else if (RenderUtils.isHrefFqdn(traffic.target.href.split('x')[0])) {
            fqdnType = 'fqdn';
          }

          const key = source.map(s => getHref(s)).join(',') + ',' + target.map(t => getHref(t)).join(',');
          const tierInitialData = result[key] || {
            type: 'tiertotier',
            services: {},
            source,
            target,
            fqdnType,
            sourceAddresses: {},
            targetAddresses: {},
            connectionKeys: {},
            serviceKeys: {},
            totalServices: 0,
            connections: 0,
            missingConnections: 0,
            sessions: 0,
            sessionsWithRules: 0,
            connectionsWithRules: 0,
            vulnerabilities: {},
          };

          result[key] = getTierDetail(traffic, tierInitialData, rulesetId);
          result[key].rule = buildRule(traffic, 'role', extraScope);
          result[key].rule.services = [getAllServices()];
          result[key].key = getRuleKey(result[key].rule);
          result[key].filterKey = getFilterKey(result[key]);
          result[key].connections += traffic.missingConnections;

          if (_.isEmpty(result[key].services)) {
            delete result[key];
          }

          break;

        case 'microsegmentation':
          result = getConnectionDetail(
            traffic,
            result,
            'role',
            rulesetId,
            extraScope,
            closedVulnerabilities[traffic.href],
          );
          break;
        //no default
      }

      return result;
    }, {}),
  );
}

function getAppGroupLevelTableData(traffics, ruleType, extraScope) {
  return Object.values(
    traffics.reduce((result, traffic) => {
      switch (ruleType) {
        case 'tiertotier':
          const tierInitialData = _.isEmpty(result)
            ? {
                type: 'tiertotier',
                services: {},
                source: [],
                target: [],
                sourceAddresses: {},
                targetAddresses: {},
                connectionKeys: {},
                totalServices: 0,
                connections: 0,
                missingConnections: 0,
                sessions: 0,
                vulnerabilities: {},
              }
            : result.ring;

          result.ring = getRingDetail(traffic, tierInitialData, 'appgroup', extraScope);
          result.ring.rule = buildRule(traffic, extraScope ? 'appgroup' : 'ring', extraScope);
          result.ring.rule.service = getAllServices();
          result.ring.key = getRuleKey(result.ring.rule);
          result.ring.filterKey = getFilterKey(result.ring);

          if (!traffic.allServicesRule) {
            result.ring.connections += traffic.missingConnections;
          }

          if (!result.ring.sessions) {
            result = {};
          }

          break;

        case 'microsegmentation':
          result = getConnectionGroupDetail(traffic, result, 'appgroup', extraScope);
          break;
        //no default
      }

      return result;
    }, {}),
  );
}

function getExtraTableData(trafficByGroup, ruleTypes, rulesetId, optimizeLevel) {
  return _.reduce(
    trafficByGroup,
    (result, groupTraffic, key) => {
      const {level, type} = ruleTypes;
      let appGroup = TrafficStore.getAppGroupNode(key);

      if (key === 'discovered') {
        appGroup = {name: 'discovered'};
      }

      if (type === 'auto' && appGroup) {
        result[appGroup.name] = getOptimizedRoleLevelTableData(groupTraffic, rulesetId, true, optimizeLevel);
      } else if (level === 'role' && appGroup) {
        result[appGroup.name] = getRoleLevelTableData(groupTraffic, type, rulesetId, true);
      } else if (appGroup) {
        result[appGroup.name] = getAppGroupLevelTableData(groupTraffic, type, rulesetId, true);
      }

      return result;
    },
    {},
  );
}

function getIpListTableData(traffic, ruleTypes, rulesetId, optimizeLevel) {
  const {level, type} = ruleTypes;

  if (type === 'auto') {
    return getOptimizedRoleLevelTableData(traffic, rulesetId, 'ipList', optimizeLevel);
  }

  if (level === 'role') {
    return getRoleLevelTableData(traffic, type, rulesetId, false);
  }

  return getAppGroupLevelTableData(traffic, type, rulesetId, false);
}

function getOptimizedRoleLevelTableData(traffics, rulesetId, workflow, optimizeLevel) {
  const {ringFenceLinks, tierToTierLinks, microsegmentationLinks, closedVulnerabilities} = optimizeVulnerabiltyTraffic(
    traffics,
    optimizeLevel,
    workflow,
  );

  return [
    ...getRoleLevelTableData(ringFenceLinks, 'psuedoRingfencing', rulesetId, workflow === 'extra'),
    ...getRoleLevelTableData(tierToTierLinks, 'tiertotier', rulesetId, workflow === 'extra'),
    ...getRoleLevelTableData(
      microsegmentationLinks,
      'microsegmentation',
      rulesetId,
      workflow === 'extra',
      closedVulnerabilities,
    ),
  ];
}

function isTrafficHrefInWorkflow(href, workflow) {
  const endpoints = href.split(',');
  const groups = endpoints.map(endpoint => endpoint.split('-'));

  if (workflow === 'intra') {
    // If every endpoint had a '-' and the groups before the '-' are the same
    // ie. axbxc-d,axbxc-e
    return groups.every(group => group.length === 2) && groups[0][0] === groups[1][0];
  }

  if (workflow === 'extra') {
    // If every endpoint had a '-' and the groups before the '-' are different
    // ie. axbxc-d,axbxg-h
    return groups.every(group => group.length === 2) && groups[0][0] !== groups[1][0];
  }

  // For IP Lists if one endpoint does not have a '-'
  // ie. class_a,axbxc-d
  return groups.some(group => group.length < 2);
}

// Decide how the rules are written based on vulnerabilities and the rules needed to expose them:
// If the target node has no vulnerabilities, all workloads to talk to that node.
// If all the vulnerabilities have traffic flowing to them, might as well open all the ports.
// If some of the vulnerabilities can be closed by writting micro-segmented rules, do that.
function optimizeVulnerabiltyTraffic(traffic, optimizeLevel, workflow) {
  const targetConsumingTrafficForWorkflow = {};

  return traffic.reduce(
    (result, link) => {
      if (link.target.type === 'ipList' || link.target.type === 'fqdn') {
        result.tierToTierLinks.push(link);
      } else if (link.target.data.consumingTraffic) {
        const vulnerabilities = TrafficStore.getNodeVulnerabilityByHref(link.target.data.href);
        const nodeConsumingTraffic = link.target.data.consumingTraffic.byPort;
        let consumingTrafficForWorkflow = targetConsumingTrafficForWorkflow[link.target.href];

        if (!consumingTrafficForWorkflow) {
          // Filter the traffic for this target to only the traffic applicable to this workflow
          consumingTrafficForWorkflow = Object.keys(nodeConsumingTraffic).reduce((result, portKey) => {
            const consumingTrafficForPort = nodeConsumingTraffic[portKey];

            // Find all the traffic to this port in the workflow
            const portTraffic = Object.keys(consumingTrafficForPort).reduce((portResult, trafficHref) => {
              if (isTrafficHrefInWorkflow(trafficHref, workflow)) {
                portResult[trafficHref] = {...consumingTrafficForPort[trafficHref]};
              }

              return portResult;
            }, {});

            // If anything was found, add it to the result
            if (Object.keys(portTraffic).length) {
              result[portKey] = portTraffic;
            }

            return result;
          }, {});
        }

        //Save this for the next time we are looking at this target in the loop
        targetConsumingTrafficForWorkflow[link.target.href] = consumingTrafficForWorkflow;

        // Ignore all vulnerabilities without a port/protocol and
        // all vulnerabilites lower than the optimizeLevel
        const filteredVunerabilities = vulnerabilities
          ? _.filter(
              vulnerabilities.instances,
              (vulerabilityPort, key) =>
                key !== ',' &&
                vulerabilityPort.some(v => RenderUtils.getVulnerabilityForRender(v.severity, 'key') >= optimizeLevel),
            ).flat()
          : [];

        if (!filteredVunerabilities.length && workflow !== 'extra') {
          // If the target node has no vulnerabilities, write a pseudo ring fence rule for this nod
          result.ringFenceLinks.push(link);
        } else if (
          !filteredVunerabilities.length ||
          filteredVunerabilities.every(v => consumingTrafficForWorkflow[[v.port, v.protocol].join(',')])
        ) {
          // If every vulnerability has traffic, then might as wel write r2r
          result.tierToTierLinks.push(link);
        } else {
          result.microsegmentationLinks.push(link);
          // Find all the vulnerabilites without traffic, which will be closed by writing microsegementation rules here
          result.closedVulnerabilities[link.href] = _.filter(
            filteredVunerabilities,
            v => !consumingTrafficForWorkflow[[v.port, v.protocol].join(',')],
          );
        }
      }

      return result;
    },
    {ringFenceLinks: [], tierToTierLinks: [], microsegmentationLinks: [], closedVulnerabilities: {}},
  );
}

function getExtraVulnerabilities(trafficByGroup, targetNodes) {
  return _.reduce(trafficByGroup, (result, groupTraffic) => getVulnerabilities(groupTraffic, targetNodes, result), 0);
}

// Calculate all the vulnerability data
function getVulnerabilities(traffic, targetNodes, aggregatedVulnerabilities) {
  // If we are aggregating per group, start with those values
  // But don't accumluate the counts, because we are going over the same target nodes over an over again.
  const vulnerabilities = {
    vulnerabilityInstances: aggregatedVulnerabilities ? aggregatedVulnerabilities.vulnerabilityInstances : {},
    ruleVulnerabilities: aggregatedVulnerabilities ? aggregatedVulnerabilities.ruleVulnerabilities : {},
    ruleClosedVulnerabilities: aggregatedVulnerabilities ? aggregatedVulnerabilities.ruleClosedVulnerabilities : {},
    counts: {1: 0, 2: 0, 3: 0, 4: 0, 5: 0},
  };

  // Walk through all the targetNodes and count the vulnerabilities
  // Create a vulnerability look up
  // vulnerabiliite.vulnerabilitiyIntances[target,port.protocol]: {rules: {List of rulekeys}, instances: [list of instance details]}
  // The rules will be used to see if every rule opening a vulnerability has been excluded
  // The instances have the details to list in the tooltip
  _.forOwn(targetNodes, node => {
    const nodeVulnerabilities = TrafficStore.getNodeVulnerabilityByHref(node);

    if (nodeVulnerabilities) {
      _.forOwn(nodeVulnerabilities.instances, (vulnerabilityPort, key) => {
        if (key !== ',') {
          if (!vulnerabilities.vulnerabilityInstances[[node, key].join(',')]) {
            vulnerabilities.vulnerabilityInstances[[node, key].join(',')] = {
              rules: [],
              instances: vulnerabilityPort,
            };
          }

          // All these vulnerabilities will be reduced because there is traffic on all the vulnerable ports
          vulnerabilities.counts = vulnerabilityPort.reduce((result, vulnerability) => {
            result[RenderUtils.getVulnerabilityForRender(vulnerability.severity, 'key')] += 1;

            return result;
          }, vulnerabilities.counts);
        }
      });
    }
  });

  // Walk through the table data.
  //  1. Store all the rules opening each vulnerability
  //  2. Store all the vulnerabilities opened by each rule
  //  3. Store all the closedVulnerabilities closed by each rule
  traffic.forEach(rule => {
    if (!_.isEmpty(rule.vulnerabilities)) {
      if (rule.key === getRuleKey(getRingFencingRule([])[0])) {
        _.forOwn(vulnerabilities.vulnerabilityInstances, instance => {
          instance.rules.push(rule.key);
        });
      } else {
        // Fill in the list of rulekeys per vulnerability instance
        _.forEach(rule.vulnerabilities, (vulnerabilityInstance, key) => {
          if (vulnerabilities.vulnerabilityInstances[key]) {
            vulnerabilities.vulnerabilityInstances[key].rules.push(rule.key);
          }
        });
      }

      // Save the set of vulnerabilities per ruleKey
      vulnerabilities.ruleVulnerabilities[rule.key] = rule.vulnerabilities;
      vulnerabilities.ruleClosedVulnerabilities[rule.key] = rule.closedVulnerabilities;
    }
  });

  return vulnerabilities;
}

export function parseIntraAppGroupTraffic(traffics, appGroup, ruleType, rulesetId, targetNodes, optimizeLevel) {
  const roles = {};
  const entities = {
    appGroup: TrafficStore.getAppGroupNode(appGroup.href) || appGroup,
    optimizeLevel,
    intraTraffic: [],
    intraTableTraffic: [],
    roles: {},
    targetNodes,
    vulnerabilities: {},
    counts: {
      intraConnections: 0,
      intraConnectionRules: 0,
      missingConnections: 0,
    },
  };

  if (!appGroup || !traffics || !traffics.length) {
    return entities;
  }

  const appGroupHref = appGroup.href;

  _.forOwn(traffics, traffic => {
    // Find traffic whose target matches the appGroup
    const sourceNode = TrafficStore.getNode(traffic.source.href);
    const targetNode = TrafficStore.getNode(traffic.target.href);

    if (
      sourceNode &&
      targetNode &&
      targetNode.appGroupParent === appGroupHref &&
      sourceNode.appGroupParent === appGroupHref
    ) {
      // Both Source and Target are in the appGroup
      roles[traffic.target.href] = traffic.target.href;

      const link = {...traffic};

      link.source.data = sourceNode;
      link.target.data = targetNode;

      const missing = _.max([0, link.serviceNum - link.totalConnections]);

      link.missingConnections = missing;

      entities.intraTraffic.push(link);

      entities.counts.intraConnections += link.totalConnections + missing;
      entities.counts.intraConnectionRules += link.totalConnectionRules;

      // If we are replacing the rules in this ruleset, subtract the connections covered by this ruleset from the rule count
      if (rulesetId && link.totalConnectionRulesPerRuleset[rulesetId]) {
        entities.counts.intraConnectionRules -= link.totalConnectionRulesPerRuleset[rulesetId];
      }

      entities.counts.missingConnections += missing;

      if (link.allServicesRule) {
        entities.counts.intraConnectionRules += missing;
      }
    }
  });

  entities.counts.intraConnectionsWorkingTotal =
    entities.counts.intraConnections - entities.counts.intraConnectionRules;

  if (ruleType === 'auto') {
    entities.intraTableTraffic = getOptimizedRoleLevelTableData(
      entities.intraTraffic,
      rulesetId,
      'intra',
      optimizeLevel,
    );
  } else {
    entities.intraTableTraffic = getRoleLevelTableData(entities.intraTraffic, ruleType, rulesetId, false);
  }

  entities.vulnerabilities = getVulnerabilities(entities.intraTableTraffic, entities.targetNodes);
  entities.roles = Object.values(roles);

  return entities;
}

export function parseExtraAppGroupTraffic(traffics, appGroup, rulesetId) {
  const roles = {};
  const connectedAppGroups = {};
  const entities = {
    extraTraffic: [],
    roles: {},
    connectedAppGroups: [],
    connectionCounts: {},
    counts: {
      extraConnections: 0,
      extraConnectionRules: 0,
      missingConnections: 0,
    },
  };
  const discoveredAppGroup = {
    href: 'missing',
    name: intl('PolicyGenerator.MissingLabels'),
    labels: [],
  };

  if (!appGroup || !traffics || !traffics.length) {
    return entities;
  }

  const appGroupHref = appGroup.href;

  _.forOwn(traffics, traffic => {
    // Find traffic whose target matches the appGroup
    const sourceNode = TrafficStore.getNode(traffic.source.href);
    const targetNode = TrafficStore.getNode(traffic.target.href);

    if (
      sourceNode &&
      targetNode &&
      targetNode.appGroupParent === appGroupHref &&
      sourceNode.appGroupParent !== appGroupHref
    ) {
      let sourceAppGroupParent = sourceNode.appGroupParent;

      if (!TrafficStore.getAppGroupNode(sourceAppGroupParent)) {
        sourceAppGroupParent = discoveredAppGroup.href;
      }

      // Need to get the full traffic for each traffic, so use the target node
      roles[traffic.target.href] = traffic.target.href;

      const link = {...traffic};

      link.source.data = sourceNode;
      link.target.data = targetNode;

      // Only the Target is in the AppGroup, the source is a valid node, so it's an inbound traffic
      const missing = _.max([0, link.serviceNum - link.totalConnections]);

      link.missingConnections = missing;

      entities.extraTraffic.push(link);
      entities.counts.extraConnectionRules += link.totalConnectionRules;

      let connectedAppGroup = connectedAppGroups[sourceAppGroupParent];

      if (!connectedAppGroup) {
        connectedAppGroup = connectedAppGroups[sourceAppGroupParent] = {
          appGroup: TrafficStore.getAppGroupNode(sourceAppGroupParent) || discoveredAppGroup,
          connections: 0,
          connectionRules: 0,
        };
      }

      connectedAppGroup.connections += link.totalConnections + missing;
      connectedAppGroup.connectionRules += link.totalConnectionRules;

      // If we are replacing the rules in this ruleset, subtract the connections covered by this ruleset from the rule count
      if (rulesetId && link.totalConnectionRulesPerRuleset[rulesetId]) {
        connectedAppGroup.connectionRulesReplace =
          connectedAppGroup.connectionRules - link.totalConnectionRulesPerRuleset[rulesetId];
      }

      if (link.allServicesRule) {
        connectedAppGroup.connectionRules += missing;
      }
    }
  });

  entities.roles = Object.values(roles);
  entities.connectedAppGroups = Object.values(connectedAppGroups);
  entities.connectionCounts = entities.connectedAppGroups.reduce((result, connected) => {
    result[connected.appGroup.href] = connected.connections;

    return result;
  }, {});

  return entities;
}

export function parseExtraAppGroupConfigTraffic(
  traffics,
  appGroup,
  selected,
  rulesetId,
  extraConfig,
  targetNodes,
  optimizeLevel,
) {
  const roles = {};
  const connectedAppGroups = {};
  const entities = {
    appGroup: TrafficStore.getAppGroupNode(appGroup.href),
    optimizeLevel,
    extraTraffic: [],
    extraTableTraffic: [],
    extraTrafficByGroup: {},
    roles: {},
    connectedAppGroups: [],
    vulnerabilities: {},
    targetNodes,
    counts: {
      extraConnections: 0,
      extraConnectionRules: 0,
      missingConnections: 0,
    },
  };
  const discoveredAppGroup = {
    href: 'missing',
    name: intl('PolicyGenerator.MissingLabels'),
    labels: [],
  };

  if (!appGroup || !traffics || !traffics.length) {
    return entities;
  }

  const appGroupHref = appGroup.href;

  _.forOwn(traffics, traffic => {
    // Find traffic whose target matches the appGroup
    const sourceNode = TrafficStore.getNode(traffic.source.href);
    const targetNode = TrafficStore.getNode(traffic.target.href);

    if (
      sourceNode &&
      targetNode &&
      targetNode.appGroupParent === appGroupHref &&
      sourceNode.appGroupParent !== appGroupHref
    ) {
      let sourceAppGroupParent = sourceNode.appGroupParent;

      if (!TrafficStore.getAppGroupNode(sourceAppGroupParent)) {
        sourceAppGroupParent = discoveredAppGroup.href;
      }

      // Need to get the full traffic for each traffic, so use the target node
      roles[traffic.target.href] = traffic.target.href;

      if (traffic.totalConnections) {
        const link = {...traffic};

        link.source.data = sourceNode;
        link.target.data = targetNode;

        // Only the Target is in the AppGroup, the source is a valid node, so it's an inbound traffic
        if (selected.includes(sourceAppGroupParent)) {
          const missing = _.max([0, link.serviceNum - link.totalConnections]);

          link.missingConnections = missing;
          entities.extraTraffic.push(link);

          if (!entities.extraTrafficByGroup[sourceAppGroupParent]) {
            entities.extraTrafficByGroup[sourceAppGroupParent] = [];
          }

          entities.extraTrafficByGroup[sourceAppGroupParent].push(link);

          let connectedAppGroup = connectedAppGroups[sourceAppGroupParent];

          if (!connectedAppGroup) {
            connectedAppGroup = connectedAppGroups[sourceAppGroupParent] = {
              appGroup: TrafficStore.getAppGroupNode(sourceAppGroupParent) || discoveredAppGroup,
              connections: 0,
              connectionRules: 0,
            };
          }

          entities.counts.extraConnections += link.totalConnections + missing;
          entities.counts.extraConnectionRules += link.totalConnectionRules;
          entities.counts.missingConnections += missing;
          connectedAppGroup.connections += link.totalConnections + missing;
          connectedAppGroup.connectionRules += link.totalConnectionRules;

          // If we are replacing the rules in this ruleset, subtract the connections covered by this ruleset from the rule count
          if (rulesetId && link.totalConnectionRulesPerRuleset[rulesetId]) {
            entities.counts.extraConnectionRules -= link.totalConnectionRulesPerRuleset[rulesetId];
          }

          if (link.allServicesRule) {
            entities.counts.extraConnectionRules += missing;
            connectedAppGroup.connectionRules += missing;
          }
        }
      }
    }
  });

  if (!_.isEmpty(entities.extraTrafficByGroup)) {
    entities.extraTableTraffic = getExtraTableData(entities.extraTrafficByGroup, extraConfig, rulesetId, optimizeLevel);
    entities.vulnerabilities = getExtraVulnerabilities(entities.extraTableTraffic, entities.targetNodes);
  }

  entities.roles = Object.values(roles);
  entities.counts.extraConnectionsWorkingTotal =
    entities.counts.extraConnections - entities.counts.extraConnectionRules;
  entities.connectedAppGroups = connectedAppGroups;

  return entities;
}

export function parseIpListTraffic(traffics, appGroup) {
  const roles = {};
  const ipLists = {};
  let ipListData;
  const entities = {
    ipListTraffic: [],
    roles: {},
    ipLists: [],
  };

  if (!appGroup || !traffics || !traffics.length) {
    return entities;
  }

  const appGroupHref = appGroup.href;
  const scopedUser = !SessionStore.isGlobalEditEnabled();

  _.forOwn(traffics, traffic => {
    let fqdnType = 'iplist';
    let sourceIpLists = [];
    let targetIpLists = [];
    // Find traffic whose target matches the appGroup
    const sourceNode = TrafficStore.getNode(traffic.source.href);
    const targetNode = TrafficStore.getNode(traffic.target.href);

    if (traffic.source.href.includes('ip_list')) {
      sourceIpLists = _.compact(traffic.source.href.split('x').map(list => IpListStore.getSpecified(list)));
    }

    if (traffic.target.href.includes('ip_list')) {
      targetIpLists = _.compact(
        traffic.target.href
          .split('x')
          .map(list =>
            !scopedUser && RenderUtils.isHrefFqdn(list)
              ? {href: list, name: TrafficStore.getFqdn(list)}
              : IpListStore.getSpecified(list),
          ),
      );

      if (traffic.target.fqdnIpListMatch) {
        fqdnType = 'fqdnIpList';
      }
    }

    if (traffic.source.type === 'internet') {
      sourceIpLists = IpListStore.getAnyIpList() ? [IpListStore.getAnyIpList()] : [];
    }

    if (traffic.target.href.includes('internet') || traffic.target.href.includes('class')) {
      targetIpLists = _.compact(
        traffic.target.href
          .split('x')
          .map(list =>
            !scopedUser && RenderUtils.isHrefFqdn(list)
              ? {href: list, name: TrafficStore.getFqdn(list)}
              : (scopedUser || list !== 'fqdn') && IpListStore.getAnyIpList() && IpListStore.getAnyIpList(),
          ),
      );
    }

    if (
      (sourceNode && sourceNode.appGroupParent === appGroupHref && targetIpLists.length) ||
      (targetNode && targetNode.appGroupParent === appGroupHref && sourceIpLists.length)
    ) {
      if (targetIpLists.length) {
        roles[traffic.source.href] = traffic.source.href;
        ipListData = targetIpLists;
      } else {
        roles[traffic.target.href] = traffic.target.href;
        ipListData = sourceIpLists;
      }

      ipListData.forEach(list => {
        const link = {...traffic};

        link.source.data = sourceNode || list;
        link.target.data = targetNode || list;
        entities.ipListTraffic.push(link);

        const direction = targetIpLists.length ? intl('Common.Outbound') : intl('Common.Inbound');
        const href = [list.href, direction].join('x');
        let ipList = ipLists[href];

        if (!ipList) {
          if (IpListStore.getAnyIpList() && href.split('x')[0] === IpListStore.getAnyIpList().href) {
            fqdnType = 'iplist';
          }

          ipList = ipLists[href] = {
            href,
            direction,
            fqdnType,
            ipList: list,
            connections: 0,
            connectionRules: 0,
            missingConnections: 0,
            connectionKeys: {},
          };
        }

        let missing = _.max([0, link.serviceNum - link.totalAddressConnections]);
        const totalConnections = link.totalAddressConnections;
        const totalAddressRules = link.totalAddressRules;

        // Merge the class_a, class_b, class_c and internet link counts
        // Assumption that the rules will be the same for any of these for the same other end and port
        if (traffic.source.type === 'internet' || traffic.target.type === 'internet') {
          const linkKey =
            traffic.source.type === 'internet'
              ? ['internet', traffic.target.href].join(',')
              : [traffic.source.href, 'internet'].join(',');

          Object.values(link.connections).forEach(connection => {
            if (!ipList.connectionKeys[linkKey]) {
              ipList.connectionKeys[linkKey] = {[connection.key]: connection};
            } else if (!ipList.connectionKeys[linkKey][connection.key]) {
              ipList.connectionKeys[linkKey][connection.key] = connection;
            }
          });

          missing = _.max([0, link.serviceNum - totalConnections]);
        }

        ipList.connections += totalConnections + missing;
        ipList.connectionRules += totalAddressRules;

        if (link.allServicesRule) {
          ipList.connectionRules += missing;
        } else {
          ipList.missingConnections += missing;
        }
      });
    }
  });

  entities.roles = Object.values(roles);
  entities.ipLists = Object.values(ipLists);

  return entities;
}

export function parseIpListConfigTraffic(
  traffics,
  appGroup,
  selected,
  rulesetId,
  ipListConfig,
  targetNodes,
  optimizeLevel,
) {
  const roles = {};
  const entities = {
    appGroup: TrafficStore.getAppGroupNode(appGroup.href),
    optimizeLevel,
    ipListTraffic: [],
    ipListTableTraffic: [],
    targetNodes,
    vulnerabilities: {},
    connectionKeys: {},
    roles: {},
    counts: {
      vulnerabilities: 0,
      ipListConnections: 0,
      ipListConnectionRules: 0,
      missingConnections: 0,
    },
  };

  const lists = selected.map(select => ({
    list: select.split('x').shift(),
    direction: select.split('x').pop(),
  }));

  const anyList = IpListStore.getAnyIpList();

  if (!appGroup || !traffics || !traffics.length) {
    return entities;
  }

  const appGroupHref = appGroup.href;
  const scopedUser = !SessionStore.isGlobalEditEnabled();

  _.forOwn(traffics, traffic => {
    const sourceIpList = {};
    const targetIpList = {};
    // Find traffic whose target matches the appGroup
    const sourceNode = TrafficStore.getNode(traffic.source.href);
    const targetNode = TrafficStore.getNode(traffic.target.href);

    if (traffic.source.href.includes('ip_list')) {
      sourceIpList.ip_lists = _.compact(
        lists
          .filter(
            list => list.direction === intl('Common.Inbound') && traffic.source.href.split('x').includes(list.list),
          )
          .map(list => IpListStore.getSpecified(list.list)),
      );
    }

    if (traffic.target.href.includes('ip_list')) {
      targetIpList.ip_lists = _.compact(
        lists
          .filter(
            list => list.direction === intl('Common.Outbound') && traffic.target.href.split('x').includes(list.list),
          )
          .map(list =>
            !scopedUser && RenderUtils.isHrefFqdn(list.list)
              ? {href: list.list, name: TrafficStore.getFqdn(list.list)}
              : IpListStore.getSpecified(list.list),
          ),
      );
    }

    if (traffic.source.type === 'internet') {
      sourceIpList.ip_lists = lists
        .filter(list => list.direction === intl('Common.Inbound') && anyList && list.list === anyList.href)
        .map(() => anyList);
    }

    if (traffic.target.href.includes('internet') || traffic.target.href.includes('class')) {
      targetIpList.ip_lists = _.compact(
        lists
          .filter(
            list =>
              list.direction === intl('Common.Outbound') &&
              (traffic.target.href.split('x').includes(list.list) || (anyList && list.list === anyList.href)),
          )
          .map(list =>
            !scopedUser && RenderUtils.isHrefFqdn(list.list)
              ? {href: list.list, name: TrafficStore.getFqdn(list.list)}
              : (scopedUser || list.list !== 'fqdn') && anyList,
          ),
      );
    }

    if (
      (sourceNode &&
        sourceNode.appGroupParent === appGroupHref &&
        targetIpList.ip_lists &&
        targetIpList.ip_lists.length) ||
      (targetNode &&
        targetNode.appGroupParent === appGroupHref &&
        sourceIpList.ip_lists &&
        sourceIpList.ip_lists.length)
    ) {
      if (targetIpList.ip_lists && targetIpList.ip_lists.length) {
        roles[traffic.source.href] = traffic.source.href;
      } else {
        roles[traffic.target.href] = traffic.target.href;
      }

      const link = {...traffic};
      let missing = _.max([0, link.serviceNum - link.totalAddressConnections]);

      link.source.data = sourceNode || sourceIpList;
      link.target.data = targetNode || targetIpList;

      entities.ipListTraffic.push(link);

      let totalConnections = link.totalAddressConnections;

      // Merge the class_a, class_b, class_c and internet link counts
      // Assumption that the rules will be the same for any of these for the same other end and port
      if (traffic.source.type === 'internet' || traffic.target.type === 'internet') {
        const linkKey =
          traffic.source.type === 'internet'
            ? ['internet', traffic.target.href].join(',')
            : [traffic.source.href, 'internet'].join(',');

        totalConnections = 0;

        Object.values(link.connections).forEach(connection => {
          if (!entities.connectionKeys[linkKey]) {
            entities.connectionKeys[linkKey] = {[connection.key]: connection};
            totalConnections = 1;
          } else if (!entities.connectionKeys[linkKey][connection.key]) {
            entities.connectionKeys[linkKey][connection.key] = connection;
            totalConnections += 1;
          } else {
            totalConnections += 1;
          }
        });

        missing = _.max([0, link.serviceNum - totalConnections]);
      }

      link.missingConnections = missing;
      entities.counts.ipListConnections += totalConnections + missing;
      entities.counts.ipListConnectionRules += link.totalAddressRules;

      // If we are replacing the rules in this ruleset, subtract the connections covered by this ruleset from the rule count
      if (rulesetId && link.totalAddressRulesPerRuleset[rulesetId]) {
        entities.counts.ipListConnectionRules -= link.totalAddressRulesPerRuleset[rulesetId];
      }

      if (link.allServicesRule) {
        entities.counts.ipListConnectionRules += missing;
      } else {
        entities.counts.missingConnections += missing;
      }
    }
  });

  entities.ipListTableTraffic = getIpListTableData(entities.ipListTraffic, ipListConfig, rulesetId, optimizeLevel);
  entities.vulnerabilities = getVulnerabilities(entities.ipListTableTraffic, entities.targetNodes);
  entities.roles = Object.values(roles);
  entities.counts.ipListConnectionsWorkingTotal =
    entities.counts.ipListConnections - entities.counts.ipListConnectionRules;

  return entities;
}

export function getTrafficTypes(traffics, appGroupHref) {
  return traffics.reduce(
    (result, traffic) => {
      const sourceNode = TrafficStore.getNode(traffic.source.href);
      const targetNode = TrafficStore.getNode(traffic.target.href);
      let otherNode;
      let otherNodeDirection;

      if (sourceNode && sourceNode.appGroupParent === appGroupHref) {
        otherNode = targetNode;
        otherNodeDirection = 'target';
      } else if (targetNode && targetNode.appGroupParent === appGroupHref) {
        otherNode = sourceNode;
        otherNodeDirection = 'source';
      }

      if (otherNode && otherNode.appGroupParent === appGroupHref) {
        result.intra = true;
      } else if (otherNode && otherNode.href === sourceNode.href && otherNode.appGroupParent) {
        //Only look for consuming extrascope traffic
        result.extra = true;
      } else if (
        otherNodeDirection &&
        RenderUtils.isInternetIpList(otherNodeDirection === 'source' ? traffic.source : traffic.target)
      ) {
        result.ipList = true;
      }

      return result;
    },
    {intra: false, extra: false, ipList: false},
  );
}

export default {
  getTransmissionFilters,
  getTransmissionFilterString,
  calculateRuleCoverage,
  calculateClosedVulnerabilities,
  calculateExtraRuleCoverage,
  truncateProviderConsumerLink,
  getRoleVulnerabilities,
  buildNewService,
  buildPortService,
  buildService,
  buildPort,
  getAllServices,
  getOptimizedRules,
  getRingFencingRule,
  getAllServicesRules,
  getMicroSegmentedRules,
  getMergedRules,
  RemoveDuplicateRules,
  getEndpointKey,
  getEndpointScopeKey,
  findEndpointLabels,
  getRuleKey,
  getScopes,
  getServicePortKey,
  getStrippedItem,
  getStrippedRules,
  getStrippedRuleset,
  getStrippedVesRules,
  getStrippedDeletedRules,
  parseIntraAppGroupTraffic,
  parseExtraAppGroupTraffic,
  parseExtraAppGroupConfigTraffic,
  getExtraConnectionsGridTitle,
  getExtraVulnerabilities,
  getVulnerabilities,
  parseIpListTraffic,
  parseIpListConfigTraffic,
  getRoleLevelTableData,
  getOptimizedRoleLevelTableData,
  getExtraTableData,
  getIpListTableData,
  getServices,
  getIpLists,
  getTrafficTypes,
  getPolicyGeneratorId,
};
