/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {Component, PropTypes} from 'react';
import {Checkbox, Dialog} from '../..';
import actionCreators from '../../../actions/actionCreators';
import {RenderUtils} from '../../../utils';

export default class CreateRulesetModal extends Component {
  static propTypes = {
    labels: PropTypes.array,
    name: PropTypes.string,
    onConfirm: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handleUseScope = this.handleUseScope.bind(this);

    this.state = {rulesetName: props.name, useScope: false};
  }

  getActions() {
    return [
      {
        text: intl('Common.Cancel'),
        tid: 'cancel',
        type: 'nofill',
        onClick: () => {
          this.handleClose();
        },
      },
      {
        text: intl('Common.Continue'),
        disabled: !this.state || !this.state.rulesetName,
        tid: 'continue',
        autoFocus: true,
        onClick: () => {
          this.handleClose();
          this.handleConfirm();
        },
      },
    ];
  }

  handleClose() {
    actionCreators.closeDialog();
  }

  handleConfirm() {
    const {rulesetName, rulesetDescription, useScope} = this.state;
    const {forceScope, scopes} = this.props;

    this.props.onConfirm(rulesetName, rulesetDescription || '', (forceScope || useScope) && scopes);
  }

  handleNameChange(evt) {
    this.setState({rulesetName: evt.target.value});
  }

  handleDescriptionChange(evt) {
    this.setState({rulesetDescription: evt.target.value});
  }

  handleUseScope() {
    this.setState({useScope: !this.state.useScope});
  }

  render() {
    const {onCancel, scopes, forceScope} = this.props;
    const {useScope} = this.state;

    return (
      <Dialog
        ref="dialog"
        className="CreateRulesetDialog"
        type="form"
        title={intl('Map.CreateRuleset')}
        actions={this.getActions()}
        onClose={onCancel}
        tid="create-ruleset-dialog"
      >
        <div className="FormPanel-CreateRuleset">
          <input
            type="text"
            ref="nameInput"
            placeholder={intl('Rulesets.CreatePage.Placeholder.RulesetName')}
            onChange={this.handleNameChange}
            className="FormInput"
            value={this.state ? this.state.rulesetName : ''}
            autoFocus={true}
          />
        </div>
        <div className="FormPanel-CreateRuleset">
          <textarea
            ref="descriptionInput"
            placeholder={intl('Rulesets.CreatePage.Placeholder.RulesetDescription')}
            onChange={this.handleDescriptionChange}
            className="FormInput"
            value={this.state ? this.state.rulesetDescription : ''}
          />
        </div>
        {!forceScope && scopes && Object.keys(scopes).length ? (
          <Checkbox checked={useScope} onChange={this.handleUseScope} label={intl('PolicyGenerator.AddScopes')} />
        ) : null}
        {forceScope || (useScope && Object.keys(scopes).length) ? (
          <div className="FormPanel-CreateRuleset">
            {forceScope ? <div className="MapInfoPanel-Row-Label">{intl('Common.Scopes')}</div> : null}
            {RenderUtils.renderScopeLabels(Object.values(scopes))}
          </div>
        ) : null}
      </Dialog>
    );
  }
}
