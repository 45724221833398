/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import React, {PropTypes} from 'react';
import Slider from 'react-rangeslider';

export default React.createClass({
  propTypes: {
    time: PropTypes.shape({
      hour: PropTypes.number.isRequired,
      minute: PropTypes.number.isRequired,
    }).isRequired,
    onChange: PropTypes.func.isRequired,
    type: PropTypes.oneOf(['from', 'to']).isRequired,
  },

  getInitialState() {
    const {hour, minute} = this.props.time;
    const hourStr = hour.toString().padStart(2, '0');
    const minuteStr = minute.toString().padStart(2, '0');

    return {
      hourTextValue: hourStr,
      minuteTextValue: minuteStr,
    };
  },

  componentWillReceiveProps(nextProps) {
    const {
      time: {hour, minute},
      type: nextType,
    } = nextProps;
    const {type} = this.props;

    if (nextType !== type) {
      const hourStr = hour.toString().padStart(2, '0');
      const minuteStr = minute.toString().padStart(2, '0');

      this.setState({
        hourTextValue: hourStr,
        minuteTextValue: minuteStr,
      });
    }
  },

  handleHourRangeChange(val) {
    const hour = Number(val);

    this.setState({hourTextValue: hour.toString().padStart(2, '0')});
    this.props.onChange({...this.props.time, hour}, this.props.type);
  },

  handleHourTextChange(evt) {
    const evtValue = evt.target.value;
    const intValue = Number(evtValue);

    if (isNaN(intValue) || evtValue.length > 2 || intValue > 23) {
      return;
    }

    if (intValue < 0) {
      this.setState({hourTextValue: '0'});
      this.props.onChange({...this.props.time, hour: 0}, this.props.type);
    } else {
      this.setState({hourTextValue: evtValue});
      this.props.onChange({...this.props.time, hour: intValue}, this.props.type);
    }
  },

  handleHourKeyDown(evt) {
    const hour = this.props.time.hour;
    const up = evt.key === 'ArrowUp';
    const down = evt.key === 'ArrowDown';

    if (evt.key === 'Enter') {
      this.setState({hourTextValue: this.state.hourTextValue.padStart(2, '0')});
    } else if ((up && hour < 23) || (down && hour > 0)) {
      const hourChange = up ? 1 : -1;

      this.setState({hourTextValue: (hour + hourChange).toString().padStart(2, '0')});
      this.props.onChange({...this.props.time, hour: hour + hourChange}, this.props.type);
    }
  },

  handleHourBlur() {
    this.setState({hourTextValue: this.state.hourTextValue.padStart(2, '0')});
  },

  handleMinuteRangeChange(val) {
    const stepSize = 5;
    const modValue = val % stepSize;
    const roundedValue = modValue < 3 ? val - modValue : val + (5 - modValue);
    // don't allow user to set minute to 60
    const minute = roundedValue === 60 ? 59 : roundedValue;

    this.setState({minuteTextValue: minute.toString().padStart(2, '0')});
    this.props.onChange({...this.props.time, minute}, this.props.type);
  },

  handleMinuteBlur() {
    this.setState({minuteTextValue: this.state.minuteTextValue.padStart(2, '0')});
  },

  handleMinuteTextChange(evt) {
    const evtValue = evt.target.value;
    const intValue = Number(evtValue);

    if (isNaN(intValue) || evtValue.length > 2 || intValue > 59) {
      return;
    }

    if (intValue < 0) {
      this.setState({minuteTextValue: '0'});
      this.props.onChange({...this.props.time, minute: 0}, this.props.type);
    } else {
      this.setState({minuteTextValue: evtValue});
      this.props.onChange({...this.props.time, minute: intValue}, this.props.type);
    }
  },

  handleMinuteKeyDown(evt) {
    const minute = this.props.time.minute;
    const up = evt.key === 'ArrowUp';
    const down = evt.key === 'ArrowDown';

    if (evt.key === 'Enter') {
      this.setState({minuteTextValue: this.state.minuteTextValue.padStart(2, '0')});
    } else if ((up && minute < 59) || (down && minute > 0)) {
      const minuteChange = up ? 1 : -1;

      this.setState({minuteTextValue: (minute + minuteChange).toString().padStart(2, '0')});
      this.props.onChange({...this.props.time, minute: minute + minuteChange}, this.props.type);
    }
  },

  render() {
    const {hour, minute} = this.props.time;

    return (
      <div className="DTPickerClock">
        <div className="DTPickerClock-TextField">
          <input
            type="text"
            value={this.state.hourTextValue}
            onChange={this.handleHourTextChange}
            onKeyDown={this.handleHourKeyDown}
            onBlur={this.handleHourBlur}
          />
          <span>:</span>
          <input
            type="text"
            value={this.state.minuteTextValue}
            onChange={this.handleMinuteTextChange}
            onKeyDown={this.handleMinuteKeyDown}
            onBlur={this.handleMinuteBlur}
          />
        </div>
        <div className="DTPickerClock-RangeField">
          <span>{intl('Common.Hours')}</span>
          <Slider
            min={0}
            max={23}
            tooltip={false}
            value={hour}
            onClick={this.handleClick}
            onChange={this.handleHourRangeChange}
          />
        </div>
        <div className="DTPickerClock-RangeField">
          <span>{intl('Common.Minutes')}</span>
          <Slider
            min={0}
            max={59}
            tooltip={false}
            value={minute}
            onClick={this.handleClick}
            onChange={this.handleMinuteRangeChange}
          />
        </div>
      </div>
    );
  },
});
