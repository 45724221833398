/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {Address4, Address6} from 'ip-address';
import {addMethod, string, type StringSchema} from 'yup';
import {isPositiveInteger} from 'utils/general';
import {isValidPort, portRegex} from 'utils/port';
import {Map as IMap, OrderedMap} from 'immutable';
import * as PropTypes from 'prop-types';
import {mutuallyExclusiveTruePropsSpread} from 'utils/react';
import type {BigInteger} from 'jsbn';
import type {typesUtils} from 'utils';
import _ from 'lodash';
import type {ContentBlock} from 'draft-js';

const parsedWithoutBrnCache = new Map();
const parsedWithBrnCache = new Map();
const parsedNoValidationCache = new Map();

const subnetMaskRegex = '/(\\d+|\\w+)';

export type IP_Address = string | undefined;
export type IP_Domain = string;
export type IP_Values = string;
export type IP_Type = string | number | undefined;
export type IP = string;
export interface IP_ValidDomainList {
  valid: string[];
  invalid: string[];
}

/** Nominal Types */
/** IP is branded to indicate validity */
export type IP_IP = string & {_brand: 'ip'};
export type IPv4BroadcastAddress = string & {_brand: 'broadcastAddress'};
export type ValidFQDN = string & {_brand: 'fqdn'};
export type IPv4ClassE = string & {_brand: 'experimental'};
export type ValidIPV4 = string & {_brand: 'ipv4'};
export type ValidIPV6 = string & {_brand: 'ipv6'};
export type ValidDomain = string & {_brand: 'domain'};

export interface NetworkList {
  name: string;
  data_center: string;
}

export interface IpParts {
  port: number | null | string;
  ip: string | undefined | null;
  subnetMask: string | null;
}

export interface LineWithBrn {
  line: string;
  networkList: NetworkList;
}

export interface ReturnLineWithBrn {
  brnObjs?: LineWithBrn['networkList'][];
  ip: IP;
}

export type IPHelperAddress = Address4 | Address6;

export interface DuplicateCounts {
  ipText: string;
  duplicateCounts: IMap<string, IMap<string, number>>;
  blockKey?: string;
}

/**
 * Set the proper IP objects when parsing ip information
 * Note: ipstring holds the original value
 *       ip is used as a place holder to hold the original value or be reassigned if it is a cidr block
 */
export interface IPObject {
  helper?: IPHelperAddress;
  ip4?: boolean;
  ip6?: boolean;
  version?: number;
  cidr?: number;
  isValid?: boolean;
  bigInt?: BigInteger;
  ip?: IP_IP;
  ipString: string;
}

export interface ParseAddressLine {
  type?: string;
  text?: string;
  warning?: boolean;
  error?: string | null;
  fqdn?: string;
  exclusion?: boolean;
  name?: string;
  description?: string;
  fromIp?: IPObject;
  version?: IPObject['version'];
  original?: ParseAddressLine;
  toIp?: IPObject;
  to_ip?: string;
  gateway?: IPObject;
  default_gateway_address?: IP_IP;
  interface?: boolean;
  from_ip?: string;
  address?: IP_IP;
  cidr_block?: IPObject['cidr'];
  brnObjs?: LineWithBrn['networkList'][];
}

export type ParseAddressLineKeys = keyof ParseAddressLine;

export type ParseAddressLineMap = IMap<ParseAddressLineKeys, ParseAddressLine[ParseAddressLineKeys]>;

export interface StringifyAddressObject extends ParseAddressLine {
  friendly_name?: string;
  network?: {name: string};
}

export interface GenerateIpFqdn {
  fqdn: ParseAddressLine['fqdn'];
  description: ParseAddressLine['description'];
  network: StringifyAddressObject['network'];
  port: number;
  ip: IP;
}

export type IPObjectKeys = keyof IPObject;

export interface MapIpRangesOverlapping {
  address: string;
  duplicate: null | string;
  fromIp: IMap<IPObjectKeys, IPObject[IPObjectKeys]>;
  from_ip: string;
  text: string;
  type: undefined;
  toIp?: IMap<IPObjectKeys, IPObject[IPObjectKeys]>;
  ip: IP;
}

export type IpRangesOverlappingKeys = keyof MapIpRangesOverlapping;
export type ImmutableMapRange = IMap<IpRangesOverlappingKeys, MapIpRangesOverlapping[IpRangesOverlappingKeys]>;
export type MapIsRangesOverLapping = ImmutableMapRange & {_brand: 'overlap'};

export type ParseAddressChecks = typeof parseAddressChecks;

export type ParseAddressPropTypes = typesUtils.SetSpecificType<
  ParseAddressChecks,
  keyof ParseAddressChecks,
  PropTypes.Requireable<boolean>
>;

/**  This validates valid fqdn patterns to sync with backend.
 * (?![0-9]+$): negative lookahead to validate that fqdn does not have just numbers
 * (?!-)(?!\.): negative lookahead to validate that fqdn does start with - or .
 * [a-zA-Z0-9\-.\*]{1,253}: validate set of allowed characters in fqdn
 * [^.-]: make sure we did not end in a . and -
 *
 * Note: When Safari and FF supports 'lookbehind' we can use this
 * e.g. http://kangax.github.io/compat-table/es2016plus
 * (?<!-)(?<!\.): negative look behind pattern to make sure we did not end in a . and -
 * Future Regex when Safari and FF supported: /^(?!\d+$)(?!-)(?!\.)[\d*.A-Za-z-]{1,253}(?<!-)(?<!\.)$/;
 *
 */
const VALID_FQDN_PATTERN = /^(?!\d+$)(?!-)(?!\.)[\d*.A-Za-z-]{1,253}[^.-]$/;

/** Consecutive ** or .. pattern to sync with backend.
 *
 * \*\*|\.\. - Any repeating ** or ..
 * */
const CONSECUTIVE_WILD_DOT = /\*\*|\.\./;

/**
 *  Wild card check to sync with backend.
 * ^[*.]*$ - Start with '* | .' and end with '*'
 */
const WILD_CARDS = /^[*.]*$/;

/**
 * Check to determine if value input is a valid fqdn
 * Check for valid_fqdn_pattern AND make sure doesn't have
 * consecutive_wild_dot AND disallow wild cards.
 * Note: This FQDN check mirrors backend.
 *
 * */
export const isValidFqdn = (value: string): value is ValidFQDN =>
  VALID_FQDN_PATTERN.test(value) && !CONSECUTIVE_WILD_DOT.test(value) && !WILD_CARDS.test(value);

/**
 * Source of truth to enable or disable specific address checks.
 * Add to this property for potential new parseAddressLine() logic that needs to be disabled.
 *
 * By default all these checks are allowed in parseAddressLine().
 * Setting to boolean true is to turn them off when passing to parseAddressLine()
 * e.g. {noComment: true} - Do not allow comment checks e.g. 23.4.55.6 #Comment Text
 **/
export const parseAddressChecks = {
  // A boolean true to not allow FQDN but allow IP e.g. illumio.com (mutual exclusive check against noIP)
  noFQDN: false,
  // A boolean true "noExperimental: true" to not allow experimental
  noExperimental: false,
  // A boolean true "noIP: true" to not allow IP but only FQDN e.g. illumio.com (mutual exclusive check against noFQDN)
  noIP: false,
  // A boolean true "noExclusion: true" to not allow exclusion e.g. !10.1.1.1
  noExclusion: false,
  // A boolean true "noComments: true" to not allow comments e.g. 23.4.55.6 #Comment Text
  noComments: false,
  // A boolean true "noInterfaces: true" to not allow interfaces: e.g. eth0: 10.1.1.1
  noInterfaces: false,
  // A boolean true "noRanges: true" to not allow ranges. e.g. 10.1.1.2 - 10.2.2.2
  noRanges: false,
  // A boolean true "noIpV6: true" to not allow IP 6 e.g. 2001:0db8:0a0b:12f0:0000:0000:0000:0001
  noIpV6: false,
  // A boolean true "noIpV4: false" to not allow IPv4 e.g. 127.0.0.1
  noIpV4: false,
};

/**
 * Takes a address (ipv4, ipv6, or FQDN) and breaks down its structure into the following parts:
 *
 * - Address
 * - Port
 * - Subnet mast
 *
 * @param ip
 * @param type
 * @returns
 */
export const ipParts = (ip: IP, type?: IP_Type): IpParts => {
  const subnetMaskArray = new RegExp(subnetMaskRegex, 'g').exec(ip);

  if (type === 'ip6') {
    let subnetMask = null;

    if (subnetMaskArray?.length) {
      ip = ip.replace(subnetMaskArray[0], '');
      subnetMask = subnetMaskArray[0];
    }

    let portInfo: IpParts;

    try {
      /** fromUrl() needs to receive a URL with optional port number.
       *  With updated library fromUrl will throw an error when incorrect parameter
       *  passed in.
       *
       *  e.g. http://ip-address.js.org/#address6/fromurl
       * */
      const {address, port} = Address6.fromURL(ip);

      portInfo = {
        ip: address?.address || null,
        port,
        subnetMask,
      };
    } catch {
      portInfo = {} as IpParts;
    }

    return portInfo;
  }

  if (type === 'ip4') {
    const portRegexArray = new RegExp(portRegex, 'g').exec(ip);
    let port = null;
    let subnetMask = null;

    if (portRegexArray?.length) {
      ip = ip.replace(portRegexArray[0], '');
      port = portRegexArray[1];
    }

    if (subnetMaskArray?.length) {
      ip = ip.replace(subnetMaskArray[0], '');
      subnetMask = subnetMaskArray[0];
    }

    return {
      ip,
      port,
      subnetMask,
    };
  }

  const portRegexArray = new RegExp(portRegex, 'g').exec(ip);
  let port = null;

  if (portRegexArray?.length) {
    ip = ip.replace(portRegexArray[0], '');
    port = portRegexArray[1];
  }

  return {
    ip,
    port,
    subnetMask: null,
  };
};

const isIPv4BroadcastAddress = (address: IP_Address): address is IPv4BroadcastAddress =>
  address?.split('/')[0] === '255.255.255.255';

// Illumio disallows Class E addresses, reserved for research. EYE-59384
const isIPv4ClassE = (address: IP_Address): address is IPv4ClassE => {
  // Broadcast Address is not considered an experimental address
  if (isIPv4BroadcastAddress(address)) {
    return false;
  }

  const octets = address?.split('.');
  let firstOctet: number;

  if (octets?.length && typeof octets?.[0] === 'string') {
    firstOctet = parseInt(octets?.[0], 10);

    if (Number.isNaN(firstOctet)) {
      return false;
    }

    return firstOctet >= 240 && (Number(octets?.[1]) <= 255 || Number(octets?.[2]) < 255 || Number(octets?.[3]) <= 254);
  }

  return false;
};

export const isValidIPv4 = (ip: IP, startAddressOnly: boolean): ip is ValidIPV4 => {
  if (!ip) {
    return false;
  }

  const parts = ipParts(ip, 'ip4');

  if (parts.port !== null && !isValidPort(parts.port, 1)) {
    return false;
  }

  let address = parts.ip || ip;

  if (parts.subnetMask) {
    address += parts.subnetMask;
  }

  try {
    /** Need to wrap newAddress4 in try/catch for latest ip-address library */
    const addressInfo = new Address4(address);

    const valid = isIPv4BroadcastAddress(address) || !isIPv4ClassE(address);

    if (valid && startAddressOnly && address.includes('/')) {
      const [ipValue] = address.split('/').map(_.trim);

      return ipValue === addressInfo.startAddress().address;
    }

    return valid;
  } catch {
    return false;
  }
};

export const isValidIPv6 = (ip: IP): ip is ValidIPV6 => {
  if (!ip) {
    return false;
  }

  const parts = ipParts(ip, 'ip6');

  if (parts.port !== null && !isValidPort(parts.port, 1)) {
    return false;
  }

  let address = parts.ip || ip;

  if (parts.subnetMask) {
    address += parts.subnetMask;
  }

  // Use static isValid() call since Address6 instance is not needed
  return Address6.isValid(address);
};

export const validateIPAddress = (ip: IP, type: IP_Type, startAddressOnly: boolean): ip is IP_IP => {
  if (type === 4) {
    return isValidIPv4(ip, startAddressOnly);
  }

  if (type === 6) {
    return isValidIPv6(ip);
  }

  return isValidIPv4(ip, startAddressOnly) || isValidIPv6(ip);
};

export const parseIPList = (values: IP_Values, type?: IP_Type, startAddressOnly = false): IP_ValidDomainList => {
  const valid: string[] = [];
  const invalid: string[] = [];

  if (values) {
    values
      .split(/,|;|\s+/)
      .map(_.trim)
      .forEach(ip => ip && (validateIPAddress(ip, type, startAddressOnly) ? valid.push(ip) : invalid.push(ip)));
  }

  return {valid, invalid};
};

export const isValidDomain = (domain: IP_Domain): domain is ValidDomain => {
  const parts = ipParts(domain);

  if (parts.port !== null && !isValidPort(parts.port, 1)) {
    return false;
  }

  domain = parts.ip || domain;

  if (typeof domain === 'string') {
    // not *, not have just numbers, not start with - or ., not end with - or ., valid characters with length 1 to 253,
    // not just . and *, no two consecutive *, no two consecutive .
    return (
      domain !== '*' &&
      /\D/.test(domain) &&
      !/^[.-]/.test(domain) &&
      !/[.-]$/.test(domain) &&
      /^[\w*.-]{1,253}$/.test(domain) &&
      !/^[*.]*$/.test(domain) &&
      !domain.includes('**') &&
      !domain.includes('..')
    );
  }

  return false;
};

export const parseDomainList = (values: IP_Values): IP_ValidDomainList => {
  const valid: string[] = [];
  const invalid: string[] = [];

  if (values) {
    values
      .split(/,|;|\s+/)
      .map(_.trim)
      .forEach(domain => domain && (isValidDomain(domain) ? valid.push(domain) : invalid.push(domain)));
  }

  // We only show invalid address once
  return {valid, invalid: [...new Set(invalid)]};
};

export const parseAddressList = (type: IP_Type, values: IP_Values): IP_ValidDomainList =>
  type === 'domain' ? parseDomainList(values) : parseIPList(values);

// augment the yup schema
declare module 'yup' {
  export interface StringSchema<T extends string | null | undefined = string | undefined, C = object> {
    isValidIpv4(): StringSchema<T, C>;
  }
}

addMethod(string, 'isValidIpv4', function (this: StringSchema<string>) {
  return this.test(
    'validate-ipv4',
    intl('IPLists.Errors.Ip4'),
    value => !value || (typeof value === 'string' && validateIPAddress(value, 4, false)),
  );
});

/**
 * Validate IP address string
 *
 * @params ipString e.g. 10.1.1.1
 * @params noCIDR e.g. 10.1.1.1 without 192.168.100.0/24
 * @params checkNonPrefixValidation e.g. Check to determine if prefix needs validation
 * @params addressCheckToggle
 * @returns
 */
export const parseIP = (
  ipString: string,
  noCIDR?: boolean,
  checkNonPrefixValidation?: boolean,
  addressCheckToggle?: typeof parseAddressChecks,
): IPObject => {
  const allowAddressCheck = {...parseAddressChecks, ...addressCheckToggle};

  ipString = _.trim(ipString);

  /** ipString holds the original value
   *  e.g. 192.168.100.0/24
   * */
  const result: IPObject = {ipString};

  /** ip will either hold original 'ipString' value or re-assign if the ip is cidr block
   *
   * e.g. 192.168.100.0/24 will convert to 192.168.100.0
   * */
  let ip = ipString;
  let isIPv6 = ip.includes(':');
  let cidr;

  if (ip.includes('/')) {
    if (noCIDR) {
      throw new TypeError('CIDR is not allowed');
    }

    [ip, cidr] = ip.split('/').map(_.trim);

    if (!ip) {
      throw new TypeError('Missing IP address');
    }

    if (!cidr) {
      throw new TypeError('Missing CIDR block');
    }

    let addressInfo;
    let isValidCidr;

    isIPv6 = ip.includes(':');

    if (isIPv6 && !allowAddressCheck.noIpV6) {
      // IPv6
      /** Important to use try/catch/finally block to allow the logic to continue. */
      try {
        // When ipString is fails, will throw an error
        addressInfo = new Address6(ipString);
        isValidCidr = true;
      } catch {
        isValidCidr = false;
      } finally {
        // Don't check when there is a pre-fix attached e.g. !3ffe:1900:4545:3:200:f8ff:fe21:67cf
        if (checkNonPrefixValidation && isValidCidr) {
          // Without cidr, example: ip = 2620:0:860:2:: (original: 2620:0:860:2::/64)
          const addressInfoWithoutCIDR = new Address6(ip);
          const canonicalWithoutCIDR = addressInfoWithoutCIDR.canonicalForm();

          // Address with cidr 2620:0:860:2::/64
          const addressWithCIDR = addressInfo?.startAddress().address;

          // Worked with backend to confirm logic that a CIDR starting address and without CIDR canonical address needs to match to be valid
          isValidCidr = canonicalWithoutCIDR === addressWithCIDR;
        }
      }
    } else if (!allowAddressCheck.noIpV4) {
      // IPv4
      /** Important to use try/catch/finally block to allow the logic to continue. */
      try {
        addressInfo = new Address4(ipString);
        isValidCidr = true;
      } catch {
        isValidCidr = false;
      } finally {
        // Not all ipString require validation to determine if ipString is the start network
        // ip with prefixes: e.g. eth0: 10.0.0.1, !10.0.0.0 does not need to be check.
        // checkNonPrefixValidation - ipString without prefix must be boolean true
        if (checkNonPrefixValidation === true && isValidCidr) {
          // addressInfo.startAddress().address must match the user ip input to be valid cidr
          isValidCidr = ip && ip === addressInfo?.startAddress().address;
        }
      }
    }

    // cidr must be greater than 0 (cidr > 0) to be valid
    // ip/cdr: "0.0.0.0/0" is valid for isPostiiveInteger check
    // isValidCidr: false - invalid cidr for both IPv4 and IPv6
    // checkNonPrefixValidation - ipString without prefix must be boolean true
    if (
      (checkNonPrefixValidation === true && !isValidCidr) ||
      (ip !== '0.0.0.0' && Number(cidr) <= 0 && !isIPv6) ||
      !isPositiveInteger(cidr, isIPv6 ? 128 : 32)
    ) {
      throw new TypeError('Invalid CIDR block');
    }
  }

  if (isIPv6 && !allowAddressCheck.noIpV6) {
    // Ipv6
    // IP6, must be checked first, because can contain IP4
    let errorMessage;

    try {
      result.helper = new Address6(ipString);
    } catch (error) {
      if (error instanceof Error) {
        errorMessage = error.message;
      }
    }

    if (result.helper) {
      result.ip6 = true;
      result.version = 6;

      if (cidr) {
        result.cidr = Number(cidr);
      }

      if (result.helper instanceof Address6 && result.helper?.zone) {
        throw new TypeError(`Zone or scope id must be removed: ${result.helper.zone}`);
      }
    } else {
      throw new TypeError(errorMessage?.replace('Address failed regex', 'Bad block detected in address'));
    }
  } else if (ip.includes('.') && !allowAddressCheck.noIpV4) {
    // IP4

    // Don't allow experimental class E
    if (allowAddressCheck.noExperimental && isIPv4ClassE(ipString)) {
      // experimental
      throw new TypeError(intl('IPLists.Errors.Experimental'));
    }

    let isValid;

    try {
      /** Invalid ipString will throw an AddressError thus need to add try/catch block
       *  Allow the logic below to throw the appropriate error thus don't put inside finally
       * */
      result.helper = new Address4(ipString);
      isValid = true;
    } catch {
      isValid = false;
    }

    if (isValid && result.helper?.isCorrect()) {
      result.ip4 = true;
      result.version = 4;

      if (cidr) {
        result.cidr = Number(cidr);
      }
    } else {
      const match = _.tail(ip.match(/^(\d{1,3})\.(\d{1,3})\.(\d{1,3})\.(\d{1,3})$/));

      if (_.isEmpty(match)) {
        // Don't show FQDN text when not allowed
        const errorMessage = !allowAddressCheck.noFQDN ? intl('IPLists.Errors.Ip4Fqdn') : intl('IPLists.Errors.Ip4');

        throw new TypeError(errorMessage);
      }

      if (match.some(block => block.startsWith('0') && block.length > 1)) {
        throw new TypeError('Only decimal numbers are allowed in IPv4'); // No octal (leading 0) or hexadecimal (leading 0X)
      }

      if (match.some(block => Number(block) > 255)) {
        throw new TypeError(intl('Common.IPAddressInvalid'));
      }

      throw new TypeError('Invalid IP');
    }
  } else {
    throw new TypeError('Invalid IP');
  }

  result.ip = ip as IP_IP;
  result.isValid = true;
  result.bigInt = result.helper.bigInteger();

  return result;
};

export const getLineWithBrn = (line: LineWithBrn['line'], networkList?: NetworkList[]): ReturnLineWithBrn => {
  const [ip, brnPart] = line.split(/\s+"/).map(_.trim);

  if (brnPart && brnPart.length > 0 && brnPart.slice(-1) === '"') {
    const brn = brnPart.slice(0, -1);

    const brnObjs = networkList?.filter(value => value.name === brn && value.data_center !== 'link_local');

    if (brnObjs?.length === 0) {
      throw new TypeError(intl('Common.NetworkNameInvalid'));
    }

    return {ip, brnObjs};
  }

  return {ip: line};
};

export const parseIPNoCIDR = (ip: IP): IPObject => parseIP(ip, true);

/**
 * PropTypes for parseAddressChecks with mutual exclusive
 * Can't have noFQDN and noIP to be true thus adding mutuallyExclusiveTruePropsSpread
 **/
export const parseAddressChecksPropTypes = (): ParseAddressPropTypes => {
  let kAddress: keyof typeof parseAddressChecks;

  const addressPropTypes = {} as ParseAddressPropTypes;

  for (kAddress in parseAddressChecks) {
    if (parseAddressChecks.hasOwnProperty('kAddress')) {
      addressPropTypes[kAddress] = PropTypes.bool;
    }
  }

  return {...addressPropTypes, ...mutuallyExclusiveTruePropsSpread('noFQDN', 'noIP')};
};

/**
 * Parse IP/FQDN without any validation, but need to set the proper object values when sending to API
 * @params line is user's input
 * @params  parseAddressChecks
 * @returns results is a an object with necessary API values
 */
export const parseAddressNoValidation = ({
  line,
  addressCheckToggle,
}: {
  line: string;
  addressCheckToggle: Partial<ParseAddressChecks>;
}): ParseAddressLine => {
  const allowAddressCheck = {...parseAddressChecks, ...addressCheckToggle};
  let result = parsedNoValidationCache.get(line);

  if (result) {
    return result;
  }

  let value = _.trim(line);

  result = {text: value};

  if (!value) {
    parsedNoValidationCache.set(line, result);

    return result;
  }

  if (allowAddressCheck.noIP) {
    // When noIP then exclusively FQDN only
    /** Set fqdn to indicate this is fqdn property
     * fqdn property will be used for logical data processing when sending to API
     * when merging IP only with FQDN only entries
     * e.g. dataValidation::ipListsGetUpdate */
    result.fqdn = value;

    return result;
  }

  /** Exclusion syntax
   * e.g. !192.168.100.0/30 */
  if (value.startsWith('!')) {
    value = value.substr(1);
    result.exclusion = true;
  }

  /** Split the description */
  let description = null;

  /** Comments
   * e.g. 23.4.55.6 #Comment Text */
  if (value.includes('#')) {
    [value, description] = value.split('#').map(_.trim);
    result.description = description;
  }

  /** All IPS */
  if (value === '*' || value === '0.0.0.0/0') {
    result.fromIp = parseIP('0.0.0.0/0');
  } else if (value.includes('-')) {
    const [fromIp, toIp] = value.split('-');

    result.fromIp = fromIp;
    result.from_ip = fromIp;
    result.toIp = toIp;
    result.to_ip = toIp;
  } else if (value.includes(' ')) {
    /** Interface with gateway
     * e.g. space indicate there will be a range e.g. 10.1.1.1 - 10.2.22.2
     * */
    const [fromIp, toIp] = value.split(' ');

    result.to_ip = toIp;
    result.from_ip = fromIp;
  } else {
    /** Single line input
     * e.g. 10.1.1.1, 92.168.100.0/24
     */
    result.from_ip = value;
  }

  parsedNoValidationCache.set(line, result);

  return result;
};

/**
 * Parse IP string into consumable IP range.
 * Accepts *, x.x.x.x, x.x.x.x/x and x.x.x.x - y.y.y.y
 * Accepts # as the description separator
 *
 * @params line
 * @params isSingle
 * @params isInterface e.g. eth0: 10.1.11.1
 * @params allowBrn
 * @params networkList
 * @params parseAddressChecks
 * @returns results
 */
export const parseAddressLine = (
  line: string,
  isSingle = false,
  isInterface = false,
  allowBrn = false,
  networkList?: NetworkList[],
  addressCheckToggle?: Partial<ParseAddressChecks>,
): ParseAddressLine => {
  const allowAddressCheck = {...parseAddressChecks, ...addressCheckToggle};

  const parsedCache = allowBrn ? parsedWithBrnCache : parsedWithoutBrnCache;
  let result: ParseAddressLine = parsedCache.get(line);
  let brnObjs;

  if (result) {
    return result;
  }

  result = {text: line};

  let value = _.trim(line);

  try {
    if (allowBrn) {
      const ipWithBrn = getLineWithBrn(value, networkList);

      value = ipWithBrn.ip;
      brnObjs = ipWithBrn.brnObjs;
    }

    if (!value) {
      parsedCache.set(line, result);

      return result;
    }

    if (allowAddressCheck.noIP) {
      // When noIP then exclusively FQDN only
      if (!isValidFqdn(value)) {
        result.error = intl('IPLists.Errors.FQDN');
      }

      // Set fqdn to indicate this is fqdn property
      // fqdn property will be used for logical data processing when sending to API
      // when merging IP only with FQDN only entries
      // e.g. dataValidation::ipListsGetUpdate
      result.fqdn = value;

      return result;
    }

    // Remove the exclusion
    if (!isInterface && value.startsWith('!') && !allowAddressCheck.noExclusion) {
      // exclusion
      value = value.substr(1);
      result.exclusion = true;
    }

    // Determine ip without prefix interface: e.g. '10.10.1.1', '10.1.1.1/24'
    // ip with prefix interface: eth0: 10.10.1.1, !10.0.0.0
    const checkNonPrefixValidation = !isInterface && !result.exclusion;

    // Split the interface Name
    if (isInterface && !allowAddressCheck.noInterfaces) {
      // interfaces
      let name = null;

      [, name, value] = value.split(/^([^\s:]+):/).map(_.trim);

      if (!name) {
        throw new TypeError('Missing Interface Name');
      }

      if (!value) {
        throw new TypeError('Missing IP address');
      }

      value
        .split(' ')
        .map(_.trim)
        .map(line => parseIP(line));

      result.name = name;
    }

    // Split the description
    let description = null;

    if (!isSingle && value.includes('#') && !allowAddressCheck.noComments) {
      // comments
      [value, description] = value.split('#').map(_.trim);
      result.description = description;
    }

    if ((value === '*' || value === '0.0.0.0/0') && !isSingle) {
      // All IPs
      result.fromIp = parseIP('0.0.0.0/0');
    } else if (!isSingle && !isInterface && value.includes('-') && !allowAddressCheck.noRanges) {
      // ranges
      // IP range using '-'
      const [fromIp, toIp] = value.split('-').map(parseIPNoCIDR);

      if (fromIp.version !== toIp.version) {
        throw new TypeError('Combining IPv4 and IPv6 in range is forbidden');
      }

      if (fromIp?.bigInt && toIp?.bigInt && fromIp?.bigInt.compareTo(toIp?.bigInt) >= 0) {
        throw new TypeError('Invalid address range');
      }

      // Don't allow experimental class e range
      if (allowAddressCheck.noExperimental) {
        // experimental
        if (isIPv4ClassE(fromIp?.ip) && (isIPv4BroadcastAddress(toIp?.ip) || isIPv4ClassE(toIp?.ip))) {
          throw new TypeError(intl('IPLists.Errors.Experimental'));
        }
      }

      result.fromIp = fromIp;
      result.toIp = toIp;
      result.to_ip = toIp.ipString;
    } else if (isInterface && value.includes(' ') && !allowAddressCheck.noRanges) {
      // space indicate there will be a range e.g. 10.1.1.1 - 10.2.22.2
      // Interface with gateway
      const [fromIp, gateway] = value.split(' ');

      result.fromIp = parseIP(fromIp);

      result.gateway = parseIP(gateway, true);
      result.default_gateway_address = result.gateway.ip;

      result.interface = true;
    } else {
      // Simple one IP
      result.fromIp = parseIP(value, isSingle, checkNonPrefixValidation, allowAddressCheck);
    }

    result.from_ip = result.fromIp.ipString;
    // We sould pass pure ip as 'address' and cidr as 'cidr_block' in unmanaged workloads (interface) separately
    result.address = result.fromIp.ip;

    if (result.fromIp.cidr) {
      result.cidr_block = result.fromIp.cidr;
    }
  } catch (err) {
    if (err instanceof Error) {
      result.error = err.message;
    }
  }

  if (allowBrn) {
    result.brnObjs = brnObjs;
  }

  if (result.error && !result.exclusion) {
    if (isValidDomain(value)) {
      // Use the default result.error message from the validation to keep consistent error message.
      // By keeping and preserve result.error, proper error validation will be kept during
      // validateOrderedMapAddresses() (DraftJS - Immutable.js) or validateAddresses() (Legacy - Javascript Objects) parsing
      // When result.error doesn't match experimental class e and noFQDN does't exist then reset result.error to null
      if (result.error !== intl('IPLists.Errors.Experimental') && !allowAddressCheck.noFQDN && !isInterface) {
        result.error = null;
      }

      result.fqdn = value;
    }
  }

  // Set warning flag
  if (value === '0.0.0.0') {
    result.warning = true;
  }

  parsedCache.set(line, result);

  return result;
};

export const isValidIP = (ip: IP, isSingleIp: boolean, networkList: boolean): ip is IP_IP =>
  !parseAddressLine(ip, isSingleIp, networkList, false).error;

export const areIpRangesEqual = (
  oldRange: ParseAddressLine,
  newRange: ParseAddressLine,
  ignoreExclusion?: boolean,
): boolean => {
  if (!oldRange && !newRange) {
    return true;
  }

  if (!oldRange || !newRange) {
    return false;
  }

  const oldExclusion = ignoreExclusion ? null : Boolean(oldRange.exclusion);
  const newExclusion = ignoreExclusion ? null : Boolean(newRange.exclusion);

  const oldDescription = oldRange.description ? oldRange.description : null;
  const newDescription = newRange.description ? newRange.description : null;

  return (
    oldRange.from_ip === newRange.from_ip &&
    oldRange.to_ip === newRange.to_ip &&
    oldDescription === newDescription &&
    oldExclusion === newExclusion
  );
};

/**
 * Convert the raw data from the API into a readable format inside the Draft Editor
 * Note: This can also be used if the detail page display the same formatting as the Draft Editor.
 *
 *
 * e.g. from_ip: 10.1.1.1
 *      to_ip: 10.10.10.10
 *
 *      Draft Editor will display as "10.1.1.1 - 10.10.10.10"
 * @param value
 * @param readonly
 * @returns formatted string
 */
export const stringifyAddressObject = (
  value: StringifyAddressObject,
  readonly = false,
  isExternalPartner = false,
): string => {
  let result = value.from_ip || '';

  if (value.fqdn && result === '') {
    result = value.fqdn;
  }

  if (value.to_ip) {
    result = `${result} - ${value.to_ip}`;
  }

  if (value.description) {
    result = `${result} #${value.description}`;
  }

  if (value.exclusion) {
    result = `!${result}`;
  }

  if (value.name) {
    result = `${value.name}: ${result}`;
  }

  if (value.default_gateway_address) {
    result = `${result} ${value.default_gateway_address}`;
  }

  if (value.exclusion && readonly) {
    result = `${result} (exclude)`;
  }

  if (value.friendly_name && readonly) {
    result = `${result} (${value.friendly_name})`;
  }

  if (value.network?.name && readonly && !isExternalPartner) {
    result = `${result} (${value.network.name})`;
  }

  return result;
};

// const areIpRangesOverlapping = (oldIp, newIp) => {
//   if (oldIp.text && oldIp.text.trim() && oldIp.text === newIp.text) { // if they are the same
//     return true;
//   }
//
//   if (!oldIp || !newIp || !oldIp.fromIp || !newIp.fromIp) {
//     return false;
//   }
//
//   // If versions of ip are different and only one ip in each line, compare ip4 and converted 6to4
//   if (oldIp.fromIp.version !== newIp.fromIp.version) {
//     // Don't check if one of line is range or gateway
//     if (oldIp.toIp || newIp.toIp) {
//       return false;
//     }
//
//     const [ip4, ip6] = newIp.fromIp.ip6 ? [oldIp, newIp] : [newIp, oldIp];
//
//     // Check only Teredo
//     if (ip6.fromIp?.helper instanceof Address6 && !ip6.fromIp.helper.isTeredo()) {
//       return false;
//     }
//
//     const ip6to4Helper = ip6.fromIp?.helper instanceof Address6 && ip6?.fromIp?.helper?.to4();
//
//     if (ip6to4Helper instanceof Address4 && ip4.fromIp?.helper instanceof Address4) {
//       return (
//         ip4.fromIp.helper.isInSubnet(ip6to4Helper) ||
//           ip6to4Helper.isInSubnet(ip4.fromIp.helper)
//       );
//     }
//
//     return false;
//   }
//
//   if (!oldIp.toIp && !newIp.toIp) {
//     if (oldIp.fromIp.version === 6 || oldIp.fromIp.ip.includes('/') || newIp.fromIp.ip.includes('/')) {
//       // This calculation is expensive so only do it if necessary
//       return (
//         oldIp.fromIp.helper.isInSubnet(newIp.fromIp.helper) ||
//         newIp.fromIp.helper.isInSubnet(oldIp.fromIp.helper)
//       );
//     }
//
//     return oldIp.fromIp.ip === newIp.fromIp.ip;
//   }
//
//   if (!oldIp.toIp) {
//     return (
//       oldIp.fromIp.bigInt.compareTo(newIp.fromIp.bigInt) >= 0 &&
//       oldIp.fromIp.bigInt.compareTo(newIp.toIp.bigInt) <= 0
//     );
//   }
//
//   if (!newIp.toIp) {
//     return (
//       newIp.fromIp.bigInt.compareTo(oldIp.fromIp.bigInt) >= 0 &&
//       newIp.fromIp.bigInt.compareTo(oldIp.toIp.bigInt) <= 0
//     );
//   }
//
//   return (
//     oldIp.fromIp.bigInt.compareTo(newIp.toIp.bigInt) <= 0 &&
//     newIp.fromIp.bigInt.compareTo(oldIp.toIp.bigInt) <= 0
//   );
// };

// const areInterfacesDuplicates = (oldRange: ParseAddressLine, newRange: ParseAddressLine): boolean => {
//   if (!oldRange || !newRange || !oldRange.name || !newRange.name) {
//     return false;
//   }
//
//   return (
//     oldRange.name === newRange.name &&
//     oldRange.address === newRange.address &&
//     (
//       !oldRange.default_gateway_address && !newRange.default_gateway_address ||
//       oldRange.default_gateway_address === newRange.default_gateway_address
//     ) &&
//     (
//       !oldRange.cidr_block && !newRange.cidr_block ||
//       oldRange.cidr_block === newRange.cidr_block
//     )
//   );
// };

export const isIpContainedIn = (ip: ParseAddressLine, containingIp: ParseAddressLine): boolean => {
  if (!containingIp || !ip || !containingIp.fromIp || !ip.fromIp) {
    return false;
  }

  if (!containingIp.toIp && ip.fromIp.helper !== undefined && containingIp.fromIp.helper !== undefined) {
    return containingIp.from_ip !== ip.from_ip && ip.fromIp.helper.isInSubnet(containingIp.fromIp.helper);
  }

  // FromIp must be in container
  if (
    ip.fromIp.bigInt !== undefined &&
    containingIp.fromIp.bigInt !== undefined &&
    containingIp.toIp !== undefined &&
    containingIp.toIp.bigInt !== undefined
  ) {
    if (
      ip.fromIp.bigInt.compareTo(containingIp.fromIp.bigInt) < 0 ||
      ip.fromIp.bigInt.compareTo(containingIp.toIp.bigInt) > 0
    ) {
      return false;
    }
  }

  if (!ip.toIp) {
    return true;
  }

  // If they are both ranges, one end can match, but not both
  if (
    ip.toIp !== undefined &&
    ip.toIp.bigInt !== undefined &&
    containingIp.toIp !== undefined &&
    containingIp.toIp.bigInt !== undefined &&
    ip.fromIp.bigInt !== undefined &&
    containingIp.fromIp.bigInt !== undefined
  ) {
    return (
      ip.toIp.bigInt.compareTo(containingIp.toIp.bigInt) < 0 ||
      (ip.toIp.bigInt.compareTo(containingIp.toIp.bigInt) === 0 &&
        ip.fromIp.bigInt.compareTo(containingIp.fromIp.bigInt) !== 0)
    );
  }

  return false;
};

// export const validateAddresses = values => {
//   const verifyOverlapping = values.length < 1000;
//
//   const data = values.map((value, index, array) => {
//     if (!value) {
//       return value;
//     }
//
//     if (verifyOverlapping && !value.error && !value.removed && !value.exclusion &&
//       _.some(array, (currentValue, currentIndex) => index !== currentIndex &&
//         !currentValue.removed && !currentValue.error && !currentValue.exclusion &&
//         (!value.name && areIpRangesOverlapping(value, currentValue) || value.name && areInterfacesDuplicates(value, currentValue))
//       )) {
//       if (value.fqdn) {
//         value.duplicate = intl('IPLists.List.OverlappingFQDN');
//       } else if (value.name) {
//         value.duplicate = intl('IPLists.List.OverlappingInterfaces');
//       } else {
//         value.duplicate = intl('IPLists.List.OverlappingAddresses');
//       }
//     } else if (verifyOverlapping && !value.error && !value.removed && value.exclusion && !value.name &&
//       !_.some(array, (currentValue, currentIndex) => index !== currentIndex &&
//         !currentValue.removed && !currentValue.error && !currentValue.exclusion && isIpContainedIn(value, currentValue)
//       )) {
//       value.duplicate = intl('IPLists.List.Range');
//     } else {
//       value.duplicate = null;
//     }
//
//     if (areIpRangesEqual(value.original, value)) {
//       value.type = value.type === 'updated' ? 'old' : value.type;
//     } else {
//       value.type = value.type === 'old' ? 'updated' : value.type;
//     }
//
//     return value;
//   });
//
//   return data;
// };

/** Determine if ip ranges are equal
 *
 * @params oldRange
 * @params newRange
 * @returns
 */
export const areMapIpRangesEqual = (
  oldRange: ParseAddressLineMap,
  newRange: ParseAddressLineMap,
  ignoreExclusion?: boolean,
): boolean => {
  if (!oldRange && !newRange) {
    return true;
  }

  if (!oldRange || !newRange) {
    return false;
  }

  const oldExclusion = ignoreExclusion ? null : Boolean(oldRange.get('exclusion'));
  const newExclusion = ignoreExclusion ? null : Boolean(newRange.get('exclusion'));

  const oldDescription = oldRange.get('description') ? oldRange.get('description') : null;
  const newDescription = newRange.get('description') ? newRange.get('description') : null;

  return (
    oldRange.get('from_ip') === newRange.get('from_ip') &&
    oldRange.get('to_ip') === newRange.get('to_ip') &&
    oldDescription === newDescription &&
    oldExclusion === newExclusion
  );
};

/** Validate to determine if any ip address is a duplicate by using Map data type
 *
 * @params oldRange
 * @params newRange
 * @returns
 */
const areMapInterfacesDuplicates = (oldRange: ParseAddressLineMap, newRange: ParseAddressLineMap): boolean => {
  if (!oldRange || !newRange || !oldRange.get('name') || !newRange.get('name')) {
    return false;
  }

  return (
    oldRange.get('name') === newRange.get('name') &&
    oldRange.get('address') === newRange.get('address') &&
    ((!oldRange.get('default_gateway_address') && !newRange.get('default_gateway_address')) ||
      oldRange.get('default_gateway_address') === newRange.get('default_gateway_address')) &&
    ((!oldRange.get('cidr_block') && !newRange.get('cidr_block')) ||
      oldRange.get('cidr_block') === newRange.get('cidr_block'))
  );
};

/** Validate to determine if any ip address is in containing ip
 *
 * @params ip
 * @params containingIp
 * @returns
 */
export const isMapIpContainedIn = (ip: ImmutableMapRange, containingIp: ImmutableMapRange): boolean => {
  if (
    !containingIp ||
    !ip ||
    typeof ip.get('fromIp') !== 'object' ||
    typeof containingIp.get('fromIp') !== 'object' ||
    !containingIp.get('fromIp') ||
    !ip.get('fromIp')
  ) {
    return false;
  }

  const ipInfo = ip.get('fromIp') as MapIpRangesOverlapping['fromIp'];
  const ipHelper = ipInfo.get('helper') as IPObject['helper'];
  const ipBigInt = ipInfo.get('bigInt') as IPObject['bigInt'];

  const ipToIp = ip.get('toIp') as MapIpRangesOverlapping['toIp'];
  const ipToIpBigInt = ipToIp?.get('bigInt') as IPObject['bigInt'];

  const containingIpInfo = containingIp.get('fromIp') as MapIpRangesOverlapping['fromIp'];
  const containingIpHelper = containingIpInfo.get('helper') as IPObject['helper'];
  const containingIpBigInt = containingIpInfo.get('bigInt') as IPObject['bigInt'];

  const containingIpToIp = containingIp.get('toIp') as MapIpRangesOverlapping['toIp'];
  const containingIpToIpBigInt = containingIpToIp?.get('bigInt') as IPObject['bigInt'];

  if (!containingIp.get('toIp') && ipHelper !== undefined && containingIpHelper !== undefined) {
    return containingIp.get('from_ip') !== ip.get('from_ip') && ipHelper.isInSubnet(containingIpHelper);
  }

  // FromIp must be in container
  if (ipBigInt !== undefined && containingIpBigInt !== undefined && containingIpToIpBigInt !== undefined) {
    if (ipBigInt.compareTo(containingIpBigInt) < 0 || ipBigInt.compareTo(containingIpToIpBigInt) > 0) {
      return false;
    }
  }

  if (!ip.get('toIp')) {
    return true;
  }

  // If they are both ranges, one end can match, but not both
  return ipToIpBigInt !== undefined &&
    ipBigInt !== undefined &&
    containingIpToIpBigInt !== undefined &&
    containingIpBigInt !== undefined
    ? ipToIpBigInt.compareTo(containingIpToIpBigInt) < 0 ||
        (ipToIpBigInt.compareTo(containingIpToIpBigInt) === 0 && ipBigInt.compareTo(containingIpBigInt) !== 0)
    : false;
};

/** Check to determine if oldIp and newIp are overlapping
 *
 * @params oldIp
 * @params newIp
 * @returns
 */
const areMapIpRangesOverlapping = (
  oldIp: ImmutableMapRange,
  newIp: ImmutableMapRange,
): oldIp is MapIsRangesOverLapping => {
  const oldIpText = oldIp.get('text');

  if (typeof oldIpText === 'string' && oldIpText.trim() && oldIpText === newIp.get('text')) {
    // if they are the same
    return true;
  }

  const oldIpFromIp = oldIp.get('fromIp') as MapIpRangesOverlapping['fromIp'];
  const newIpFromIp = newIp.get('fromIp') as MapIpRangesOverlapping['fromIp'];

  // Don't need to continue if any of these values do not exist
  if (
    !oldIp ||
    !newIp ||
    !oldIpFromIp ||
    !newIpFromIp ||
    typeof oldIpFromIp !== 'object' ||
    typeof newIpFromIp !== 'object'
  ) {
    return false;
  }

  // If versions of ip are different and only one ip in each line, compare ip4 and converted 6to4
  if (oldIpFromIp.get('version') !== newIpFromIp.get('version')) {
    // Don't check if one of line is range or gateway
    if (oldIp.get('toIp') || newIp.get('toIp')) {
      return false;
    }

    const [ip4, ip6]: ImmutableMapRange[] = newIpFromIp.get('ip6') ? [oldIp, newIp] : [newIp, oldIp];

    const fromIpV6 = ip6.get('fromIp') as MapIpRangesOverlapping['fromIp'];
    const helperV6 = fromIpV6.get('helper') as Address6;

    const fromIpV4 = ip4.get('fromIp') as MapIpRangesOverlapping['fromIp'];
    const helperV4 = fromIpV4.get('helper') as Address4;

    // Check only Teredo
    // Note: ip6.get('fromIp').get('helper') is a instance property of Address4, or Address6 which is not an
    // Immutable Type because fromJS() ONLY converts Object and Array thus instance property like helper<Address4> is not converted
    if (helperV6.isTeredo() === false) {
      return false;
    }

    const ip6to4Helper = helperV6.to4();

    return helperV4.isInSubnet(ip6to4Helper) || ip6to4Helper.isInSubnet(helperV4);
  }

  const IPOld = oldIpFromIp.get('ip') as IP;
  const IPNew = newIpFromIp.get('ip') as IP;

  const oldIpHelper = oldIpFromIp.get('helper') as IPHelperAddress;
  const newIpHelper = newIpFromIp.get('helper') as IPHelperAddress;

  const oldBigInt = oldIpFromIp.get('bigInt') as BigInteger;
  const newBigInt = newIpFromIp.get('bigInt') as BigInteger;

  const newIpToIp = newIp.get('toIp') as MapIpRangesOverlapping['toIp'];
  const newIpToIpBigInt = newIpToIp !== undefined ? (newIpToIp.get('bigInt') as BigInteger) : undefined;

  const oldIpToIp = oldIp.get('toIp') as MapIpRangesOverlapping['toIp'];
  const oldIpToIpBigInt = oldIpToIp !== undefined ? (oldIpToIp.get('bigInt') as BigInteger) : undefined;

  if (!oldIp.get('toIp') && !newIp.get('toIp')) {
    if (oldIpFromIp.get('version') === 6 || IPOld.includes('/') || IPNew.includes('/')) {
      // This calculation is expensive so only do it if necessary
      return oldIpHelper.isInSubnet(newIpHelper) || newIpHelper.isInSubnet(oldIpHelper);
    }

    return oldIpFromIp.get('ip') === newIpFromIp.get('ip');
  }

  if (oldIp.get('toIp') === undefined && newIpToIpBigInt && newBigInt) {
    return oldBigInt.compareTo(newBigInt) >= 0 && oldBigInt.compareTo(newIpToIpBigInt) <= 0;
  }

  if (newIp.get('toIp') === undefined && oldBigInt && oldIpToIpBigInt) {
    return newBigInt.compareTo(oldBigInt) >= 0 && newBigInt.compareTo(oldIpToIpBigInt) <= 0;
  }

  if (newIpToIpBigInt && oldIpToIpBigInt) {
    return oldBigInt.compareTo(newIpToIpBigInt) <= 0 && newBigInt.compareTo(oldIpToIpBigInt) <= 0;
  }

  return false;
};

/** Set the first duplicate key which is needed for logic to show lines with exact same values. We
 * don't show error on the first line with duplicate, only the lines after. This method is needed
 * to not clash with other duplicate values that don't have same value. In addition, get a count of duplicates.
 *   e.g. When ip are duplicates will show this type of error message
 *      [1] 10.1.1.1
 *      [2] 10.1.1.1   // A tooltip with "Overlapping Address Error at line 1"
 *      [3] 10.1.1.1   // A tooltip with "Overlapping Address Error at line 1"
 *
 * @params duplicateCounts
 * @params blockKey
 * @params ipText
 * @params duplicateCount
 * @returns
 */
export const setDuplicateCounts = ({
  ipText,
  duplicateCounts,
  blockKey,
}: DuplicateCounts): DuplicateCounts['duplicateCounts'] => {
  let duplicates = duplicateCounts;

  if (duplicates.has(ipText)) {
    const count = duplicates.getIn([ipText, 'count']);

    duplicates = duplicates.setIn([ipText, 'count'], count + 1);
  } else {
    // Need to save the blockKey to determine which blockKey started the duplicates
    duplicates = duplicates.setIn([ipText, 'firstDuplicateBlockKey'], blockKey);
    duplicates = duplicates.setIn([ipText, 'count'], 1);
  }

  return duplicates;
};

export interface ValidateOrderedMapAddressed {
  data: unknown;
  text: string;
  key: string;
  getData: () => void;
}

/** Validate IP by using OrderedMap data type
 *  e.g. https://immutable-js.github.io/immutable-js/docs/#/OrderedMap
 *
 * @params values
 * @returns newEditorState | editorState
 */
export const validateOrderedMapAddresses = (values: OrderedMap<string, ContentBlock>): unknown => {
  const verifyOverlapping = values.size < 1000;
  let valuesOrderedMap = values;

  let duplicateInfo: IMap<string, IMap<string, number>> = IMap();
  let duplicateCounts: IMap<string, IMap<string, number>> = IMap();
  let index = 0;

  values.forEach((block, blockKey) => {
    const blockContent = block?.getData();
    let value = blockContent?.get('ip');

    // Don't parse any empty content block or empty value
    if (!blockContent?.size || !value) {
      return;
    }

    const ipText = value.get('text');

    index++;

    if (
      verifyOverlapping &&
      !value.get('error') &&
      !value.get('removed') &&
      !value.get('exclusion') &&
      values.some((currentValueData, curIndex) => {
        const currentData = currentValueData?.getData();
        const currentValue = currentData?.get('ip');

        return (
          curIndex !== blockKey &&
          currentValue &&
          !currentValue.get('removed') &&
          !currentValue.get('error') &&
          !currentValue.get('exclusion') &&
          ((!value.get('name') && areMapIpRangesOverlapping(value, currentValue)) ||
            (value.get('name') && areMapInterfacesDuplicates(value, currentValue)))
        );
      })
    ) {
      if (value.get('fqdn')) {
        value = value.set('duplicate', intl('IPLists.List.OverlappingFQDN'));
        duplicateCounts = setDuplicateCounts({ipText, duplicateCounts, blockKey});
      } else if (value.get('name')) {
        value = value.set('duplicate', intl('IPLists.List.OverlappingInterfaces'));
        duplicateCounts = setDuplicateCounts({ipText, duplicateCounts, blockKey});
      } else {
        value = value.set('duplicate', intl('IPLists.List.OverlappingAddresses'));
        duplicateCounts = setDuplicateCounts({ipText, duplicateCounts, blockKey});
      }
    } else if (
      verifyOverlapping &&
      !value.get('error') &&
      !value.get('removed') &&
      value.get('exclusion') &&
      !value.get('name') &&
      !values.some((currentValueData, curIndex) => {
        const currentData = currentValueData?.getData();
        const currentValue = currentData?.get('ip');

        return (
          curIndex !== blockKey &&
          currentValue &&
          !currentValue.get('removed') &&
          !currentValue.get('error') &&
          !currentValue.get('exclusion') &&
          isMapIpContainedIn(value, currentValue)
        );
      })
    ) {
      // Set as an error when there is an exclusion
      value = value.set('error', intl('IPLists.List.Range'));
    } else {
      value = value.set('duplicate', null);
    }

    if (areMapIpRangesEqual(value.get('original'), value)) {
      const type = value.get('type') === 'updated' ? 'old' : value.get('type');

      value = value.set('type', type);
    } else {
      const type = value.get('type') === 'old' ? 'updated' : value.get('type');

      value = value.set('type', type);
    }

    // Logic use to set the text used for tool tip to show where the first duplicate occurred
    // There are instances when a value is set as duplicate but doesn't necessary mean there are multiple instances of it.
    // e.g. When ip are overlapping then a duplicate will be set. Still need to show error message.
    //    10.1.1.23                     // Overlapping Address
    //    10.0.0.0 - 10.255.255.255     // Overlapping Address
    //
    // e.g. When ip are duplicates will show this type of error message
    //      [1] 10.1.1.1
    //      [2] 10.1.1.1   // A tooltip with "Overlapping Address Error at line 1"
    //      [3] 10.1.1.1   // A tooltip with "Overlapping Address Error at line 1"
    if (value.get('duplicate')) {
      // Get the count
      const count = duplicateCounts.getIn([ipText, 'count']);

      // Make sure there are duplicates with same ipText
      if (count > 1 && duplicateInfo.has(ipText)) {
        const duplicateAt = duplicateInfo.get(ipText).get('duplicateAt');
        const duplicateMessage = value.get('duplicate');
        const firstDuplicateKey = duplicateCounts.getIn([ipText, 'firstDuplicateBlockKey']);
        // Get the blockMap<OrderedMap> by using the firstDuplicateKey. Set a marker to ip<Map> where
        // the first occurrence happened. By having this marker 'toolTipErrorLineToReference' will know to not show
        // the error but only show the error on the other duplicates.
        const blockMapKeyValue = valuesOrderedMap.get(firstDuplicateKey);
        const blockMapData = blockMapKeyValue.getData();
        let blockMapIp = blockMapData.get('ip');

        // Setting toolTipErrorLineToReference to indicate there are duplicates with the exact ipText thus don't
        // need to show the first occurrence of the duplicate only the ones after
        blockMapIp = blockMapIp.set('toolTipErrorLineToReference', true);
        valuesOrderedMap = valuesOrderedMap.setIn([firstDuplicateKey, 'data', 'ip'], blockMapIp);

        // Set 'toolTipError' property with duplicate message to point to first error occurrence
        value = value.set(
          'toolTipError',
          intl('IPLists.List.DuplicateErrorLineMessage', {duplicateAt, duplicateMessage}),
        );
      } else if (!duplicateInfo.has(ipText) && value.has('toolTipError')) {
        // When line [1] is dynamically changed from 10.1.1.1 to 10.1.1.17 then check if duplicateInfo.has(ipText) doesn't exist
        // and 'toolTipError' flag exist then need to delete and reset this value to reflect a new duplicate index position
        // e.g  Original
        //      [1] 10.1.1.1
        //      [2] 10.1.1.1   // A tooltip with "Overlapping Address Error at line 1"
        //      [3] 10.1.1.1   // A tooltip with "Overlapping Address Error at line 1"
        //
        // e.g  New - A change from line [1] 10.1.1.1 to 10.1.1.17
        //      [1] 10.1.1.17
        //      [2] 10.1.1.1
        //      [3] 10.1.1.1  // A tooltip with "Overlapping Address Error at line 2"
        value = value.delete('toolTipError');
        duplicateInfo = duplicateInfo.setIn([ipText, 'duplicateAt'], index);
      } else {
        // Set the first index where the duplicate started
        duplicateInfo = duplicateInfo.setIn([ipText, 'duplicateAt'], index);
      }
    } else {
      // Remove toolTipError property when duplicate is no longer valid
      value = value.delete('toolTipError');
    }

    valuesOrderedMap = valuesOrderedMap.setIn([blockKey, 'data', 'ip'], value);
  });

  return valuesOrderedMap;
};

export const generateIpFqdn = (item: GenerateIpFqdn): string | undefined => {
  let port;

  if (item.port) {
    port = `:${item.port}`;
  }

  if (item.fqdn) {
    const description = item.description ? ` "${item.description}"` : '';

    return port ? item.fqdn + port + description : item.fqdn + description;
  }

  if (item.ip) {
    let ip = item.ip;

    if (port && isValidIPv6(ip)) {
      ip = `[${ip}]`;
    }

    const networkName = item.network?.name ? ` "${item.network.name}"` : '';

    return port ? ip + port + networkName : ip + networkName;
  }
};

/** Get the ip format that is used for parsing ip validation for (trusted proxy list, global network ips)
 * @example
 *   Use formatting in ip::parseAddressLine()
 */
export const getIpFormat = (
  data: string[],
): {
  originalIpNamesFormat: string[];
  ipFormat: ParseAddressLine[];
} => {
  const originalIpNamesFormat: string[] = [];
  const ipFormat = data.map(address => {
    const ip = {from_ip: address, exclusion: false, type: 'old'};

    const originalText = stringifyAddressObject(ip);
    const transformedIP = parseAddressLine(originalText);

    // Used to display the proper ip name
    // Don't add to originalIpNames when address === null. An address === null is used to indicate doesn't have
    // trusted proxy ips set.
    if (address !== null && originalText !== '') {
      originalIpNamesFormat.push(originalText);
    }

    transformedIP.type = ip.type;

    if (!transformedIP.original) {
      /** Clone to prevent circular reference
       *  Setting original is important for modification logic when editing
       * */
      transformedIP.original = _.cloneDeep(transformedIP);
    }

    return transformedIP;
  });

  return {originalIpNamesFormat, ipFormat};
};
