/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import d3 from 'd3';
import _ from 'lodash';
import React from 'react';
import cx from 'classnames';
import intl from 'intl';
import {State} from 'react-router';
import {findDOMNode} from 'react-dom';
import update from 'react-addons-update';
import MapPageStore from '../../stores/MapPageStore';
import actionCreators from '../../actions/actionCreators';
import NodeComponent from './Node.jsx';
import RoleComponent from './Role.jsx';
import LinkComponent from './Link.jsx';
import InternetComponent from './Internet.jsx';
import ClusterVisualization from '../../utils/Cluster';
import {AlertDialog} from '..';
import GraphTransformStore from '../../stores/GraphTransformStore';
import TrafficStore from '../../stores/TrafficStore';
import TrafficFilterStore from '../../stores/TrafficFilterStore';

let dragged = false;

export default React.createClass({
  mixins: [State],

  componentDidMount() {
    this.d3Wrapper = d3.select(findDOMNode(this));

    if (
      GraphTransformStore.getInteractionType() === 'move' &&
      MapPageStore.getMapLevel() === 'full' &&
      this.props.whileDragCluster &&
      this.props.afterDragCluster
    ) {
      this.d3Wrapper.call(ClusterVisualization.dragCluster, this.whileDragCluster, this.afterDragCluster);
    } else if (
      GraphTransformStore.getInteractionType() === 'select' &&
      MapPageStore.getMapLevel() !== 'full' &&
      this.props.whileDragCluster &&
      this.props.afterDragCluster
    ) {
      this.d3Wrapper.call(ClusterVisualization.dragCluster, this.whileDragCluster, this.afterDragCluster);
    }

    // todo: need refactor this part
    // if mapType changes, the data is not updated yet
    // thus don't enter a new component, but keep the current component
    if (this.props.preventGraphElementAnimation) {
      this.d3Wrapper
        .datum(this.props.data)
        .call(
          _.bind(
            ClusterVisualization.update,
            ClusterVisualization,
            this.props.truncated,
            this.props.mapLevel,
            this.props.linearAnimation,
            0,
          ),
        );

      return;
    }

    this.d3Wrapper
      .datum(this.props.data)
      .call(_.bind(ClusterVisualization.enter, ClusterVisualization, this.props.mapLevel))
      .call(
        _.bind(
          ClusterVisualization.update,
          ClusterVisualization,
          this.props.truncated,
          this.props.mapLevel,
          this.props.linearAnimation,
          0,
        ),
      );
  },

  componentDidUpdate() {
    if (
      GraphTransformStore.getInteractionType() === 'move' &&
      MapPageStore.getMapLevel() === 'full' &&
      this.props.whileDragCluster &&
      this.props.afterDragCluster
    ) {
      this.d3Wrapper.call(ClusterVisualization.dragCluster, this.whileDragCluster, this.afterDragCluster);
    } else if (
      GraphTransformStore.getInteractionType() === 'select' &&
      MapPageStore.getMapLevel() !== 'full' &&
      this.props.whileDragCluster &&
      this.props.afterDragCluster
    ) {
      this.d3Wrapper.call(ClusterVisualization.dragCluster, this.whileDragCluster, this.afterDragCluster);
    } else {
      this.d3Wrapper.on('mousedown.drag', null);
    }

    this.d3Wrapper
      .datum(this.props.data)
      .call(
        _.bind(
          ClusterVisualization.update,
          ClusterVisualization,
          this.props.truncated,
          this.props.mapLevel,
          this.props.linearAnimation,
          0,
        ),
      );
  },

  callParentAfterDragCluster() {
    // todo: need think about full map/sweet spot map
    const data = {
      type: 'group',
      href: this.props.data.href,
      locHref:
        this.props.data.displayType === 'summary'
          ? this.props.data.labels && this.props.data.labels.loc && this.props.data.labels.loc.href
          : null,
      displayType: this.props.data.displayType,
      clusterId: this.props.data.clusterId,
      x: this.props.data.x,
      y: this.props.data.y,
    };

    this.props.afterDragCluster(data);
  },

  afterDragCluster() {
    if (!dragged || !this.props.afterDragCluster) {
      dragged = false;

      return;
    }

    _.defer(() => {
      this.callParentAfterDragCluster();
    });
  },

  afterDragNode(node) {
    if (d3.event.defaultPrevented) {
      return;
    }

    const x1 = this.props.data.x - this.props.data.width / 2;
    const y1 = this.props.data.y - this.props.data.height / 2;
    const x2 = x1 + this.props.data.width;
    const y2 = y1 + this.props.data.height;

    // Need to convert node's x & y to global graph positions
    const nodeX = node.x + this.props.data.x;
    const nodeY = node.y + this.props.data.y;

    const data = {};

    // First see if the node is still in cluster
    if (x1 < nodeX && nodeX < x2 && y1 < nodeY && nodeY < y2) {
      if (this.props.data.href || this.props.data.clusterId) {
        data.cluster = {
          href: this.props.data.href,
          labels: this.props.data.labels,
          clusterId: this.props.data.clusterId,
          nodes: {},
        };

        if (this.props.data.discovered) {
          _.each(this.props.data.nodes, node => {
            data.cluster.nodes[node.href] = {href: node.href};
          });
        }

        data.node = node;
      }

      actionCreators.updatePosition(data);
      this.props.afterDragNode();

      return true;
    }

    // If not, then let's have graph calculate where it went
    // but first convert the node's positions to global positions
    node.x = nodeX;
    node.y = nodeY;

    this.props.afterDragNode(node, this.props.data);

    return;
  },

  handleHoverCluster(evt) {
    if (
      GraphTransformStore.getInteractionType() === 'move' ||
      GraphTransformStore.getInteractionType() === 'rightClick'
    ) {
      return;
    }

    if (
      (this.props.mapLevel === 'group' || this.props.mapLevel === 'workload') &&
      (this.props.data.displayType === 'summary' || this.props.data.displayType === 'token')
    ) {
      if (this.props.data.displayType !== 'full' && this.props.hoverCluster) {
        this.props.hoverCluster(this.props.data);
      }

      const roleNum = {roleNum: this.props.data.data.roleCounts.length};

      actionCreators.showMapTooltip({
        type: 'cluster',
        tooltipInfo: {...this.props.data, ...roleNum},
        location: {x: evt.pageX, y: evt.pageY},
      });
    }
  },

  handleUnHoverCluster() {
    if (
      GraphTransformStore.getInteractionType() === 'move' ||
      GraphTransformStore.getInteractionType() === 'rightClick'
    ) {
      return;
    }

    if (
      (this.props.mapLevel === 'group' || this.props.mapLevel === 'workload') &&
      (this.props.data.displayType === 'summary' || this.props.data.displayType === 'token')
    ) {
      if (this.props.data.displayType !== 'full' && this.props.unhoverCluster) {
        this.props.unhoverCluster();
      }

      actionCreators.hideMapTooltip();
    }
  },

  handleTextHover(evt) {
    if (
      GraphTransformStore.getInteractionType() === 'move' ||
      GraphTransformStore.getInteractionType() === 'rightClick'
    ) {
      return;
    }

    const roles = [];

    this.props.data.nodes.forEach(node => {
      const role = node.labels && node.labels.role ? node.labels.role.value : undefined;

      if (!roles.includes(role)) {
        roles.push(role);
      }
    });

    const roleNum = {roleNum: roles.length};

    if (
      (this.props.mapLevel === 'workload' ||
        this.props.mapLevel === 'focusedAppGroup' ||
        this.props.mapLevel === 'connectedAppGroup' ||
        this.props.mapLevel === 'full') &&
      this.props.data.displayType === 'full'
    ) {
      actionCreators.showMapTooltip({
        type: 'cluster',
        tooltipInfo: {...this.props.data, ...roleNum},
        location: {x: evt.pageX, y: evt.pageY},
      });
    }
  },

  handleTextUnhover() {
    if (
      GraphTransformStore.getInteractionType() === 'move' ||
      GraphTransformStore.getInteractionType() === 'rightClick'
    ) {
      return;
    }

    if (
      (this.props.mapLevel === 'workload' ||
        this.props.mapLevel === 'focusedAppGroup' ||
        this.props.mapLevel === 'connectedAppGroup' ||
        this.props.mapLevel === 'full') &&
      this.props.data.displayType === 'full'
    ) {
      actionCreators.hideMapTooltip();
    }
  },

  // Cluster events
  handleSelectCluster(evt) {
    if (evt.defaultPrevented) {
      return;
    }

    if (this.props.data.tooManyWorkloads) {
      actionCreators.openDialog(
        <AlertDialog
          message={
            this.props.data.type === 'appGroup'
              ? intl('Map.Workloads.AppGroupTooManyToDisplay')
              : intl('Map.Workloads.GroupTooManyToDisplay')
          }
        />,
      );

      return;
    }

    let clusters = [];
    const currentLevel = MapPageStore.getMapLevel();
    let mapLevel;

    if (this.props.data.displayType === 'token' && currentLevel === 'workload') {
      actionCreators.updateGraphCalculated();

      // if in workload map and token is clicked, expand the cluster
      const focusedCluster = this.props.data.href;
      const expandedCluster = MapPageStore.getMapRoute().id;
      const data = [focusedCluster, expandedCluster];

      actionCreators.expandCluster(data);
    } else if (currentLevel === 'group') {
      mapLevel = {
        type: this.props.data.labels.loc || currentLevel === 'workload' ? 'group' : 'full',
        id: this.props.data.href !== 'discovered' && !this.props.data.labels.loc ? 'nolocation' : this.props.data.href,
      };
      this.props.updateMapLevel(mapLevel);

      if (this.props.data.href || this.props.data.clusterId) {
        clusters = [
          {
            type: 'group',
            href: this.props.data.href,
            clusterId: this.props.data.clusterId,
            caps: this.props.data.caps,
          },
        ];

        if (this.props.data.selected) {
          actionCreators.unselectComponent(clusters);
        } else {
          actionCreators.updateComponentSelection(clusters);
        }
      }
    } else if (currentLevel === 'workload' && this.props.data.displayType === 'full') {
      const isGroupWithLoc = this.props.data.labels && this.props.data.labels.loc;

      // click one group
      // if the group is unselected, select it and open command panel
      // if the group is selected and focused, unselect it and close command panel
      // if the group is not focused with loc label, focus it and open command panel
      // if the group is not focused without loc label, unselect it and close command panel
      if (!this.props.data.selected) {
        clusters = [
          {
            type: 'group',
            href: this.props.data.href,
            clusterId: this.props.data.clusterId,
          },
        ];
        actionCreators.unselectComponent();
        actionCreators.selectComponent(clusters);
      } else if (this.props.data.focused) {
        actionCreators.unselectComponent();
      } else if (isGroupWithLoc) {
        mapLevel = {
          type: 'group',
          id: this.props.data.href,
        };
        this.props.updateMapLevel(mapLevel);
      } else {
        actionCreators.unselectComponent();
      }
    } else if (currentLevel === 'full' || this.props.mapType === 'app') {
      // if in full map, clicking on cluster results in opening the command panel
      if (this.props.data.href || this.props.data.clusterId) {
        clusters = [
          {
            type: this.props.mapType === 'app' ? 'appGroup' : 'group',
            href: this.props.data.href,
            clusterId: this.props.data.clusterId,
            x: this.props.data.x,
            y: this.props.data.y,
          },
        ];

        if (this.props.data.selected && !dragged) {
          actionCreators.unselectComponent(clusters);
        } else {
          actionCreators.unselectComponent();
          actionCreators.selectComponent(clusters);
        }

        dragged = false;
      }
    }

    this.tooltip = false;
    actionCreators.hideMapTooltip();
  },

  // Drag clusters
  whileDragCluster(dx, dy) {
    if (!this.props.whileDragCluster) {
      return;
    }

    const x = this.props.data.x + dx;
    const y = this.props.data.y + dy;
    const locHref =
      this.props.data.displayType === 'summary'
        ? this.props.data.labels && this.props.data.labels.loc && this.props.data.labels.loc.href
        : null;

    const cluster = update(this.props.data, {
      $merge: {x, y, locHref, drag: true},
    });

    dragged = true;
    this.props.whileDragCluster(cluster);
  },

  // Drag nodes inside of clusters
  whileDragNode(node) {
    const nodes = _.map(this.props.data.nodes, n => {
      // If 'n' was the dragged node, return updated 'node',
      // else return unaltered 'n'
      if (n.href === node.href) {
        return node;
      }

      return n;
    });
    const links = _.map(this.props.data.links, link => {
      // Update any of the clusters' links whose
      // source or target may be the updated node
      let newLink;

      if (link.source === link.target && link.source.href === node.href) {
        //If a role has a link to itself;
        newLink = update(link, {
          $merge: {source: node, target: node, drag: true},
        });
      } else if (link.source.href === node.href) {
        newLink = update(link, {
          $merge: {source: node, drag: true},
        });
      } else if (link.target.href === node.href) {
        newLink = update(link, {
          $merge: {target: node, drag: true},
        });
      }

      return newLink || link;
    });
    const cluster = update(this.props.data, {
      $merge: {nodes, links},
    });

    this.props.whileDragNode(cluster, node);
  },

  handleContextMenu(evt) {
    evt.preventDefault();

    actionCreators.showMapMenu({
      type: 'cluster',
      data: {...this.props.data},
      location: {x: evt.pageX, y: evt.pageY},
      component: this.d3Wrapper,
    });

    /* Hide the tooltip on right click */
    this.tooltip = false;
    actionCreators.hideMapTooltip();
  },

  render() {
    const {data} = this.props;
    const reduceOpacity = {
      true: 'reduce-opacity',
      false: 'normal-opacity',
    };

    const vulnerabilityExposureScoreClass = cx('il-cluster-vulnerability', {
      'None': data.vulnerabilitySeverity === 'none',
      'Vulnerability-Text--low': data.vulnerabilitySeverity === 'low',
      'Vulnerability-Text--medium': data.vulnerabilitySeverity === 'medium',
      'Vulnerability-Text--high': data.vulnerabilitySeverity === 'critical',
      'Vulnerability-Text--critical': data.vulnerabilitySeverity === 'high',
    });

    let vulnerabilityExposureScore = null;

    if (data.vulnerability) {
      const ves = data.vulnerability.aggregatedValues?.vulnerabilityExposureScore;

      vulnerabilityExposureScore = isNaN(ves) ? '' : `${ves} `;
    }

    const clusterNameClass = cx('il-cluster-name', {
      'il-vulnerability-name': Boolean(data.vulnerability),
    });

    const clusterLabels = this.props.data.name && (
      <g className="il-cluster-label" onMouseOver={this.handleTextHover} onMouseLeave={this.handleTextUnhover}>
        <text className="il-cluster-label-text">
          {Boolean(data.vulnerability) && vulnerabilityExposureScore !== null && (
            <tspan className={vulnerabilityExposureScoreClass}>{vulnerabilityExposureScore}</tspan>
          )}
          <tspan className={clusterNameClass}>{data.name}</tspan>
        </text>
      </g>
    );

    const nodes =
      this.props.data.displayType === 'full' &&
      _.map(this.props.data.nodes, node => {
        if (node.type === 'role') {
          return (
            <RoleComponent
              key={node.href}
              data={node}
              hoverNode={this.props.hoverNode}
              unhoverNode={this.props.unhoverNode}
              whileDragRole={this.whileDragNode}
              afterDragRole={this.afterDragNode}
            />
          );
        }

        if (node.type === 'workload') {
          return (
            <NodeComponent
              key={node.href}
              data={node}
              hoverNode={this.props.hoverNode}
              unhoverNode={this.props.unhoverNode}
              whileDragNode={this.whileDragNode}
              afterDragNode={this.afterDragNode}
            />
          );
        }

        if (node.type === 'virtualService') {
          return (
            <NodeComponent
              key={node.href}
              data={node}
              hoverNode={this.props.hoverNode}
              unhoverNode={this.props.unhoverNode}
              whileDragNode={this.whileDragNode}
              afterDragNode={this.afterDragNode}
            />
          );
        }
      });

    const links =
      this.props.data.displayType === 'full' &&
      _.map(this.props.data.links, link => (
        <LinkComponent
          key={link.identifier}
          data={{groupTransform: [this.props.data.x, this.props.data.y], ...link}}
          preventGraphElementAnimation={this.props.preventGraphElementAnimation}
          mapType={this.props.mapType}
          hoverLink={this.props.hoverLink}
          unhoverLink={this.props.unhoverLink}
        />
      ));
    const internets =
      this.props.data.displayType === 'full' &&
      _.map(this.props.data.internets, internet => (
        <InternetComponent
          key={internet.identifier}
          data={internet}
          hoverInternet={this.props.hoverInternet}
          unhoverInternet={this.props.unhoverInternet}
        />
      ));

    const workloadsNum = this.props.data.displayType !== 'full' && (
      <text className="il-cluster-workloadsNum">{this.props.data.entityCounts}</text>
    );
    const path = this.props.data.tween ? <path className="il-cluster-animation-path" /> : null;
    const reducedOpacityClass = this.props.data.reduceOpacity
      ? ` il-${reduceOpacity[this.props.data.reduceOpacity]}`
      : '';

    let emptyText = null;

    if (
      TrafficStore.isAppGroupsLoaded() &&
      !nodes.length &&
      data.appGroup &&
      TrafficFilterStore.getHiddenPolicyStates().length
    ) {
      emptyText = (
        <g className="il-empty-group-msg">
          <text className="il-empty-text">{intl('Map.Traffic.Filter.NoResult')}</text>
        </g>
      );
    }

    return (
      <g className={`il-cluster${reducedOpacityClass}`} data-tid={this.props.data.name}>
        <g
          className="il-cluster-bg"
          onClick={GraphTransformStore.getInteractionType() === 'select' ? this.handleSelectCluster : null}
          onContextMenu={this.handleContextMenu}
        >
          <rect
            className="il-cluster-type"
            onMouseMove={this.handleHoverCluster}
            onMouseLeave={this.handleUnHoverCluster}
          />
          {path}
        </g>
        <g className="il-cluster-others">
          {clusterLabels}
          {links}
          {nodes}
          {internets}
          {workloadsNum}
          {emptyText}
        </g>
      </g>
    );
  },
});
