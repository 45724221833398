/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import d3 from 'd3';
import _ from 'lodash';
import React from 'react';
import cx from 'classnames';
import {findDOMNode} from 'react-dom';
import LinkVisualization from '../../utils/Link';
import actionCreators from '../../actions/actionCreators';
import {GraphTransformStore, MapPageStore} from '../../stores';

let timeoutRef;

export default React.createClass({
  componentDidMount() {
    const {truncated, preventGraphElementAnimation} = this.props;

    this.d3Wrapper = d3.select(findDOMNode(this));
    this.link = this.d3Wrapper.selectAll('.il-link');
    this.d3Wrapper.selectAll('path').datum(this.props.data);

    this.link
      .call(_.bind(LinkVisualization.enterLink, LinkVisualization))
      .call(_.bind(LinkVisualization.updateLink, LinkVisualization, truncated, preventGraphElementAnimation));
  },

  // todo: need refactor this part
  // if mapType changes, the data is not updated yet
  // thus don't enter a new component, but keep the current component
  shouldComponentUpdate() {
    return !this.props.preventGraphElementAnimation;
  },

  componentDidUpdate() {
    const {truncated, preventGraphElementAnimation} = this.props;

    this.d3Wrapper.selectAll('path').datum(this.props.data);
    this.link.call(_.bind(LinkVisualization.updateLink, LinkVisualization, truncated, preventGraphElementAnimation));
  },

  handleHoverLink(evt) {
    if (
      this.props.data.type === 'notLoaded' ||
      !this.props.data.identifier ||
      this.props.data.linkType === 'appSupergroupLink' ||
      evt.buttons !== 0 ||
      GraphTransformStore.getInteractionType() === 'move' ||
      GraphTransformStore.getInteractionType() === 'rightClick'
    ) {
      return;
    }

    if (this.props.hoverLink) {
      if (this.props.data.source.type === 'group' && this.props.data.target.type === 'group') {
        this.props.hoverLink(this.props.data);
      } else {
        this.link.call(_.bind(LinkVisualization.highlightLink, LinkVisualization));
        timeoutRef = setTimeout(() => {
          this.props.hoverLink(this.props.data);
        }, 400);
      }
    }

    if (this.props.hoverLink) {
      this.props.hoverLink(this.props.data);
    }

    if (!this.tooltip) {
      actionCreators.showMapTooltip({
        type: 'link',
        tooltipInfo: {...this.props.data},
        location: {x: evt.pageX, y: evt.pageY},
      });

      this.tooltip = true;
    }
  },

  handleSelectLink() {
    if (!this.props.data.identifier || this.props.data.type === 'notLoaded' || _.isEmpty(this.props.data.connections)) {
      return;
    }

    // If this is an internet link, then send over all the hrefs.
    // If there's no internetLinks attribute, then this must
    // be a regularly link between workloads, so it only has one href
    let traffics = [];
    const source = this.props.data.source;
    const target = this.props.data.target;
    const clusterHref =
      source.cluster && target.cluster && source.cluster.href === target.cluster.href ? source.cluster.href : null;

    if (this.props.data.internetLinks) {
      traffics = _.map(this.props.data.internetLinks, link => ({
        type: link.type,
        href: link.href,
        identifier: this.props.data.identifier,
        clusterHref,
      }));
    } else {
      traffics = [
        {
          type: 'traffic',
          href: this.props.data.href,
          identifier: this.props.data.identifier,
          clusterHref,
        },
      ];
    }

    if (this.props.data.selected) {
      actionCreators.unselectComponent(traffics);
    } else {
      actionCreators.updateComponentSelection(traffics);

      // zoom to fit when click on a link between one expanded cluster and another token
      // if zoom to fit, then the expanded cluster should be closed
      if (
        this.props.data.source.type === 'group' &&
        this.props.data.target.type === 'group' &&
        (this.props.data.source.displayType === 'full' || this.props.data.target.displayType === 'full')
      ) {
        actionCreators.removeExpandedCluster();
        actionCreators.updateZoomToFit(this.props.data);
      }
    }

    if (this.props.hoverLink) {
      if (this.props.data.source.type === 'group' && this.props.data.target.type === 'group') {
        this.props.hoverLink(this.props.data);
      } else {
        this.link.call(_.bind(LinkVisualization.highlightLink, LinkVisualization));
        timeoutRef = setTimeout(() => {
          this.props.hoverLink(this.props.data);
        }, 400);
      }
    }
  },

  handleUnhoverLink(evt) {
    if (
      this.props.data.type === 'notLoaded' ||
      evt.buttons !== 0 ||
      GraphTransformStore.getInteractionType() === 'move' ||
      GraphTransformStore.getInteractionType() === 'rightClick'
    ) {
      return;
    }

    if (this.props.unhoverLink) {
      this.props.unhoverLink(this.props.data);
    }

    if (timeoutRef) {
      clearTimeout(timeoutRef);
      timeoutRef = undefined;
    }

    this.tooltip = false;
    actionCreators.hideMapTooltip();
  },

  handleContextMenu(evt) {
    evt.preventDefault();

    const notExpandable = ['workload', 'internet', 'fqdn', 'ipList', 'virtualService'];
    const contextConditionForExpandLink =
      notExpandable.includes(this.props.data.source.type) && notExpandable.includes(this.props.data.target.type);
    const contextConditionForCollapseLink =
      MapPageStore.getMapLevel() !== 'full' &&
      notExpandable.includes(this.props.data.source.type) &&
      notExpandable.includes(this.props.data.target.type);
    const fullMapView = MapPageStore.getMapLevel() === 'full';
    const isConnectedAppGroupProviderOrConsumer =
      (MapPageStore.getMapLevel() === 'focusedAppGroup' || MapPageStore.getMapLevel() === 'connectedAppGroup') &&
      (this.props.data.source.appGroupType === 'consuming' || this.props.data.target.appGroupType === 'providing');

    if (
      !isConnectedAppGroupProviderOrConsumer &&
      (contextConditionForCollapseLink || !contextConditionForExpandLink || fullMapView)
    ) {
      actionCreators.showMapMenu({
        type: 'link',
        collapse: !contextConditionForExpandLink ? 0 : 1,
        data: {...this.props.data},
        location: {x: evt.pageX, y: evt.pageY},
      });
    }

    /* Hide the tooltip on right click */
    this.tooltip = false;
    actionCreators.hideMapTooltip();
  },

  calculateGradientCategory(source, target) {
    const isInterGroup = (source.cluster || target.cluster) && source.cluster !== target.cluster;
    let tx = target.x;
    let ty = target.y;
    let sx = source.x;
    let sy = source.y;

    if (isInterGroup) {
      if (source.cluster) {
        sx += source.cluster.x;
        sy += source.cluster.y;
      }

      if (target.cluster) {
        tx += target.cluster.x;
        ty += target.cluster.y;
      }
    }

    const dx = tx - sx;
    const dy = ty - sy;
    const angle = Math.atan2(dy, dx) * (180 / Math.PI);

    // Gradient Thresholding Angles.
    const a1 = 70;
    const a2 = 110;

    let category = 1; // 0 - 70 || -70 - 0

    if ((angle > a2 && angle << 180) || (angle > -180 && angle < -a2)) {
      category = 2;
    } else if (angle >= a1 && angle <= a2) {
      category = 3;
    } else if (angle >= -a2 && angle <= -a1) {
      category = 4;
    }

    return category;
  },

  render() {
    const interactionType = GraphTransformStore.getInteractionType();
    const gradientCategory = this.calculateGradientCategory(this.props.data.source, this.props.data.target);
    const linkClass = cx({
      'il-link': true,
      'HiddenLink': this.props.data.isHidden,
      'UnknownLink1': this.props.data.type === 'unknown' && gradientCategory === 1,
      'UnknownLink2': this.props.data.type === 'unknown' && gradientCategory === 2,
      'UnknownLink3': this.props.data.type === 'unknown' && gradientCategory === 3,
      'UnknownLink4': this.props.data.type === 'unknown' && gradientCategory === 4,
      'GrayLink': this.props.data.type === 'gray',
      'VulnerablePortLink': this.props.data.type === 'vulnerability',
      'PotentiallyVulnerablePortLink': this.props.data.type === 'potentiallyVulnerable',
      'OrangeLink': this.props.data.type === 'orange',
      'RedLink': this.props.data.type === 'red',
      'LightGreenLink': this.props.data.type === 'lightGreen',
      'DiscoveredLink': this.props.data.type === 'discovered',
      'GreenLink': this.props.data.type === 'green',
      'ColorDeficiency': this.props.data.colorBlind !== 'normal',
      'DraftLink': this.props.data.version === 'draft' && !this.props.data.isSupressed,
      'ReduceOpacity': !this.props.data.isHidden && (this.props.data.reduceOpacity || this.props.data.isSupressed),
      'TrafficLink': this.props.data.method !== 'legend',
    });

    const linkIdentifier = this.props.data.identifier;
    const linkId = linkIdentifier ? 'link-' + linkIdentifier.replace(/[,/]/g, '-') : '';

    return (
      <g>
        <path
          gradientTransform="rotate(90)"
          id={linkId}
          className={linkClass}
          onClick={interactionType === 'select' ? this.handleSelectLink : null}
          data-tid={this.props.data.href}
          onMouseEnter={this.handleHoverLink}
          onMouseLeave={this.handleUnhoverLink}
          onContextMenu={this.handleContextMenu}
        />
      </g>
    );
  },
});
