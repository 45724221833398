/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {TrafficStore, SessionStore} from '../../stores';
import {RestApiUtils, WorkloadUtils, IpUtils, LabelUtils, PortUtils, ServiceUtils, RenderUtils} from '../../utils';
import React, {PropTypes} from 'react';
import ObjectSelector from '../ObjectSelector.jsx';
import Tooltip from '../Tooltip.jsx';

const workloadFacetMap = isEdge => ({
  [intl('Common.Workloads')]: 'workloads',
  ...(isEdge ? {} : {[intl('Common.ContainerWorkloads')]: 'containerWorkloads'}),
});

const ipaddressFacetMap = () => ({
  [intl('Common.IPAddress')]: 'ipaddress',
  [intl('IPLists.CIDRBlock')]: 'cidrBlock',
});

const ipListsFacetMap = () => ({
  [intl('Common.IPLists')]: 'iplist',
});

const domainFacetMap = () => ({
  [intl('PCE.FQDN')]: 'fqdn',
});

const providerFacetMap = isEdge =>
  isEdge
    ? {
        [intl('Common.Groups')]: 'labels-role',
        [intl('Common.Workloads')]: 'workloads',
        [intl('Common.IPLists')]: 'iplist',
        [intl('Common.IPAddress')]: 'ipaddress',
        [intl('IPLists.CIDRBlock')]: 'cidrBlock',
        [intl('PCE.FQDN')]: 'fqdn',
      }
    : {
        [intl('Common.Labels')]: 'labels',
        [intl('Labels.Groups')]: 'label_group',
        [intl('Common.AppGroups')]: 'appgroups',
        [intl('Common.IPLists')]: 'iplist',
        [intl('Common.Workloads')]: 'workloads',
        [intl('Common.ContainerWorkloads')]: 'containerWorkloads',
        [intl('Common.IPAddress')]: 'ipaddress',
        [intl('IPLists.CIDRBlock')]: 'cidrBlock',
        [intl('PCE.FQDN')]: 'fqdn',
      };

const consumerOrProviderFacetMapWithFQDN = isEdge =>
  isEdge
    ? {
        [intl('Common.Groups')]: 'labels-role',
        [intl('Common.Workloads')]: 'workloads',
        [intl('Common.IPLists')]: 'iplist',
        [intl('Common.IPAddress')]: 'ipaddress',
        [intl('IPLists.CIDRBlock')]: 'cidrBlock',
        [intl('PCE.FQDN')]: 'fqdn',
      }
    : {
        [intl('Common.Labels')]: 'labels',
        [intl('Labels.Groups')]: 'label_group',
        [intl('Common.AppGroups')]: 'appgroups',
        [intl('Common.IPLists')]: 'iplist',
        [intl('Common.Workloads')]: 'workloads',
        [intl('Common.ContainerWorkloads')]: 'containerWorkloads',
        [intl('Common.IPAddress')]: 'ipaddress',
        [intl('IPLists.CIDRBlock')]: 'cidrBlock',
        [intl('PCE.FQDN')]: 'fqdn',
      };

const consumerFacetMap = isEdge =>
  isEdge
    ? {
        [intl('Common.Groups')]: 'labels-role',
        [intl('Common.Workloads')]: 'workloads',
        [intl('Common.IPLists')]: 'iplist',
        [intl('Common.IPAddress')]: 'ipaddress',
        [intl('IPLists.CIDRBlock')]: 'cidrBlock',
      }
    : {
        [intl('Common.Labels')]: 'labels',
        [intl('Labels.Groups')]: 'label_group',
        [intl('Common.AppGroups')]: 'appgroups',
        [intl('Common.IPLists')]: 'iplist',
        [intl('Common.Workloads')]: 'workloads',
        [intl('Common.ContainerWorkloads')]: 'containerWorkloads',
        [intl('Common.IPAddress')]: 'ipaddress',
        [intl('IPLists.CIDRBlock')]: 'cidrBlock',
      };

const appGroupFacetMap = () => ({
  [intl('Common.AppGroups')]: 'appgroups',
});

const appGroupFacetMapProviders = () => ({
  [intl('Common.AppGroups')]: 'appgroups',
});

const reverseFacetMap = isEdge => ({
  role: isEdge ? intl('Common.Groups') : intl('Common.Labels'),
  app: isEdge ? intl('Common.Applications') : intl('Common.Labels'),
  env: isEdge ? intl('Common.Environments') : intl('Common.Labels'),
  loc: isEdge ? intl('Common.Locations') : intl('Common.Labels'),
  appgroups: intl('Common.AppGroups'),
  workloads: intl('Common.Workloads'),
  containerWorkloads: intl('Common.ContainerWorkloads'),
  ipaddress: intl('Common.IPAddress'),
  cidrBlock: intl('IPLists.CIDRBlock'),
  portProtocol: intl('Common.PortAndOrProtocol'),
  portRange: intl('Port.PortRange'),
  processName: intl('Common.Process'),
  windowsService: intl('Common.WinService'),
  policyService: intl('Services.PolicyServices'),
  iplist: intl('Common.IPLists'),
  fqdn: intl('PCE.FQDN'),
  transmission: intl('Components.Transmission'),
  label_group: intl('Labels.Groups'),
  allWorkloads: intl('Workloads.All'),
});

const servicesFacetMap = () => ({
  [intl('Common.PortAndOrProtocol')]: 'portProtocol',
  [intl('Port.PortRange')]: 'portRange',
  [intl('Common.Process')]: 'processName',
  [intl('Common.WinService')]: 'windowsService',
  [intl('Services.PolicyServices')]: 'policyService',
});

const getFacetMaps = props => {
  const {isEdge} = props;

  const maps = {
    workloadFacetMap: workloadFacetMap(isEdge),
    ipaddressFacetMap: ipaddressFacetMap(),
    ipListsFacetMap: ipListsFacetMap(),
    domainFacetMap: domainFacetMap(),
    providerFacetMap: providerFacetMap(isEdge),
    consumerOrProviderFacetMapWithFQDN: consumerOrProviderFacetMapWithFQDN(isEdge),
    consumerFacetMap: consumerFacetMap(isEdge),
    appGroupFacetMap: appGroupFacetMap(),
    appGroupFacetMapProviders: appGroupFacetMapProviders(),
    reverseFacetMap: reverseFacetMap(isEdge),
    servicesFacetMap: servicesFacetMap(),
  };

  return [
    'providerFacetMap',
    'consumerOrProviderFacetMapWithFQDN',
    'appGroupFacetMapProviders',
    'workloadFacetMap',
    'ipaddressFacetMap',
    'ipListsFacetMap',
    'domainFacetMap',
  ].reduce((facetMaps, map) => {
    if (props.filterKey.toLowerCase().includes('provider')) {
      facetMaps[map] = {...facetMaps[map], [intl('Components.Transmission')]: 'transmission'};
    }

    return facetMaps;
  }, maps);
};

export default React.createClass({
  propTypes: {
    filters: PropTypes.array,
    addItem: PropTypes.func,
    removeItem: PropTypes.func,
    placeholder: PropTypes.string,
  },

  getInitialState() {
    return {
      items: {},
      dropdownValues: {},
      singleValues: {},
      filters: this.props.filters,
    };
  },

  componentWillMount() {
    this.setState(getFacetMaps(this.props));
  },

  componentDidMount() {
    const {filterKey} = this.props;
    const shouldExcludeAllWorkloads = filterKey.includes('port') || filterKey.includes('Exclude') || this.props.isEdge;

    _.defer(() => {
      TrafficStore.addAppGroupsLoadedListener(this.getAppGroupsLoaded);
      this.setState({
        items: this.getItems(this.props.filters),
        singleValues: shouldExcludeAllWorkloads ? null : {[intl('Workloads.All')]: {allWorkloads: true}},
      });
      this.getDefaultValues();
    });
  },

  componentWillReceiveProps(nextProps) {
    this.setState(getFacetMaps(nextProps));

    if (!_.isEqual(nextProps.filters, this.state.filters)) {
      const {filters} = nextProps;

      this.setState({
        filters,
        items: this.getItems(filters),
      });
    }
  },

  componentWillUnmount() {
    TrafficStore.removeAppGroupsLoadedListener(this.getAppGroupsLoaded);
  },

  getDefaultValues() {
    const currentFacetMap = this.props.filterKey.includes('port')
      ? this.state.servicesFacetMap
      : this.props.filterKey.includes('provider') || (this.props.filterKey.includes('Exclude') && this.props.OrFilter)
      ? this.state.providerFacetMap
      : this.props.filterKey.includes('Include') && this.props.OrFilter
      ? this.state.consumerOrProviderFacetMapWithFQDN
      : this.state.consumerFacetMap;

    Object.values(currentFacetMap).forEach(facet => this.getFacetValues(facet));
  },

  getAppGroupsLoaded() {
    this.getFacetValues('appgroups');
  },

  getCustomClass(value) {
    let additionalClass = '';

    if (value) {
      additionalClass = 'ObjectSelector-item--label--' + value.key;
    }

    return additionalClass;
  },

  // given the items of the filter, return the available facets (categories)
  getFacets() {
    let facets =
      this.props.filterKey.includes('provider') || (this.props.filterKey.includes('Exclude') && this.props.OrFilter)
        ? {...this.state.providerFacetMap}
        : this.props.filterKey.includes('Include') && this.props.OrFilter
        ? {...this.state.consumerOrProviderFacetMapWithFQDN}
        : {...this.state.consumerFacetMap};

    if (this.props.filterKey.includes('port')) {
      facets = this.state.servicesFacetMap;
    }

    if (this.props.filterKey.includes('Exclude')) {
      delete facets[intl('Common.AppGroups')];
    }

    if (SessionStore.isEdge()) {
      delete facets[intl('Common.AppGroups')];
    }

    return facets;
  },

  getFacetValues(key, query = '', maxResults = 25) {
    if (!key) {
      return;
    }

    if (key === 'policyService') {
      this.setPolicyServices(key, query);

      return;
    }

    if (key === 'iplist') {
      this.setIPLists(key, query);

      return;
    }

    if (key === 'fqdn') {
      this.setFQDN(key, query);

      return;
    }

    if (key === 'transmission') {
      this.setTransmission(key, query);

      return;
    }

    if (key === 'windowsService' || key === 'processName') {
      this.setWindowsAndProcessNames(key, query);

      return;
    }

    if (key === 'ipaddress') {
      this.setIpAddress(key, query);

      return;
    }

    if (key === 'cidrBlock') {
      this.setCIDRBlock(key, query);

      return;
    }

    if (key === 'appgroups') {
      this.setAppGroups(key, query);

      return;
    }

    if (key === 'label_group') {
      this.setLabelGroup(key, query);

      return;
    }

    if (this.state.dropdownValues[key + '-' + query]) {
      return;
    }

    if ((key === 'portProtocol' || key === 'portRange') && query) {
      this.setPortProtocol(key, query);

      return;
    }

    const api = key === 'workloads' || key === 'containerWorkloads' ? key : 'labels';
    const params = {query, max_results: maxResults};

    if (api === 'labels') {
      params.key = key.split('-').pop();
    }

    // if the key is labels, delete the params.key so that the query will search across all keys
    if (key === 'labels') {
      delete params.key;
    }

    RestApiUtils[api].autocomplete(undefined, params).then(response => {
      this.setDropdownValues(key, query, response.body);
    });
  },

  setWindowsAndProcessNames(key, query) {
    query = key === 'processNames' ? query.split('\\').pop() : query;

    this.setDropdownValues(key, query, {
      matches:
        query && query.length
          ? [
              {
                key,
                value: query,
                href: query,
              },
            ]
          : [],
      num_matches: query ? 1 : 0,
    });
  },

  setFQDN(key, query) {
    if (query.includes('*') && !query.endsWith('*') && !query.startsWith('*')) {
      return this.setDropdownValues(key, query, {
        matches: [],
        num_matches: 0,
      });
    }

    this.setDropdownValues(key, query, {
      matches:
        query && query.length
          ? [
              {
                key,
                value: query,
                href: query,
              },
            ]
          : [],
      num_matches: query ? 1 : 0,
    });
  },

  setTransmission(key, query) {
    this.setDropdownValues(key, query, {
      matches: [
        {
          key,
          value: 'Unicast',
          href: 'unicast',
        },
        {
          key,
          value: 'Multicast',
          href: 'multicast',
        },
        {
          key,
          value: 'Broadcast',
          href: 'broadcast',
        },
      ],
      num_matches: 3,
    });
  },

  setDropdownValues(key, query, values) {
    const dropdownValues = {...this.state.dropdownValues};

    const processAndWindowsServices = {};
    const ipLists = {};

    if (key === 'workloads' || key === 'containerWorkloads') {
      values.matches = values.matches.map(match => ({...match, key})).filter(match => match.hostname || match.name);
    }

    if (key === 'iplist') {
      ipLists.matches = values.map(value => {
        let values = [];

        if (value && value.ip_ranges) {
          values = value.ip_ranges;
        }

        if (value && value.fqdns) {
          values = [...values, ...value.fqdns];
        }

        if (value) {
          return {href: value.href, value: values, key, name: value.name};
        }

        return;
      });

      ipLists.num_matches = Object.keys(values).length;
      values = {...ipLists};
    }

    if (key === 'policyService') {
      processAndWindowsServices.matches = values.map(value => {
        if (value && value.windows_services) {
          return {href: value.href, value: value.windows_services, key, name: value.name};
        }

        if (value && value.service_ports) {
          return {href: value.href, value: value.service_ports, key, name: value.name};
        }

        return;
      });
      processAndWindowsServices.num_matches = Object.keys(values).length;
      values = {...processAndWindowsServices};
    }

    if (!(key === 'portProtocol' && dropdownValues.hasOwnProperty(key + '-' + query))) {
      if (query.includes(' ') && this.props.filterKey.includes('port')) {
        values.matches = _.differenceBy(values.matches, this.props.filters, 'href');
      }

      dropdownValues[key + '-' + query] = values;
    }

    if (this.isMounted()) {
      this.setState({dropdownValues});
    }
  },

  setPolicyServices(key, query) {
    const params = {name: query, max_results: 500};

    RestApiUtils.services.getCollection(params, 'draft', true).then(response => {
      response.body = response.body.filter(item => item.name !== 'All Services');
      this.setDropdownValues(key, query, response.body);
    });
  },

  setIPLists(key, query) {
    const params = {name: query, max_results: 500};

    RestApiUtils.ipLists.getCollection(params, 'draft', true).then(response => {
      this.setDropdownValues(key, query, response.body);
    });
  },

  setLabelGroup(key, query) {
    RestApiUtils.labelGroups
      .autocomplete('draft', {
        query,
        max_results: 500,
      })
      .then(response => {
        const labelGroupsResp = response.body;

        this.setDropdownValues(key, query, {
          matches: labelGroupsResp.matches.map(labelGroup => ({
            key: labelGroup.key,
            value: labelGroup.name,
            href: labelGroup.href,
          })),
          num_matches: labelGroupsResp.num_matches,
        });
      });
  },

  setAppGroups(key, query) {
    let appGroups = TrafficStore.getAllAppGroupNodes().filter(appGroup => appGroup.caps?.rulesets?.includes('read'));

    if (!_.isEmpty(query)) {
      appGroups = appGroups.filter(appGroup => appGroup.name.toLowerCase().includes(query.toLowerCase()));
    }

    this.setDropdownValues(key, query, {
      matches: appGroups.map(appGroup => ({
        key,
        value: appGroup.name,
        href: appGroup.href,
      })),
      num_matches: appGroups.length,
    });
  },

  setIpAddress(key, query) {
    let address;

    if (IpUtils.validateIPAddress(query)) {
      address = query;
    }

    this.setDropdownValues(key, query, {
      matches: address
        ? [
            {
              key,
              value: address,
              href: address,
            },
          ]
        : [],
      num_matches: address ? 1 : 0,
    });
  },

  setCIDRBlock(key, query) {
    let address;

    if (IpUtils.validateCidrBlock(query)) {
      address = query;
    }

    this.setDropdownValues(key, query, {
      matches: address
        ? [
            {
              key,
              value: address,
              href: address,
            },
          ]
        : [],
      num_matches: address ? 1 : 0,
    });
  },

  setPortProtocol(key, query) {
    const regexPortProtocol = ServiceUtils.lookupRegexPortProtocol('portProtocol');
    const regexPortRangeProtocol = ServiceUtils.lookupRegexPortProtocol('portRangeProtocol');
    let portProtocol = query.toUpperCase();

    if (key !== 'portRange') {
      const protocols = [
        intl('Protocol.TCP'),
        intl('Protocol.UDP'),
        intl('Protocol.IPv4'),
        intl('Protocol.IPv6'),
        intl('Protocol.ICMP'),
        intl('Protocol.ICMPv6'),
      ].filter(protocol => protocol.toUpperCase().includes(portProtocol));

      if (protocols.length) {
        this.setDropdownValues(key, query, {
          matches: protocols.map(protocol => ({
            key,
            value: protocol,
            href: protocol,
          })),
          num_matches: protocols.length,
        });

        return;
      }
    }

    if (
      (key === 'portProtocol' && this.validatePortProtocolValue(key, query)) ||
      (key === 'portRange' && this.validatePortRange(key, query))
    ) {
      const portProtoList = portProtocol.split(' ');

      if (
        (key === 'portProtocol' && regexPortProtocol.test(portProtocol)) ||
        (key === 'portRange' && regexPortRangeProtocol.test(portProtocol))
      ) {
        const protocolValue = portProtoList[1].includes('T') ? intl('Protocol.TCP') : intl('Protocol.UDP');

        this.setDropdownValues(key, query, {
          matches: [
            {
              key,
              value: `${portProtoList[0]} ${protocolValue}`,
              href: `${portProtoList[0]} ${protocolValue}`,
            },
          ],
          num_matches: 1,
        });

        return;
      }

      if ((portProtoList[1] && portProtoList[1].length <= 2) || !portProtoList[1]) {
        query = query.toUpperCase();

        const blank = ' ';

        if (key === 'portProtocol') {
          query = query.replace(/\s+.*/g, blank).replace(/(^T|TC|TCP|U|UD|UDP$)/g, blank);
        } else if (key === 'portRange') {
          query = query.replace(/\s+.*/g, blank).replace(/(^T|TC|TCP|U|UD|UDP-T|UDP$)/g, blank);
        }

        portProtocol = portProtocol.replace(/\s/g, '').trim();

        this.setDropdownValues(key, query, {
          matches: portProtocol
            ? [
                {
                  key,
                  value: portProtocol,
                  href: portProtocol,
                },
                {
                  key,
                  value: `${portProtocol} ${intl('Protocol.TCP')}`,
                  href: `${portProtocol} ${intl('Protocol.TCP')}`,
                },
                {
                  key,
                  value: `${portProtocol} ${intl('Protocol.UDP')}`,
                  href: `${portProtocol} ${intl('Protocol.UDP')}`,
                },
              ]
            : [],
          num_matches: portProtocol ? 3 : 0,
        });
      } else {
        this.setDropdownValues(key, query, {
          matches: [],
          num_matches: 0,
        });
      }
    } else {
      this.setDropdownValues(key, query, {
        matches: [],
        num_matches: 0,
      });
    }
  },

  getItems(filters) {
    const items = {};

    if (filters && filters.length) {
      filters.forEach(filter => {
        let key;

        if (filter.href && filter.href.includes('label_group')) {
          key = 'label_group';
        } else {
          key = filter.key || 'allWorkloads';
        }

        const facet = this.state.reverseFacetMap[key];

        if (!items[facet]) {
          items[facet] = [];
        }

        items[facet].push(filter);
      });
    }

    return items;
  },

  addItem(filter, value) {
    let items = {...this.state.items};

    if (!value && !items[filter]) {
      items = Object.keys(items).reduce((result, itemKey) => {
        if (items[itemKey]) {
          result[itemKey] = items[itemKey];
        }

        return result;
      }, {});

      items[filter] = null;
    }

    if (value) {
      // Remove extraneous icon from the ObjectSelector
      // so the component update comparison will work
      const filterValue = {...value};

      delete filterValue.icon;

      const addNow = this.props.addItem(filterValue);

      if (addNow !== 'wait') {
        items[filter] = items[filter] || [];
        items[filter].push(filterValue);
      }
    }

    if (filter === intl('Common.Process')) {
      this.getFacetValues('processName');
    }

    if (filter === intl('Common.WinService')) {
      this.getFacetValues('windowsService');
    }

    if (filter === intl('Services.PolicyServices')) {
      this.getFacetValues('policyService');
    }

    if (filter === intl('Common.IPLists')) {
      this.getFacetValues('iplist');
    }

    if (!this.props.filterKey.includes('port')) {
      this.getFacetValues('appgroups');
    }

    if (filter === intl('Components.Transmission')) {
      this.getFacetValues('transmission');
    }

    if (filter === intl('PCE.FQDN')) {
      this.getFacetValues('fqdn');
    }

    this.setState({items});
  },

  removeItem(item) {
    const items = {...this.state.items};

    this.props.removeItem(item === intl('Workloads.All') ? {allWorkloads: true} : item);
    delete items[item];
    this.setState({items});
  },

  removeMulti(filter, value) {
    const items = {...this.state.items};
    const itemValue = value || _.last(items[filter]);

    items[filter] =
      items && items[filter] && items[filter].filter(currentFilter => !_.isEqual(currentFilter, itemValue));
    this.props.removeItem(itemValue);
    this.setState({items});
  },

  returnValue(value, facet, isCapsule = false) {
    if (facet === intl('Common.Workloads') || facet === intl('Common.ContainerWorkloads')) {
      return WorkloadUtils.friendlyName(value);
    }

    if (facet === intl('Services.PolicyServices') || facet === intl('Common.IPLists')) {
      return value?.name;
    }

    if (isCapsule && facet === intl('Common.AppGroups')) {
      const truncatedContent = RenderUtils.truncateAppGroupName(value.value, 37, [17, 10, 10]);

      return truncatedContent;
    }

    return value?.value;
  },

  returnTooltip(value, facet, truncated, capsule) {
    if (facet === intl('Common.AppGroups')) {
      return truncated?.length < value.value?.length ? (
        <Tooltip key={value.value} content={value.value} width={400} position="formpanel" location="bottomright">
          {capsule}
        </Tooltip>
      ) : (
        capsule
      );
    }

    return capsule;
  },

  validatePortProtocolValue(key, service) {
    const regexPort = ServiceUtils.lookupRegexPortProtocol('port');
    const regexProtocol = ServiceUtils.lookupRegexPortProtocol('protocol');
    let isPortValid = false;
    let numberSection;

    if (service.includes(' ')) {
      numberSection = service.slice(0, service.indexOf(' '));
    } else if (!isNaN(Number(service))) {
      numberSection = service;
    }

    if (!isNaN(Number(numberSection))) {
      numberSection = numberSection.trim();
      isPortValid = PortUtils.isValidPort(Number(numberSection));
    }

    if (regexProtocol.test(service) || (regexPort.test(numberSection) && isPortValid)) {
      return true;
    }

    this.setDropdownValues(key, service, {
      matches: [],
      num_matches: 0,
    });

    return false;
  },

  validatePortRange(key, service) {
    const regexPortRange = ServiceUtils.lookupRegexPortProtocol('portRange');
    const numberSection = service.includes(' ') ? service.split(' ')[0] : service;
    const regexProtocol = ServiceUtils.lookupRegexPortProtocol('protocol');

    let isValidPortRange = false;

    //This section takes the portRange in the format 90-100 and validates the range to be in the format (N-N+1)
    //portRange: /(^[0-9]+)[-]([0-9]+)$/, numberSection: 90-100
    numberSection.replace(regexPortRange, match => {
      match = match.split('-');

      const lowerPortRange = match[0];
      const upperPortRange = match[1];

      if (lowerPortRange && upperPortRange) {
        const lowerPortRangeNum = Number(lowerPortRange.trim());
        const upperPortRangeNum = Number(upperPortRange.trim());

        // e.g. numberSection: 80-100
        // lowerPortRangeNum = 80, upperPortRangeNum = 100
        // Make sure port is valid port within a range of number 0 - 65535 and upperPortRangeNum > lowerPortRangeNum
        if (
          PortUtils.isValidPort(lowerPortRangeNum) &&
          PortUtils.isValidPort(upperPortRangeNum) &&
          upperPortRangeNum > lowerPortRangeNum
        ) {
          isValidPortRange = true;
        }
      }
    });

    if (isValidPortRange && !regexProtocol.test(service)) {
      return true;
    }

    this.setDropdownValues(key, service, {
      matches: [],
      num_matches: 0,
    });

    return false;
  },

  renderPlaceHolder(items) {
    const placeHolderValue = {};
    const facets = this.getFacets(items);

    Object.keys(facets).forEach(facet => {
      switch (facet) {
        case intl('Common.IPAddress'):
          placeHolderValue[facet] = intl('Port.IpAddressPlaceHolder');
          break;
        case intl('IPLists.CIDRBlock'):
          placeHolderValue[facet] = intl('Port.CIDRBlockPlaceHolder');
          break;
        case intl('Common.PortAndOrProtocol'):
          placeHolderValue[facet] = intl('Port.PortProtocolPlaceHolder');
          break;
        case intl('Port.PortRange'):
          placeHolderValue[facet] = intl('Port.PortRangePlaceHolder');
          break;
        case intl('Common.WinService'):
          placeHolderValue[facet] = intl('Port.WindowsServicePlaceHolder');
          break;
        case intl('Common.Process'):
          placeHolderValue[facet] = intl('Port.ProcessNamePlaceHolder');
          break;
        case intl('Services.PolicyServices'):
          placeHolderValue[facet] = intl('ObjectSelector.TypeToShowMoreObject', {
            object: intl('Services.PolicyServices'),
          });
          break;
        case intl('Common.IPLists'):
          placeHolderValue[facet] = intl('ObjectSelector.TypeToShowMoreObject', {object: intl('Common.IPLists')});
          break;
        case intl('Components.Transmission'):
          placeHolderValue[facet] = intl('ObjectSelector.TypeToShowMoreObject', {
            object: intl('Components.Transmission'),
          });
          break;
        case intl('PCE.FQDN'):
          placeHolderValue[facet] = intl('ObjectSelector.TypeToShowMoreObject', {object: intl('PCE.FQDN')});
          break;
      }
    });

    return placeHolderValue || {};
  },

  customListItem({text, props}) {
    if (Array.isArray(text) && text.length) {
      text = text.reduce((acc, item) => {
        if (item.props && item.props.children) {
          acc = acc.concat(item.props.children);
        }

        return acc;
      }, '');
    }

    if (!props.icon && text.length > 37) {
      const truncatedContent = RenderUtils.truncateAppGroupName(text, 37, [17, 10, 10]);

      return (
        <Tooltip key={text} content={text} width={400} position="formpanel" location="bottomright">
          <li {...props}>{truncatedContent}</li>
        </Tooltip>
      );
    }

    return <li {...props}>{LabelUtils.generateLabelOrLabelgroupOrPlainElement({...props, text})}</li>;
  },

  render() {
    const {
      addItem,
      removeItem,
      getFacetValues,
      removeMulti,
      returnValue,
      returnTooltip,
      getCustomClass,
      customListItem,
    } = this;
    const {items, dropdownValues, singleValues} = this.state;
    const {filterKey, isEdge} = this.props;
    const facets = this.getFacets(items);
    const initialValues = Object.keys(facets);
    const showFacetItems = [
      intl('Common.Workloads'),
      intl('Common.ContainerWorkloads'),
      intl('Common.IPAddress'),
      intl('Common.AppGroups'),
      intl('IPLists.CIDRBlock'),
      intl('Common.IPLists'),
    ];

    const props = {
      customClassItems: initialValues,
      getCustomClass,
      dropdownValues,
      items,
      multiItems: initialValues,
      facetMap: facets,
      initialValues,
      addItem,
      or: filterKey.includes('Exclude'),
      removeItem,
      getFacetValues,
      placeholder: this.props.placeholder,
      singleValues,
      customListItem,
      customPlaceholders: this.renderPlaceHolder(items),
      removeMulti,
      returnValue,
      returnTooltip,
      showFacetItems,
      hintTextOptionType: ['Port and/or Protocol'],
      hintText: {'Port and/or Protocol': 'Type to show '},
      defaultSelected: filterKey.includes('port')
        ? intl('Common.PortAndOrProtocol')
        : isEdge
        ? intl('Common.Groups')
        : intl('Common.Labels'),
    };

    return <ObjectSelector {...props} />;
  },
});
