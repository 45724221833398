/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import React, {PropTypes} from 'react';
import intl from 'intl';
import cx from 'classnames';
import {Icon} from '.';

const storageCloseKey = 'certWarning';
const selfSignedPeriod = 30;
const trustedCertLink = 'https://support.illumio.com/knowledge-base/articles/How-to-update-the-PCE-certificates.html';
const selfSignedCertLink =
  'https://support.illumio.com/knowledge-base/articles/Understanding-Illumio-Trial-Certificates.html';

export function resetCertWarning() {
  localStorage.removeItem(storageCloseKey);
}

export default React.createClass({
  propTypes: {
    selfSigned: PropTypes.bool,
    expiration: PropTypes.any,
  },

  getDefaultProps() {
    return {
      selfSigned: false,
      expiration: null,
    };
  },

  getInitialState() {
    let certShown;

    try {
      const storedDate = JSON.parse(localStorage.getItem(storageCloseKey));
      const today = intl.date(new Date(), 'L');

      certShown = today === storedDate;
    } catch {
      certShown = false;
    }

    return {show: !certShown && this.props.expiration};
  },

  handleClose() {
    localStorage.setItem(storageCloseKey, JSON.stringify(intl.date(new Date(), 'L')));
    this.setState({show: false});
  },

  render() {
    if (!this.state.show) {
      return null;
    }

    const {selfSigned, expiration} = this.props;
    const expDate = new Date(expiration);

    if (selfSigned) {
      expDate.setDate(expDate.getDate() - selfSignedPeriod);
    }

    const daysLeft = intl.utils.diffInDays(expDate);

    if (!selfSigned && daysLeft > 30) {
      return null;
    }

    let title;
    let message;
    let counter = null;
    let expired = daysLeft < 0;

    // show number of days left until expiration in the reminder counter
    // if it expires later today, show "Today"
    // if it expired yesterday or before that, don't show counter
    if (daysLeft && !expired) {
      counter = (
        <div className="Reminder-counter">
          <div className="Reminder-counter-time-left">{daysLeft}</div>
          {intl('Certificate.Days', {count: daysLeft})}
        </div>
      );
    } else if (daysLeft === 0 && !expired) {
      const hoursLeft = intl.utils.diffInHours(expDate);

      if (hoursLeft < 0) {
        expired = true;
      } else {
        counter = (
          <div className="Reminder-counter">
            <div className="Reminder-counter-today">{intl('Certificate.Today').toLocaleUpperCase()}</div>
          </div>
        );
      }
    }

    let color = 'red';

    if (selfSigned && daysLeft > 10 && !expired) {
      color = 'orange';
    }

    if (expired) {
      title = selfSigned ? intl('Certificate.TrialEnded') : intl('Certificate.CertExpired');
      message = selfSigned ? intl('Certificate.TrialEndedMessage') : intl('Certificate.CertExpiredMessage');
    } else {
      title = intl(
        selfSigned ? 'Certificate.ValidTrial' : 'Certificate.ValidCert',
        {
          when: expDate,
          className: cx({'ReminderTitle--red': color === 'red'}),
        },
        {html: true},
      );
      message = selfSigned ? intl('Certificate.MessageSelfSigned') : intl('Certificate.MessageRealCert');
    }

    const link = selfSigned ? selfSignedCertLink : trustedCertLink;

    const classes = cx('Reminder', {
      'Reminder--orange': color === 'orange',
      'Reminder--red': color === 'red',
    });

    const titleClasses = cx('Reminder-title', {
      'Reminder-title--unexpired': !expired,
      'Reminder-title--expired': expired,
    });

    return (
      <div className={classes}>
        {counter}
        <div className={expired ? 'Reminder-expired' : 'Reminder-unexpired'}>
          <div className={titleClasses}>{title}</div>
          <div className="Reminder-message">{message}</div>
          <span className="Reminder-close" onClick={this.handleClose}>
            <Icon name="close" />
          </span>
          {link && (
            <a
              className={expired ? 'Reminder-link-expired' : 'Reminder-link-unexpired'}
              href={link}
              target="_new"
              data-tid="elem-link-about"
            >
              {intl('Common.ClickHere')}
            </a>
          )}
        </div>
      </div>
    );
  },
});
