/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import Schema from 'api/schema';
import Session from './session';
import request from './request';
import Constants from '../constants';
import {keyExists} from '../utils/GeneralUtils';
import dispatcher from '../actions/dispatcher';
import actionCreators from '../actions/actionCreators';
import {clearCachedResponses} from './responseCache';
import JSONBig from 'json-bigint-keep-object-prototype-methods';

const JSONBigIntNative = JSONBig({useNativeBigInt: true, objectProto: true});

const failureCodes = {
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  NOT_ACCEPTABLE: 406,
  TOO_MANY_REQUESTS: 429,
  ERROR: 500,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
  TIMEOUT: 504,
};

const pendingRequests = {};
let pendingRequestCount = 0;

const CACHE_THRESHOLD_MS = 60_000; // 1m
const LABELS_LIST_USAGE_KEYS = ['pairing_profiles', 'rule_sets', 'workloads', 'label_groups', 'virtual_services'];
const allowlistResourceMethods = {
  get_collection_pairing_profiles_max: true,
  get_collection_label_groups_max: true,
  get_collection_virtual_services_max: true,
  get_collection_ip_lists_max: true,
  get_collection_labels_max: true,
  get_collection_services_max: true,

  get_collection_agent_support_report_requests: true,
  get_collection_ip_lists: true,
  get_collection_labels: true,
  get_collection_org_users: true,
  get_collection_pairing_profiles: true,
  get_collection_rule_sets: true,
  get_collection_scoped_services: true,
  get_collection_services: true,
  get_collection_virtual_servers: true,
  get_collection_slbs: true,
  get_collection_nfcs: true,
  get_collection_discovered_virtual_servers: true,
  get_collection_label_groups: true,
  get_collection_virtual_services: true,
  get_collection_blocked_traffic: true,
  get_collection_sec_policies: true,
  get_collection_ip_tables_rules: true,

  get_instance_ip_lists: true,
  get_instance_labels: true,
  get_instance_pairing_profiles: true,
  get_instance_rule_sets: true,
  get_instance_scoped_services: true,
  get_instance_services: true,
  get_instance_workloads: true,
  get_instance_sec_policies: true,
  get_instance_ip_tables_rules: true,

  get_config: true,
  get_collection_network_interfaces: true,
  get_email: true,
  get_sec_policy_rule_view: true,

  // Items which should not go into this list:
  //get_sec_policy_pending: true,
  //get_sec_policies_dependencies
  // Users
  // Pairing Key
};

// Don't dispatch actions for these API failure responses
// This prevents Error Dialog from showing up
//
// key    => Status Code
// value  => Object of Message bodies which should not show the Dialog
//           The Object keys should be unique and are only relevant to UI codebase
//
// Export the variable to be able to be used in other parts of the codebase
// FIXME: Known limitation - the API message body has to be exactly same as here
export const preventFailureDispatch = {
  503: {
    // The Illumination API returns a 503 with a cache initialization error message on first time PCEs
    // Currently the 'IlluminationCacheBuilding' key is not used anywhere
    illuminationCacheBuilding: {error: '503 Waiting for cache initialization'},
  },
  500: {
    // If the number of rules is too many for Workload Rules tab, the API returns a 500 response
    // The response body is an array of a single item
    tooManyWorkloadRules: [{token: 'system_error', message: 'Unexpected system error. Response too large'}],
  },
  406: {
    // Don't dispatch error for user creation when there is an existing user form the same org
    // This error will be handled manually in LocalUsers.jsx handleAddSave
    existingUserFromSameOrg: [{token: 'user_already_exists', message: 'This user already exists'}],
  },
};

const collectionQueryCache = {};

let requestTimestamps = {};

export function clearTimestamps() {
  requestTimestamps = {};
}

function addPendingRequest(id, key, options, promise) {
  if (!pendingRequests[key]) {
    pendingRequests[key] = {};
  }

  pendingRequests[key][id] = {id, key, options, promise};
}
function removePendingRequest(id, key) {
  return delete pendingRequests[key][id];
}

function findPendingRequest(key, options) {
  return _.find(pendingRequests[key], request => _.isEqual(request.options, options));
}

export function getInstanceResources() {
  const resources = [];

  _.each(Schema.classes, (resource, key) => {
    if (
      (resource.collection && resource.methods && resource.methods.get_instance) ||
      (!resource.collection && resource.methods && resource.methods.get)
    ) {
      resources.push({
        type: key,
        path: (resource.methods.get_instance || resource.methods.get).path,
      });
    }
  });

  return resources;
}

export function getHrefParams(href) {
  if (!href) {
    return {};
  }

  const resources = getInstanceResources();
  const hrefParts = href.split('/');
  let hrefParams = {};

  _.some(resources, resource => {
    const uriParts = resource.path.split('/');

    if (uriParts.length === hrefParts.length) {
      const parametizedUri = uriParts
        .map((part, index) => {
          const isParam = part.indexOf(':') === 0;

          if (isParam) {
            hrefParams[part.substr(1)] = hrefParts[index];

            return hrefParts[index];
          }

          return part;
        })
        .join('/');

      if (parametizedUri === href) {
        return true;
      }

      hrefParams = {};
    }
  });

  return hrefParams;
}

/**
 * Convert xxxlabels into the special character query.
 * xxxlabels is a query parameter which consists of a list of labels, and need to have double-quotes around each URI
 * They are only used for a certain set of APIs, like the workloads.
 */
export function getLabelsQueryParam(labelArray = []) {
  if (!labelArray.length) {
    return;
  }

  const result = labelArray.map(labels => `["${labels.join('","')}"]`);

  if (result.length === 1 && result[0] === '[""]') {
    result[0] = '[]';
  }

  // Result looks like :
  // 'labels=[["labelHref,"labelHref"],["labelHref"]]'
  return {
    labels: `[${result.join(',')}]`,
  };
}

function getClass(className) {
  return Schema.classes[className];
}

export const getMethod = (className, method) => {
  if (className === 'methods') {
    return Schema.methods[method];
  }

  const classConfig = getClass(className);

  if (classConfig && classConfig.methods) {
    return classConfig.methods[method];
  }
};

// Uris
export const getParametizedUri = (uri, params) =>
  uri ? _.reduce(params, (result, value, key) => result.replace(`:${key}`, value), uri) : uri;

export const getSessionUri = (uri, params) => (uri ? Session.getOrgUri(getParametizedUri(uri, params)) : uri);

// Collections
export function hasValidCollection(resource) {
  const classConfig = getClass(resource);

  return classConfig && classConfig.collection && getMethod(resource, 'get_collection');
}

export function getCollectionUri(resource) {
  if (hasValidCollection(resource)) {
    return getMethod(resource, 'get_collection').path;
  }
}

// Instances
export function hasValidInstance(resource) {
  const classConfig = getClass(resource);

  return classConfig && classConfig.collection && getMethod(resource, 'get_instance');
}

export function getInstanceUri(resource) {
  if (hasValidInstance(resource)) {
    return getMethod(resource, 'get_instance').path;
  }
}

export const getMethodUri = (resource, method) => _.get(getMethod(resource, method), 'path');

// Methods
export function getResourceMethod(resource, method, params = {}) {
  const resourceMethod = getMethod(resource, method);

  if (resourceMethod) {
    return {
      http_method: resourceMethod.http_method,
      uri: getSessionUri(resourceMethod.path, params),
    };
  }
}

export const getDispatchType = (resource, method, type) =>
  _.compact([resource, method, type])
    .map(part => part.toLocaleUpperCase())
    .join('_');

export function dispatchResourceMethod(resource, method, type, payload) {
  dispatcher.dispatch({...payload, type: getDispatchType(resource, method, type)});
}

export function dispatchApiFailure(result, payload, resource, method) {
  const {status, body} = result;
  let type;

  // For certain responses, don't dispatch any action
  if (preventFailureDispatch[status]) {
    // preventFailureDispatch[status] is an Object of response bodies as values, and at least one of the values has to match
    const preventDispatch = Object.values(preventFailureDispatch[status]).some(reponseBody =>
      _.isEqual(reponseBody, body),
    );

    if (preventDispatch) {
      return;
    }
  }

  switch (status) {
    case failureCodes.UNAUTHORIZED:
      type = Constants.API_UNAUTHORIZED_FAIL;
      clearTimestamps();
      break;
    case failureCodes.FORBIDDEN:
      type = Constants.API_FORBIDDEN_FAIL;
      clearTimestamps();
      break;
    case failureCodes.NOT_ACCEPTABLE:
      type = Constants.API_NOT_ACCEPTABLE_FAIL;
      break;
    case failureCodes.TOO_MANY_REQUESTS:
      type = Constants.API_TOO_MANY_REQUESTS_FAIL;
      break;
    case failureCodes.ERROR:
      type = Constants.API_ERROR_FAIL;
      break;
    case failureCodes.BAD_GATEWAY:
      type = Constants.API_BAD_GATEWAY_FAIL;
      break;
    case failureCodes.TIMEOUT:
      type = Constants.API_TIMEOUT_FAIL;
      clearTimestamps();
      break;
    case failureCodes.SERVICE_UNAVAILABLE:
      type = Constants.API_SERVICE_UNAVAILABLE;
      break;
    case failureCodes.NOT_FOUND:
      type = Constants.API_NOT_FOUND_FAIL;
      break;
    default:
      type = Constants.API_FAIL;
  }

  if (!type && payload.error) {
    type = Constants.API_SERVER_CONNECTION_FAIL;
    clearTimestamps();
  }

  // If the error is of the type '5xx', show the request ID on the Alert Dialog
  dispatcher.dispatch({
    ...payload,
    type,
    response: result,
    failureType: resource && method ? getDispatchType(resource, method, 'fail') : null,
    showRequestId: status > 499 && status < 600,
  });
}

/**
 * This function sets the 'force' parameter for the getCollection of some pages.
 * This function tries to detect usages of certain things (like Labels) which might effect
 * the list page (like the usage of Labels at different parts of the app will effect the usage tick marks
 * on the Labels list page).
 */
function setForceRefresh(httpMethod, resource, method, options) {
  // A GET doesn't change anything
  if (httpMethod === 'GET') {
    return;
  }

  // A delete just deletes a resource with href, so refresh labels list
  if (httpMethod === 'DELETE' || method === 'delete') {
    if (LABELS_LIST_USAGE_KEYS.includes(resource)) {
      actionCreators.forceListPageRefresh('labelList', true);
    }

    if (resource === 'rule_set') {
      actionCreators.forceListPageRefresh('labelGroupList', true);
    }
  }

  if (httpMethod === 'POST' || httpMethod === 'PUT') {
    // Update the labels list page if we have modified the usage of labels anywhere
    // This is to get the correct 'usage' green tick marks
    if (method !== 'verify_caps' && (keyExists(options.data, 'label') || keyExists(options.data, 'labels'))) {
      actionCreators.forceListPageRefresh('labelList', true);
    }

    if (keyExists(options.data, 'label_group')) {
      actionCreators.forceListPageRefresh('labelGroupList', true);
    }

    // Update the ruleset list page if any particular rule has been updated
    if (resource === 'rule_set' && method === 'update') {
      actionCreators.forceListPageRefresh('rulesetList', true);
    }
  }
}

// Any api call extends user session on backend. Backend timeout after last api call - 10min
// If there is no api calls (user just staring at the screen)
// we need to extend backend session as long as user is active, i.e is moving a mouse
// (handleMoveToLogout in Main.jsx will logout user after 10 min of inactivity)
// This noop method will be called 5min after any api call, to extend backend session.
const callNoop = _.debounce(() => {
  execute('methods', 'noop', {}, true);
}, 300_000);

export async function execute(resource, method, options = {}, preventDispatch, cachedResponse) {
  const resourceMethod = getResourceMethod(resource, method, options.params);

  if (!resourceMethod) {
    throw new Error('API: Resource and/or method does not exist!');
  }

  const {query = {}, params = {}, data, timeout = 180_000} = options;
  const {http_method: methodHTTP, uri} = resourceMethod;
  const sortedQueryKeys = Object.keys(query).sort();
  const isMaxStore = method === 'get_collection' && sortedQueryKeys.includes('max_results');
  const allowlistKey = `${method}_${resource}${isMaxStore ? '_max' : ''}`;

  if (allowlistResourceMethods[allowlistKey]) {
    const now = Date.now();
    const sortedQueryKeysJoined = sortedQueryKeys.join('-');
    const queryChanged = isMaxStore && collectionQueryCache[allowlistKey] !== sortedQueryKeysJoined;
    const requestTimestampKey = [
      methodHTTP,
      uri,
      ...Object.keys(params)
        .sort()
        .map(key => `${key}_${params[key]}`),
      ...sortedQueryKeys.map(key => `${key}_${query[key]}`),
    ].join('_');

    if (options.force || queryChanged || !requestTimestamps[requestTimestampKey]) {
      requestTimestamps[requestTimestampKey] = now;
    } else {
      const oldTimeInMs = requestTimestamps[requestTimestampKey];
      const timeDifferenceMs = now - oldTimeInMs;

      if (timeDifferenceMs < CACHE_THRESHOLD_MS) {
        // TODO: cache must be stored inside resolved promised here, on api controller side, and we must just return this promise
        // Now empty object without body means for consumers cached responsed, but this is not obvious and bugy
        return {CACHED: true}; // Don't reload.
      }

      requestTimestamps[requestTimestampKey] = now;
    }

    collectionQueryCache[allowlistKey] = sortedQueryKeysJoined;
  }

  const headers = {Accept: 'application/json', ...options.headers};
  const csrfCookie = await cookieStore.get('csrf_token');

  if (csrfCookie?.value) {
    headers['X-Csrf-Token'] = csrfCookie.value;
  }

  let queryToSend;
  let dataToSend;

  switch (methodHTTP) {
    case 'GET':
      queryToSend = query;

      // xxxLabels are lists of labels which require special formatting for the query string.
      // They are used by the workloads api. Other lists of labels can use the standard query encoding.
      if (!_.isEmpty(query.xxxlabels)) {
        queryToSend = {...getLabelsQueryParam(query.xxxlabels), ...queryToSend};
        delete queryToSend.xxxlabels;
      }

      break;
    case 'PUT':
    case 'POST':
      queryToSend = query;
      headers['Content-Type'] = 'application/json';
      dataToSend = data || {};
      break;
    case 'DELETE':
      queryToSend = query;
      break;
  }

  // Whenever things change, certain list pages need to be force refreshed
  setForceRefresh(methodHTTP, resource, method, options);

  const dispatchType = getDispatchType(resource, method, 'init');
  let pendingRequest = _.get(findPendingRequest(dispatchType, options), 'promise');

  if (pendingRequest) {
    return pendingRequest;
  }

  if (!preventDispatch) {
    dispatchResourceMethod(resource, method, 'init');
  }

  const requestId = pendingRequestCount++;

  pendingRequest = (async () => {
    let load;

    try {
      let result;

      if (cachedResponse) {
        result = cachedResponse;
      } else {
        const response = await request({
          url: `${Schema.api_prefix}${uri}`,
          method: methodHTTP,
          query: queryToSend,
          data: dataToSend,
          headers,
          timeout,
        });

        // If request executed with success code, postpone next noop call
        callNoop();

        const responseText = await response.text();

        result = {
          body: responseText ? JSONBigIntNative.parse(responseText) : null,
          headers: response.headers,
          status: response.status,
          response,
        };
      }

      load = {
        data: result.body,
        options,
        count: {
          matched: Number(result.headers.get('x-matched-count')) || 0,
          total: Number(result.headers.get('x-total-count')) || 0,
        },
      };

      if (options.iterate) {
        // Allow the next iteration to get started before processing this response.
        _.defer(() => dispatchResourceMethod(resource, method, 'success', load));
      } else if (!preventDispatch) {
        dispatchResourceMethod(resource, method, 'success', load);
      }

      return result;
    } catch (error) {
      const response = error.response || {};
      const responseText = response.text ? await response.text() : null;
      let body;

      if (!_.isEmpty(responseText)) {
        try {
          body = JSONBigIntNative.parse(responseText);
        } catch {
          body = responseText;
        }
      }

      error.parsedBody = body;

      if (_.isString(body)) {
        error.message += '. ' + body;
      }

      const result = {
        body,
        method: error.method,
        options,
        headers: response.headers || {},
        status: error.status,
        response: error.response,
        error,
      };

      load = {error};

      if (!preventDispatch) {
        dispatchResourceMethod(resource, method, 'fail', load);
        dispatchApiFailure(result, load, resource, method);
      }

      if (body) {
        console.error('api execute', methodHTTP, uri, 'response', body);
      }

      throw error;
    } finally {
      if (!cachedResponse) {
        removePendingRequest(requestId, dispatchType);
      }

      if (!preventDispatch) {
        dispatchResourceMethod(resource, method, 'complete', load);
      }
    }
  })();

  clearCachedResponses(resource, method, options);

  if (!cachedResponse) {
    addPendingRequest(requestId, dispatchType, options, pendingRequest);
  }

  return pendingRequest;
}

let hostIsSuperclusterMember = false;

export const setHostIsSupercluserMember = isHostSupercluserMember =>
  (hostIsSuperclusterMember = isHostSupercluserMember);

let roleNames = [];

export const setRoleNames = names => {
  if (names !== roleNames) {
    roleNames = names;
  }
};

export const isAPIAvailable = (...apiNames) =>
  apiNames.every(apiName => {
    let result = false;
    const [className, method] = apiName.split('.');

    if (className && method) {
      const schemaMethod = getMethod(className, method);

      if (schemaMethod) {
        const {pretty_authz: prettyAuthz, allowed_on_non_leader: allowedOnNonleader} = schemaMethod;

        result =
          hostIsSuperclusterMember && !allowedOnNonleader
            ? false
            : !Array.isArray(prettyAuthz) || prettyAuthz.some(authz => roleNames.has(authz));
      }
    }

    return result;
  });
