/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import {createApiStore} from '../lib/store';
import Constants from '../constants';

let venReleases = null;

export default createApiStore(['VEN_'], {
  dispatchHandler(action) {
    switch (action.type) {
      case Constants.VEN_SOFTWARE_RELEASES_GET_COLLECTION_SUCCESS:
        venReleases = action.data;
        break;

      default:
        return true;
    }

    this.emitChange();

    return true;
  },

  getVenReleases() {
    return venReleases || [];
  },
});
