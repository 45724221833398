/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import React from 'react';
import intl from 'intl';
import _ from 'lodash';
import {Icon} from '..';
import {SessionStore} from '../../stores';
import {StoreMixin} from '../../mixins';
import {RestApiUtils} from 'scripts/utils';
import actionCreators from '../../actions/actionCreators';

const getStateFromStores = () => {
  return {
    product: SessionStore.getProduct(),
    version: SessionStore.getUIVersion(),
    parsedVersion: SessionStore.getParsedUIVersionNumber(),
    isEnabled: SessionStore.isWhatsNewEnabled(),
    user: SessionStore.getUserId(),
    docUrl: SessionStore.getWhatsNewDocUrl(),
  };
};

export default React.createClass({
  mixins: [StoreMixin([SessionStore], getStateFromStores)],

  handleWhatsNewClose() {
    actionCreators.closeWhatsNew(this.state.parsedVersion);
    RestApiUtils.kvPair.update(this.state.user, 'whats_new', this.state.parsedVersion);
  },

  render() {
    return this.state.isEnabled ? (
      <div className="WhatsNewPopup" data-tid="whatsnew">
        <Icon name="close" styleClass="close" size="large" onClick={this.handleWhatsNewClose} />
        <div>
          <div className="WhatsNewPopup-title">{intl('WhatsNew.NewAnnouncement')}</div>
          <div className="WhatsNewPopup-content">
            {intl('WhatsNew.IsReleased', {product: _.capitalize(this.state.product), version: this.state.version})}
          </div>
        </div>
        <div>
          <a href={this.state.docUrl} className="WhatsNewPopup-link" target="_blank">
            {intl('WhatsNew.SeeWhatsNew')}
          </a>
        </div>
      </div>
    ) : null;
  },
});
