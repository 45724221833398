/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import React from 'react';
import StoreMixin from './mixins/StoreMixin';
import DialogStore from './stores/DialogStore';
import SessionStore from './stores/SessionStore';
import SettingStore from './stores/SettingStore';
import HealthStore from './stores/HealthStore';
import OrgStore from './stores/OrgStore';

import {State, Navigation, RouteHandler} from 'react-router';
import {
  SpinnerOverlay,
  BrowserWarning,
  SessionExpiration,
  CertReminder,
  ProvisionProgress,
  RollingUpgradeReminder,
  ListenOnlyReminder,
  InstantSearchContainer,
  WhatsNewPopup,
} from './components';

const pagesWithoutGreyBorder = [
  'map',
  'mapLevel',
  'prevMapLevel',
  'appMap',
  'appMapLevel',
  'prevAppMapLevel',
  'explorer',
  'segmentationTemplates.list',
  'segmentationTemplate.detail',
  'helpmenu',
  'helpmenuold',
];

function getStateFromStores() {
  return {
    dialog: DialogStore.getActiveDialog(),
    session: SessionStore.hasValidSession(),
    selfSignedCert: SessionStore.isCertSelfSigned(),
    certExpiration: SessionStore.getCertExpiration(),
    sessionTimeout: SessionStore.getSessionTimeout(),
    listenOnlyEnableTime: HealthStore.getLocalListenOnlyEnableTime(),
    remainingRollingUpgradeCount: HealthStore.getRemainingRollingUpgradeCount(),
    rollingUpgradeCount: HealthStore.getRollingUpgradeCount(),
    org: OrgStore.getOrg(),
  };
}

export default React.createClass({
  mixins: [State, Navigation, StoreMixin([HealthStore, DialogStore, SessionStore, SettingStore], getStateFromStores)],

  render() {
    if (!this.state.session) {
      return null;
    }

    const route = this.getRoutes()[1].name;
    const content = !['helpmenu', 'helpmenuold'].includes(route) ? (
      <div>
        {this.state.dialog}
        <BrowserWarning />
        <div className="Reminder-container">
          <CertReminder selfSigned={this.state.selfSignedCert} expiration={this.state.certExpiration} />
          <RollingUpgradeReminder
            remainingRollingUpgradeCount={this.state.remainingRollingUpgradeCount}
            rollingUpgradeCount={this.state.rollingUpgradeCount}
          />
          <ListenOnlyReminder enabledTime={this.state.listenOnlyEnableTime} />
          <WhatsNewPopup />
          <InstantSearchContainer />
        </div>
        <SessionExpiration />
      </div>
    ) : null;

    const rootClass = cx('Root', {
      RootWithBorder: !pagesWithoutGreyBorder.includes(route),
    });

    return (
      <div className={cx({GreyBorder: !pagesWithoutGreyBorder.includes(route)})}>
        <div className={rootClass}>
          {this.state.redirecting ? <SpinnerOverlay /> : null}
          <RouteHandler />
          {content}
          {route !== 'provisioning' && <ProvisionProgress />}
        </div>
      </div>
    );
  },
});
