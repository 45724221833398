/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import React, {PropTypes} from 'react';
import {Link, Navigation} from 'react-router';
import Dialog from '../components/Dialog.jsx';
import actionCreators from '../actions/actionCreators';

export default React.createClass({
  propTypes: {
    disabled: PropTypes.array,
    deleted: PropTypes.array,
    onClose: PropTypes.func,
  },

  getActions() {
    return [
      {
        text: intl('Common.OK'),
        tid: 'ok',
        ref: 'confirmButton',
        onClick: this.handleClose,
      },
    ];
  },

  mixin: [Navigation],

  handleClose() {
    actionCreators.closeDialog();

    if (this.props.onClose) {
      this.props.onClose();
    }
  },

  handleSingleProvision(href) {
    actionCreators.updateGeneralSelection('provision', [href]);
    this.handleClose();
  },

  handleProvision() {
    actionCreators.updateGeneralSelection(
      'provision',
      this.props.deleted.map(ruleset => ruleset.rulesetHref || ruleset.href),
    );
    this.handleClose();
  },

  handleRevert() {
    actionCreators.updateGeneralSelection(
      'provision',
      this.props.deleted.map(ruleset => ruleset.rulesetHref || ruleset.href),
    );
    this.handleClose();
  },

  render() {
    let provision;
    let revert;

    if (this.props.deleted.length > 1) {
      provision = (
        <Link
          to="provision"
          onClick={this.handleProvision}
          data-tid="elem-link"
          className="PolicyGenerator-link PolicyGenerator-link--all"
        >
          {intl('PolicyGenerator.ProvisionDeletedRulesets')}
        </Link>
      );
      revert = (
        <Link
          to="revert"
          onClick={this.handleRevert}
          data-tid="elem-link"
          className="PolicyGenerator-link PolicyGenerator-link--all"
        >
          {intl('PolicyGenerator.RevertDeletedRulesets')}
        </Link>
      );
    }

    const disabled = this.props.disabled.map(ruleset => {
      const href = ruleset.rulesetHref || ruleset.href;
      const id = href.split('/').pop();

      return (
        <Link
          to="rulesetSummaryEdit"
          params={{id}}
          onClick={this.handleClose}
          data-tid="elem-link"
          className="PolicyGenerator-link"
          key={id}
        >
          {ruleset.name}
        </Link>
      );
    });
    const deleted = this.props.deleted.map(ruleset => {
      const href = ruleset.rulesetHref || ruleset.href;

      return (
        <Link
          to="provision"
          onClick={_.partial(this.handleSingleProvision, href)}
          data-tid="elem-link"
          className="PolicyGenerator-link"
          key={href}
        >
          {ruleset.name}
        </Link>
      );
    });

    return (
      <Dialog
        ref="dialog"
        className="PolicyGeneratorDisabledRulesetDialog"
        type="detail"
        actions={this.getActions()}
        title={intl('PolicyGenerator.DisabledRulesets')}
      >
        {disabled.length ? (
          <div className="PolicyGeneratorDisabledRuleset">
            {intl('PolicyGenerator.DisabledRulesetsInstructions', {count: disabled.length}, {html: true})}
            <div className="PolicyGeneratorRuleset">{disabled}</div>
          </div>
        ) : null}
        {deleted.length ? (
          <div className="PolicyGeneratorDisabledRuleset">
            {intl('PolicyGenerator.DeletedRulesetsInstructions', {count: deleted.length}, {html: true})}
            <div className="PolicyGeneratorRuleset">
              {deleted}
              {provision}
              {revert}
            </div>
          </div>
        ) : null}
      </Dialog>
    );
  },
});
