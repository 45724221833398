/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import {PropTypes} from 'react';
import cx from 'classnames';
import {Icon} from '../../components';

MapNotification.propTypes = {
  type: PropTypes.oneOf(['check', 'warning', 'info']),
  message: PropTypes.string,
  clickable: PropTypes.bool,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
};

MapNotification.defaultProps = {
  type: 'check',
  message: '',
  clickable: false,
  onClick: () => {},
  onClose: () => {},
};

export default function MapNotification(props) {
  const classes = cx({
    'MapNotification': true,
    'MapNotification-type--warning': props.type === 'warning',
    'MapNotification-type--check': props.type === 'check',
    'MapNotification-type--info': props.type === 'info',
    'MapNotification-click': props.clickable,
  });

  return (
    <div className={classes} onClick={props.onClick} data-tid="Map-notification">
      <div className="MapNotification-type">
        <Icon size="xxlarge" name={props.type} />
      </div>
      <div className="MapNotification-message">
        {props.message}
        <div className="MapNotification-CloseIcon" onClick={props.onClose}>
          <Icon size="large" name="close" />
        </div>
      </div>
    </div>
  );
}
