/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import React, {PropTypes} from 'react';
import Icon from './Icon.jsx';
import ComponentUtils from '../utils/ComponentUtils';
import LabelUtils from '../utils/LabelUtils';

const defaultTid = 'comp-label-group';

export default React.createClass({
  propTypes: {
    icon: PropTypes.string,
    onClick: PropTypes.func,
    text: PropTypes.any,
    type: PropTypes.string,
  },

  getDefaultProps() {
    return {
      onClick: null,
      text: '',
    };
  },

  render() {
    const classes = cx({
      'LabelGroup': true,
      'LabelGroup--role': this.props.type === 'role',
      'LabelGroup--app': this.props.type === 'app',
      'LabelGroup--env': this.props.type === 'env',
      'LabelGroup--loc': this.props.type === 'loc',
      'LabelGroup--created': this.props.type === 'created',
      'LabelGroup--deleted': this.props.type === 'deleted',
    });

    const tids = ComponentUtils.tid(defaultTid, this.props.type);
    let titleText = this.props.text;
    let icon = this.props.icon ? this.props.icon : null;

    if (this.props.type && ['role', 'app', 'env', 'loc'].includes(this.props.type)) {
      titleText = LabelUtils.getLabelTypeName(this.props.type) + ' Groups: ' + titleText;

      icon = icon || this.props.type;
    }

    if (icon === 'loc') {
      icon = 'location';
    }

    return (
      <div className={classes} data-tid={ComponentUtils.tidString(tids)} onClick={this.props.onClick} title={titleText}>
        <span className="LabelGroup-content" data-tid="label-group-content">
          {icon ? (
            <div className={`LabelGroup-icon-group LabelGroup--${icon}`}>
              <Icon name={icon} position="before" />
            </div>
          ) : null}
          <span className="LabelGroup-text" data-tid="elem-text">
            {this.props.text}
          </span>
        </span>
      </div>
    );
  },
});
