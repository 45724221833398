/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import React from 'react';
import intl from 'intl';
import {findDOMNode} from 'react-dom';
import {PolicyGeneratorUtils} from '../../utils';
import {Icon, Checkbox} from '..';

export default React.createClass({
  getInitialState() {
    const filters = this.props.filters;

    return {
      hidden: true,
      filters,
    };
  },

  shouldComponentUpdate(nextProps, nextState) {
    // Do not rerender if filter is hidden and remain hidden and highlighting hasn't been changed
    const isFilterHidden = !nextState.hidden || !this.state.hidden;

    return isFilterHidden;
  },

  handleSetUnicastTraffic() {
    this.setState({filters: {...this.state.filters, unicast: !this.state.filters.unicast}});
  },

  handleSetBroadcastTraffic() {
    this.setState({filters: {...this.state.filters, broadcast: !this.state.filters.broadcast}});
  },

  handleSetMulticastTraffic() {
    this.setState({filters: {...this.state.filters, multicast: !this.state.filters.multicast}});
  },

  handleSavefilter() {
    if (PolicyGeneratorUtils.getTransmissionFilters(this.state.filters).length) {
      this.props.onSetFilter(this.state.filters);
      this.setState({hidden: true});
    }
  },

  handleHideFilter(evt) {
    // if the element being blurred out contains
    // the element that caused the blur (e.relatedTarget)
    // then don't hide it, and keep the focus on
    const relatedTarget = evt.relatedTarget || evt.nativeEvent.explicitOriginalTarget || document.activeElement;

    if (findDOMNode(this).contains(relatedTarget)) {
      findDOMNode(this).focus();

      return;
    }

    if (PolicyGeneratorUtils.getTransmissionFilters(this.state.filters).length) {
      this.props.onSetFilter(this.state.filters);
    } else {
      this.setState({filters: this.props.filters});
    }

    this.setState({hidden: true});
  },

  handleToggleFilter() {
    this.setState({hidden: !this.state.hidden});
  },

  render() {
    const {filters, hidden} = this.state;
    const noFilters = !PolicyGeneratorUtils.getTransmissionFilters(this.state.filters).length;

    return (
      <div className="FilterPanel-transmission" tabIndex="-1" onBlur={this.handleHideFilter}>
        {intl('PolicyGenerator.Grid.Subtitle')}
        <span className="FilterButton-transmission" onClick={this.handleToggleFilter} data-tid="filter-button">
          {PolicyGeneratorUtils.getTransmissionFilterString(this.props.filters)} &nbsp;
          <Icon name="dropdown" />
        </span>
        {!hidden && (
          <div className="FilterPanel-body">
            <div className="FilterPanel-Row-OneColumn">
              <Checkbox
                label={intl('Map.Traffic.Unicast')}
                tid="show-unicast"
                checked={filters.unicast}
                onChange={this.handleSetUnicastTraffic}
              />
            </div>
            <div className="FilterPanel-Row-OneColumn">
              <Checkbox
                label={intl('Map.Traffic.Broadcast')}
                tid="show-broadcast"
                checked={filters.broadcast}
                onChange={this.handleSetBroadcastTraffic}
              />
            </div>
            <div className="FilterPanel-Row-OneColumn">
              <Checkbox
                label={intl('Map.Traffic.Multicast')}
                tid="show-multicast"
                checked={filters.multicast}
                onChange={this.handleSetMulticastTraffic}
              />
            </div>
            {noFilters ? <div className="FilterPanel-Warning">{intl('PolicyGenerator.Grid.NoFilter')}</div> : null}
            <div className="FilterPanel-Divider" />
            <div
              className={`FilterPanel-Done${noFilters ? ' FilterPanel-Done-disabled' : ''}`}
              onClick={this.handleSavefilter}
            >
              {intl('Common.Done')}
            </div>
          </div>
        )}
        {intl('PolicyGenerator.Grid.Connection')}
      </div>
    );
  },
});
