/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import keyMirror from 'keymirror';

export default keyMirror({
  // Filters
  ADD_FILTERS: null,
  ADD_API_FILTER: null,
  REMOVE_FILTER: null,
  REMOVE_API_FILTER: null,
  REMOVE_AUTOMATIC_FILTERS: null,

  START_SPINNER: null,
  STOP_SPINNER: null,

  SHOW_MAP_TOOLTIP: null,
  HIDE_MAP_TOOLTIP: null,
  SHOW_MAP_MENU: null,
  HIDE_MAP_MENU: null,

  // DNS
  DNS_REVERSE_LOOKUP_CREATE_INIT: null,
  DNS_REVERSE_LOOKUP_CREATE_SUCCESS: null,
  DNS_REVERSE_LOOKUP_CREATE_FAIL: null,
  DNS_REVERSE_LOOKUP_CREATE_COMPLETE: null,

  // Selection
  SELECT_COMPONENT: null,
  UNSELECT_COMPONENT: null,
  UPDATE_COMPONENT_SELECTION: null,
  UPDATE_MULTIPLE_SELECTION: null,
  UPDATE_INTERACTION_TYPE: null,
  UPDATE_SELECTION_TYPE: null,
  UPDATE_HOVERED_COMPONENT: null,
  UPDATE_UNHOVERED_COMPONENT: null,
  HOVER_COMPONENT: null,
  UNHOVER_COMPONENT: null,
  EXPAND_CLUSTER: null,
  REMOVE_EXPANDED_CLUSTER: null,
  CLEAR_EXPANDED_CLUSTERS: null,
  EXPAND_ROLE: null,
  COLLAPSE_ROLE: null,
  CLEAR_LOADED_ROLES: null,
  UNMOUNT_GRAPH: null,

  // Map
  UPDATE_TRAFFIC_PARAMETERS: null,
  SET_TOTAL_WORKLOADS: null,
  SET_MAP_POLICY_VERSION: null,
  SET_APP_MAP_VERSION: null,
  SET_MAP_NOTIFICATION: null,

  // Dialogs
  OPEN_DIALOG: null,
  CLOSE_DIALOG: null,
  NOT_SHOWING_MESSAGE: null,

  // General Store
  UPDATE_GENERAL_SORTING: null,
  UPDATE_GENERAL_FILTER: null,
  UPDATE_GENERAL_API_FILTER: null,
  UPDATE_GENERAL_FACET_FILTER: null,
  UPDATE_GENERAL_SELECTION: null,
  GENERAL_FACET_UPDATE_STATUS: null,
  CLEAR_GENERAL_SELECTION: null,
  RESET_FACET_VALUES: null,

  // Provision handling
  PROVISION_PENDING: null,
  PROVISION_DONE: null,

  // Support Report handling
  GENERATE_REPORT_PENDING: null,
  GENERATE_REPORT_DONE: null,

  // Workload Connectivity
  CLEAR_CONNECTIVITY: null,

  // History
  CLEAR_HISTORY: null,

  // Log Out
  USER_LOG_OUT: null,

  // Change Password
  USER_CHANGE_PASSWORD: null,

  // Agent Traffic
  NETWORK_TRAFFIC_GET_INIT: null,
  NETWORK_TRAFFIC_GET_SUCCESS: null,
  NETWORK_TRAFFIC_GET_FAIL: null,
  NETWORK_TRAFFIC_GET_COMPLETE: null,

  NETWORK_TRAFFIC_CLEAR_INIT: null,
  NETWORK_TRAFFIC_CLEAR_SUCCESS: null,
  NETWORK_TRAFFIC_CLEAR_FAIL: null,
  NETWORK_TRAFFIC_CLEAR_COMPLETE: null,

  NETWORK_TRAFFIC_DELETE_INIT: null,
  NETWORK_TRAFFIC_DELETE_SUCCESS: null,
  NETWORK_TRAFFIC_DELETE_FAIL: null,
  NETWORK_TRAFFIC_DELETE_COMPLETE: null,

  NETWORK_TRAFFIC_UPDATE_FOR_SCOPES: null,
  NETWORK_TRAFFIC_UPDATE_FOR_NODES: null,
  NETWORK_TRAFFIC_UPDATE_FOR_All: null,
  UPDATE_FOR_All: null,
  CLEAR_TRAFFIC_ON_NEXT: null,

  UPDATE_POLICY_GENERATOR_RULESET: null,

  APP_GROUPS_OBSERVED_RULE_COVERAGE_INIT: null,
  APP_GROUPS_OBSERVED_RULE_COVERAGE_SUCCESS: null,
  APP_GROUPS_OBSERVED_RULE_COVERAGE_FAIL: null,
  APP_GROUPS_OBSERVED_RULE_COVERAGE_COMPLETE: null,

  APP_GROUP_OBSERVED_RULE_COVERAGE_INIT: null,
  APP_GROUP_OBSERVED_RULE_COVERAGE_SUCCESS: null,
  APP_GROUP_OBSERVED_RULE_COVERAGE_FAIL: null,
  APP_GROUP_OBSERVED_RULE_COVERAGE_COMPLETE: null,

  // Traffic Store testing purposes
  TRAFFIC_STORE_SET_ATTRIBUTES_FOR_TEST: null,

  // Traffic Events
  TRAFFIC_EVENTS_GET_COLLECTION_INIT: null,
  TRAFFIC_EVENTS_GET_COLLECTION_SUCCESS: null,
  TRAFFIC_EVENTS_GET_COLLECTION_FAIL: null,
  TRAFFIC_EVENTS_GET_COLLECTION_COMPLETE: null,

  TRAFFIC_EVENTS_GET_INSTANCE_INIT: null,
  TRAFFIC_EVENTS_GET_INSTANCE_SUCCESS: null,
  TRAFFIC_EVENTS_GET_INSTANCE_FAIL: null,
  TRAFFIC_EVENTS_GET_INSTANCE_COMPLETE: null,

  // Audit Log Events
  AUDIT_LOG_EVENTS_GET_COLLECTION_INIT: null,
  AUDIT_LOG_EVENTS_GET_COLLECTION_SUCCESS: null,
  AUDIT_LOG_EVENTS_GET_COLLECTION_FAIL: null,
  AUDIT_LOG_EVENTS_GET_COLLECTION_COMPLETE: null,

  AUDIT_LOG_EVENTS_GET_INSTANCE_INIT: null,
  AUDIT_LOG_EVENTS_GET_INSTANCE_SUCCESS: null,
  AUDIT_LOG_EVENTS_GET_INSTANCE_FAIL: null,
  AUDIT_LOG_EVENTS_GET_INSTANCE_COMPLETE: null,

  // IP Lists
  IP_LISTS_GET_COLLECTION_INIT: null,
  IP_LISTS_GET_COLLECTION_SUCCESS: null,
  IP_LISTS_GET_COLLECTION_FAIL: null,
  IP_LISTS_GET_COLLECTION_COMPLETE: null,

  IP_LISTS_GET_INSTANCE_INIT: null,
  IP_LISTS_GET_INSTANCE_SUCCESS: null,
  IP_LISTS_GET_INSTANCE_FAIL: null,
  IP_LISTS_GET_INSTANCE_COMPLETE: null,

  IP_LISTS_CREATE_INIT: null,
  IP_LISTS_CREATE_SUCCESS: null,
  IP_LISTS_CREATE_FAIL: null,
  IP_LISTS_CREATE_COMPLETE: null,

  IP_LISTS_DELETE_INIT: null,
  IP_LISTS_DELETE_SUCCESS: null,
  IP_LISTS_DELETE_FAIL: null,
  IP_LISTS_DELETE_COMPLETE: null,

  IP_LISTS_AUTOCOMPLETE_INIT: null,
  IP_LISTS_AUTOCOMPLETE_SUCCESS: null,
  IP_LISTS_AUTOCOMPLETE_FAIL: null,
  IP_LISTS_AUTOCOMPLETE_COMPLETE: null,

  IP_LISTS_FACETS_INIT: null,
  IP_LISTS_FACETS_SUCCESS: null,
  IP_LISTS_FACETS_FAIL: null,
  IP_LISTS_FACETS_COMPLETE: null,

  // IP List
  IP_LIST_UPDATE_INIT: null,
  IP_LIST_UPDATE_SUCCESS: null,
  IP_LIST_UPDATE_FAIL: null,
  IP_LIST_UPDATE_COMPLETE: null,

  IP_LIST_DELETE_INIT: null,
  IP_LIST_DELETE_SUCCESS: null,
  IP_LIST_DELETE_FAIL: null,
  IP_LIST_DELETE_COMPLETE: null,

  // IP Tables Rules
  IP_TABLES_RULES_CREATE_INIT: null,
  IP_TABLES_RULES_CREATE_SUCCESS: null,
  IP_TABLES_RULES_CREATE_FAIL: null,
  IP_TABLES_RULES_CREATE_COMPLETE: null,

  IP_TABLES_RULE_UPDATE_INIT: null,
  IP_TABLES_RULE_UPDATE_SUCCESS: null,
  IP_TABLES_RULE_UPDATE_FAIL: null,
  IP_TABLES_RULE_UPDATE_COMPLETE: null,

  // Labels
  LABELS_GET_COLLECTION_INIT: null,
  LABELS_GET_COLLECTION_SUCCESS: null,
  LABELS_GET_COLLECTION_FAIL: null,
  LABELS_GET_COLLECTION_COMPLETE: null,

  LABELS_CREATE_INIT: null,
  LABELS_CREATE_SUCCESS: null,
  LABELS_CREATE_FAIL: null,
  LABELS_CREATE_COMPLETE: null,

  LABELS_DELETE_INIT: null,
  LABELS_DELETE_SUCCESS: null,
  LABELS_DELETE_FAIL: null,
  LABELS_DELETE_COMPLETE: null,

  LABELS_GET_INSTANCE_INIT: null,
  LABELS_GET_INSTANCE_SUCCESS: null,
  LABELS_GET_INSTANCE_FAIL: null,
  LABELS_GET_INSTANCE_COMPLETE: null,

  LABELS_AUTOCOMPLETE_INIT: null,
  LABELS_AUTOCOMPLETE_SUCCESS: null,
  LABELS_AUTOCOMPLETE_FAIL: null,
  LABELS_AUTOCOMPLETE_COMPLETE: null,

  LABELS_FACETS_INIT: null,
  LABELS_FACETS_SUCCESS: null,
  LABELS_FACETS_FAIL: null,
  LABELS_FACETS_COMPLETE: null,

  // Label
  LABEL_UPDATE_INIT: null,
  LABEL_UPDATE_SUCCESS: null,
  LABEL_UPDATE_FAIL: null,
  LABEL_UPDATE_COMPLETE: null,

  LABEL_DELETE_INIT: null,
  LABEL_DELETE_SUCCESS: null,
  LABEL_DELETE_FAIL: null,
  LABEL_DELETE_COMPLETE: null,

  // Label Groups
  LABEL_GROUPS_GET_COLLECTION_INIT: null,
  LABEL_GROUPS_GET_COLLECTION_SUCCESS: null,
  LABEL_GROUPS_GET_COLLECTION_FAIL: null,
  LABEL_GROUPS_GET_COLLECTION_COMPLETE: null,

  LABEL_GROUPS_CREATE_INIT: null,
  LABEL_GROUPS_CREATE_SUCCESS: null,
  LABEL_GROUPS_CREATE_FAIL: null,
  LABEL_GROUPS_CREATE_COMPLETE: null,

  LABEL_GROUPS_DELETE_INIT: null,
  LABEL_GROUPS_DELETE_SUCCESS: null,
  LABEL_GROUPS_DELETE_FAIL: null,
  LABEL_GROUPS_DELETE_COMPLETE: null,

  LABEL_GROUPS_GET_INSTANCE_INIT: null,
  LABEL_GROUPS_GET_INSTANCE_SUCCESS: null,
  LABEL_GROUPS_GET_INSTANCE_FAIL: null,
  LABEL_GROUPS_GET_INSTANCE_COMPLETE: null,

  LABEL_GROUPS_AUTOCOMPLETE_INIT: null,
  LABEL_GROUPS_AUTOCOMPLETE_SUCCESS: null,
  LABEL_GROUPS_AUTOCOMPLETE_FAIL: null,
  LABEL_GROUPS_AUTOCOMPLETE_COMPLETE: null,

  LABEL_GROUPS_FACETS_INIT: null,
  LABEL_GROUPS_FACETS_SUCCESS: null,
  LABEL_GROUPS_FACETS_FAIL: null,
  LABEL_GROUPS_FACETS_COMPLETE: null,

  // Label
  LABEL_GROUP_UPDATE_INIT: null,
  LABEL_GROUP_UPDATE_SUCCESS: null,
  LABEL_GROUP_UPDATE_FAIL: null,
  LABEL_GROUP_UPDATE_COMPLETE: null,

  LABEL_GROUP_DELETE_INIT: null,
  LABEL_GROUP_DELETE_SUCCESS: null,
  LABEL_GROUP_DELETE_FAIL: null,
  LABEL_GROUP_DELETE_COMPLETE: null,

  LABEL_GROUP_MEMBER_OF_INIT: null,
  LABEL_GROUP_MEMBER_OF_SUCCESS: null,
  LABEL_GROUP_MEMBER_OF_FAIL: null,
  LABEL_GROUP_MEMBER_OF_COMPLETE: null,

  // Pairing Profiles
  PAIRING_PROFILES_GET_COLLECTION_INIT: null,
  PAIRING_PROFILES_GET_COLLECTION_SUCCESS: null,
  PAIRING_PROFILES_GET_COLLECTION_FAIL: null,
  PAIRING_PROFILES_GET_COLLECTION_COMPLETE: null,

  PAIRING_PROFILES_GET_INSTANCE_INIT: null,
  PAIRING_PROFILES_GET_INSTANCE_SUCCESS: null,
  PAIRING_PROFILES_GET_INSTANCE_FAIL: null,
  PAIRING_PROFILES_GET_INSTANCE_COMPLETE: null,

  PAIRING_PROFILES_CREATE_INIT: null,
  PAIRING_PROFILES_CREATE_SUCCESS: null,
  PAIRING_PROFILES_CREATE_FAIL: null,
  PAIRING_PROFILES_CREATE_COMPLETE: null,

  PAIRING_PROFILES_DELETE_INIT: null,
  PAIRING_PROFILES_DELETE_SUCCESS: null,
  PAIRING_PROFILES_DELETE_FAIL: null,
  PAIRING_PROFILES_DELETE_COMPLETE: null,

  PAIRING_PROFILES_FACETS_INIT: null,
  PAIRING_PROFILES_FACETS_SUCCESS: null,
  PAIRING_PROFILES_FACETS_FAIL: null,
  PAIRING_PROFILES_FACETS_COMPLETE: null,

  // Pairing Profile
  PAIRING_PROFILE_UPDATE_INIT: null,
  PAIRING_PROFILE_UPDATE_SUCCESS: null,
  PAIRING_PROFILE_UPDATE_FAIL: null,
  PAIRING_PROFILE_UPDATE_COMPLETE: null,

  PAIRING_PROFILE_DELETE_INIT: null,
  PAIRING_PROFILE_DELETE_SUCCESS: null,
  PAIRING_PROFILE_DELETE_FAIL: null,
  PAIRING_PROFILE_DELETE_COMPLETE: null,

  PAIRING_PROFILE_PAIRING_KEY_INIT: null,
  PAIRING_PROFILE_PAIRING_KEY_SUCCESS: null,
  PAIRING_PROFILE_PAIRING_KEY_FAIL: null,
  PAIRING_PROFILE_PAIRING_KEY_COMPLETE: null,

  // Roles
  ROLES_GET_COLLECTION_INIT: null,
  ROLES_GET_COLLECTION_SUCCESS: null,
  ROLES_GET_COLLECTION_FAIL: null,
  ROLES_GET_COLLECTION_COMPLETE: null,

  // Rule Search
  SEC_POLICY_RULE_SEARCH_CREATE_INIT: null,
  SEC_POLICY_RULE_SEARCH_CREATE_SUCCESS: null,
  SEC_POLICY_RULE_SEARCH_CREATE_FAIL: null,
  SEC_POLICY_RULE_SEARCH_CREATE_COMPLETE: null,
  SEC_POLICY_RULE_SEARCH_SET_FILTERS: null,

  // Releases
  RELEASES_GET_COLLECTION_INIT: null,
  RELEASES_GET_COLLECTION_FAIL: null,
  RELEASES_GET_COLLECTION_COMPLETE: null,

  // Rulesets
  RULE_SETS_GET_COLLECTION_INIT: null,
  RULE_SETS_GET_COLLECTION_SUCCESS: null,
  RULE_SETS_GET_COLLECTION_FAIL: null,
  RULE_SETS_GET_COLLECTION_COMPLETE: null,

  RULE_SETS_GET_INSTANCE_INIT: null,
  RULE_SETS_GET_INSTANCE_SUCCESS: null,
  RULE_SETS_GET_INSTANCE_FAIL: null,
  RULE_SETS_GET_INSTANCE_COMPLETE: null,

  RULE_SETS_CREATE_INIT: null,
  RULE_SETS_CREATE_SUCCESS: null,
  RULE_SETS_CREATE_FAIL: null,
  RULE_SETS_CREATE_COMPLETE: null,

  RULE_SETS_DELETE_INIT: null,
  RULE_SETS_DELETE_SUCCESS: null,
  RULE_SETS_DELETE_FAIL: null,
  RULE_SETS_DELETE_COMPLETE: null,

  RULE_SETS_FACETS_INIT: null,
  RULE_SETS_FACETS_SUCCESS: null,
  RULE_SETS_FACETS_FAIL: null,
  RULE_SETS_FACETS_COMPLETE: null,

  // Ruleset
  RULE_SET_UPDATE_INIT: null,
  RULE_SET_UPDATE_SUCCESS: null,
  RULE_SET_UPDATE_FAIL: null,
  RULE_SET_UPDATE_COMPLETE: null,

  RULE_SET_DELETE_INIT: null,
  RULE_SET_DELETE_SUCCESS: null,
  RULE_SET_DELETE_FAIL: null,
  RULE_SET_DELETE_COMPLETE: null,

  RULE_SET_COMMIT_HISTORY_INIT: null,
  RULE_SET_COMMIT_HISTORY_SUCCESS: null,
  RULE_SET_COMMIT_HISTORY_FAIL: null,
  RULE_SET_COMMIT_HISTORY_COMPLETE: null,

  // Rules
  SEC_RULES_CREATE_INIT: null,
  SEC_RULES_CREATE_SUCCESS: null,
  SEC_RULES_CREATE_FAIL: null,
  SEC_RULES_CREATE_COMPLETE: null,

  SEC_RULE_UPDATE_INIT: null,
  SEC_RULE_UPDATE_SUCCESS: null,
  SEC_RULE_UPDATE_FAIL: null,
  SEC_RULE_UPDATE_COMPLETE: null,

  // Management Ports
  MANAGEMENT_PORTS_GET_COLLECTION_INIT: null,
  MANAGEMENT_PORTS_GET_COLLECTION_SUCCESS: null,
  MANAGEMENT_PORTS_GET_COLLECTION_FAIL: null,
  MANAGEMENT_PORTS_GET_COLLECTION_COMPLETE: null,

  // Security Policies
  SEC_POLICIES_GET_COLLECTION_INIT: null,
  SEC_POLICIES_GET_COLLECTION_SUCCESS: null,
  SEC_POLICIES_GET_COLLECTION_FAIL: null,
  SEC_POLICIES_GET_COLLECTION_COMPLETE: null,

  SEC_POLICIES_GET_INSTANCE_INIT: null,
  SEC_POLICIES_GET_INSTANCE_SUCCESS: null,
  SEC_POLICIES_GET_INSTANCE_FAIL: null,
  SEC_POLICIES_GET_INSTANCE_COMPLETE: null,

  SEC_POLICIES_CREATE_INIT: null,
  SEC_POLICIES_CREATE_SUCCESS: null,
  SEC_POLICIES_CREATE_FAIL: null,
  SEC_POLICIES_CREATE_COMPLETE: null,

  // Security Policy
  SEC_POLICY_MODIFIED_OBJECTS_INIT: null,
  SEC_POLICY_MODIFIED_OBJECTS_SUCCESS: null,
  SEC_POLICY_MODIFIED_OBJECTS_FAIL: null,
  SEC_POLICY_MODIFIED_OBJECTS_COMPLETE: null,

  SEC_POLICY_ALLOWS_GET_COLLECTION_INIT: null,
  SEC_POLICY_ALLOWS_GET_COLLECTION_SUCCESS: null,
  SEC_POLICY_ALLOWS_GET_COLLECTION_FAIL: null,
  SEC_POLICY_ALLOWS_GET_COLLECTION_COMPLETE: null,

  // Security Policy Rule View
  SEC_POLICY_RULE_VIEW_GET_INIT: null,
  SEC_POLICY_RULE_VIEW_GET_SUCCESS: null,
  SEC_POLICY_RULE_VIEW_GET_FAIL: null,
  SEC_POLICY_RULE_VIEW_GET_COMPLETE: null,

  // Security Policy Graph
  SEC_POLICY_GRAPH_GET_INIT: null,
  SEC_POLICY_GRAPH_GET_SUCCESS: null,
  SEC_POLICY_GRAPH_GET_FAIL: null,
  SEC_POLICY_GRAPH_GET_COMPLETE: null,

  SEC_POLICY_RULE_COVERAGE_ITERATE_INIT: null,
  SEC_POLICY_RULE_COVERAGE_ITERATE_COMPLETE: null,
  NETWORK_TRAFFIC_ITERATE_INIT: null,
  NETWORK_TRAFFIC_ITERATE_COMPLETE: null,

  NETWORK_TRAFFIC_REBUILD_START: null,
  NETWORK_TRAFFIC_REBUILD_COMPLETE: null,

  SEC_POLICY_RULES_FOR_SCOPE: null,
  SEC_POLICY_RULES_FOR_WORKLOAD: null,
  SEC_POLICY_RULES_FOR_ALL: null,

  // Security Policy Coverage
  SEC_POLICY_RULE_COVERAGE_CREATE_INIT: null,
  SEC_POLICY_RULE_COVERAGE_CREATE_SUCCESS: null,
  SEC_POLICY_RULE_COVERAGE_CREATE_FAIL: null,
  SEC_POLICY_RULE_COVERAGE_CREATE_COMPLETE: null,
  UPDATE_REQUESTED_LINKS: null,

  // Security Policy Provisioning
  SEC_POLICY_PENDING_GET_INIT: null,
  SEC_POLICY_PENDING_GET_SUCCESS: null,
  SEC_POLICY_PENDING_GET_FAIL: null,
  SEC_POLICY_PENDING_GET_COMPLETE: null,

  SEC_POLICY_PENDING_DELETE_INIT: null,
  SEC_POLICY_PENDING_DELETE_SUCCESS: null,
  SEC_POLICY_PENDING_DELETE_FAIL: null,
  SEC_POLICY_PENDING_DELETE_COMPLETE: null,

  // Segmentation Template Store
  SEGMENTATION_TEMPLATE_PARSE_DATA: null,
  SEGMENTATION_TEMPLATE_SET_STATUS: null,
  SEGMENTATION_TEMPLATES_CLEAR_INSTALLED_TEMPLATES: null,
  SEGMENTATION_TEMPLATES_COMPARE_INSTALLED_TEMPLATES: null,

  // Services
  SERVICES_GET_COLLECTION_INIT: null,
  SERVICES_GET_COLLECTION_SUCCESS: null,
  SERVICES_GET_COLLECTION_FAIL: null,
  SERVICES_GET_COLLECTION_COMPLETE: null,

  SERVICES_GET_INSTANCE_INIT: null,
  SERVICES_GET_INSTANCE_SUCCESS: null,
  SERVICES_GET_INSTANCE_FAIL: null,
  SERVICES_GET_INSTANCE_COMPLETE: null,

  SERVICES_CREATE_INIT: null,
  SERVICES_CREATE_SUCCESS: null,
  SERVICES_CREATE_FAIL: null,
  SERVICES_CREATE_COMPLETE: null,

  SERVICES_DELETE_INIT: null,
  SERVICES_DELETE_SUCCESS: null,
  SERVICES_DELETE_FAIL: null,
  SERVICES_DELETE_COMPLETE: null,

  SERVICES_FACETS_INIT: null,
  SERVICES_FACETS_SUCCESS: null,
  SERVICES_FACETS_FAIL: null,
  SERVICES_FACETS_COMPLETE: null,

  SERVICES_AUTOCOMPLETE_INIT: null,
  SERVICES_AUTOCOMPLETE_SUCCESS: null,
  SERVICES_AUTOCOMPLETE_FAIL: null,
  SERVICES_AUTOCOMPLETE_COMPLETE: null,

  // Service
  SERVICE_UPDATE_INIT: null,
  SERVICE_UPDATE_SUCCESS: null,
  SERVICE_UPDATE_FAIL: null,
  SERVICE_UPDATE_COMPLETE: null,

  SERVICE_DELETE_INIT: null,
  SERVICE_DELETE_SUCCESS: null,
  SERVICE_DELETE_FAIL: null,
  SERVICE_DELETE_COMPLETE: null,

  SET_USER_DEFINED_SERVICE: null,
  CLEAR_USER_DEFINED_SERVICE: null,

  // Scoped Services
  SCOPED_SERVICES_GET_COLLECTION_SUCCESS: null,

  //Virtual Services
  VIRTUAL_SERVICES_GET_COLLECTION_SUCCESS: null,
  VIRTUAL_SERVICES_GET_INSTANCE_SUCCESS: null,

  VIRTUAL_SERVICES_AUTOCOMPLETE_INIT: null,
  VIRTUAL_SERVICES_AUTOCOMPLETE_SUCCESS: null,
  VIRTUAL_SERVICES_AUTOCOMPLETE_FAIL: null,
  VIRTUAL_SERVICES_AUTOCOMPLETE_COMPLETE: null,

  VIRTUAL_SERVICES_FACETS_INIT: null,
  VIRTUAL_SERVICES_FACETS_SUCCESS: null,
  VIRTUAL_SERVICES_FACETS_FAIL: null,
  VIRTUAL_SERVICES_FACETS_COMPLETE: null,

  // Service Binding
  SERVICE_BINDING_DELETE_SUCCESS: null,

  // Service Bindings
  SERVICE_BINDINGS_GET_COLLECTION_SUCCESS: null,

  SERVICE_BINDINGS_CREATE_INIT: null,
  SERVICE_BINDINGS_CREATE_SUCCESS: null,
  SERVICE_BINDINGS_CREATE_FAIL: null,
  SERVICE_BINDINGS_CREATE_COMPLETE: null,

  // Firewall Settings
  FIREWALL_SETTINGS_GET_INIT: null,
  FIREWALL_SETTINGS_GET_SUCCESS: null,
  FIREWALL_SETTINGS_GET_FAIL: null,
  FIREWALL_SETTINGS_GET_COMPLETE: null,

  FIREWALL_SETTINGS_UPDATE_INIT: null,
  FIREWALL_SETTINGS_UPDATE_SUCCESS: null,
  FIREWALL_SETTINGS_UPDATE_FAIL: null,
  FIREWALL_SETTINGS_UPDATE_COMPLETE: null,

  // Orgs
  ORGS_GET_INSTANCE_INIT: null,
  ORGS_GET_INSTANCE_SUCCESS: null,
  ORGS_GET_INSTANCE_FAIL: null,
  ORGS_GET_INSTANCE_COMPLETE: null,

  // Org
  ORG_UPDATE_INIT: null,
  ORG_UPDATE_SUCCESS: null,
  ORG_UPDATE_FAIL: null,
  ORG_UPDATE_COMPLETE: null,

  ORG_GRANT_ACCESS_INIT: null,
  ORG_GRANT_ACCESS_SUCCESS: null,
  ORG_GRANT_ACCESS_FAIL: null,
  ORG_GRANT_ACCESS_COMPLETE: null,

  // Org Users
  USERS_GET_COLLECTION_INIT: null,
  USERS_GET_COLLECTION_SUCCESS: null,
  USERS_GET_COLLECTION_FAIL: null,
  USERS_GET_COLLECTION_COMPLETE: null,

  // Org Auth Security Principals
  ORG_AUTH_SECURITY_PRINCIPALS_GET_COLLECTION_INIT: null,
  ORG_AUTH_SECURITY_PRINCIPALS_GET_COLLECTION_SUCCESS: null,
  ORG_AUTH_SECURITY_PRINCIPALS_GET_COLLECTION_FAIL: null,
  ORG_AUTH_SECURITY_PRINCIPALS_GET_COLLECTION_COMPLETE: null,

  ORG_AUTH_SECURITY_PRINCIPALS_CREATE_INIT: null,
  ORG_AUTH_SECURITY_PRINCIPALS_CREATE_SUCCESS: null,
  ORG_AUTH_SECURITY_PRINCIPALS_CREATE_FAIL: null,
  ORG_AUTH_SECURITY_PRINCIPALS_CREATE_COMPLETE: null,

  // Org Permissions
  ORG_PERMISSIONS_GET_COLLECTION_INIT: null,
  ORG_PERMISSIONS_GET_COLLECTION_SUCCESS: null,
  ORG_PERMISSIONS_GET_COLLECTION_FAIL: null,
  ORG_PERMISSIONSS_GET_COLLECTION_COMPLETE: null,

  ORG_PERMISSIONS_CREATE_INIT: null,
  ORG_PERMISSIONS_CREATE_SUCCESS: null,
  ORG_PERMISSIONS_CREATE_FAIL: null,
  ORG_PERMISSIONS_CREATE_COMPLETE: null,

  // Org Permissions
  ORG_PERMISSIONS_SCOPE_ROLES_INIT: null,
  ORG_PERMISSIONS_SCOPE_ROLES_SUCCESS: null,
  ORG_PERMISSIONS_SCOPE_ROLES_FAIL: null,
  ORG_PERMISSIONSS_SCOPE_ROLES_COMPLETE: null,

  // Org User
  ORG_USER_DELETE_INIT: null,
  ORG_USER_DELETE_SUCCESS: null,
  ORG_USER_DELETE_FAIL: null,
  ORG_USER_DELETE_COMPLETE: null,

  // Org Users Roles
  ORG_USERS_ROLES_GET_COLLECTION_INIT: null,
  ORG_USERS_ROLES_GET_COLLECTION_SUCCESS: null,
  ORG_USERS_ROLES_GET_COLLECTION_FAIL: null,
  ORG_USERS_ROLES_GET_COLLECTION_COMPLETE: null,

  ORG_USERS_ROLES_GET_INSTANCE_INIT: null,
  ORG_USERS_ROLES_GET_INSTANCE_SUCCESS: null,
  ORG_USERS_ROLES_GET_INSTANCE_FAIL: null,
  ORG_USERS_ROLES_GET_INSTANCE_COMPLETE: null,

  ORG_USERS_ROLES_CREATE_INIT: null,
  ORG_USERS_ROLES_CREATE_SUCCESS: null,
  ORG_USERS_ROLES_CREATE_FAIL: null,
  ORG_USERS_ROLES_CREATE_COMPLETE: null,

  ORG_USERS_ROLES_DELETE_INIT: null,
  ORG_USERS_ROLES_DELETE_SUCCESS: null,
  ORG_USERS_ROLES_DELETE_FAIL: null,
  ORG_USERS_ROLES_DELETE_COMPLETE: null,

  ORG_USERS_ROLE_UPDATE_INIT: null,
  ORG_USERS_ROLE_UPDATE_SUCCESS: null,
  ORG_USERS_ROLE_UPDATE_FAIL: null,
  ORG_USERS_ROLE_UPDATE_COMPLETE: null,

  ORG_USERS_ROLE_DELETE_INIT: null,
  ORG_USERS_ROLE_DELETE_SUCCESS: null,
  ORG_USERS_ROLE_DELETE_FAIL: null,
  ORG_USERS_ROLE_DELETE_COMPLETE: null,

  // PCE Health
  HEALTH_GET_INIT: null,
  HEALTH_GET_SUCCESS: null,
  HEALTH_GET_FAIL: null,
  HEALTH_GET_COMPLETE: null,

  // API Keys
  API_KEYS_GET_COLLECTION_INIT: null,
  API_KEYS_GET_COLLECTION_SUCCESS: null,
  API_KEYS_GET_COLLECTION_FAIL: null,
  API_KEYS_GET_COLLECTION_COMPLETE: null,

  API_KEYS_GET_INSTANCE_INIT: null,
  API_KEYS_GET_INSTANCE_SUCCESS: null,
  API_KEYS_GET_INSTANCE_FAIL: null,
  API_KEYS_GET_INSTANCE_COMPLETE: null,

  // Workloads
  WORKLOADS_GET_COLLECTION_INIT: null,
  WORKLOADS_GET_COLLECTION_SUCCESS: null,
  WORKLOADS_GET_COLLECTION_FAIL: null,
  WORKLOADS_GET_COLLECTION_COMPLETE: null,

  WORKLOADS_GET_INSTANCE_INIT: null,
  WORKLOADS_GET_INSTANCE_SUCCESS: null,
  WORKLOADS_GET_INSTANCE_FAIL: null,
  WORKLOADS_GET_INSTANCE_COMPLETE: null,

  WORKLOADS_CREATE_INIT: null,
  WORKLOADS_CREATE_SUCCESS: null,
  WORKLOADS_CREATE_FAIL: null,
  WORKLOADS_CREATE_COMPLETE: null,

  WORKLOADS_DELETE_INIT: null,
  WORKLOADS_DELETE_SUCCESS: null,
  WORKLOADS_DELETE_FAIL: null,
  WORKLOADS_DELETE_COMPLETE: null,

  WORKLOADS_UNPAIR_INIT: null,
  WORKLOADS_UNPAIR_SUCCESS: null,
  WORKLOADS_UNPAIR_FAIL: null,
  WORKLOADS_UNPAIR_COMPLETE: null,

  WORKLOADS_SET_LABELS_INIT: null,
  WORKLOADS_SET_LABELS_SUCCESS: null,
  WORKLOADS_SET_LABELS_FAIL: null,
  WORKLOADS_SET_LABELS_COMPLETE: null,

  WORKLOADS_REMOVE_LABELS_INIT: null,
  WORKLOADS_REMOVE_LABELS_SUCCESS: null,
  WORKLOADS_REMOVE_LABELS_FAIL: null,
  WORKLOADS_REMOVE_LABELS_COMPLETE: null,

  WORKLOADS_SET_FLOW_REPORTING_FREQUENCY_INIT: null,
  WORKLOADS_SET_FLOW_REPORTING_FREQUENCY_SUCCESS: null,
  WORKLOADS_SET_FLOW_REPORTING_FREQUENCY_FAIL: null,
  WORKLOADS_SET_FLOW_REPORTING_FREQUENCY_COMPLETE: null,

  WORKLOADS_FACETS_INIT: null,
  WORKLOADS_FACETS_SUCCESS: null,
  WORKLOADS_FACETS_FAIL: null,
  WORKLOADS_FACETS_COMPLETE: null,

  WORKLOADS_AUTOCOMPLETE_INIT: null,
  WORKLOADS_AUTOCOMPLETE_SUCCESS: null,
  WORKLOADS_AUTOCOMPLETE_FAIL: null,
  WORKLOADS_AUTOCOMPLETE_COMPLETE: null,

  WORKLOADS_VERIFY_CAPS_INIT: null,
  WORKLOADS_VERIFY_CAPS_SUCCESS: null,
  WORKLOADS_VERIFY_CAPS_FAIL: null,
  WORKLOADS_VERIFY_CAPS_COMPLETE: null,

  // Workload
  WORKLOAD_UPDATE_INIT: null,
  WORKLOAD_UPDATE_SUCCESS: null,
  WORKLOAD_UPDATE_FAIL: null,
  WORKLOAD_UPDATE_COMPLETE: null,

  WORKLOAD_DELETE_INIT: null,
  WORKLOAD_DELETE_SUCCESS: null,
  WORKLOAD_DELETE_FAIL: null,
  WORKLOAD_DELETE_COMPLETE: null,

  WORKLOAD_IP_SECURE_CONNECTIONS_INIT: null,
  WORKLOAD_IP_SECURE_CONNECTIONS_SUCCESS: null,
  WORKLOAD_IP_SECURE_CONNECTIONS_FAIL: null,
  WORKLOAD_IP_SECURE_CONNECTIONS_COMPLETE: null,

  WORKLOAD_UPGRADE_INIT: null,
  WORKLOAD_UPGRADE_SUCCESS: null,
  WORKLOAD_UPGRADE_FAIL: null,
  WORKLOAD_UPGRADE_COMPLETE: null,

  WORKLOAD_REDETECT_NETWORK_INIT: null,
  WORKLOAD_REDETECT_NETWORK_SUCCESS: null,
  WORKLOAD_REDETECT_NETWORK_FAIL: null,
  WORKLOAD_REDETECT_NETWORK_COMPLETE: null,

  WORKLOAD_SERVICE_REPORTS_LATEST_INIT: null,
  WORKLOAD_SERVICE_REPORTS_LATEST_SUCCESS: null,
  WORKLOAD_SERVICE_REPORTS_LATEST_FAIL: null,
  WORKLOAD_SERVICE_REPORTS_LATEST_COMPLETE: null,

  AGGREGATED_DETECTED_VULNERABILITIES_GET_COLLECTION_INIT: null,
  AGGREGATED_DETECTED_VULNERABILITIES_GET_COLLECTION_SUCCESS: null,
  AGGREGATED_DETECTED_VULNERABILITIES_GET_COLLECTION_FAIL: null,
  AGGREGATED_DETECTED_VULNERABILITIES_GET_COLLECTION_COMPLETE: null,

  DETECTED_VULNERABILITIES_GET_COLLECTION_INIT: null,
  DETECTED_VULNERABILITIES_GET_COLLECTION_SUCCESS: null,
  DETECTED_VULNERABILITIES_GET_COLLECTION_FAIL: null,
  DETECTED_VULNERABILITIES_GET_COLLECTION_COMPLETE: null,

  RULE_SETS_PROJECTED_VES_INIT: null,
  RULE_SETS_PROJECTED_VES_SUCCESS: null,
  RULE_SETS_PROJECTED_VES_FAIL: null,
  RULE_SETS_PROJECTED_VES_COMPLETE: null,

  RULE_SET_PROJECTED_VES_INIT: null,
  RULE_SET_PROJECTED_VES_SUCCESS: null,
  RULE_SET_PROJECTED_VES_FAIL: null,
  RULE_SET_PROJECTED_VES_COMPLETE: null,

  AGENT_COMPATIBILITY_REPORT_INIT: null,
  AGENT_COMPATIBILITY_REPORT_FAIL: null,
  AGENT_COMPATIBILITY_REPORT_COMPLETE: null,

  // Command Panel
  CLICK_ACTION_ITEM: null,
  CLOSE_ACTION_ITEM: null,
  SELECT_SERVICE: null,
  SELECT_ACTIONS: null,
  SELECT_PBUB: null,
  SELECT_VULNERABILITY: null,
  CLEAR_TRAFFIC_SELECTION: null,

  // Users
  USERS_GET_INSTANCE_INIT: null,
  USERS_GET_INSTANCE_SUCCESS: null,
  USERS_GET_INSTANCE_FAIL: null,
  USERS_GET_INSTANCE_COMPLETE: null,

  USERS_LOGIN_INIT: null,
  USERS_LOGIN_SUCCESS: null,
  USERS_LOGIN_FAIL: null,
  USERS_LOGIN_COMPLETE: null,

  // User
  USER_UPDATE_INIT: null,
  USER_UPDATE_SUCCESS: null,
  USER_UPDATE_FAIL: null,
  USER_UPDATE_COMPLETE: null,

  USER_ORGS_INIT: null,
  USER_ORGS_SUCCESS: null,
  USER_ORGS_FAIL: null,
  USER_ORGS_COMPLETE: null,

  USER_LOGOUT: null,

  // kvPairs
  KVPAIRS_GET_INSTANCE_SUCCESS: null,
  KVPAIR_UPDATE_SUCCESS: null,

  // Agent OS Families
  WORKLOAD_OS_FAMILIES_GET_COLLECTION_INIT: null,
  WORKLOAD_OS_FAMILIES_GET_COLLECTION_SUCCESS: null,
  WORKLOAD_OS_FAMILIES_GET_COLLECTION_FAIL: null,
  WORKLOAD_OS_FAMILIES_GET_COLLECTION_COMPLETE: null,

  // Support Reports
  AGENT_SUPPORT_REPORT_REQUESTS_GET_COLLECTION_INIT: null,
  AGENT_SUPPORT_REPORT_REQUESTS_GET_COLLECTION_SUCCESS: null,
  AGENT_SUPPORT_REPORT_REQUESTS_GET_COLLECTION_FAIL: null,
  AGENT_SUPPORT_REPORT_REQUESTS_GET_COLLECTION_COMPLETE: null,

  SUPPORT_REPORT_REQUESTS_GET_COLLECTION_INIT: null,
  SUPPORT_REPORT_REQUESTS_GET_COLLECTION_SUCCESS: null,
  SUPPORT_REPORT_REQUESTS_GET_COLLECTION_FAIL: null,
  SUPPORT_REPORT_REQUESTS_GET_COLLECTION_COMPLETE: null,

  // Server Load Balancer
  SLBS_GET_COLLECTION_INIT: null,
  SLBS_GET_COLLECTION_SUCCESS: null,
  SLBS_GET_COLLECTION_FAIL: null,
  SLBS_GET_COLLECTION_COMPLETE: null,

  // Network Function Controls
  NFCS_GET_COLLECTION_INIT: null,
  NFCS_GET_COLLECTION_SUCCESS: null,
  NFCS_GET_COLLECTION_FAIL: null,
  NFCS_GET_COLLECTION_COMPLETE: null,

  // Virtual Servers
  VIRTUAL_SERVERS_GET_COLLECTION_INIT: null,
  VIRTUAL_SERVERS_GET_COLLECTION_SUCCESS: null,
  VIRTUAL_SERVERS_GET_COLLECTION_FAIL: null,
  VIRTUAL_SERVERS_GET_COLLECTION_COMPLETE: null,

  VIRTUAL_SERVERS_AUTOCOMPLETE_INIT: null,
  VIRTUAL_SERVERS_AUTOCOMPLETE_SUCCESS: null,
  VIRTUAL_SERVERS_AUTOCOMPLETE_FAIL: null,
  VIRTUAL_SERVERS_AUTOCOMPLETE_COMPLETE: null,

  VIRTUAL_SERVERS_GET_INSTANCE_INIT: null,
  VIRTUAL_SERVERS_GET_INSTANCE_SUCCESS: null,
  VIRTUAL_SERVERS_GET_INSTANCE_FAIL: null,
  VIRTUAL_SERVERS_GET_INSTANCE_COMPLETE: null,

  // Discovered Virtual Servers
  DISCOVERED_VIRTUAL_SERVERS_GET_COLLECTION_INIT: null,
  DISCOVERED_VIRTUAL_SERVERS_GET_COLLECTION_SUCCESS: null,
  DISCOVERED_VIRTUAL_SERVERS_GET_COLLECTION_FAIL: null,
  DISCOVERED_VIRTUAL_SERVERS_GET_COLLECTION_COMPLETE: null,

  DISCOVERED_VIRTUAL_SERVERS_GET_INSTANCE_INIT: null,
  DISCOVERED_VIRTUAL_SERVERS_GET_INSTANCE_SUCCESS: null,
  DISCOVERED_VIRTUAL_SERVERS_GET_INSTANCE_FAIL: null,
  DISCOVERED_VIRTUAL_SERVERS_GET_INSTANCE_COMPLETE: null,

  // Status
  STATUS_IDLE: null,
  STATUS_BUSY: null,
  STATUS_FAIL: null,
  STATUS_SUCCESS: null,

  // Map
  UPDATE_MAP_ROUTE: null,
  UPDATE_MAP_TYPE: null,
  UPDATE_GRAPH_CALCULATED: null,
  UPDATE_ZOOM_IN: null,
  UPDATE_ZOOM_OUT: null,
  UPDATE_ZOOM_TO_FIT: null,
  UPDATE_TRANSFORM: null,
  SELECT_TRAFFIC_FILTERS: null,
  SELECT_SERVICE_FILTERS: null,
  SELECT_TRAFFIC_TIME_FILTERS: null,
  SELECT_TRAFFIC_CONNECTION_FILTERS: null,
  SET_TRAFFIC_FILTER_HIDDEN: null,
  RESET_DEFAULT_FILTERS: null,
  UPDATE_POSITION: null,
  UPDATE_PANEL_SHOWN: null,
  SET_CONNECTED_SEARCH: null,
  EXPAND_COLLAPSE_CONNECTED_GROUP: null,
  RESET_LAYOUT: null,
  SEC_POLICY_RULE_COVERAGE_IS_TRUNCATED: null,
  UPDATE_RING_FENCE_RULES: null,

  // API Status
  API_FAIL: null,
  API_ERROR_FAIL: null,
  API_UNAUTHORIZED_FAIL: null,
  API_FORBIDDEN_FAIL: null,
  API_NOT_ACCEPTABLE_FAIL: null,
  API_TOO_MANY_REQUESTS_FAIL: null,
  API_NOT_FOUND_FAIL: null,
  API_INTERNAL_SERVER_ERROR_FAIL: null,
  API_SERVER_CONNECTION_FAIL: null,
  API_BAD_GATEWAY_FAIL: null,
  API_SERVICE_UNAVAILABLE: null,
  API_TIMEOUT_FAIL: null,

  OPTIONAL_FEATURES_GET_INIT: null,
  OPTIONAL_FEATURES_GET_SUCCESS: null,
  OPTIONAL_FEATURES_GET_FAIL: null,
  OPTIONAL_FEATURES_GET_COMPLETE: null,

  //Location Summary
  LOCATION_SUMMARY_GET_INIT: null,
  LOCATION_SUMMARY_GET_SUCCESS: null,
  LOCATION_SUMMARY_GET_FAIL: null,
  LOCATION_SUMMARY_GET_COMPLETE: null,

  // APP Group Summary
  APP_GROUP_SUMMARY_GET_INIT: null,
  APP_GROUP_SUMMARY_GET_SUCCESS: null,
  APP_GROUP_SUMMARY_GET_FAIL: null,
  APP_GROUP_SUMMARY_GET_COMPLETE: null,

  // App Groups
  APP_GROUPS_GET_COLLECTION_INIT: null,
  APP_GROUPS_GET_COLLECTION_SUCCESS: null,
  APP_GROUPS_GET_COLLECTION_FAIL: null,
  APP_GROUPS_GET_COLLECTION_COMPLETE: null,

  // Rule Validation
  REQUIRED_SCOPE_FIELDS: null,
  REQUIRED_RULE_FIELDS: null,
  REQUIRED_RULEIPTABLES_FIELDS: null,
  IP_LIST_ON_BOTH_SIDES: null,
  FPE_INVALID_PE: null,
  FPE_INVALID_PE_REVERSE: null,
  NO_FPE_ONLY_PE_BS: null,
  NO_FPE_ONLY_PE_VS: null,
  SECURECONNECT_INVALID_PE_CE: null,
  ONLY_USER_GROUPS: null,
  USER_GROUP_IP_LIST: null,
  WINDOWS_SERVICE_INVALID_PE: null,
  SCOPE_APP_LABEL_NOT_ALL: null,
  SCOPE_ENV_LABEL_NOT_ALL: null,
  SCOPE_LOC_LABEL_NOT_ALL: null,
  RULE_APP_LABEL_IN_USE: null,
  RULE_ENV_LABEL_IN_USE: null,
  RULE_LOC_LABEL_IN_USE: null,
  ALL_APP_LABEL_AND_LABEL: null,
  ALL_ENV_LABEL_AND_LABEL: null,
  ALL_LOC_LABEL_AND_LABEL: null,
  DUPLICATE_SCOPE: null,
  UNPAIRED_WORKLOADS: null,
  STATEMENTS_INVALID: null,
  CAN_ONLY_USE_NULLUB: null,
  STATELESS_WITH_SECURECONNECT: null,
  STATELESS_WITH_MACHINE_AUTH: null,
  STATELESS_WITH_LABEL_GROUP: null,
  STATELESS_WITH_IP_LIST: null,
  STATELESS_INVALID_LABELS: null,
  STATELESS_INVALID_CONSUMERS: null,
  MACHINE_AUTH_WITH_ALL_SERVICES: null,
  MACHINE_AUTH_WITH_NULL_SERVICE: null,
  MACHINE_AUTH_WITH_NULL_UB_SERVICE: null,
  SECURECONNECT_WITH_NULL_UB_SERVICE: null,
  SECURECONNECT_WITH_NULL_SERVICE: null,
  SECURECONNECT_WITH_SECURITY_PRINCIPAL: null,
  PE_IP_LIST_WITH_MACHINE_AUTH: null,
  CE_IP_LIST_WITH_MACHINE_AUTH: null,
  CAN_ONLY_USE_FROM_CONSUMERS: null,
  PE_WITH_VIRTUAL_SERVICE_VIRTUAL_SERVER: null,
  PE_WITH_IP_LIST_WORKLOAD: null,
  PE_WITH_IP_LIST_WINDOWS: null,
  PE_WITH_IP_VS_VS_W: null,
  PE_WITH_VS_SERVICES: null,
  PE_WITH_VIRTUAL_SERVICE: null,
  CE_WITH_VIRTUAL_SERVICE: null,
  CE_FROM_CONSUMERS_BOTH: null,
  CE_WITH_IP_LIST_WORKLOAD: null,
  CE_WITH_IP_VS_W: null,
  CH_PE_INVALID: null,
  CH_BY_ITSELF: null,
  CH_STATELESS_MACHINE_AUTH_SECURECONNECT: null,
  CH_WINDOWS_SERVICE: null,
  CH_NON_TCP_SERVICE: null,
  NON_BRN_MUST_USE_IP_LIST: null,
  CANT_USE_SECURE_CONNECT_WITH_NON_DEFAULT_NETWORK: null,
  CANT_USE_MACHINE_AUTH_WITH_NON_DEFAULT_NETWORK: null,
  NETWORK_TYPE_MUST_BE_BRN: null,
  CONSUMER_MUST_HAVE_ONLY_IP_LISTS: null,
  PROVIDER_MUST_HAVE_ONLY_IP_LISTS: null,

  // User Groups
  SECURITY_PRINCIPALS_GET_COLLECTION_INIT: null,
  SECURITY_PRINCIPALS_GET_COLLECTION_SUCCESS: null,
  SECURITY_PRINCIPALS_GET_COLLECTION_FAIL: null,
  SECURITY_PRINCIPALS_GET_COLLECTION_COMPLETE: null,

  SECURITY_PRINCIPALS_GET_INSTANCE_INIT: null,
  SECURITY_PRINCIPALS_GET_INSTANCE_SUCCESS: null,
  SECURITY_PRINCIPALS_GET_INSTANCE_FAIL: null,
  SECURITY_PRINCIPALS_GET_INSTANCE_COMPLETE: null,

  SECURITY_PRINCIPALS_CREATE_INIT: null,
  SECURITY_PRINCIPALS_CREATE_SUCCESS: null,
  SECURITY_PRINCIPALS_CREATE_FAIL: null,
  SECURITY_PRINCIPALS_CREATE_COMPLETE: null,

  SECURITY_PRINCIPALS_FACETS_INIT: null,
  SECURITY_PRINCIPALS_FACETS_SUCCESS: null,
  SECURITY_PRINCIPALS_FACETS_FAIL: null,
  SECURITY_PRINCIPALS_FACETS_COMPLETE: null,

  // Provision Dependencies
  SEC_POLICIES_DEPENDENCIES_INIT: null,
  SEC_POLICIES_DEPENDENCIES_SUCCESS: null,
  SEC_POLICIES_DEPENDENCIES_FAIL: null,
  SEC_POLICIES_DEPENDENCIES_COMPLETE: null,

  // Provision Dependencies
  SEC_POLICY_DEPENDENCIES_INIT: null,
  SEC_POLICY_DEPENDENCIES_SUCCESS: null,
  SEC_POLICY_DEPENDENCIES_FAIL: null,
  SEC_POLICY_DEPENDENCIES_COMPLETE: null,

  // Provision Counts
  DASHBOARDS_RULE_CALC_PROGRESS_INIT: null,
  DASHBOARDS_RULE_CALC_PROGRESS_SUCCESS: null,
  DASHBOARDS_RULE_CALC_PROGRESS_FAIL: null,
  DASHBOARDS_RULE_CALC_PROGRESS_COMPLETE: null,

  // SecureConnect Gateways
  SECURE_CONNECT_GATEWAYS_GET_COLLECTION_INIT: null,
  SECURE_CONNECT_GATEWAYS_GET_COLLECTION_SUCCESS: null,
  SECURE_CONNECT_GATEWAYS_GET_COLLECTION_FAIL: null,
  SECURE_CONNECT_GATEWAYS_GET_COLLECTION_COMPLETE: null,

  SECURE_CONNECT_GATEWAYS_GET_INSTANCE_INIT: null,
  SECURE_CONNECT_GATEWAYS_GET_INSTANCE_SUCCESS: null,
  SECURE_CONNECT_GATEWAYS_GET_INSTANCE_FAIL: null,
  SECURE_CONNECT_GATEWAYS_GET_INSTANCE_COMPLETE: null,

  // Blocked Traffic
  BLOCKED_TRAFFICS_GET_COLLECTION_INIT: null,
  BLOCKED_TRAFFICS_GET_COLLECTION_SUCCESS: null,
  BLOCKED_TRAFFICS_GET_COLLECTION_FAIL: null,
  BLOCKED_TRAFFICS_GET_COLLECTION_COMPLETE: null,

  BLOCKED_TRAFFICS_GET_INSTANCE_INIT: null,
  BLOCKED_TRAFFICS_GET_INSTANCE_SUCCESS: null,
  BLOCKED_TRAFFICS_GET_INSTANCE_FAIL: null,
  BLOCKED_TRAFFICS_GET_INSTANCE_COMPLETE: null,

  BLOCKED_TRAFFICS_QUERIES_INIT: null,
  BLOCKED_TRAFFICS_QUERIES_SUCCESS: null,
  BLOCKED_TRAFFICS_QUERIES_FAIL: null,
  BLOCKED_TRAFFICS_QUERIES_COMPLETE: null,

  TRAFFIC_FLOWS_TRAFFIC_ANALYSIS_QUERIES_INIT: null,
  TRAFFIC_FLOWS_TRAFFIC_ANALYSIS_QUERIES_SUCCESS: null,
  TRAFFIC_FLOWS_TRAFFIC_ANALYSIS_QUERIES_FAIL: null,
  TRAFFIC_FLOWS_TRAFFIC_ANALYSIS_QUERIES_COMPLETE: null,

  TRAFFIC_FLOWS_ASYNC_QUERIES_CREATE_INIT: null,
  TRAFFIC_FLOWS_ASYNC_QUERIES_CREATE_SUCCESS: null,
  TRAFFIC_FLOWS_ASYNC_QUERIES_CREATE_FAIL: null,
  TRAFFIC_FLOWS_ASYNC_QUERIES_CREATE_COMPLETE: null,

  TRAFFIC_FLOWS_ASYNC_QUERIES_GET_COLLECTION_INIT: null,
  TRAFFIC_FLOWS_ASYNC_QUERIES_GET_COLLECTION_SUCCESS: null,
  TRAFFIC_FLOWS_ASYNC_QUERIES_GET_COLLECTION_FAIL: null,
  TRAFFIC_FLOWS_ASYNC_QUERIES_GET_COLLECTION_COMPLETE: null,

  TRAFFIC_FLOWS_ASYNC_QUERIES_DOWNLOAD_INIT: null,
  TRAFFIC_FLOWS_ASYNC_QUERIES_DOWNLOAD_SUCCESS: null,
  TRAFFIC_FLOWS_ASYNC_QUERIES_DOWNLOAD_FAIL: null,
  TRAFFIC_FLOWS_ASYNC_QUERIES_DOWNLOAD_COMPLETE: null,

  ENFORCEMENT_BOUNDARIES_GET_INSTANCE_SUCCESS: null,
  ENFORCEMENT_BOUNDARY_DELETE_SUCCESS: null,

  CONTAINER_CLUSTERS_GET_COLLECTION_INIT: null,
  CONTAINER_CLUSTERS_GET_COLLECTION_SUCCESS: null,
  CONTAINER_CLUSTERS_GET_COLLECTION_FAIL: null,
  CONTAINER_CLUSTERS_GET_COLLECTION_COMPLETE: null,

  CONTAINER_CLUSTERS_AUTOCOMPLETE_INIT: null,
  CONTAINER_CLUSTERS_AUTOCOMPLETE_SUCCESS: null,
  CONTAINER_CLUSTERS_AUTOCOMPLETE_FAIL: null,
  CONTAINER_CLUSTERS_AUTOCOMPLETE_COMPLETE: null,

  CONTAINER_CLUSTER_GET_INSTANCE_INIT: null,
  CONTAINER_CLUSTER_GET_INSTANCE_SUCCESS: null,
  CONTAINER_CLUSTER_GET_INSTANCE_FAIL: null,
  CONTAINER_CLUSTER_GET_INSTANCE_COMPLETE: null,

  CONTAINER_WORKLOADS_GET_COLLECTION_INIT: null,
  CONTAINER_WORKLOADS_GET_COLLECTION_SUCCESS: null,
  CONTAINER_WORKLOADS_GET_COLLECTION_FAIL: null,
  CONTAINER_WORKLOADS_GET_COLLECTION_COMPLETE: null,

  CONTAINER_WORKLOADS_AUTOCOMPLETE_INIT: null,
  CONTAINER_WORKLOADS_AUTOCOMPLETE_SUCCESS: null,
  CONTAINER_WORKLOADS_AUTOCOMPLETE_FAIL: null,
  CONTAINER_WORKLOADS_AUTOCOMPLETE_COMPLETE: null,

  CONTAINER_WORKLOADS_GET_INSTANCE_INIT: null,
  CONTAINER_WORKLOADS_GET_INSTANCE_SUCCESS: null,
  CONTAINER_WORKLOADS_GET_INSTANCE_FAIL: null,
  CONTAINER_WORKLOADS_GET_INSTANCE_COMPLETE: null,
  CONTAINER_WORKLOADS_GET_RUNNING_CONTAINERS_SUCCESS: null,

  NETWORKS_GET_COLLECTION_INIT: null,
  NETWORKS_GET_COLLECTION_SUCCESS: null,
  NETWORKS_GET_COLLECTION_FAIL: null,
  NETWORKS_GET_COLLECTION_COMPLETE: null,

  NETWORKS_AUTOCOMPLETE_INIT: null,
  NETWORKS_AUTOCOMPLETE_SUCCESS: null,
  NETWORKS_AUTOCOMPLETE_FAIL: null,
  NETWORKS_AUTOCOMPLETE_COMPLETE: null,

  // Store Manipulation
  RULESET_STORE_ADD: null,
  RULESET_STORE_DELETE: null,
  VIRTUAL_SERVICE_STORE_ADD: null,
  VIRTUAL_SERVICE_STORE_DELETE: null,

  // Traffic Explorer
  LOAD_TRAFFIC_DATA: null,
  CLEAN_DATA: null,
  UPDATE_INFO: null,
  CLICK_TICK: null,
  CHOOSE_PC_LABEL: null,
  LOAD_FILES: null,
  LOAD_PATTERNS: null,
  SET_TRAFFIC_EXPLORER_FILTERS: null,
  INTERRUPT_DNS_LOOKUP: null,
  ADD_TRAFFIC_EXPLORER_APPGROUP_FILTER: null,
  REMOVE_TRAFFIC_EXPLORER_APPGROUP_FILTER: null,
  REMOVE_MULTI_TRAFFIC_EXPLORER_APPGROUP_FILTER: null,
  RESET_TRAFFIC_EXPLORER_APPGROUP_FILTER: null,
  ADD_FILTER_ON_HOVER_SELECTION: null,
  AND_OR_FILTER_TYPE: null,
  SET_DEFAULT_EXCLUSIONS: null,
  SET_EXPLORER_TYPE: null,
  CLEAR_NEW_EXPLORER_QUERY: null,

  // Ven
  VENS_AUTOCOMPLETE_SUCCESS: null,
  VEN_SOFTWARE_RELEASES_GET_COLLECTION_SUCCESS: null,
  VEN_COMPATIBILITY_REPORT_SUCCESS: null,

  // Instant Search
  OPEN_INSTANT_SEARCH: null,
  CLOSE_INSTANT_SEARCH: null,

  // Routes
  SET_ROUTES_MAP: null,

  // Org Settings
  SETTINGS_GET_INIT: null,
  SETTINGS_GET_SUCCESS: null,
  SETTINGS_GET_FAIL: null,
  SETTINGS_GET_COMPLETE: null,

  // Core Services
  SETTINGS_CORE_SERVICES_GET_SUCCESS: null,

  // what's new
  CLOSE_WHATS_NEW: null,

  ESSENTIAL_SERVICE_RULES: null,

  // Traffic DB Metrics
  TRAFFIC_FLOWS_DATABASE_METRICS_GET_INIT: null,
  TRAFFIC_FLOWS_DATABASE_METRICS_GET_SUCCESS: null,
});
