/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import React from 'react';
import cx from 'classnames';
import intl from 'intl';
import RestApiUtils from '../../utils/RestApiUtils';
import ServiceStore from '../../stores/ServiceStore';
import MapPageStore from '../../stores/MapPageStore';
import actionCreators from '../../actions/actionCreators';
import {GeneralStore} from '../../stores';
import {GridDataUtils, RenderUtils, ServiceUtils} from '../../utils';
import {Icon, Vulnerability, Spinner} from '..';

const MAX_SERVICES = 256;

export default React.createClass({
  getInitialState() {
    const sort = GeneralStore.getSorting('trafficPanel');

    return sort ? {sort} : {sort: {key: 'trafficWeight', direction: true}};
  },

  componentDidMount() {
    _.defer(() => {
      if (!ServiceStore.isLoaded()) {
        RestApiUtils.services.getCollection({max_results: 100_000});
      }
    });
  },

  handleSort(column) {
    const {key, direction} = this.state.sort;
    const sort = {key: column, direction: key === column ? !direction : true};

    actionCreators.updateGeneralSorting('trafficPanel', sort);

    this.setState({sort});
  },

  handleSelectService(formData) {
    actionCreators.clickActionItem({
      type: 'detailAction',
      formData,
    });
  },

  renderServices() {
    if (this.spinner) {
      return <Spinner size="twenty" />;
    }

    const vulnerabilityView = MapPageStore.getAppMapVersion() === 'vulnerability';
    const services = this.props.data.services.sort((a, b) => {
      const key = this.state.sort.key || 'trafficWeight';
      let value = b[key] > a[key] ? 1 : b[key] < a[key] ? -1 : 0;

      if (key === 'name') {
        value =
          b[key].toLocaleLowerCase() > a[key].toLocaleLowerCase()
            ? 1
            : b[key].toLocaleLowerCase() < a[key].toLocaleLowerCase()
            ? -1
            : 0;
      }

      if (key === 'trafficWeight' && MapPageStore.getAppMapVersion() === 'vulnerability') {
        if (a.vulnerabilities && b.vulnerabilities) {
          value = GridDataUtils.sortVulnerability(
            a.vulnerabilities.aggregatedValues,
            b.vulnerabilities.aggregatedValues,
          );
        } else if (!a.vulnerabilities && !b.vulnerabilities) {
          value = 0;
        } else if (!b.vulnerabilities) {
          value = 1;
        } else {
          value = -1;
        }
      } else if (key === 'trafficWeight') {
        value *= -1;
      }

      return this.state.sort.direction ? -1 * value : value;
    });

    return (
      services &&
      _.map(services.slice(0, MAX_SERVICES), (service, index) => {
        const serviceClasses = cx({
          'MapSubInfoPanel-Row': true,
          'MapSubInfoPanel-Row--Selected': service.isSelected,
          'MapSubInfoPanel-Row--Selectable': this.props.data.services.length > 1 && !service.isSelected,
          'MapSubInfoPanel-Row--NoHover': this.props.data.services.length <= 1,
        });
        const trafficBarClasses = cx({
          TrafficBar: !vulnerabilityView,
          TrafficBytes: vulnerabilityView,
          TrafficAllowed: service.trafficAllowed,
          trafficPotentiallyBlocked: service.trafficPotentiallyBlocked,
          TrafficBlocked: !service.trafficAllowed && !service.trafficUnknown && !service.trafficPotentiallyBlocked,
          TrafficUnknown: !service.trafficAllowed && service.trafficUnknown,
          ColorDeficiency: this.props.data.colorBlind !== 'normal',
        });
        const trafficTransmissionClasses = cx({
          'Transmission-Chevron-Padding': true,
          'TrafficTransmissionAllowed': service.trafficAllowed,
          'TrafficTransmissionPotentiallyBlocked': service.trafficPotentiallyBlocked,
          'TrafficTransmissionBlocked':
            !service.trafficAllowed && !service.trafficPotentiallyBlocked && !service.trafficUnknown,
          'TrafficTransmissionUnknown': service.trafficUnknown,
          'ColorDeficiency': this.props.data.colorBlind !== 'normal',
        });

        const trafficBarStyle = {width: service.trafficWeight};
        const clickService = _.partial(this.handleSelectService, service);
        let vulnerability;

        if (vulnerabilityView) {
          const values = service.vulnerabilities && service.vulnerabilities.aggregatedValues;

          vulnerability = service.vulnerabilities ? (
            <td className="MapSubInfoPanel-Row-TrafficVulnerability">
              {values && <Vulnerability vulnerability={{...values}} opacity />}
            </td>
          ) : (
            <td className="MapSubInfoPanel-Row-TrafficVulnerability" />
          );
        }

        return (
          <tr className={serviceClasses} key={index} onClick={clickService} data-tid="map-info-panel-row">
            <td
              className="MapSubInfoPanel-Row-Label MapSubInfoPanel-Row-Width"
              data-tid="map-info-panel-row-value-service"
            >
              <Icon
                styleClass={trafficTransmissionClasses}
                size={service.connectionClass === 'U' ? 'xsmall' : 'medium'}
                name={RenderUtils.getTransmissionModeIcon(service.connectionClass)}
              />
              <span className="MapSubInfoPanel-Row-Overflow">{service.name}</span>
            </td>
            <td className="MapSubTrafficPanel-Row-Value" data-tid="map-info-panel-row-value-port-protocol">
              <div>
                {ServiceUtils.getPort(service) || ''} {service.friendlyProtocol}
              </div>
            </td>
            {!vulnerabilityView ? (
              <td className="MapSubInfoPanel-Row-TrafficBar" data-tid="map-info-panel-row-value-traffic">
                <div className={trafficBarClasses} style={trafficBarStyle} />
              </td>
            ) : (
              vulnerability
            )}
            <td className="MapSubInfoPanel-Row-TrafficAction">{service.trafficAction}</td>
            <td data-tid="map-info-panel-row-view-service">
              <Icon styleClass="Chevron-Padding" size="xxlarge" name="next" />
            </td>
          </tr>
        );
      })
    );
  },

  render() {
    const {services} = this.props.data;

    const vulnerabilityView = MapPageStore.getAppMapVersion() === 'vulnerability';
    let serviceTitle;
    let serviceNum;

    // If there are more services than we are displaying, show the total number
    if (this.props.data.serviceNum > services.length || services.length > MAX_SERVICES) {
      serviceTitle = intl('Map.Traffic.TopServices');
      serviceNum = intl('Common.NumOfNum', {
        low: Math.min(services.length, MAX_SERVICES),
        high: this.props.data.serviceNum,
      });

      if (this.spinner) {
        serviceNum = this.props.data.serviceNum;
      }
    } else {
      serviceNum = intl.num(services.length);
      serviceTitle = intl('Map.Traffic.ServiceCount', {count: serviceNum});
    }

    const serviceLabel = (
      <div>
        {serviceNum} {serviceTitle}
      </div>
    );
    const {key, direction} = this.state.sort;
    const sort = this.props.data.services.length > 1;
    const nameSortClass = key === 'name' ? 'MapSubInfoPanel-Sort-On' : 'MapSubInfoPanel-Sort-Off';
    const portSortClass = key === 'port' ? 'MapSubInfoPanel-Sort-On' : 'MapSubInfoPanel-Sort-Off';
    const trafficSortClass = key === 'trafficWeight' ? 'MapSubInfoPanel-Sort-On' : 'MapSubInfoPanel-Sort-Off';
    let servicesRow;

    let servicesTitle = (
      <tr className="MapSubInfoPanel-Row MapSubInfoPanel-Header" data-tid="map-sub-info-panel-row">
        <td className="MapSubInfoPanel-Row-Label MapSubInfoPanel-Row-Width" data-tid="map-info-panel-row-label">
          <span
            className="MapSubInfoPanel-Row-Header"
            onClick={() => this.handleSort('name')}
            data-tid="map-info-panel-row-header-label"
          >
            <span className="MapSubInfoPanel-Row-Overflow">{serviceLabel}</span>
            {sort ? (
              <span className="MapSubInfoPanel-Sort" data-tid="map-sub-info-panel-row">
                <Icon
                  styleClass={`${nameSortClass}${key === 'name' && !direction ? ' MapSubInfoPanel-Sort-Min' : ''}`}
                  name="sort-up"
                  size="medium"
                />
                <Icon
                  styleClass={`${nameSortClass}${key === 'name' && direction ? ' MapSubInfoPanel-Sort-Min' : ''}`}
                  name="sort-down"
                  size="medium"
                />
              </span>
            ) : null}
          </span>
        </td>
        <td className="MapSubInfoPanel-Row-Header-Value" data-tid="map-info-panel-row-value-service">
          <span
            className="MapSubInfoPanel-Row-Header"
            onClick={() => this.handleSort('port')}
            data-tid="map-info-panel-row-header-port"
          >
            <span>{intl('Port.Port')}</span>
            {sort ? (
              <span className="MapSubInfoPanel-Sort" data-tid="map-sub-info-panel-row">
                <Icon
                  styleClass={`${portSortClass}${key === 'port' && !direction ? ' MapSubInfoPanel-Sort-Min' : ''}`}
                  name="sort-up"
                  size="medium"
                />
                <Icon
                  styleClass={`${portSortClass}${key === 'port' && direction ? ' MapSubInfoPanel-Sort-Min' : ''}`}
                  name="sort-down"
                  size="medium"
                />
              </span>
            ) : null}
          </span>
        </td>
        <td className="MapSubInfoPanel-Row-Header-TrafficBar" data-tid="map-info-panel-row-value-service">
          <span
            className="MapSubInfoPanel-Row-Header"
            onClick={() => this.handleSort('trafficWeight')}
            data-tid="map-info-panel-row-header-value"
          >
            {!vulnerabilityView ? <span>{intl('Common.Traffic')}</span> : <span>{intl('Common.Vulnerability')}</span>}
            {sort ? (
              <span className="MapSubInfoPanel-Sort" data-tid="map-sub-info-panel-row">
                <Icon
                  styleClass={`${trafficSortClass}${
                    key === 'trafficWeight' && !direction ? ' MapSubInfoPanel-Sort-Min' : ''
                  }`}
                  name="sort-up"
                  size="medium"
                />
                <Icon
                  styleClass={`${trafficSortClass}${
                    key === 'trafficWeight' && direction ? ' MapSubInfoPanel-Sort-Min' : ''
                  }`}
                  name="sort-down"
                  size="medium"
                />
              </span>
            ) : null}
          </span>
        </td>
      </tr>
    );

    if (!services.length) {
      servicesTitle = null;

      servicesRow = (
        <tr className="MapInfoPanel-Row">
          <td className="MapInfoPanel-Row-Message">{intl('Services.NoServices')}</td>
        </tr>
      );
    } else {
      servicesRow = (
        <tr className="MapInfoPanel-Row MapInfoPanel-Row--SetBorder">
          <td className="MapInfoPanel-Row-Value MapInfoPanel-Row-Value-Overflow">
            <div className="MapInfoPanel-Row--Scroll">
              <table className="MapSubInfoPanelWidth MapSubInfoPanel">
                <tbody>{this.renderServices()}</tbody>
              </table>
            </div>
          </td>
        </tr>
      );
    }

    return (
      <table className="MapInfoPanel">
        <tbody>
          {servicesTitle}
          {servicesRow}
        </tbody>
      </table>
    );
  },
});
